<template>
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.7 8.79999C17.1 7.59999 15.9 6.79999 14.6 6.79999C13.5 5.29999 11.7 4.29999 9.7 4.29999C6.6 4.29999 4.1 6.59999 3.7 9.59999C2.1 10.7 1 12.5 1 14.6C1 17.9 3.7 20.6 7 20.6C7.9 20.6 8.7 20.4 9.5 20.1C10.3 20.4 11.1 20.6 12 20.6C12.9 20.6 13.8 20.4 14.5 20C15.3 20.4 16.1 20.6 17 20.6C20.3 20.6 22.9 17.9 22.9 14.7C23 11.7 20.7 9.19999 17.7 8.79999ZM12.7 15.5C11 16.1 9.1 16.5 7.2 16.8C6.2 17 5.2 17.1 4.1 17.2C3.8 17.2 3.7 17.2 3.6 17C3.1 16.2 2.9 15.4 2.9 14.5C3 12.8 3.7 11.5 5.1 10.6C5.3 10.5 5.4 10.4 5.4 10.2C5.5 9.09999 5.9 8.09999 6.7 7.29999C6.8 7.19999 6.9 7.19999 7.1 7.29999C8.2 7.89999 9.5 8.49999 10.7 9.09999C12 9.69999 13.3 10.3 14.4 11.2C14.7 11.5 15.1 11.8 15.3 12.1C15.9 13 15.8 13.8 15 14.4C14.3 15 13.5 15.3 12.7 15.5ZM19 18.3C18.2 18.9 17.3 19.1 16.3 19.1C15.7 19.1 15.2 18.9 14.6 18.7C14.6 18.7 14.5 18.7 14.5 18.6C14.5 18.5 14.6 18.5 14.6 18.5C15 18.3 15.4 18.1 15.8 17.9C16.6 17.4 17.4 16.9 17.9 16.1C18.4 15.3 18.5 14.5 18 13.7C17.4 12.6 16.4 11.9 15.4 11.3C14.2 10.6 13 9.99999 11.7 9.39999C10.7 8.89999 9.78519 8.43647 8.78519 7.93648C7.96253 7.52515 7.8 7.35101 7.63263 7.18363C7.33263 6.88363 7.4 6.69999 7.8 6.49999C8.5 6.19999 9.2 5.89999 9.8 5.99999C11.6 5.99999 12.8 6.69999 13.8 8.09999C13.9 8.19999 14 8.29999 14.2 8.29999C15.3 8.29999 16.1 8.79999 16.5 9.79999C16.6 9.99999 16.6 9.99999 16.8 9.99999C17.9 10.1 18.9 10.4 19.7 11.2C20.6 12 21 13 21.1 14.1C21.2 15.9 20.5 17.3 19 18.3Z" fill="#ffb70b"/>
  </svg>
</template>

<script>
export default {
  name: 'Autologic'
}
</script>
