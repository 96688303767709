<template>
  <v-list
    flat
    class="px-3"
    nav>
    <v-list-item-group
      mandatory
      color="primary">
      <div
        v-for="(item, i) in items"
        :key="i">
        <v-list-item
          :to="item.pathName"
          v-if="userModules.findIndex(m => m.id === item.id) !== - 1"
          class="d-flex mb-5 pl-1">
          <v-list-item-icon class="align-self-center">
            <v-icon v-text="item.icon" class="item-left-icons"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.text" class="text-left"></v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon class="item-icon-arrow align-self-center">
            <v-icon v-text="item.arrow"></v-icon>
          </v-list-item-icon>
        </v-list-item>
      </div>
    </v-list-item-group>

    <v-list-group
      v-if="userModules.findIndex(m => m.id === 5) !== - 1 ||
        userModules.findIndex(m => m.id === 6) !== - 1">
      <template v-slot:activator>
        <v-list-item class="ml-0 mb-0 pl-1">
          <v-list-item-icon class="align-self-center">
            <v-icon class="item-left-icons pl-0">$carcog</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-left">Vehículos</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <v-list-item-group>
        <div
          v-for="([title, icon, pathname, id], i) in vehicles"
          :key="i">
          <v-list-item
            v-if="userModules.findIndex(m => m.id === id) !== - 1"
            :to="pathname"
            class="v-flex"
          >
            <v-list-item-content>
              <v-list-item-title v-text="title" class="text-left ml-13"></v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon class="align-self-center">
              <v-icon v-text="icon" class="item-icon-arrow"></v-icon>
            </v-list-item-icon>
          </v-list-item>
        </div>
      </v-list-item-group>
    </v-list-group>
  </v-list>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'MenuNavModules',
  components: {},
  data: () => ({
    vehicles: [
      ['Accesorios', '$arrow', '/accessories', 6],
      ['Vehículos', '$arrow', '/vehicles', 5],
      ['Catálogo', '$arrow', '/catalog', 7]
    ],
    items: [
      {
        text: 'Usuarios',
        icon: '$user',
        pathName: '/users',
        arrow: '$arrow',
        id: 1
      },
      {
        text: 'Ventas',
        icon: '$new',
        pathName: '/sales',
        arrow: '$arrow',
        id: 2
      },
      {
        text: 'Peritajes',
        icon: '$evalicon',
        pathName: '/evaluations',
        arrow: '$arrow',
        id: 4
      },
      {
        text: 'Plan de Ahorro',
        icon: '$savings',
        pathName: '/savings',
        arrow: '$arrow',
        id: 3
      },
      {
        text: 'Campañas',
        icon: '$newspaper',
        pathName: '/awarded',
        arrow: '$arrow',
        id: 's/d'
      }
    ]
  }),
  computed: {
    ...mapGetters(['userModules'])
  },
  methods: {
    activating (el) {
      this.items[el].isActive = true
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .v-list-item {
    .v-list-item__content {
      .v-list-item__title {
        @include fontStyle(bold, 14px, 0.01em, $grey);
      }
    }
    .v-list-item__icon {
      .item-left-icons {
        svg > * {
        fill: $grey !important;
        }
      }
    }
    &:hover {
      @include backgroundTransparency($white, 0.1);
      .v-list-item__content {
        .v-list-item__title {
          color: $white;
        }
      }
      .v-icon {
        svg > * {
          fill: $white !important;
        }
      }
    }
    &.v-item--active {
      @include backgroundTransparency($white, 0.15);
       .v-list-item__title {
        color: $colorPrimary !important;
      }
      .v-icon {
        svg > * {
          fill: $colorPrimary !important;
        }
      }

      .item-icon-arrow {
        border-radius: 3px;
        background-color: $colorPrimary;

        svg > * {
          fill: $black !important;
        }
      }
    }
  }
  .v-list-group__header {
    padding-left: 0px !important;
    .v-list-item__icon {
      i {
        color: $grey;
        &:hover {
          color: $white;
        }
        &:active {
          color: $colorPrimary;
        }
      }
    }
  }
}
</style>
