<template>
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22.61 19.49L13.53 10.41C14.46 8.07 13.98 5.31 12.09 3.41C9.79002 1.11 6.21002 0.900002 3.66002 2.76L7.50002 6.61L6.08002 8.02L2.25002 4.19C0.390022 6.73 0.600023 10.32 2.90002 12.61C4.76002 14.47 7.47002 14.96 9.79002 14.09L18.9 23.2C19.29 23.59 19.92 23.59 20.31 23.2L22.61 20.9C23.01 20.52 23.01 19.89 22.61 19.49ZM19.61 21.09L10.15 11.63C9.54002 12.08 8.86002 12.35 8.15002 12.45C6.79002 12.65 5.36002 12.24 4.32002 11.2C3.37002 10.26 2.93002 9 3.00002 7.76L6.09002 10.85L10.33 6.61L7.24002 3.52C8.48002 3.45 9.73002 3.89 10.68 4.83C11.76 5.91 12.17 7.4 11.92 8.79C11.8 9.5 11.5 10.16 11.04 10.75L20.49 20.2L19.61 21.09Z" fill="#ffb70b"/>
  </svg>
</template>

<script>
export default {
  name: 'Accessories'
}
</script>
