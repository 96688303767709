<template>
  <v-card-text
    class="pa-0 d-flex flex-column"
    style="height: 90%;min-height: 600px;"
  >
    <transition name="ques">
      <Questions
        ref="sq9q0"
        @goToNextQuestion="goToNextQuestion"
        :dataSeted="dataSeted"
        :isCurrency="true"
        :question="questionData.questions[0]"
        :screens_question="questionData.question"
        :sq_aclaration="questionData.aclaration"
        @sendAnswer="answerDuesAmount"
        @changeDataSeted="changeDataSeted"
      ></Questions>
    </transition>
    <transition name="ques">
      <Questions
        ref="sq9q1"
        @goToNextQuestion="goToNextQuestion"
        :dataSeted="dataSeted"
        v-show="currentQues === 1"
        :btnWidth="300"
        :question="questionData.questions[1]"
        :screens_question="null"
        :sq_aclaration="null"
        @selectOption="reportDifferenceInAmount"
      ></Questions>
    </transition>
    <transition name="ques">
      <NextQuestionButton
        v-if="showButton"
        @goToNextQuestion="goToNextQuestion"
      ></NextQuestionButton>
    </transition>
  </v-card-text>
</template>

<script>
import Questions from '@/components/savings/blocks/Questions'
import NextQuestionButton from '@/components/savings/blocks/NextQuestionButton'
import { mapGetters } from 'vuex'
import { answerData } from '@/mixins/answerData'

export default {
  name: 'SQuesNine',
  components: {
    Questions,
    NextQuestionButton
  },
  props: {
    questionData: Object
  },
  data () {
    return {
      showButton: false,
      alwaysShowButton: false,
      currentQues: 0,
      dataSeted: false,
      question0: '',
      decisionQuestionFirstValue: '',
      historyFirstValue: [],
      historyScrap: [],
      deleteData: [],
      sq_data: [
        {
          question_id: this.questionData.questions[0].id,
          ponderation: '',
          updated_at: ''
        },
        {
          question_id: this.questionData.questions[1].id,
          ponderation: '',
          updated_at: ''
        }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'getOperationNumber',
      'scoringData',
      'isBrowsingInScoringHistory',
      'continueScoring',
      'getIndexOfCurrentSQ',
      'getHistory',
      'SQNameByNumber',
      'sq_number'
    ])
  },
  methods: {
    setData (sqdata) {
      if (this.scoringData[sqdata] !== null) {
        this.dataSeted = true
        this.showButton = true
        this.alwaysShowButton = true
        this.currentQues = 1
        this.$emit('handleProgress', 65)
        this.$refs.sq9q0.setAnswer(
          this.$options.filters.magnitude(
            this.scoringData[sqdata][0].ponderation
          )
        )
        this.$refs.sq9q1.setAnswer(this.scoringData[sqdata][1].ponderation)
        for (let i = 0; i < 2; i++) {
          this.sq_data[i].ponderation = this.scoringData[sqdata][i].ponderation
          this.sq_data[i].updated_at = this.scoringData[sqdata][i].updated_at
          if (this.continueScoring) {
            if (this.scoringData[sqdata][0].id !== undefined) {
              this.sq_data[i].id = this.scoringData[sqdata][i].id
            }
          }
        }
        if (this.isBrowsingInScoringHistory) {
          this.question0 = this.scoringData[sqdata][0].ponderation
          this.decisionQuestionFirstValue = this.scoringData[
            sqdata
          ][1].ponderation
          this.historyFirstValue = this.getHistory
        }
      }
    },
    answerDuesAmount (value) {
      this.dataFromQuestions(value, 0)
    },
    reportDifferenceInAmount (value) {
      this.dataFromQuestions(value, 1)
    },
    goToNextQuestion () {
      this.handleIfEditQuesInSQWithoutCurrentQues(0, this.question0)
      if (this.isBrowsingInScoringHistory && this.currentQues === 1) {
        this.$emit('reduceDecreasing', 1)
        if (this.decisionQuestionFirstValue !== this.sq_data[1].ponderation) {
          this.$emit('reduceDecreasing', 0)
          this.handleIfEditDecisionQues(1, this.decisionQuestionFirstValue)
          this.historyScrap = this.historyFirstValue.splice(
            this.getIndexOfCurrentSQ + 1
          )
          this.historyScrap.forEach(e => {
            if (e !== 2 && e !== 32 && e !== 41) {
              if (this.scoringData[this.SQNameByNumber[e]] !== null) {
                if (
                  this.scoringData[this.SQNameByNumber[e]][0].id === undefined
                ) {
                  this.$store.dispatch('handleResetScoringData', e)
                } else {
                  this.scoringData[this.SQNameByNumber[e]].forEach(q => {
                    this.deleteData.push(q.id)
                  })
                  this.$store.dispatch('handleResetScoringData', e)
                }
              }
            }
          })
          this.$emit('deleteQuestion', this.deleteData)
          this.$store.dispatch('handleHistoryData', this.historyFirstValue)
          this.$store.dispatch('handleIfIsBrowsingInScoringHistory', false)
          this.$store.dispatch('handleHistoryIndexOfCurrentSQ', null)
        }
      }
      if (this.currentQues === 0) {
        this.dataSeted = true
        this.$refs.sq9q0.setFilter('magnitude')
        this.currentQues = 1
        this.$emit('handleProgress', 65)
        this.dataSeted = false
        if (this.alwaysShowButton) {
          this.showButton = true
        } else {
          this.showButton = false
        }
      } else if (this.currentQues === 1) {
        this.sq_data[0].ponderation = this.$options.filters.number(
          this.sq_data[0].ponderation
        )
        this.$store.dispatch('handleScoringData', this.sq_data)
        if (
          this.sq_data[1].ponderation ===
          this.questionData.questions[1].options[0]
        ) {
          this.$emit('handleProgress', 66)
          if (this.isBrowsingInScoringHistory) {
            this.$store.dispatch('handleOnlyCurrentSQ', 10)
          } else {
            this.$store.dispatch('handleCurrentSQ', 10)
          }
        } else {
          if (this.isBrowsingInScoringHistory) {
            this.$store.dispatch('handleOnlyCurrentSQ', 24)
          } else {
            this.$store.dispatch('handleCurrentSQ', 24)
          }
        }
        this.$emit('checkIfHistoryModeContinues')
      }
    }
  },
  mixins: [answerData],
  mounted () {
    this.setData('sq_nineData')
  }
}
</script>

<style lang="scss" scoped>
.ques-enter-active {
  transition: opacity 0.5s;
}
.ques-enter {
  opacity: 0;
}
</style>
