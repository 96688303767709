<template>
  <v-toolbar
    flat
    class="pb-3 ml-6"
    :class="IndicatorsRow ? 'mt-16 pt-3 mb-12' : 'my-3'">
    <v-container fluid>
      <v-row class="d-flex">
        <v-col cols="12"
          sm="6"
          class="d-flex align-center"
          :class="$vuetify.breakpoint.xs ? 'justify-space-between' : 'justify-start'">
          <v-toolbar-title class="font-weight-black tittle__font-size">{{ title }}</v-toolbar-title>
          <v-dialog @click:outside="$emit('clickOutside')" v-model="controlOpenAddOrUpdate" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :class="$vuetify.breakpoint.xs ? 'mx-2' : 'mx-7'"
                class="rounded btn__custom black--text font-weight-medium"
                elevation="0"
                width="129"
                height="44"
                color="rgba(245, 245, 247, 0.5)"
                v-bind="attrs"
                v-on="on"
                v-if="btnAdd"
                >{{ btnAdd.title }}</v-btn>
            </template>
            <slot name="addOrEditItem"></slot>
            <slot name="AddItemModal"></slot>
          </v-dialog>
          <v-dialog @click:outside="$emit('clickOutside')" v-model="controlOpenPrintSale" max-width="500px">
            <slot name="PrintItemModal"></slot>
          </v-dialog>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12"
          sm="6"
          class="d-flex justify-end align-center d-xs-block">
          <slot name="calendarButton"></slot>
          <slot name="toolbarFilterButton"></slot>
          <slot name="paginationTable"
                :pagination="pagination"
                :options="options"
                :updateOptions="updateOptions"></slot>
        </v-col>
      </v-row>
      <v-row class="mt-7 mb-14" v-if="IndicatorsRow">
        <slot name="pendingCallIndicator"></slot>
        <slot name="postponedCallIndicator"></slot>
      </v-row>
    </v-container>
  </v-toolbar>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'TableToolbar',
  props: {
    title: String,
    btnAdd: {
      type: Object
    },
    openAddOrUpdateDialog: Boolean,
    showPrintSaleModal: Boolean,
    options: Object,
    pagination: Object,
    updateOptions: Function
  },
  computed: {
    ...mapGetters(['IndicatorsRow']),
    controlOpenAddOrUpdate: {
      get () {
        return this.openAddOrUpdateDialog
      },
      set (value) {
        this.$emit('handleAddOrUpdate', value)
      }
    },
    controlOpenPrintSale: {
      get () {
        return this.showPrintSaleModal
      },
      set (value) {
        this.$emit('handlePrintSaleModal', value)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .tittle__font-size {
    font-size: 22px;
  }
  .btn__custom {
    @include fontStyle(normal, 12px, 0.01em, $black);
    border: 1px solid $black !important;
  }
  @media (max-width: 335px) {
    .v-data-footer__pagination {
      display: none;
    }
  }
}
</style>
