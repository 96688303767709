<template>
<div class="movil">
  <div class="d-flex rounded">
    <div
      class="d-flex align-center justify-center"
      style="width: 60px; height: 80px">
      <v-icon>$info</v-icon>
    </div>
    <div class="d-flex flex-column justify-center">
      <p
        class="text-start mb-1"
        style="font-weight: bold;font-size: 14px;letter-spacing: 0.01em;color: #9D9D9D;"
        >Información
      </p>
      <p
        class="text-start mb-0"
        style="font-weight: 200;font-size: 13px;letter-spacing: 0.01em;color: #000000;"
        >Agregá todas las partes del vehiculo, saca buenas imagenes y escribi lo mas detallado posible todas las observaciones que sean necesarias.
      </p>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'Info'
}
</script>

<style lang="scss" scoped>
.movil {
  width: 100%;
  min-height: 80px;
  background-color: #F5F5F7
}
@media (max-width: 800px) {
  .movil {
    display: none;
  }
}
</style>
