export const dateFormatMixin = {
  methods: {
    dateOfOperation () {
      const date = new Date()
      const options = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        timeZone: 'America/Argentina/Buenos_Aires'
      }
      const dateWithFormat = new Intl.DateTimeFormat('es-AR', options)
      const dissectionDate = dateWithFormat.formatToParts(date)
      this.improvingDateFormat(dissectionDate)
      const dateOfOperation = dissectionDate.map(this.mapingDissectionDate).join('')
      return this.$store.dispatch('handleSaleDate', dateOfOperation)
    },
    improvingDateFormat (element) {
      for (let i = 0; i < element.length; i++) {
        if (element[i].value === '/') {
          element[i].value = '-'
        }
      }
      return element
    },
    mapingDissectionDate (element) {
      const onlyValue = element.value
      return onlyValue
    }
  }
}
