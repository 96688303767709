<template>
    <v-container fluid class="mt-8" style="margin: 0 auto">
        <v-row class="d-flex justify-space-between ma-0">
            <v-col cols="8" class="d-flex align-center">
                <v-btn
                    elevation="0"
                    fab
                    color="transparent"
                    class="mr-4"
                    @click.stop="exitForm(true)">
                    <v-icon>$exitform</v-icon>
                </v-btn>
                <span v-if="application_number === 0 || application_number === null"></span>
                <span v-else class="number-font mr-9">Nº {{ '00000' + application_number }}</span>
                <TableMarkStatus v-if="edit && status" :text="status.name" :color="status.color" class="mr-8"></TableMarkStatus>
                <div class="d-flex flex-column align-start mr-6">
                    <span class="date-title-font">Fecha</span>
                    <span> {{ saleDate }} </span>
                </div>
                <div class="text-field-width" :class="getCurrentStep === 1 ? 'align-self-end' : 'align-self-center'">
                  <v-form>
                    <v-text-field
                      v-if="getCurrentStep === 1"
                      label="Nº Operacion"
                      v-model="operation_number_model"
                    >
                    </v-text-field>
                  </v-form>
                  <div v-if="getCurrentStep > 1" class="d-flex flex-column align-start">
                    <span class="operation-number-font">Nº Operacion</span>
                    <span>{{ operation_number }}</span>
                  </div>
                </div>
            </v-col>
            <v-col cols="4" class="d-flex justify-end align-center">
                <v-btn
                  v-if="getCurrentStep < 4"
                  elevation="0"
                  class="mr-3 btn-next__custom"
                  @click="doNextSaleStep"
                >SIGUIENTE</v-btn>
                <v-btn
                    elevation="0"
                    :disabled="getCurrentStep < 4"
                    class="mr-9 my-4 btn-send__custom"
                    @click="saveSaleApplication"
                    >APROBAR SOLICITUD</v-btn>
            </v-col>
        </v-row>
        <v-dialog v-model="alertExitForm" max-width="600px">
          <AlertExitForm
            :actionShowForm="'handleCreateSale'"
            @confirmExit="acceptExitForm"
          >
          </AlertExitForm>
        </v-dialog>
    </v-container>
</template>

<script>
import AlertExitForm from '@/components/layout/alerts/AlertExitForm'
import TableMarkStatus from '@/components/tables/TableMarkStatus'
import { mapActions, mapGetters, mapState } from 'vuex'
import DateHelper from '../../helpers/DateHelper'
export default {
  name: 'CreateOrEditSaleHeader',
  props: {
    application_number: {
      type: Number,
      default: 0
    },
    operation_number: {
      type: [Number, String],
      default: ''
    },
    editedIndex: Number,
    status: Object,
    edit: Boolean,
    saleSelected: Object,
    valiadationsRules: Object
  },
  components: {
    AlertExitForm,
    TableMarkStatus
  },
  computed: {
    ...mapGetters(['newSale', 'getCurrentStep', 'alertExitForm', 'currentSale']),
    ...mapState(['currentStep']),
    operation_number_model: {
      get () {
        return this.operation_number
      },
      set (value) {
        this.$emit('update:operation_number', value)
      }
    },
    saleDate () {
      return this.edit ? DateHelper.transformDateToFormat(this.saleSelected.sale.created_at, 'DD-MM-YYYY') : this.newSale.date
    }
  },
  methods: {
    ...mapActions(['handleNextStep', 'handlePreviousStep', 'handleCurrentStep']),
    doNextSaleStep () {
      // tiene sentido el condicional?
      this.$emit('doNextSaleStep', this.getCurrentStep < 4 ? (this.getCurrentStep + 1) : this.currentStep)
      // this.$emit('doNextSaleStep', (this.getCurrentStep + 1))
    },
    exitForm (val) {
      this.alertExit = val
      this.$store.dispatch('handleExitForm', val)
      // this.$emit('exitForm')
    },
    acceptExitForm () {
      this.alertExit = false
      this.$store.dispatch('handleCreateSale', false)
      this.$emit('exitForm')
      this.$emit('close')
    },
    async saveSaleApplication () {
      this.$emit('saveSaleApplication')
    }
  }
}

</script>

<style lang="scss" scoped>
.number-font {
  @include fontStyle(bold, 22px, 0.01em, $black);
}
.date-title-font {
  @include fontStyle(normal, 12px, 0.01em, $grey);
    line-height: 12px;
}
.date-font {
  @include fontStyle(200, 14px, 0.01em, $black);
}
.operation-number-font {
  @include fontStyle(normal, 12px, 0.01em, $grey);
  line-height: 12px;
}
.text-field-width {
    max-width: 140px;
}
::v-deep {
    .v-messages, .v-text-field__details {
      min-height: 4px !important;
      max-height: 16px;
    }
    .btn-draftcopy__custom {
        @include fontStyle(bold, 10px, 0.01em, $grey);
    }
    .btn-next__custom {
        @include fontStyle(normal, 10px, 0.01em, $black);
        border: 1px solid $black !important;
    }
    .btn-send__custom {
        font-size: 10px;
        letter-spacing: 0.01em;
        border: 1px solid $black !important;
    }
    input::placeholder {
        @include fontStyle(normal, 12px, 0.01em, $grey);
        padding-left: 10px;
    }
}
</style>
