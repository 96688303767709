<template>
  <v-card outlined>
    <v-card-title class="mx-1 pt-6 pb-4">
      <span class="font-weight-bold">{{ formTitle }}</span>
    </v-card-title>
    <hr class="hr-color">
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-radio-group v-model="selectedSaleTypeRadio">
               <div
                :class="selectedSaleTypeRadio === '1' ? 'borderactive' : 'bordernormal'"
                @click.stop="assignRadioValue('1')"
                class="d-flex align-center mx-7 mb-7 pa-5 rounded div-hover"
                >
                 <img
                   src="../../assets/icons/renault-new.svg"
                   alt="Logo Renault reserva convencional"
                   class="ml-3">
                 <p class="mb-0 ml-10 font-style">Reserva convencional</p>
                 <v-spacer></v-spacer>
                 <v-radio value="1" color="secondary"></v-radio>
               </div>
               <div
                :class="selectedSaleTypeRadio === '2' ? 'borderactive' : 'bordernormal'"
                @click.stop="assignRadioValue('2')"
                class="d-flex align-center mx-7 mb-7 pa-5 rounded div-hover"
                >
                 <img src="../../assets/icons/renault-old.svg" alt="Logo Renault Reserva vehículo usado">
                 <p class="mb-0 ml-8 font-style">Reserva vehículo usado</p>
                 <v-spacer></v-spacer>
                 <v-radio value="2" color="secondary"></v-radio>
               </div>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-container>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="grey"
        text
        @click="close"
      >
        Cancelar
      </v-btn>
      <v-btn
        class="bor-btn rounded mx-9 mt-2 mb-3 black--text"
        width="129"
        height="44"
        elevation="0"
        @click="start"
      >
        Comenzar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { dateFormatMixin } from '@/mixins/dateFormatMixin'

export default {
  name: 'AddItemModal',
  data () {
    return {
      formTitle: 'Tipo de reserva'
    }
  },
  props: {
    editedItem: {
      type: Object,
      required: true
    },
    typeSale: String
  },
  computed: {
    selectedSaleTypeRadio: {
      get () {
        return this.typeSale
      },
      set (value) {
        this.$emit('update:typeSale', value)
      }
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    start () {
      this.$emit('start', this.editedItem, this.selectedSaleTypeRadio)
      this.dateOfOperation()
      this.$emit('close')
      this.$store.dispatch('handleCurrentStep', 1)
    },
    assignRadioValue (val) {
      this.selectedSaleTypeRadio = val
    }
  },
  mixins: [dateFormatMixin]
}
</script>

<style lang="scss" scoped>
.borderactive {
  border: 2px solid $black !important;
  background-color: #F5F5F5;
}
.bordernormal {
  border: 2px solid #F5F5F5;
  background-color: #F5F5F5;
}
.hr-color {
  border-bottom: 0.5px solid rgb(240, 238, 238);
}
.font-style {
   @include fontStyle(normal, 22px, 0.01em, $black)
 }
 div .div-hover {
   &:hover {
    cursor: pointer;
   }
 }
::v-deep {
  .v-card__title {
    span {
      font-size: 22px;
      letter-spacing: 0.01em;
    }
  }
  .v-btn {
    font-size: 12px;
    letter-spacing: 0.01em;
  }
  .v-input__slot > div {
      border-bottom: 1px solid rgb(206, 206, 206);
  }
  .bor-btn {
    border: 1px solid $black !important;
  }
}
</style>
