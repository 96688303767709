<template>
  <v-card
    elevation="0"
    class="rounded"
    color="#F5F5F7">
    <v-card-title>
      <h2
        style="font-size: 14px;letter-spacing: 0.01em;"
        class="text-no-wrap text-truncate">DATOS DEL CLIENTE</h2>
    </v-card-title>
    <v-card-text class="px-3">
      <v-col>
        <v-text-field
          dense
          :disabled="editEvaluation || makeEvaluation"
          v-model="client_data.dni"
          :rules="[valiadationsRules.required]"
          @focus="setGlobalFilter('client', 'dni', 'number')"
          @change="getClientByDNI(1)"
          label="DNI"
          placeholder="DNI">
        </v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          dense
          :disabled="editEvaluation || makeEvaluation"
          v-model="client_data.firstname"
          :rules="[valiadationsRules.required]"
          @change="setGlobalFilter('client', 'firstname', 'capitalize')"
          label="Nombre"
          placeholder="Nombre">
        </v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          dense
          :disabled="editEvaluation || makeEvaluation"
          v-model="client_data.surname"
          :rules="[valiadationsRules.required]"
          @change="setGlobalFilter('client', 'surname', 'capitalize')"
          label="Apellido"
          placeholder="Apellido">
        </v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          dense
          :disabled="editEvaluation || makeEvaluation"
          v-model="client_data.email"
          :rules="[valiadationsRules.required]"
          label="Correo"
          placeholder="Correo">
        </v-text-field>
      </v-col>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import { getByDNI } from '@/services/clients'
import AuthHelper from '@/helpers/AuthHelper'

export default {
  name: 'ClientData',
  props: {
    client_data: Object,
    valiadationsRules: Object
  },
  computed: {
    ...mapGetters(['editEvaluation', 'makeEvaluation']),
    ...mapState(['userToken'])
  },
  methods: {
    ...mapActions(['handleLoading', 'handleAlert']),
    async getClientByDNI (type) {
      const dni = this.client_data.dni
      try {
        this.handleLoading(true)
        const res = await getByDNI(this.userToken, type, dni)
        this.$emit('setDataInFields', res.data.data)
        this.alertMessage = res.data.message
        this.alertColor = 'success'
      } catch (error) {
        this.$emit('resetClientId')
        AuthHelper.checkErrorExpiredToken(error)
        this.alertMessage = error?.data?.message
        this.alertColor = 'error'
      } finally {
        this.handleLoading(false)
        this.handleAlert({
          value: true,
          text: this.alertMessage,
          color: this.alertColor
        })
      }
    },
    setGlobalFilter (form, prop, filter) {
      this.$emit('setGlobalFilter', form, prop, filter)
    }
  }
}
</script>
