<template>
  <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.12 2.84375L13.95 4.84375H18V16.8438H2V4.84375H6.05L7.88 2.84375H12.12ZM13 0.84375H7L5.17 2.84375H2C0.9 2.84375 0 3.74375 0 4.84375V16.8438C0 17.9438 0.9 18.8438 2 18.8438H18C19.1 18.8438 20 17.9438 20 16.8438V4.84375C20 3.74375 19.1 2.84375 18 2.84375H14.83L13 0.84375ZM10 7.84375C11.65 7.84375 13 9.19375 13 10.8438C13 12.4937 11.65 13.8438 10 13.8438C8.35 13.8438 7 12.4937 7 10.8438C7 9.19375 8.35 7.84375 10 7.84375ZM10 5.84375C7.24 5.84375 5 8.08375 5 10.8438C5 13.6038 7.24 15.8438 10 15.8438C12.76 15.8438 15 13.6038 15 10.8438C15 8.08375 12.76 5.84375 10 5.84375Z" fill="#9D9D9D"/>
  </svg>
</template>

<script>
export default {
  name: 'Camera'
}
</script>
