<template>
  <v-card color="grey lighten-4" elevation="0" class="pa-6 my-4 mx-4 d-flex flex-column justify-start">
    <div class="d-flex flex-col justify-space-between px-0">
      <span>
        <span class="text-uppercase text-no-wrap font-weight-bold align-self-start pb-4 align-flex-start">Vehiculo usado</span>
        <span v-if="this.edit && this.data.vehicle_status && Object.prototype.hasOwnProperty.call(this.data.vehicle_status, 'id') && this.data.vehicle_status.id !== 1"
          class="ml-3 align-self-start pb-4 align-flex-start green--text"
        >({{this.data.vehicle_status.name}} - ${{this.data.buying_value}})</span>
        <span v-else-if="this.data.vehicle_status &&
          Object.prototype.hasOwnProperty.call(this.data.vehicle_status, 'name')"
          class="ml-3 align-self-start pb-4 align-flex-start red--text"
          >({{this.data.vehicle_status.name}})</span>
        <span v-else-if="
          this.usedVehicleComputed.brand !== '' &&
          this.usedVehicleComputed.vehicle_model_id !== '' &&
          this.usedVehicleComputed.car_year !== '' &&
          this.usedVehicleComputed.fuel_type !== '' &&
          this.usedVehicleComputed.doors !== '' &&
          this.usedVehicleComputed.color !== '' &&
          this.usedVehicleComputed.domain !== '' &&
          this.usedVehicleComputed.transmition !== ''
          " class="ml-3 align-self-start pb-4 align-flex-start red--text">(Pendiente de Peritaje)</span>
        </span>
      <span>
        <v-btn
          :disabled="userDb.role.id === 4"
          v-if="index === 0"
          icon
          elevation="0"
          small
          @click.stop="addUsedCarCard()"
          >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <v-btn
          :disabled="userDb.role.id === 4"
          v-if="index !== 0"
          icon
          elevation="0"
          small
          @click.stop="removeUsedCarCard()"
          >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-btn
          :disabled="userDb.role.id === 4"
          v-if="index === 0"
          icon
          elevation="0"
          small
          @click.stop="clearUsedCarCardData()"
          >
          <v-icon>mdi-trash-can</v-icon>
        </v-btn>
      </span>
    </div>
    <div class="d-flex flex-column">
      <v-row class="mt-0">
        <v-col>
          <v-select
            :disabled="userDb.role.id === 4"
            dense
            label="Marca"
            placeholder="Marca"
            :items="brandsItems"
            v-model="usedVehicleComputed.brand"
            class="pr-6"
          />
        </v-col>
        <v-col>
          <v-select
            :disabled="userDb.role.id === 4"
            dense
            label="Modelo"
            placeholder="Modelo"
            :items="modelsItems"
            v-model="usedVehicleComputed.vehicle_model_id"
            class="pr-6"
          />
        </v-col>
        <v-col>
          <v-text-field
            :disabled="userDb.role.id === 4"
            dense
            label="Dominio"
            placeholder="Dominio"
            v-model="usedVehicleComputed.domain"
          />
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col cols="4">
          <v-select
            :disabled="userDb.role.id === 4"
            dense
            label="Año"
            placeholder="Año"
            :items="VehicleHelper.getYearsOptions()"
            v-model="usedVehicleComputed.car_year"
            class="pr-6"
          />
        </v-col>
        <v-col cols="4">
          <v-select
            :disabled="userDb.role.id === 4"
            dense
            label="Transmisión"
            placeholder="Transmisión"
            :items="transmitionItems"
            v-model="usedVehicleComputed.transmition"
            class="pr-6"
          />
        </v-col>
        <v-col cols="4">
          <v-text-field
            :disabled="userDb.role.id === 4"
            dense
            label="Precio aproximado"
            placeholder="Precio aproximado"
            v-model="usedVehicleComputed.estimated_price"
          />
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col cols="4">
          <v-select
            :disabled="userDb.role.id === 4"
            dense
            label="Combustible"
            placeholder="Combustible"
            :items="fuelItems"
            v-model="usedVehicleComputed.fuel_type"
            class="pr-6"
          />
        </v-col>
        <v-col cols="4">
          <v-select
            :disabled="userDb.role.id === 4"
            dense
            label="Puertas"
            placeholder="Puertas"
            :items="VehicleHelper.getDoorsOptions()"
            v-model="usedVehicleComputed.doors"
            class="pr-6"
          />
        </v-col>
        <v-col cols="4">
          <v-select
            :disabled="userDb.role.id === 4"
            dense
            label="Color"
            placeholder="Color"
            :items="colorItems"
            v-model="usedVehicleComputed.color"
          >
          <template v-slot:item="{ item, attrs, on }">
            <v-list-item v-on="on" v-bind="attrs">
              <v-list-item-avatar
                :color="item.code"
                :size="24"
              />
              <v-list-item-title>
                {{item.text}}
              </v-list-item-title>
            </v-list-item>
          </template>
          </v-select>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>

import VehicleHelper from '../../../helpers/VehicleHelper'
import Helper from '../../../helpers/Helper'

export default {
  name: 'UsedVehicleCard',
  props: {
    userDb: Object,
    data: Object,
    completeData: Array,
    valiadationsRules: Object,
    brandsItems: Array,
    transmitionItems: Array,
    fuelItems: Array,
    colorItems: Array,
    brands: Array,
    edit: Boolean,
    index: Number
  },
  data () {
    return {
      VehicleHelper,
      colors: [],
      modelsItems: []
    }
  },
  methods: {
    addUsedCarCard () {
      this.$emit('addUsedCarCard')
    },
    removeUsedCarCard () {
      this.$emit('removeUsedCarCard', this.index)
      this.usedVehicleComputed.brand = ''
      this.usedVehicleComputed.vehicle_model_id = ''
      this.usedVehicleComputed.car_year = ''
      this.usedVehicleComputed.fuel_type = ''
      this.usedVehicleComputed.doors = ''
      this.usedVehicleComputed.color = ''
      this.usedVehicleComputed.domain = ''
      this.usedVehicleComputed.transmition = ''
      this.usedVehicleComputed.buying_value = null
      this.usedVehicleComputed.vehicle_status = ''
      this.usedVehicleComputed.estimated_price = ''
    },
    clearUsedCarCardData () {
      if (this.completeData.length === 1) {
        this.$emit('clearUsedCarCardData')
        this.usedVehicleComputed.brand = ''
        this.usedVehicleComputed.vehicle_model_id = ''
        this.usedVehicleComputed.car_year = ''
        this.usedVehicleComputed.fuel_type = ''
        this.usedVehicleComputed.doors = ''
        this.usedVehicleComputed.color = ''
        this.usedVehicleComputed.domain = ''
        this.usedVehicleComputed.transmition = ''
        this.usedVehicleComputed.buying_value = null
        this.usedVehicleComputed.vehicle_status = ''
        this.usedVehicleComputed.estimated_price = ''
      } else if (this.completeData.length > 1) {
        this.$emit('removeUsedCarCard', this.index)
      }
    },
    selectBrand () {
      const brand = Helper.getItemFromArray(this.usedVehicleComputed.brand, this.brands)
      if (brand !== null) {
        this.setModelsByBrand(brand)
      }
    },
    setModelsByBrand (brand) {
      this.modelsItems = Helper.transformToSelect(brand.vehicles_models, 'model')
    }
  },
  computed: {
    usedVehicleComputed: {
      get () {
        return this.data
      },
      set (value) {
        this.$emit('update:used_vehicle', value)
      }
    }
  },
  watch: {
    'usedVehicleComputed.brand': {
      deep: true,
      handler () {
        this.selectBrand()
      }
    },
    brands: {
      deep: true,
      handler () {
        this.selectBrand()
      }
    }
  },
  mounted () {
    if (this.edit) {
      this.selectBrand()
      if (this.edit && this.data.vehicle_status && Object.prototype.hasOwnProperty.call(this.data.vehicle_status, 'id') && this.data.vehicle_status.id !== 1) {
        // this.usedVehicleComputed.buying_value = 1000
      }
    }
  }
}
</script>
