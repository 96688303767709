<template>
  <div class="align-self-start d-flex flex-column align-start files-width">
    <div class="d-flex flex-column files-width mb-2 text-start" v-for="(type, index) in types" :key="index">
      <div v-if="(index === 0 || clientType === 1) && type.id !== 5 || type.id === 5 && userDb.role.id === 4">
      <h3 class="mb-1 text-start">{{ type.name }}</h3>
          <attachment-list
            v-for="(sub, index) in type.attachments_subtype" :key="index"
            :subtypeId="sub.id"
            :files="files"
            :title="sub.name"
          >
          <!-- <input v-if="type.id === 5 && userDb.role.id === 4" type="file" name="" ref="inputFile" id="input-file" @change="getFiles(type.id, sub.id)" hidden accept=".pdf">
          <input v-else type="file" name="" ref="inputFile" id="input-file" @change="getFiles(type.id, sub.id)" hidden accept=".jpg, .jpeg, .png, .gif, .pdf, .doc, .docx"> -->
          <input type="file" name="" ref="inputFile" id="input-file" @change="getFiles(type.id, sub.id)" hidden accept=".jpg, .jpeg, .png, .gif, .pdf, .doc, .docx">
          <span v-if="userDb.role.id === 4">
            <span v-if="!files.find(f => f.subtype === sub.id) && sub.id === 7 && credit !== ''" class="attach-link" @click.stop="selectFiles(sub.id - 1)">Adjuntar</span>
            <span v-else class="d-flex flex-grow-1 justify-space-between">
              <span class="text-start" v-if="files.find(f => f.subtype === sub.id)">
                <a v-if="files[files.findIndex(f => f.subtype === sub.id)].path" :href="files[files.findIndex(f => f.subtype === sub.id)].path" target="__blanc">
                  {{files[files.findIndex(f => f.subtype === sub.id)].name}}
                </a>
                <span v-else>{{files[files.findIndex(f => f.subtype === sub.id)].name}}</span>
              </span>
              <span v-if="files.find(f => f.subtype === sub.id)">
                  <v-btn
                    :disabled="userDb.role.id === 4 && sub.id !== 7"
                    icon
                    elevation=0
                    x-small
                    @click.prevent="openAlertDelete(files[files.findIndex(f => f.subtype === sub.id)])"
                  ><v-icon>mdi-close-circle-outline</v-icon>
                  </v-btn>
              </span>
            </span>
          </span>
          <span v-else>
            <span v-if="!files.find(f => f.subtype === sub.id)" class="attach-link" @click.stop="selectFiles(sub.id - 1)">Adjuntar</span>
            <span v-else class="d-flex flex-grow-1 justify-space-between text-start">
              <span>
                <a v-if="files[files.findIndex(f => f.subtype === sub.id)].path" :href="files[files.findIndex(f => f.subtype === sub.id)].path" target="__blanc">
                  {{files[files.findIndex(f => f.subtype === sub.id)].name}}
                </a>
                <span v-else>{{files[files.findIndex(f => f.subtype === sub.id)].name}}</span>
              </span>
              <span>
                  <v-btn
                    icon
                    elevation=0
                    x-small
                    @click.prevent="openAlertDelete(files[files.findIndex(f => f.subtype === sub.id)])"
                  ><v-icon>mdi-close-circle-outline</v-icon>
                  </v-btn>
              </span>
            </span>
          </span>
          </attachment-list>
      </div>
    </div>
  <DocumentOwnershipModal
    :files="files"
    :items="items"
    @addFiles="addFiles"
    @cancelFiles="cancelFiles"
  />
  <v-dialog
    v-model="deleteFileModal"
    width="500"
  >
    <v-card>
      <v-card-title></v-card-title>
      <v-card-text>
        <h2>¿Seguro que desea borrar el archivo <span class="font-weight-bold">{{ fileToDelete }}</span>?</h2>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          @click="closeAlertDelete"
          elevation="0"
          class="mr-2"
          color="blue darken-1"
          text
        >Cancelar
        </v-btn>
        <v-btn
          @click="deleteFileSelected"
          elevation="0"
          class="mr-2"
          color="blue darken-1"
          text
        >Aceptar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <AppAlert></AppAlert>
</div>
</template>

<script>
import AppAlert from '@/components/layout/toast/AppAlert'
import DocumentOwnershipModal from '@/components/forms/DocumentOwnershipModal'
import AttachmentList from '@/components/forms/AttachmentList'
import { mapActions } from 'vuex'

export default {
  name: 'FileInput',
  components: {
    DocumentOwnershipModal,
    AppAlert,
    AttachmentList
  },
  props: {
    userDb: Object,
    credit: String,
    title: String,
    sub_title: String,
    items: Array,
    types: Array,
    filesFromDb: [Array, Object],
    edit: Boolean,
    clientType: Number
  },
  data () {
    return {
      files: [],
      auxFiles: [],
      active: false,
      maxSize: 10000000,
      alertMessage: '',
      deleteFileModal: false,
      fileToDelete: '',
      orderFileToDelete: 0,
      index: ''
    }
  },
  methods: {
    ...mapActions(['handleAlert', 'handleSetSaleDataFiles']),
    addFiles () {
      this.handleSetSaleDataFiles(this.files)
      this.$refs.inputFile.value = null
    },
    cancelFiles () {
      this.auxFiles.forEach(fIndex => {
        this.files.splice(fIndex, 1)
      })
      this.$refs.inputFile.value = null
    },

    selectFiles (index) {
      this.$refs.inputFile[index].click()
    },
    getFiles (typeId, subId) {
      this.handleFiles(this.$refs.inputFile[subId - 1].files, typeId, subId)
      this.active = false
    },
    handleFiles (files, typeId, subId) {
      // this.handleOrderNumber()
      this.auxFiles = []
      files.forEach(file => {
        if (file.size < this.maxSize) {
          this.files.push({
            file: file,
            type: typeId,
            subtype: subId,
            name: file.name
          })
          this.auxFiles.push((this.files.length - 1))
        } else {
          this.handleAlert({
            value: true,
            text: `El tamaño del archivo ${file.name} debe ser menor a 10MB`,
            color: 'error'
          })
        }
      })
      this.handleSetSaleDataFiles(this.files)
    },
    handleOrderNumber () {
      // console.log('handle order number', this.files, this.files.length, this.order_file)
      for (let i = 0; i < this.files.length; i++) {
        if (this.files[i].order > this.order_file) {
          this.order_file = this.files[i].order
        }
      }
      return this.order_file + 1
    },
    toggleActive () {
      this.active = !this.active
    },
    openDocumentOwnership () {
      this.$store.dispatch('handleDocumentOwnershipModal', true)
    },
    deleteFileSelected () {
      const indexFile = this.findFilesIndex(this.fileSubtypeToDelete)
      this.files.splice(indexFile, 1)
      this.handleSetSaleDataFiles(this.files)
      this.closeAlertDelete()
    },
    findFilesIndex (subtype) {
      const index = this.files.findIndex(e => {
        return e.subtype === subtype
      })
      return index
    },
    openAlertDelete (file, order, name) {
      this.deleteFileModal = true
      this.fileToDelete = file.name
      this.fileSubtypeToDelete = file.subtype
    },
    closeAlertDelete () {
      this.deleteFileModal = false
      this.fileToDelete = ''
      this.fileSubtypeToDelete = 0
    },
    formatFilesToDisplay (files) {
      const formattedFiles = []
      files.forEach(f => {
        // console.log('archivo', f)
        formattedFiles.push({ ...f, path: f.path, name: f.name, type: f.id, subtype: f.type?.id })
      })
      // console.log('archivos formateados para mostrar ', formattedFiles)
      return formattedFiles
    }
  },
  async mounted () {
    if (this.edit && this.filesFromDb.length > 0) {
      this.files = this.formatFilesToDisplay(this.filesFromDb)
      this.handleSetSaleDataFiles(this.files)
    }
  }
}
</script>

<style lang="scss" scoped>
ul {
  margin: 0;
  padding: 0;

  li {
    @include fontStyle(500, 14px, 0.01em, $black);
    list-style: none;
  }
}

.drop-container {
  width: 100%;
}

.drop-area {
  border: 1px dashed $grey;
  width: 88%;
  height: 170px;
  cursor: pointer;
  h2 {
    @include fontStyle(normal, 16px, 0.01em, $grey);
  }

  &.active {
    background-color: $white;
    border: 1px solid $black;

    h2 {
      color: orange;
    }
  }
}

.attach-link{
  cursor: pointer;
  color: orange;
  &.hover {
    @include fontStyle(bold, 16px, inherit, orange);
  }
}

.files-width {
  width: 100%;

  h3 {
    @include fontStyle(bold, 14px, 0.01em, $grey);
  }
}

.text-title {
  @include fontStyle(bold, 14px, 0.01em, $grey);
}

</style>
