<template>
  <div>
    <!--
    <AdvancedFilter @filter="filter">
      <template v-slot:filterOptions>
        <VehiclesFilterOptions :transmitions="transmitions" :statuses="statuses" :models="models" :types="types"></VehiclesFilterOptions>
      </template>
    </AdvancedFilter>
    -->
    <Table
      v-if="!addOrEditCatalog"
      :title="tableData.title"
      :headers="tableData.headers"
      :items="tableData.items"
      :hideDefaultTableHeader="hideDefaultTableHeader"
      @rowClicked="rowClicked"
      >
      <template v-slot:chipTypeStatus="slotProps">
        <TableMarkStatus :text="slotProps.item.vehicle_type.status" :color="slotProps.item.vehicle_type.color">{{slotProps.item}}</TableMarkStatus>
      </template>
      <template v-slot:chipStatus="slotProps">
        <TableMarkStatus :text="slotProps.item.status.status" :color="slotProps.item.status.color">{{slotProps.item}}</TableMarkStatus>
      </template>

      <template v-slot:tableToolbar="slotProps">
        <TableToolbar
          :title="tableData.title"
          :btnAdd="tableData.btnAdd"
          @handleAddOrUpdate="handleAddOrUpdate(true)"
          :pagination="slotProps.pagination"
          :options="slotProps.options"
          :updateOptions="slotProps.updateOptions"
          >
          <template v-slot:toolbarFilterButton>
            <!--
            <TableFilterButton @filter="filter"></TableFilterButton>
            -->
          </template>

          <template v-slot:paginationTable="slotProps">
            <TablePagination
              :pagination="slotProps.pagination"
              :options="slotProps.options"
              :updateOptions="slotProps.updateOptions" >

            </TablePagination>
          </template>
        </TableToolbar>
      </template>

      <template v-slot:componentDelete>
        <TableDeleteItem
          :dialogDelete="dialogDelete"
          @handleDialogDelete="handleDialogDelete"
          @closeDelete="closeDelete"
          @deleteItemConfirm="deleteItemConfirm" />
      </template>

      <template v-slot:no-data>
        <TableNoData />
      </template>
    </Table>
    <AddOrEditCatalog
      v-if="addOrEditCatalog"
      :vehicleToEdit="vehicleToEdit"
      :allModels="models"
      :allStatuses="statuses"
      :allVehicleTypes="types"
      :allTransmitions="transmitions"
      :allBrands="brands"
      :allLines="lines"
      :allFuelTypes="fuelTypes"
      :allColors="allColors"
      @stopEditing="stopEditing"
      @addModel="addVehicle"
      @updateModel="updateVehicle"
      @setValueFilterWhenCancel="setValueFilterWhenCancel"
      >
    </AddOrEditCatalog>
  </div>
</template>

<script>
// import AdvancedFilter from '../components/tables/AdvancedFilter'
import Table from '../components/tables/Table.vue'
import TableMarkStatus from '@/components/tables/TableMarkStatus'
import TableToolbar from '../components/tables/TableToolbar'
// import TableFilterButton from '../components/tables/TableFilterButton'
import TablePagination from '../components/tables/TablePagination'
import TableDeleteItem from '../components/tables/TableDeleteItem'
import TableNoData from '../components/tables/TableNoData'
import AddOrEditCatalog from '../components/catalog/AddOrEditCatalog'
// import VehiclesFilterOptions from '../components/vehicles/VehiclesFilterOptions'
import { getAllVehicleStatuses, getAllTransmitions, getAllTypes, getAllVehicleLines, getAllVehicleBrands, getAllFuelsTypes, getAllVehicleColors } from '@/services/vehicles'
import { deleteModel } from '@/services/catalog'
// import { getAllVehicles, deleteVehicle, getAllVehicleStatuses, getAllTransmitions, getAllTypes } from '@/services/vehicles'
import { getAllModels } from '@/services/globals'
import { mapState, mapGetters, mapActions } from 'vuex'
import AuthHelper from '@/helpers/AuthHelper'
import Helper from '@/helpers/Helper'
import { EventBus } from '@/EventBus.js'

export default {
  name: 'Catalog',
  components: {
    // AdvancedFilter,
    Table,
    TableMarkStatus,
    TableToolbar,
    // TableFilterButton,
    TablePagination,
    TableDeleteItem,
    TableNoData,
    AddOrEditCatalog
    // VehiclesFilterOptions
  },
  data () {
    return {
      allVehicles: [],
      vehicles: [],
      vehicleToEdit: {},
      lines: [],
      brands: [],
      fuelTypes: [],
      allColors: [],
      tableData: {
        title: 'Catálogo',
        headers: [
          {
            text: 'Marca',
            value: 'brand',
            class: 'white'
          },
          {
            text: 'Línea',
            value: 'line',
            class: 'white'
          },
          {
            text: 'Modelo',
            value: 'model',
            class: 'white'
          },
          {
            text: 'Colores',
            value: 'colors',
            class: 'white'
          },
          {
            text: 'Puertas',
            value: 'doors',
            class: 'white'
          },
          {
            text: '',
            value: 'actions',
            class: 'white'
          }
        ],
        items: [],
        btnAdd: {
          title: 'Nuevo modelo'
        }
      },
      editedIndex: -1,
      editedItem: {},
      userToEdit: null,
      hideDefaultTableHeader: false,
      dialogDelete: false,
      itemToDelete: {},
      models: [],
      statuses: [],
      transmitions: [],
      types: [],
      filteredVehicles: [],
      vehicleTypeColors: [
        {
          id: 1,
          color: '#FFB70B'
        },
        {
          id: 2,
          color: '#007BD4'
        }
      ]
    }
  },
  computed: {
    ...mapState(['userToken']),
    ...mapGetters(['addOrEditCatalog', 'searchVehiclesData'])
  },
  methods: {
    handleAddOrUpdate (value) {
      this.$store.dispatch('handleShowAddOrEditCatalog', value)
    },
    // agregar cantidad de funciones necesarias para todos los filtros
    filter () {
      if (this.searchVehiclesData.status === '' && this.searchVehiclesData.model === '' && this.searchVehiclesData.transmition && this.searchVehiclesData.type) {
        this.filteredVehicles = this.allVehicles
      } else {
        this.filteredVehicles = this.allVehicles.filter(v => (this.filterByStatus(v) && this.filterByModel(v) && this.filterByTransmition(v) && this.filterByType(v)))
      }
      this.formatVehiclesTable(this.filteredVehicles)
      this.handleAdvancedFilter(false)
    },
    // revisar por que campos se busca y agregarlos al store vehicles.js
    filterByStatus (vehicle) {
      return this.searchVehiclesData.status === '' || vehicle.vehicle_status_id === this.searchVehiclesData.status
    },
    filterByModel (vehicle) {
      return this.searchVehiclesData.model === '' || vehicle.vehicle_model_id === this.searchVehiclesData.model
    },
    filterByTransmition (vehicle) {
      return this.searchVehiclesData.transmition === '' || vehicle.transmition_id === this.searchVehiclesData.transmition
    },
    filterByType (vehicle) {
      return this.searchVehiclesData.type === '' || vehicle.vehicle_type_id === this.searchVehiclesData.type
    },
    searchVehicles (vehicles, search) {
      this.filteredVehicles = this.allVehicles
      if (search !== '') {
        search = search.toString().toLocaleLowerCase()
        this.filteredVehicles = vehicles.filter(v => v.domain !== null ? this.filterVehicleBySearchParam(v, search) : false)
      } else {
        this.filteredVehicles = this.allVehicles
      }
      // this.tableData.items = []
      this.formatVehiclesTable(this.filteredVehicles)
    },
    filterVehicleBySearchParam (vehicle, search) {
      return Helper.searchItemByField(vehicle, 'domain', search)
    },
    /*
    Descomentar y modificar en caso de que haga falta buscar por otros parámetros
    filterVehicleByPatent (vehicle, search) {
      console.log('bypatent', vehicle)
      return Helper.searchItemByField(vehicle, 'domain', search) || Helper.searchItemByField(vehicle, 'vin_code', search)
    }, */
    ...mapActions(['handleAlert', 'handleLoading', 'handleAdvancedFilter']),
    rowClicked (item, rowData) {
      this.editVehicle(item)
    },
    addVehicle (vehicle) {
      this.allVehicles.unshift(vehicle)
      this.formatVehiclesTable(this.allVehicles)
    },
    updateVehicle (vehicle) {
      const index = this.allVehicles.findIndex(v => v.id === vehicle.id)
      if (index !== -1) {
        this.allVehicles[index] = vehicle
      }
      this.formatVehiclesTable(this.allVehicles)
    },
    async getVehicles () {
      this.handleLoading(true)
      this.filteredAccessories = []
      try {
        const res = await getAllModels(this.userToken)
        this.allVehicles = res.data.data
        this.formatVehiclesTable(this.allVehicles)
        this.filteredVehicles = [...this.allVehicles]
        this.alertMessage = res.data.message
        this.alertColor = 'success'
        this.handleLoading(false)
      } catch (error) {
        console.log('error en getVehicles', error)
        AuthHelper.checkErrorExpiredToken(error)
        this.alertMessage = error?.data?.message
        this.alertColor = 'error'
        this.handleLoading(false)
      } finally {
        this.handleAlert({
          value: true,
          text: this.alertMessage,
          color: this.alertColor
        })
      }
    },
    formatVehiclesTable (data) {
      this.tableData.items = []
      data.forEach(v => {
        this.tableData.items.push(this.getFormatVehiclesTable(v))
      })
    },
    getFormatVehiclesTable (v) {
      return {
        id: v.id,
        brand: v.vehicle_brand?.brand,
        model: v.model,
        line: v.vehicle_line?.line,
        colors: v.vehicle_color.length,
        doors: v.vehicle_doors,
        actions: [
          {
            text: 'Editar',
            icon: 'mdi-pencil',
            fn: (item) => {
              this.editVehicle(item)
            },
            classes: ''
          },
          {
            text: 'Eliminar Modelo',
            icon: 'mdi-block-helper',
            fn: (item) => {
              this.deleteModel(item)
            },
            classes: 'option-delete'
          }
        ],
        data: v
      }
    },
    stopEditing () {
      this.vehicleToEdit = {}
    },
    async getModels () {
      this.handleLoading(true)
      try {
        const res = await getAllModels(this.userToken)
        this.models = res.data.data
        this.alertMessage = res.data.message
        this.alertColor = 'success'
        this.handleLoading(false)
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
        console.log(error)
        this.alertMessage = error?.data?.message ? error.data.message : error
        this.alertColor = 'error'
        this.handleLoading(false)
      } finally {
        this.handleAlert({
          value: true,
          text: this.alertMessage,
          color: this.alertColor
        })
      }
    },
    async getVehiclesStatuses () {
      this.handleLoading(true)
      try {
        const res = await getAllVehicleStatuses(this.userToken)
        this.statuses = res.data.data
        this.alertMessage = res.data.message
        this.alertColor = 'success'
        this.handleLoading(false)
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
        console.log(error)
        this.alertMessage = error?.data?.message ? error.data.message : error
        this.alertColor = 'error'
        this.handleLoading(false)
      } finally {
        this.handleAlert({
          value: true,
          text: this.alertMessage,
          color: this.alertColor
        })
      }
    },
    async getVehiclesTransmitions () {
      this.handleLoading(true)
      try {
        const res = await getAllTransmitions(this.userToken)
        this.transmitions = res.data.data
        this.alertMessage = res.data.message
        this.alertColor = 'success'
        this.handleLoading(false)
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
        console.log(error)
        this.alertMessage = error?.data?.message ? error.data.message : error
        this.alertColor = 'error'
        this.handleLoading(false)
      } finally {
        this.handleAlert({
          value: true,
          text: this.alertMessage,
          color: this.alertColor
        })
      }
    },
    async getVehiclesTypes () {
      this.handleLoading(true)
      try {
        const res = await getAllTypes(this.userToken)
        this.types = res.data.data
        this.alertMessage = res.data.message
        this.alertColor = 'success'
        this.handleLoading(false)
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
        this.alertMessage = error?.data?.message ? error.data.message : error
        console.log(error)
        this.alertColor = 'error'
        this.handleLoading(false)
      } finally {
        this.handleAlert({
          value: true,
          text: this.alertMessage,
          color: this.alertColor
        })
      }
    },
    async getVehiclesLines () {
      this.handleLoading(true)
      try {
        const res = await getAllVehicleLines(this.userToken)
        this.lines = res.data.data
        this.alertMessage = res.data.message
        this.alertColor = 'success'
        this.handleLoading(false)
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
        this.alertMessage = error?.data?.message ? error.data.message : error
        console.log(error)
        this.alertColor = 'error'
        this.handleLoading(false)
      } finally {
        this.handleAlert({
          value: true,
          text: this.alertMessage,
          color: this.alertColor
        })
      }
    },
    async getVehiclesBrands () {
      this.handleLoading(true)
      try {
        const res = await getAllVehicleBrands(this.userToken)
        this.brands = res.data.data
        this.alertMessage = res.data.message
        this.alertColor = 'success'
        this.handleLoading(false)
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
        this.alertMessage = error?.data?.message ? error.data.message : error
        console.log(error)
        this.alertColor = 'error'
        this.handleLoading(false)
      } finally {
        this.handleAlert({
          value: true,
          text: this.alertMessage,
          color: this.alertColor
        })
      }
    },
    async getFuelTypes () {
      this.handleLoading(true)
      try {
        const res = await getAllFuelsTypes(this.userToken)
        this.fuelTypes = res.data.data
        this.alertMessage = res.data.message
        this.alertColor = 'success'
        this.handleLoading(false)
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
        this.alertMessage = error?.data?.message ? error.data.message : error
        console.log(error)
        this.alertColor = 'error'
        this.handleLoading(false)
      } finally {
        this.handleAlert({
          value: true,
          text: this.alertMessage,
          color: this.alertColor
        })
      }
    },
    async getAllColors () {
      this.handleLoading(true)
      try {
        const res = await getAllVehicleColors(this.userToken)
        this.allColors = res.data.data
        this.alertMessage = res.data.message
        this.alertColor = 'success'
        this.handleLoading(false)
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
        this.alertMessage = error?.data?.message ? error.data.message : error
        console.log(error)
        this.alertColor = 'error'
        this.handleLoading(false)
      } finally {
        this.handleAlert({
          value: true,
          text: this.alertMessage,
          color: this.alertColor
        })
      }
    },
    handleDialogDelete (value) {
      this.dialogDelete = value
    },
    closeDelete () {
      this.handleDialogDelete(false)
      this.itemToDelete = {}
    },
    editVehicle (item) {
      console.log(item)
      this.vehicleToEdit = item
      this.vehicleToEdit.value = item.value
        ? this.$options.filters.number(Helper.transformTwoDecimalFloatToInt(item.value))
        : null
      this.vehicleToEdit.domain = item.data.domain
        ? item.data.domain
        : null
      this.vehicleToEdit.location = item.data.location
        ? item.data.location
        : null
      this.vehicleToEdit.serial_number = item.data.serial_number
        ? item.data.serial_number
        : null
      this.vehicleToEdit.vehicle_status = item.data.vehicle_status
        ? item.data.vehicle_status
        : null
      this.vehicleToEdit.data.list_price = item.data.list_price
        ? this.$options.filters.magnitude(Helper.transformTwoDecimalFloatToInt(item.data.list_price))
        : null
      this.vehicleToEdit.data.mileage_km = item.data.mileage_km
        ? this.$options.filters.magnitude(item.data.mileage_km)
        : null
      this.vehicleToEdit.data.rasa_bonification = item.data.rasa_bonification
        ? this.$options.filters.magnitude(Helper.transformTwoDecimalFloatToInt(item.data.rasa_bonification))
        : null
      this.vehicleToEdit.data.net_price_renault = item.data.net_price_renault
        ? this.$options.filters.magnitude(Helper.transformTwoDecimalFloatToInt(item.data.net_price_renault))
        : null
      this.vehicleToEdit.data.lepic_bonification = item.data.lepic_bonification
        ? this.$options.filters.magnitude(Helper.transformTwoDecimalFloatToInt(item.data.lepic_bonification))
        : null
      this.vehicleToEdit.data.lepic_bonification = item.data.lepic_bonification
        ? this.$options.filters.magnitude(Helper.transformTwoDecimalFloatToInt(item.data.lepic_bonification))
        : null
      this.vehicleToEdit.data.percentage_discount = item.data.percentage_discount
        ? this.$options.filters.magnitude(Helper.transformTwoDecimalFloatToInt(item.data.percentage_discount))
        : null
      this.vehicleToEdit.data.lepic_price = item.data.lepic_price
        ? this.$options.filters.magnitude(Helper.transformTwoDecimalFloatToInt(item.data.lepic_price))
        : null
      this.$store.dispatch('handleEditCatalog', true)
      this.$store.dispatch('handleShowAddOrEditCatalog', true)
    },
    setValueFilterWhenCancel () {
      this.tableData.items.forEach(i => {
        i.value = this.$options.filters.number(i.value)
        i.value = this.$options.filters.currency(i.value)
      })
    },
    deleteModel (item) {
      this.itemToDelete = {
        id: item.id
      }
      this.handleDialogDelete(true)
    },
    async deleteItemConfirm () {
      try {
        this.handleLoading(true)
        const res = await deleteModel(this.userToken, this.itemToDelete.id)
        this.alertMessage = res.data.message
        this.alertColor = 'success'
        const index = this.allVehicles.findIndex(a => a.id === this.itemToDelete.id)
        if (index !== -1) {
          this.allVehicles.splice(index, 1)
          this.formatVehiclesTable(this.allVehicles)
        }
      } catch (error) {
        console.log('error en deleteitemconfirm', error)
        AuthHelper.checkErrorExpiredToken(error)
        this.alertMessage = error?.data?.message ? error?.data?.message : error
        this.alertColor = 'error'
      } finally {
        this.handleLoading(false)
        this.handleAlert({
          value: true,
          text: this.alertMessage,
          color: this.alertColor
        })
        this.closeDelete()
      }
    }
  },
  mounted () {
    this.handleLoading(true)
    this.$store.dispatch('handleShowAddOrEditCatalog', false)
    this.$store.dispatch('handleEditCatalog', false)
    this.getVehicles()
    this.getModels()
    this.getVehiclesStatuses()
    this.getVehiclesTypes()
    this.getVehiclesTransmitions()
    this.getVehiclesLines()
    this.getVehiclesBrands()
    this.getFuelTypes()
    this.getAllColors()
    EventBus.$on('searchVehicles', (input) => this.searchVehicles(this.filteredVehicles, input))
  }
}
</script>

<style>

</style>
