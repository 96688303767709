<template>
    <svg width="14" height="21" viewBox="0 0 14 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.3" d="M2 14.01V17H12V14.01V14H2V14.01ZM10 14.5C10.55 14.5 11 14.95 11 15.5C11 16.05 10.55 16.5 10 16.5C9.45 16.5 9 16.05 9 15.5C9 14.95 9.45 14.5 10 14.5ZM4 14.5C4.55 14.5 5 14.95 5 15.5C5 16.05 4.55 16.5 4 16.5C3.45 16.5 3 16.05 3 15.5C3 14.95 3.45 14.5 4 14.5Z"/>
        <path d="M4 16.5C4.55228 16.5 5 16.0523 5 15.5C5 14.9477 4.55228 14.5 4 14.5C3.44772 14.5 3 14.9477 3 15.5C3 16.0523 3.44772 16.5 4 16.5Z"/>
        <path d="M10 16.5C10.5523 16.5 11 16.0523 11 15.5C11 14.9477 10.5523 14.5 10 14.5C9.44772 14.5 9 14.9477 9 15.5C9 16.0523 9.44772 16.5 10 16.5Z"/>
        <path d="M12.25 8.6C12.23 8.58 12.22 8.56 12.2 8.53C11.82 8.01 11.28 8 11.28 8H2.72C2.72 8 2.18 8.01 1.8 8.54C1.78 8.56 1.77 8.58 1.75 8.6C1.68 8.71 1.61 8.84 1.56 9C1.34 9.66 0.82 11.22 0 13.69V20.19C0 20.64 0.35 21 0.78 21H1.22C1.65 21 2 20.64 2 20.19V19H12V20.19C12 20.64 12.34 21 12.78 21H13.22C13.65 21 14 20.64 14 20.19V13.69C13.18 11.23 12.66 9.66 12.44 9C12.39 8.84 12.32 8.71 12.25 8.6ZM3.33 10H10.67L10.9 10.69L11.33 12H2.67L3.33 10ZM12 14.01V17H2V14.01V14H12V14.01Z"/>
        <path d="M5.83 2C5.41 0.83 4.3 0 3 0C1.34 0 0 1.34 0 3C0 4.65 1.34 6 3 6C4.3 6 5.41 5.16 5.83 4H11V6H13V4H14V2H5.83ZM3 4C2.45 4 2 3.55 2 3C2 2.45 2.45 2 3 2C3.55 2 4 2.45 4 3C4 3.55 3.55 4 3 4Z"/>
    </svg>
</template>

<script>
export default {
  name: 'SaleNew'
}
</script>

<style lang="scss" scoped>
</style>
