<template>
  <v-app>
    <v-main v-if="userLogged">
      <Header />
      <MenuNav />
      <router-view />
    </v-main>
    <Login v-else />
    <AppLoading />
    <AppAlert />
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import MenuNav from '@/components/layout/navbar/MenuNav'
import Header from '@/components/layout/header/Header'
import Login from '@/views/Login'
import AppLoading from '@/components/layout/loading/AppLoading'
import AppAlert from '@/components/layout/toast/AppAlert'
export default {
  name: 'App',
  components: {
    MenuNav,
    Header,
    Login,
    AppLoading,
    AppAlert
  },
  data: () => ({
    //
  }),
  computed: {
    ...mapGetters(['userLogged'])
  },

  methods: {
    ...mapActions(['checkUserLogged'])
  },
  mounted () {
    this.checkUserLogged()
  }
}
</script>

<style lang="scss" scoped>
#app {
  background-color: $white;
}
</style>
