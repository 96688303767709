<template>
  <v-card>
    <v-card-title class="text-h5">Imprimir solicitud</v-card-title>
    <v-card-text>Imprimir solicitud n° {{itemToPrint.id}}</v-card-text>
    <v-card-text>Imprimir solicitud n° {{salePdfToPrint}}</v-card-text>
    <iframe src="salePdfToPrint" id="frame" width="400" height="400"></iframe>
    <!-- Revisar error de status undefined previo a mostrar el componente (comparar con components/users/AddOrEditItem) -->
    <div>Print item!!!</div>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="$emit('closeDialogPrint')">Cancelar</v-btn>
      <v-btn color="blue darken-1" text @click="$emit('editItemStatusConfirm')">OK</v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>

<script>
import { dateFormatMixin } from '@/mixins/dateFormatMixin'

export default {
  name: 'EditItem',
  data () {
    return {
      formTitle: 'Tipo de reserva',
      selectedSaleTypeRadio: ''
    }
  },
  props: {
    itemToPrint: {
      type: Object,
      required: true
    },
    openPrintModal: Boolean,
    salePdfToPrint: String
  },
  methods: {
    close () {},
    // era save (buscar referencias en Users)
    print () {
      // itemToPrint.contentWindow.focus()
      // itemToPrint.contentWindow.print()
    }
  },
  mixins: [dateFormatMixin]
}
</script>

<style lang="scss" scoped>
.borderactive {
  border: 2px solid $black !important;
  background-color: #F5F5F5;
}
.bordernormal {
  border: 2px solid #F5F5F5;
  background-color: #F5F5F5;
}
.hr-color {
  border-bottom: 0.5px solid rgb(240, 238, 238);
}
.font-style {
   @include fontStyle(normal, 22px, 0.01em, $black)
 }
 div .div-hover {
   &:hover {
    cursor: pointer;
   }
 }
::v-deep {
  .v-card__title {
    span {
      font-size: 22px;
      letter-spacing: 0.01em;
    }
  }
  .v-btn {
    font-size: 12px;
    letter-spacing: 0.01em;
  }
  .v-input__slot > div {
      border-bottom: 1px solid rgb(206, 206, 206);
      input::placeholder {
        padding-left: 10px;
      }
  }
  .bor-btn {
    border: 1px solid $black !important;
  }
}
</style>
