export default {
  state: {
    search: {
      status: '',
      client: '',
      car_model: ''
    },
    showCreateSale: false,
    showPrintSaleModal: false,
    showDocumentOwnershipModal: false,
    salesData: [],
    newSaleData: {
      id: '',
      date: '',
      operation_number: '',
      status: '',
      vehicle: {
        vehicle_reservation: {
          vehicle_availability: '',
          industrial_code: '',
          car_model: '',
          vin: '',
          car_year: '',
          car_domain: '',
          car_fuel: '',
          car_doors: '',
          car_color: ''
        },
        vehicle_data: {
          vehicle_search: '',
          industrial_code: '',
          car_model: '',
          car_year: '',
          car_fuel: '',
          car_doors: '',
          car_color: ''
        },
        accessories: [
          {
            item: '',
            quantity: ''
          }
        ],
        billing_data: {
          list_price: '',
          renault_bonus: '',
          subtotal: '',
          lepic_bonification: '',
          invoiced_total: '',
          accessories: ''
        },
        management: {
          patent_transference: '',
          ld_lg_vu: '',
          lepic_bonification: '',
          credit_management: '',
          freight_forms_ppe: '',
          others: '',
          management_total: '',
          operation_total: ''
        }
      },
      payment_method: {
        payment_method_details: {
          financed: '',
          credit_to_manage: '',
          dues: '',
          bank: '',
          plan: ''
        },
        insurance: {
          insurable_sum: '',
          approximate_fee: '',
          coverage: '',
          company: ''
        },
        used_vehicle: {
          car_search: '',
          car_brand: '',
          car_model: '',
          car_year: '',
          car_domain: ''
        },
        billing_data: {
          list_price: '',
          renault_bonus: '',
          subtotal: '',
          lepic_bonification: '',
          invoiced_total: '',
          accessories: ''
        },
        sale_management: {
          agency_total: '',
          operation_total: ''
        },
        sale_payment_method: {
          deposit: '',
          cash: '',
          financed: '',
          used_value: '',
          operation_total: ''
        }
      },
      client_data: {
        client_details: {
          client_id: '',
          client_dni: '',
          client_firstname: '',
          client_lastname: '',
          client_birth_date: '',
          client_marital_status: '',
          client_email: '',
          client_adress: '',
          client_adress_number: '',
          client_adress_floor: '',
          client_adress_department: '',
          client_adress_locality: '',
          client_adress_zip_code: '',
          client_phone: '',
          client_mobile: '',
          client_type: ''
        },
        condominium_data: {
          condominium_id: '',
          condominium_dni: '',
          condominium_firstname: '',
          condominium_lastname: '',
          condominium_birth_date: '',
          condominium_marital_status: '',
          condominium_email: '',
          condominium_adress: '',
          condominium_adress_number: '',
          condominium_adress_floor: '',
          condominium_adress_department: '',
          condominium_adress_locality: '',
          condominium_adress_zip_code: '',
          condominium_phone: '',
          condominium_mobile: '',
          condominium_type: ''
        }
      },
      application: {
        application_data: {},
        application_details: {
          estimated_delivery_date: '',
          is_final_review_done: false,
          is_documents_attached: false,
          is_printed_and_signed_by_client: false,
          is_approval_request_sent: false,
          is_drive_test_offered: false,
          is_drive_test_done: false,
          observations: ''
        },
        related_documentation: {}
      }
    },
    saleData: {
      id: '',
      vehicle: {},
      payment_method: {},
      client_data: {},
      application: {}
    },
    saleDataFiles: [],
    currentSale: {},
    allClientsData: [],
    currentStep: 1
  },
  mutations: {
    setSearchStatus (state, payload) {
      state.search.status = payload
    },
    setSearchClient (state, payload) {
      state.search.client = payload
    },
    setSearchCarModel (state, payload) {
      state.search.car_model = payload
    },
    setCreateSale (state, payload) {
      state.showCreateSale = payload
    },
    setCurrentSale (state, index) {
      state.currentSale = state.salesData[index]
    },
    setPrintSaleModal (state, payload) {
      state.showPrintSaleModal = payload
    },
    setNewSaleData (state, payload) {
      const stepName = {
        1: 'vehicle',
        2: 'payment_method',
        3: 'client_data',
        4: 'application'
      }
      state.newSaleData[stepName[state.currentStep]] = payload
    },
    setSaleDataByStep (state, payload) {
      const stepName = {
        1: 'vehicle',
        2: 'payment_method',
        3: 'client_data',
        4: 'application'
      }
      state.saleData[stepName[state.currentStep]] = payload
    },
    /* setEditedSaleData (state, payload) {
      state.newSaleData = payload
    }, */
    setSalesData (state, payload) {
      state.salesData = payload
    },
    setSaleData (state, payload) {
      state.saleData = payload
    },
    setSaleDataFiles (state, payload) {
      state.saleDataFiles = payload
    },
    setSaleDate (state, payload) {
      state.newSaleData.date = payload
    },
    setAllClientsData (state, payload) {
      state.allClientsData = payload
    },
    setOperationNumber (state, payload) {
      state.newSaleData.operation_number = payload
    },
    setCurrentStep (state, payload) {
      state.currentStep = payload
    },
    // chequear si llega bien el ID
    setSaleStatus (state, payload) {
      state.salesData[state.currentSale.id].status = payload
    },
    setDocumentOwnershipModal (state, payload) {
      state.showDocumentOwnershipModal = payload
    }
  },
  actions: {
    handleSearchStatus ({ commit }, payload) {
      commit('setSearchStatus', payload)
    },
    handleSearchClient ({ commit }, payload) {
      commit('setSearchClient', payload)
    },
    handleSearchCarModel ({ commit }, payload) {
      commit('setSearchCarModel', payload)
    },
    handleCreateSale ({ commit }, payload) {
      commit('setCreateSale', payload)
    },
    handleCurrentSale ({ commit }, index) {
      commit('setCurrentSale', index)
    },
    handlePrintSaleModal ({ commit }, payload) {
      commit('setPrintSaleModal', payload)
    },
    handleNewSaleData ({ commit }, payload, currentStep) {
      commit('setNewSaleData', payload, currentStep)
    },
    handleSetSaleData ({ commit }, payload) {
      commit('setSaleData', payload)
    },
    handleSetSaleDataFiles ({ commit }, payload) {
      commit('setSaleDataFiles', payload)
    },
    handleSetSaleDataByStep ({ commit }, payload) {
      commit('setSaleDataByStep', payload)
    },
    handleAllClientsData ({ commit }, payload) {
      commit('setAllClientsData', payload)
    },
    handleCurrentStep ({ commit }, payload) {
      commit('setCurrentStep', payload)
    },
    handleSaleStatus ({ commit }, payload, saleId) {
      commit('setSaleStatus', payload, saleId)
    },
    handleSaleDate ({ commit }, payload) {
      commit('setSaleDate', payload)
    },
    handleOperationNumber ({ commit }, payload) {
      commit('setOperationNumber', payload)
    },
    handleDocumentOwnershipModal ({ commit }, payload) {
      commit('setDocumentOwnershipModal', payload)
    }
  },
  getters: {
    searchData: state => state.search,
    searchStatus: state => state.search.status,
    searchClient: state => state.search.client,
    searchCarModel: state => state.search.car_model,
    createOrEditSaleOpen: state => state.showCreateSale,
    printSaleModalOpen: state => state.showPrintSaleModal,
    newSale: state => state.newSaleData,
    currentSale: state => state.saleData,
    saleFiles: state => state.saleDataFiles,
    allSalesData: state => state.salesData,
    allClientsData: state => state.allClientsData,
    getCurrentStep: state => state.currentStep,
    documentOwnershipModal: state => state.showDocumentOwnershipModal
  }
}
