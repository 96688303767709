<template>
  <div class="d-flex flex-row justify-between align-center" style="width: 100vw">
    <v-container>
      <v-row>
        <v-col cols="4">
          <!-- search md and up -->
          <div class="d-none d-sm-flex rounded bg__text-field min-w">
            <v-text-field
              placeholder="Buscar..."
              height="56px"
              solo
              background-color="#F5F5F7"
              flat
              hide-details
              class="text-subtitle-1 font-weight-medium rounded"
              v-model="searchBar"
              @keyup.enter="debounceHeaderSearch(search($route.name, searchBar))"
            >
            </v-text-field>
            <div class="d-flex  align-center rounded bg__text-field">
              <v-btn
                x-small
                height="40"
                width="40"
                elevation="0"
                class="d-flex justify-center align-center rounded mr-2 black bg__icon"
                @click="debounceHeaderSearch(search($route.name, searchBar))"
              >
                <v-icon size="18">$search</v-icon>
              </v-btn>
            </div>
          </div>
          <!-- End serch ms and up -->
          <!-- search XS -->
          <div class="d-flex d-sm-none rounded bg__text-field minim-w">
            <v-text-field
              placeholder="Buscar..."
              height="56px"
              solo
              background-color="#F5F5F7"
              flat
              hide-details
              class="text-subtitle-1 font-weight-medium rounded"
              v-model="searchBar"
              @keyup.enter="debounceHeaderSearch(search($route.name, searchBar))"
            >
            </v-text-field>
            <div class="d-flex  align-center rounded bg__text-field">
              <v-btn
                x-small
                height="40"
                width="40"
                elevation="0"
                class="d-flex justify-center align-center rounded mr-2 black bg__icon"
                @click="debounceHeaderSearch(search($route.name, searchBar))"
              >
                <v-icon size="18">$search</v-icon>
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-spacer></v-spacer>
    <v-btn
      color="transparent"
      elevation="0"
      fab
      class="text-capitalize text-subtitle-2 text--secondary"
      @click.stop="logout"
    >
      <v-icon class="ml-2" size="26">$exit</v-icon>
    </v-btn>
  </div>
</template>

<script>
import { EventBus } from '@/EventBus.js'
import DebounceHeaderSearch from '@/mixins/DebounceHeaderSearch'
import { userLogout } from '@/services/login'
import { mapActions, mapState } from 'vuex'
import AuthHelper from '@/helpers/AuthHelper'

export default {
  name: 'HeaderContent',

  data: () => ({
    alertMessage: '',
    alertColor: '',
    searchBar: ''
  }),
  computed: {
    ...mapState(['userToken'])
  },
  methods: {
    ...mapActions(['signout', 'handleLoading', 'handleAlert']),

    search (routeName, input) {
      EventBus.$emit(`search${routeName}`, input)
    },

    async logout () {
      try {
        this.handleLoading(true)
        await userLogout(this.userToken)
        this.signout()
        this.alertMessage = 'Has salido del sistema con éxito'
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
        this.alertMessage = error.data?.message
        this.alertColor = 'error'
      } finally {
        this.handleLoading(false)
        this.handleAlert({
          value: true,
          text: this.alertMessage,
          color: this.alertColor
        })
      }
    }
  },
  mixins: [DebounceHeaderSearch]
}
</script>

<style lang="scss" scoped>
.bg__text-field {
  background-color: #F5F5F7;
}
.min-w {
  min-width: 378px;
}
.minim-w {
  width: 220px;
}
::v-deep {
  .v-input__slot > div {
    input::placeholder {
      @include fontStyle(normal, 14px, 0.01em, $grey);
      padding-left: 7px;
    }
  }
}
</style>
