<template>
  <v-stepper-content :step="step">
    <v-form ref="saleStepFour">
      <div class="d-flex mx-8">
        <div class="d-flex flex-column" style="width: 50%;">
          <v-card color="grey lighten-4"
                  elevation="0"
                  class="pa-6 my-4 mx-4 d-flex flex-column justify-start">
            <span class="text-uppercase text-no-wrap font-weight-bold align-self-start">Cliente</span>
              <v-row class="mt-4">
                <v-col class="d-flex col-2">
                  <v-icon x-large :color="colorCheck">mdi-check-circle</v-icon>
                </v-col>
                <v-col class="d-flex col-10 flex-column align-start">
                  <span class="text-uppercase font-weight-bold align-self-center">Datos de solicitud completados correctamente</span>
                  <span class="align-self-center">Realiza una revisión y envía la solicitud</span>
                </v-col>
              </v-row>
          </v-card>
          <v-card color="grey lighten-4"
                  elevation="0"
                  class="pa-6 my-4 mx-4 d-flex flex-column justify-start">
            <span class="text-uppercase text-no-wrap font-weight-bold align-self-start">Detalle de solicitud</span>
              <v-row class="mt-4">
                <v-col>
                  <v-row>
                    <v-menu
                      v-model="isCalendarExpanded"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :disabled="userDb.role.id === 4"
                          v-model="data.estimated_delivery_date"
                          clearable
                          label="Fecha estimada de entrega"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        v-model="data.estimated_delivery_date"
                        @input="isCalendarExpanded = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-row>
                    <CustomSwitch
                      :disabled="userDb.role.id === 4"
                      text="¿Se realizo una revision final de la solicitud?"
                      :model="data.is_final_review_done"
                      v-on:update:model="data.is_final_review_done = $event"
                    ></CustomSwitch>
                    <CustomSwitch
                      :disabled="userDb.role.id === 4"
                      text="¿Se adjunto correctamente toda la documentacion necesaria?"
                      :model="data.is_documents_attached"
                      v-on:update:model="data.is_documents_attached = $event"
                    ></CustomSwitch>
                    <CustomSwitch
                      :disabled="userDb.role.id === 4"
                      text="¿Se imprimio por triplicado y la firmo el cliente?"
                      :model="data.is_printed_and_signed_by_client"
                      v-on:update:model="data.is_printed_and_signed_by_client = $event"
                    ></CustomSwitch>
                    <CustomSwitch
                      :disabled="userDb.role.id === 4"
                      text="¿Se envio solicitud de aprobracion?"
                      :model="data.is_approval_request_sent"
                      v-on:update:model="data.is_approval_request_sent = $event"
                    ></CustomSwitch>
                    <CustomSwitch
                      :disabled="userDb.role.id === 4"
                      text="¿Se ofrecio una prueba de manejo?"
                      :model="data.is_drive_test_offered"
                      v-on:update:model="data.is_drive_test_offered = $event"
                    ></CustomSwitch>
                    <CustomSwitch
                      :disabled="userDb.role.id === 4"
                      text="¿Se concreto la prueba de manejo?"
                      :model="data.is_drive_test_done"
                      v-on:update:model="data.is_drive_test_done = $event"
                    ></CustomSwitch>
                  <v-row class="d-flex flex-row justify-space-between align-center">
                    <v-text-field
                      :disabled="userDb.role.id === 4"
                      label="Observaciones"
                      v-model="data.observations"
                    />
                  </v-row>
                </v-col>
              </v-row>
          </v-card>
        </div>
        <div class="d-flex flex-column" style="width: 50%">
          <v-card color="grey lighten-4"
          elevation="0"
          class="pa-6 my-4 mx-4 d-flex flex-column justify-start">
            <span class="text-uppercase text-no-wrap font-weight-bold align-self-start">Documentación relacionada</span>
            <span class="gray-text text-no-wrap font-weight-bold align-self-start my-2">Documentos adjuntados</span>
              <FileInput
                :userDb="userDb"
                :title="'Documentos adjuntados'"
                :sub_title="''"
                :items="reservationAttachmentTypesItems"
                :types="reservationAttachmentTypes"
                :filesFromDb="saleData.related_documentation"
                :edit="edit"
                :clientType="this.completeData.client_data.client_type.value"
                :credit="this.completeData.payment_method.credit"
              />
          </v-card>
          <div v-if="this.completeData.client_data.client_type.value === 1" class="d-flex flex-column">
            <v-card color="grey lighten-4" elevation="0" class="pa-6 my-4 mx-4 d-flex flex-column justify-start">
              <span class="text-uppercase text-no-wrap font-weight-bold align-self-start pb-4 mt-4">Codominio</span>
                <v-row class="mt-4">
                  <v-col class="w-50">
                    <v-text-field
                      :disabled="userDb.role.id === 4"
                      dense
                      label="DNI"
                      placeholder="DNI"
                      v-model="data.condominium_dni"
                      @change="searchDataByDNI(2)"
                      class="pr-6 mt-4"
                    />
                    <v-text-field
                      :disabled="userDb.role.id === 4"
                      dense
                      label="Apellido"
                      placeholder="Apellido"
                      v-model="data.condominium_lastname"
                      class="pr-6 mt-4"
                    />
                    <v-menu
                      offset-y
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      min-width="auto"
                      v-model="isCodominiumCalendarExpanded"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :disabled="userDb.role.id === 4"
                          clearable
                          readonly
                          hide-details
                          v-bind="attrs"
                          v-on="on"
                          id="datepicker2"
                          class="pr-6"
                          label="Fecha de nacimiento"
                          v-model="data.condominium_birth_date"
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        v-model="data.condominium_birth_date"
                        @input="isCodominiumCalendarExpanded = false"
                      ></v-date-picker>
                    </v-menu>
                    <v-select
                      :disabled="userDb.role.id === 4"
                      dense
                      return-object
                      label="Provincia"
                      placeholder="Provincia"
                      :items="provincesItems"
                      v-model="provinceCodominiumModel"
                      class="pr-6 nextToDatepicker"
                    />
                    <v-text-field
                      :disabled="userDb.role.id === 4"
                      dense
                      label="Código Postal"
                      placeholder="Código Postal"
                      v-model="data.condominium_adress_zip_code"
                      class="pr-6 mt-4"
                    />
                    <v-text-field
                      :disabled="userDb.role.id === 4"
                      dense
                      label="N°"
                      placeholder="N°"
                      v-model="data.condominium_adress_number"
                      class="pr-6 mt-4"
                    />
                    <v-text-field
                      :disabled="userDb.role.id === 4"
                      dense
                      label="Depto"
                      placeholder="Depto"
                      v-model="data.condominium_adress_department"
                      class="pr-6 mt-4"
                    />
                    <v-text-field
                      :disabled="userDb.role.id === 4"
                      dense
                      label="Celular"
                      placeholder="Celular"
                      v-model="data.condominium_mobile"
                      class="pr-6 mt-4"
                    />
                  </v-col>
                  <v-col>
                    <v-text-field
                      :disabled="userDb.role.id === 4"
                      dense
                      label="Nombre"
                      placeholder="Nombre"
                      v-model="data.condominium_firstname"
                      class="mt-4"
                    />
                    <v-select
                      :disabled="userDb.role.id === 4"
                      dense
                      return-object
                      label="Est. Civil"
                      placeholder="Est. Civil"
                      :items="maritalStatusItems"
                      v-model="codominiumMaritalStatusModel"
                      class="mt-4"
                    />
                    <v-text-field
                      :disabled="userDb.role.id === 4"
                      dense
                      label="Mail"
                      placeholder="Mail"
                      v-model="data.condominium_email"
                      class="mt-4"
                    />
                    <v-select
                      :disabled="userDb.role.id === 4"
                      dense
                      return-object
                      label="Localidad"
                      placeholder="Localidad"
                      :items="localitiesCodominiumItems"
                      v-model="localityCodominiumModel"
                      class="mt-4"
                    />
                    <v-text-field
                      :disabled="userDb.role.id === 4"
                      dense
                      label="Direccion"
                      placeholder="Direccion"
                      v-model="data.condominium_adress"
                      class="mt-4"
                    />
                    <v-text-field
                      :disabled="userDb.role.id === 4"
                      dense
                      label="Piso"
                      placeholder="Piso"
                      v-model="data.condominium_adress_floor"
                      class="mt-4"
                    />
                    <v-text-field
                      :disabled="userDb.role.id === 4"
                      dense
                      label="Teléfono"
                      placeholder="Teléfono"
                      v-model="data.condominium_phone"
                      class="mt-4"
                    />
                  </v-col>
                </v-row>
            </v-card>
          </div>
        </div>
      </div>
    </v-form>
  </v-stepper-content>
</template>

<script>
import FileInput from '../../forms/FileInput'
import CustomSwitch from '../../forms/CustomSwitch'
import { getAllReservationAttachmentTypes } from '../../../services/reservation'
import { getByDNI } from '../../../services/clients'
import { mapActions, mapState } from 'vuex'
import Helper from '../../../helpers/Helper'
import AuthHelper from '../../../helpers/AuthHelper'

export default {
  name: 'StepApplication',
  components: {
    FileInput,
    CustomSwitch
  },
  props: {
    userDb: Object,
    step: Number,
    saleData: {
      type: Object,
      required: true
    },
    completeData: Object,
    passValidation: Boolean,
    edit: Boolean,
    valiadationsRules: Object,
    provinces: Array,
    provincesItems: Array,
    maritalStatus: Array,
    maritalStatusItems: Array
  },
  data () {
    return {
      isCalendarExpanded: false,
      reservationAttachmentTypes: [],
      reservationAttachmentTypesItems: [],
      localities: [],
      localitiesCodominiumItems: [],
      provinceCodominiumModel: '',
      localityCodominiumModel: '',
      isCodominiumCalendarExpanded: false,
      codominiumMaritalStatusModel: ''
    }
  },
  computed: {
    ...mapState(['userToken']),
    data: {
      get () {
        return this.saleData
      },
      set (value) {
        this.$emit('update:data', value)
      }
    },
    colorCheck () {
      return this.passValidation ? 'primary' : 'gray'
    }
  },
  methods: {
    ...mapActions(['handleLoading', 'handleAlert']),
    async getReservationAttachmentTypes () {
      try {
        this.handleLoading(true)
        const res = await getAllReservationAttachmentTypes(this.userToken)
        this.reservationAttachmentTypes = res.data.data
        this.reservationAttachmentTypesItems = Helper.transformToSelect(this.reservationAttachmentTypes, 'name')
        this.alertMessage = res.data.message
        this.alertColor = 'success'
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
        this.alertMessage = error?.data?.message ? error.data.message : error
        this.alertColor = 'error'
      } finally {
        this.handleLoading(false)
        this.handleAlert({
          value: true,
          text: this.alertMessage,
          color: this.alertColor
        })
      }
    },
    async searchDataByDNI (type) {
      const dni = this.data.condominium_dni
      try {
        this.handleLoading(true)
        const res = await getByDNI(this.userToken, type, dni)
        this.setData(res.data.data)
        this.alertMessage = res.data.message
        this.alertColor = 'success'
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
        this.alertMessage = error?.data?.message ? error.data.message : error
        this.alertColor = 'error'
        console.error(error)
        if (error.status === 404) {
          this.data.condominium_id = ''
          this.data.condominium_firstname = ''
          this.data.condominium_lastname = ''
          this.data.condominium_birth_date = ''
          this.data.condominium_email = ''
          this.data.condominium_adress = ''
          this.data.condominium_adress_number = ''
          this.data.condominium_adress_floor = ''
          this.data.condominium_adress_department = ''
          this.data.condominium_adress_locality = ''
          this.data.condominium_adress_zip_code = ''
          this.data.condominium_phone = ''
          this.data.condominium_mobile = ''
          this.codominiumMaritalStatusModel = ''
          this.provinceCodominiumModel = ''
          this.localityCodominiumModel = ''
          this.codominiumMaritalStatusModel = ''
        }
      } finally {
        this.handleLoading(false)
        this.handleAlert({
          value: true,
          text: this.alertMessage,
          color: this.alertColor
        })
      }
    },
    setData (data) {
      this.data.condominium_id = data.id
      this.data.condominium_firstname = data.name
      this.data.condominium_lastname = data.lastname
      this.data.condominium_birth_date = data.birth_date
      this.data.condominium_email = data.email
      this.data.condominium_adress = data.adress
      this.data.condominium_adress_number = data.adress_number
      this.data.condominium_adress_floor = data.address_floor
      this.data.condominium_adress_department = data.address_department
      this.data.condominium_adress_locality = data.locality
      this.data.condominium_adress_zip_code = data.postcode
      this.data.condominium_phone = data.phone
      this.data.condominium_mobile = data.mobile_phone
      this.provinceCodominiumModel = this.setModelSelected(Helper.getArrayValueFromId(data.locality.province_id, this.provinces))
      this.localityCodominiumModel = this.setModelSelected(Helper.getArrayValueFromId(data.locality.id, Helper.getArrayValueFromId(data.locality.province_id, this.provinces).localities))
      this.codominiumMaritalStatusModel = this.setModelSelected(Helper.getArrayValueFromId(data.marital_status.id, this.maritalStatus))
      /* if (data.condominium !== null) {

      } else {
        this.provinceCodominiumModel = this.setModelSelected(Helper.getArrayValueFromId(data.locality.province_id, this.provinces))
        this.localityCodominiumModel = this.setModelSelected(Helper.getArrayValueFromId(data.locality.id, Helper.getArrayValueFromId(data.locality.province_id, this.provinces).localities))
        this.codominiumMaritalStatusModel = this.setModelSelected(Helper.getArrayValueFromId(data.marital_status.id, this.maritalStatus))
      } */
    },
    setModelSelected (model) {
      return {
        value: Object.prototype.hasOwnProperty.call(model, 'value') ? model.value : model.id,
        text: model.name
      }
    }
  },
  async mounted () {
    await this.getReservationAttachmentTypes()
    // await this.setData(this.saleData.condominium)
    // cambiar this.completeData.client_data.client_type.value === 1 por client type enviado por  prop o algo mas claro
    if (this.edit && this.completeData.client_data.client_type.value === 1) {
      this.data.condominium_dni = this.saleData.condominium.condominium_dni ? this.saleData.condominium.condominium_dni : ''
      this.data.condominium_id = this.saleData.condominium.condominium_id ? this.saleData.condominium.condominium_id : ''
      this.data.condominium_firstname = this.saleData.condominium.condominium_firstname ? this.saleData.condominium.condominium_firstname : ''
      this.data.condominium_lastname = this.saleData.condominium.condominium_lastname ? this.saleData.condominium.condominium_lastname : ''
      this.data.condominium_birth_date = this.saleData.condominium.condominium_birth_date ? this.saleData.condominium.condominium_birth_date : ''
      this.data.condominium_marital_status = this.saleData.condominium?.condominium_marital_status?.id ? this.saleData.condominium.condominium_marital_status.id : ''
      this.data.condominium_email = this.saleData.condominium.condominium_email ? this.saleData.condominium.condominium_email : ''
      this.data.condominium_adress = this.saleData.condominium.condominium_adress ? this.saleData.condominium.condominium_adress : ''
      this.data.condominium_adress_number = this.saleData.condominium.condominium_adress_number ? this.saleData.condominium.condominium_adress_number : ''
      this.data.condominium_adress_floor = this.saleData.condominium.condominium_adress_floor ? this.saleData.condominium.condominium_adress_floor : ''
      this.data.condominium_adress_department = this.saleData.condominium.condominium_adress_department ? this.saleData.condominium.condominium_adress_department : ''
      this.data.condominium_adress_locality = this.saleData.condominium.condominium_adress_locality ? this.saleData.condominium.condominium_adress_locality : ''
      this.data.condominium_adress_zip_code = this.saleData.condominium.condominium_adress_zip_code ? this.saleData.condominium.condominium_adress_zip_code : ''
      this.data.condominium_phone = this.saleData.condominium.condominium_phone ? this.saleData.condominium.condominium_phone : ''
      this.data.condominium_mobile = this.saleData.condominium.condominium_mobile ? this.saleData.condominium.condominium_mobile : ''
      this.data.condominium_type = this.saleData.condominium?.condominium_type?.id ? this.saleData.condominium.condominium_type.id : ''

      this.provinceCodominiumModel = this.saleData.condominium?.condominium_locality?.province_id ? this.setModelSelected(Helper.getArrayValueFromId(this.saleData.condominium?.condominium_locality?.province_id, this.provinces)) : null
      this.localityCodominiumModel = this.saleData.condominium?.condominium_locality?.id ? this.setModelSelected(Helper.getArrayValueFromId(this.saleData.condominium?.condominium_locality?.id, Helper.getArrayValueFromId(this.saleData.condominium.condominium_locality.province_id, this.provinces).localities)) : null
      this.codominiumMaritalStatusModel = this.saleData.condominium?.condominium_marital_status?.id ? this.setModelSelected(Helper.getArrayValueFromId(this.saleData.condominium.condominium_marital_status.id, this.maritalStatus)) : null
    }
  },
  watch: {
    provinceCodominiumModel: {
      handler () {
        const province = this.provinceCodominiumModel?.value ? Helper.getItemFromArray(this.provinceCodominiumModel.value, this.provinces) : ''
        this.localitiesCodominiumItems = province?.localities ? Helper.sortArrayByTextField(Helper.transformToSelect(province.localities, 'name')) : []
      }
    },
    localityCodominiumModel: {
      handler () {
        this.data.condominium_adress_locality = this.localityCodominiumModel
      }
    },
    codominiumMaritalStatusModel: {
      handler () {
        this.data.condominium_marital_status = this.codominiumMaritalStatusModel
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .v-picker {
    margin-top: 0px !important;
  }
  #datepicker1, #datepicker2 {
    margin-top: 10px;
  }
  label[for=datepicker1], label[for=datepicker2] {
    top: 20px;
  }
  .nextToDatepicker {
    margin-top: 34px;
  }
  .gray-text {
     @include fontStyle(normal, 16px, 0.01em, $grey);
  }
}
</style>
