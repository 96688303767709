<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.02 18L16.02 12L10.02 6L8.60999 7.41L13.19 12L8.60999 16.59L10.02 18Z" fill="black"/>
    </svg>
</template>

<script>
export default {
  name: 'Arrow'
}
</script>

<style lang="scss" scoped>
</style>
