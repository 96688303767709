<template>
  <div>
    <AdvancedFilter @filter="filter">
      <template v-slot:filterOptions>
        <FilterOptions
          :statuses="allStatuses"
          :clients="allClients"
          :car_models="allModels"
        ></FilterOptions>
      </template>
    </AdvancedFilter>
    <Table
      v-if="!createOrEditSaleOpen"
      :userDb="userDb"
      :title="tableData.title"
      :headers="tableData.headers"
      :items="tableData.items"
      :sortOptions="sortOptions"
      :hideDefaultTableHeader="hideDefaultTableHeader"
      @rowClicked="rowClicked"
      >
      <template v-slot:chipStatus="slotProp">
        <TableMarkStatus :text="slotProp.item.status" :color="slotProp.item.status_color" />
      </template>

      <template v-slot:indicators="slotProp">
        <TableMarkIndicator
          :showIndicator="slotProp.item.indicators.autoLogicIndicator"
          tooltipText='Indicador de carga en auto lógica'
          :icon="'$autologic'"
        >{{slotProp}}</TableMarkIndicator>
        <TableMarkIndicator
          :showIndicator="slotProp.item.indicators.insuranceIndicator"
          tooltipText="Indicador de seguro"
          :icon="'$insurance'"
        >{{slotProp}}</TableMarkIndicator>
        <TableMarkIndicator
          :showIndicator="slotProp.item.indicators.accessoriesIndicator"
          tooltipText="Indicador de accesorios"
          :icon="'$accessories'"
        >{{slotProp}}</TableMarkIndicator>
        <TableMarkIndicator
          :showIndicator="slotProp.item.indicators.creditIndicator"
          tooltipText="Indicador de crédito a gestionar"
          :icon="'$credit'"
        >{{slotProp}}</TableMarkIndicator>
      </template>

      <template v-slot:tableToolbar="slotProps">
        <TableToolbar
          :title="tableData.title"
          :btnAdd="tableData.btnAdd"
          :openAddOrUpdateDialog="openAddOrUpdateItem"
          :showPrintSaleModal="showPrintSaleModal"
          @handleAddOrUpdate="handleAddOrUpdate"
          @handlePrintSaleModal="handlePrintSaleModal"
          :pagination="slotProps.pagination"
          :options="slotProps.options"
          :updateOptions="slotProps.updateOptions"
          >
          <template v-slot:PrintItemModal>
            <PrintItemModal
            v-if="showPrintSaleModal"
            :showPrintSaleModal="showPrintSaleModal"
            :itemToPrint="editedItem"
            :salePdfToPrint="salePdfToPrint"
            @close="close"
            @print="printApplication"
            />
          </template>
          <template v-slot:AddItemModal>
            <AddItemModal
              :editedItem="editedItem"
              :typeSale='typeSale'
              v-on:update:typeSale="typeSale = $event"
              @close="close"
              @start="start" />
          </template>

          <template v-slot:toolbarFilterButton>
            <TableFilterButton @filter="filter"></TableFilterButton>
          </template>

          <template v-slot:paginationTable="slotProps">
            <TablePagination
              :pagination="slotProps.pagination"
              :options="slotProps.options"
              :updateOptions="slotProps.updateOptions" >
            </TablePagination>
          </template>
        </TableToolbar>
      </template>

      <template v-slot:componentDelete>
        <TableDeleteItem
          :dialogDelete="dialogDelete"
          :itemId="editedIndex"
          @handleDialogDelete="handleDialogDelete"
          @closeDelete="closeDelete"
          @deleteItemConfirm="deleteItemConfirm" />
      </template>
      <template v-slot:componentEditStatus v-if="userDb.role">
        <TableEditItemStatus
          v-if="userDb.role.id !== 4 && isChangingStatus && editedItem"
          :roleID="userDb.role.id"
          :item="editedItem"
          :items="allStatuses"
          :dialogEditStatus="dialogEditStatus"
          :saleStates="['approved', 'not approved']"
          @handleDialogEditStatus="handleDialogEditStatus"
          @closeDialogEditStatus="closeDialogEditStatus"
          @editItemStatusConfirm="editItemStatusConfirm"
        />
        <!-- Distributor -->
        <TableEditItemStatus
          v-if="userDb.role.id === 4 && isChangingStatus && editedItem"
          :roleID="userDb.role.id"
          :approval_statuses="approval_statuses"
          :item="editedItem"
          :items="[approval_statuses[1], approval_statuses[2]]"
          :dialogEditStatus="dialogEditStatus"
          :saleStates="['approved', 'not approved']"
          @handleDialogEditStatus="handleDialogEditStatus"
          @closeDialogEditStatus="closeDialogEditStatus"
          @editItemStatusConfirm="editItemStatusDistributorConfirm"
        />
        <!-- Supervisor -->
        <TableEditItemStatus
          v-if="userDb.role.id === 5 && isChangingStatus && editedItem"
          :roleID="userDb.role.id"
          :item="editedItem"
          :items="[approval_statuses[1],approval_statuses[2]]"
          :dialogEditStatus="dialogEditStatus"
          :saleStates="['approved', 'not approved']"
          @handleDialogEditStatus="handleDialogEditStatus"
          @closeDialogEditStatus="closeDialogEditStatus"
          @editItemStatusConfirm="editItemStatusSupervisorConfirm"
        />
        <!-- General Manager -->
        <TableEditItemStatus
          v-if="userDb.role.id === 2 && isChangingStatus && editedItem"
          :roleID="userDb.role.id"
          :item="editedItem"
          :items="[approval_statuses[1],approval_statuses[2]]"
          :dialogEditStatus="dialogEditStatus"
          :saleStates="['approved', 'not approved']"
          @handleDialogEditStatus="handleDialogEditStatus"
          @closeDialogEditStatus="closeDialogEditStatus"
          @editItemStatusConfirm="editItemStatusGeneralManagerConfirm"
        />
      </template>
      <template v-slot:no-data>
        <TableNoData />
      </template>
    </Table>
    <CreateOrEditSale
      :userDb="userDb"
      ref="createOrEditSale"
      v-if="createOrEditSaleOpen"
      :edit="edit"
      :editedIndex="editedIndex"
      :typeSale="typeSale"
      @createThisSale="createThisSale"
      @updateThisSale="updateThisSale"
      @close="close"
      @stopEditing="stopEditing"
      @saveSaleData="saveSaleData"
      :sales="sales"
      :saleSelected="saleSelected"
      :allModels="allModels"
    >
    </CreateOrEditSale>
  </div>
</template>
<script>
import PrintItemModal from '@/components/sales/PrintItemModal'
import Table from '@/components/tables/Table'
import AddItemModal from '@/components/sales/AddItemModal'
import TableDeleteItem from '@/components/tables/TableDeleteItem'
import TableNoData from '@/components/tables/TableNoData'
import TableToolbar from '@/components/tables/TableToolbar'
import TablePagination from '@/components/tables/TablePagination'
import TableFilterButton from '../components/tables/TableFilterButton'
import TableMarkStatus from '@/components/tables/TableMarkStatus'
import TableMarkIndicator from '@/components/tables/TableMarkIndicator'
import AdvancedFilter from '@/components/tables/AdvancedFilter'
import FilterOptions from '@/components/sales/FilterOptions'
import CreateOrEditSale from '@/components/sales/CreateOrEditSale'
import TableEditItemStatus from '@/components/tables/TableEditItemStatus'
import { getAllSales, getAllSaleStatuses, deleteSale, createSale, updateSale, getASaleById, getASalePdfById, getAllClients, updateSaleStatus, sendAnApplicationToClient, updateSaleDistributor, updateSaleStatusDistributor, updateSaleStatusGeneralManager, updateSaleStatusSupervisor } from '@/services/sales'
import { EventBus } from '@/EventBus.js'
import Helper from '@/helpers/Helper'
import { mapActions, mapState, mapGetters } from 'vuex'
import AuthHelper from '@/helpers/AuthHelper'
import DateHelper from '@/helpers/DateHelper'
import SaleHelper from '@/helpers/SaleHelper'
import UserHelper from '@/helpers/UserHelper'
import { getAllModels } from '@/services/globals'

export default {
  name: 'Sales',
  components: {
    Table,
    AddItemModal,
    PrintItemModal,
    TableDeleteItem,
    TableEditItemStatus,
    TableNoData,
    TableToolbar,
    TablePagination,
    TableFilterButton,
    TableMarkStatus,
    TableMarkIndicator,
    AdvancedFilter,
    FilterOptions,
    CreateOrEditSale
  },
  data () {
    return {
      sortOptions: {},
      sales: [],
      filteredSales: [],
      userDb: {},
      tableData: {
        title: 'Ventas',
        headers: [
          {
            text: 'Nro Solicitud',
            value: 'application_number',
            class: 'white'
          },
          {
            text: 'Fecha',
            value: 'date',
            class: 'white'
          },
          {
            text: 'Cliente',
            value: 'client',
            class: 'white'
          },
          {
            text: 'Vehículo',
            value: 'vehicle',
            class: 'white'
          },
          {
            text: 'Estado',
            value: 'status',
            class: 'white'
          },
          {
            text: 'Indicadores',
            value: 'indicators',
            class: 'white'
          },
          {
            text: '',
            value: 'actions',
            sortable: 'false',
            class: 'white'
          }
        ],
        items: [],
        btnAdd: {
          title: 'Nueva Reserva'
        }
      },
      editedIndex: -1,
      editedItem: {
        data: {
          status: false
        }
      },
      defaultItem: {
        data: {
          status: false
        }
      },
      typeSale: '1',
      openAddOrUpdateItem: false,
      edit: false,
      isChangingStatus: false,
      isChangingDistributorStatus: false,
      dialogDelete: false,
      dialogEditStatus: false,
      hideDefaultTableHeader: false,
      showCreateSale: false,
      showPrintSaleModal: false,
      allStatuses: [],
      allClients: [],
      allModels: [],
      saleSelected: null,
      salePdfToPrint: '',
      approval_manager: '',
      approval_distributor: '',
      approval_distributor_status: {},
      approval_supervisor: '',
      approval_statuses: [
        {
          id: null,
          name: 'Pendiente',
          color: '#FFB70B'
        },
        {
          id: 0,
          name: 'Rechazada',
          color: '#FF460B'
        },
        {
          id: 1,
          name: 'Aprobada',
          color: '#00D422'
        }
      ]
    }
  },
  computed: {
    ...mapState(['userToken'], ['newSaleData']),
    ...mapGetters(['createOrEditSaleOpen', 'printSaleModalOpen', 'newSale', 'salesClient', 'allSalesData', 'currentSale', 'searchData', 'getUserDb'])
  },
  methods: {
    ...mapActions(['handleLoading', 'handleAlert', 'handleNewSaleData', 'handleAllSalesData', 'handleAllClientsData', 'handleEditedSaleData',
      'handleSetSaleData', 'handleCurrentStep', 'handleSaleStatus', 'handleSetSaleData', 'handleSetSaleDataByStep', 'handlePrintSaleModal']),
    rowClicked (item, rowData) {
      this.editSale(item)
    },
    async getSales () {
      try {
        const res = await getAllSales(this.userToken)
        this.sales = res.data.data
        this.approval_distributor = this.sales.approval_distributor
        this.approval_manager = this.sales.approval_manager
        this.approval_supervisor = this.sales.approval_supervisor
        this.alertMessage = res.data.message
        this.alertColor = 'success'
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
        this.alertMessage = error?.data?.message
        this.alertColor = 'error'
      } finally {
        this.handleAlert({
          value: true,
          text: this.alertMessage,
          color: this.alertColor
        })
      }
    },
    async getSaleById (id) {
      try {
        this.handleLoading(true)
        const res = await getASaleById(this.userToken, id)
        const saleSelected = await this.transformResponseToSaleData(res.data.data)
        saleSelected.id = id
        this.handleSetSaleData(JSON.parse(JSON.stringify(saleSelected)))
        this.saleSelected = JSON.parse(JSON.stringify(saleSelected))
        this.alertMessage = res.data.message
        this.alertColor = 'success'
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
        this.alertMessage = error?.data?.message ? error.data.message : error
        this.alertColor = 'error'
        console.error(error)
      } finally {
        this.handleLoading(false)
        this.handleAlert({
          value: true,
          text: this.alertMessage,
          color: this.alertColor
        })
      }
    },
    async getClients () {
      try {
        const res = await getAllClients(this.userToken)
        this.handleAllClientsData()
        this.allClients = res.data.data
        this.alertMessage = res.data.message
        this.alertColor = 'success'
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
        this.alertMessage = error?.data?.message ? error.data.message : error
        this.alertColor = 'error'
      } finally {
        this.handleAlert({
          value: true,
          text: this.alertMessage,
          color: this.alertColor
        })
      }
    },
    async getModels () {
      try {
        const res = await getAllModels(this.userToken)
        this.allModels = res.data.data
        this.alertMessage = res.data.message
        this.alertColor = 'success'
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
        this.alertMessage = error?.data?.message ? error.data.message : error
        this.alertColor = 'error'
      } finally {
        this.handleAlert({
          value: true,
          text: this.alertMessage,
          color: this.alertColor
        })
      }
    },
    transformResponseToSaleData (response) {
      const usedVehicles = []
      response.payment_method.used_vehicle.forEach(uv => {
        usedVehicles.push({
          id: uv.id,
          vehicle_id: uv.vehicle.id,
          brand: uv.vehicle.vehicle_model.vehicle_brand.id,
          vehicle_model_id: uv.vehicle.vehicle_model_id,
          domain: uv.domain,
          car_year: uv.vehicle.year,
          fuel_type: uv.vehicle.vehicle_model.vehicle_fuel.id,
          doors: uv.vehicle.vehicle_model.vehicle_doors,
          color: uv.vehicle.vehicles_colors_id,
          transmition: uv.vehicle.transmition_id,
          estimated_price: uv.estimated_price
        })
      })
      const condominium = {}
      const client = {}
      const insurance = {}
      if (response.client_data.client_details.client_type.id === 1 && response.client_data.condominium_details !== null) {
        condominium.condominium_id = response.client_data.condominium_details.condominium_id
        condominium.condominium_firstname = response.client_data.condominium_details.condominium_firstname
        condominium.condominium_lastname = response.client_data.condominium_details.condominium_lastname
        condominium.condominium_dni = response.client_data.condominium_details.condominium_dni
        condominium.condominium_email = response.client_data.condominium_details.condominium_email
        condominium.condominium_birth_date = response.client_data.condominium_details.condominium_birth_date
        condominium.condominium_marital_status = response.client_data.condominium_details.condominium_marital_status
        condominium.condominium_adress = response.client_data.condominium_details.condominium_adress
        condominium.condominium_adress_number = response.client_data.condominium_details.condominium_adress_number
        condominium.condominium_adress_floor = response.client_data.condominium_details.condominium_adress_floor
        condominium.condominium_adress_department = response.client_data.condominium_details.condominium_adress_department
        condominium.condominium_locality = response.client_data.condominium_details.condominium_adress_locality
        condominium.condominium_adress_zip_code = response.client_data.condominium_details.condominium_adress_zip_code
        condominium.condominium_phone = response.client_data.condominium_details.condominium_phone
        condominium.condominium_mobile = response.client_data.condominium_details.condominium_mobile
        condominium.condominium_type = response.client_data.condominium_details.condominium_type
        condominium.condominium_adress_locality = response.client_data.condominium_details.condominium_adress_locality.id
      } else {
        client.trade_name = response.client_data.client_details.client_trade_name
        client.cuit = response.client_data.client_details.client_cuit
      }
      if (response.payment_method.insurance !== null) {
        if (response.payment_method.insurance.coverage !== null) {
          insurance.coverage_id = parseInt(response.payment_method.insurance.coverage.id)
          insurance.coverage = parseInt(Helper.transformTwoDecimalFloatToInt(response.payment_method.insurance.coverage))
        } else {
          insurance.coverage_id = ''
          insurance.coverage = ''
        }
        insurance.id = response.payment_method.insurance.id
        insurance.aproximate_fee = response.payment_method.insurance.approximate_fee !== null ? Helper.transformTwoDecimalFloatToInt(response.payment_method.insurance.approximate_fee) : 0
        insurance.company = response.payment_method.insurance.company
        insurance.insurable_sum = response.payment_method.insurance.insurable_sum !== null ? parseInt(Helper.transformTwoDecimalFloatToInt(response.payment_method.insurance.insurable_sum)) : 0
      } else {
        insurance.id = ''
        insurance.aproximate_fee = ''
        insurance.coverage_id = ''
        insurance.coverage = ''
        insurance.company = ''
        insurance.insurable_sum = ''
      }
      const accesoriesItems = this.transformResponseAccesoriesToSaleDataAccesories(response.vehicle.accessories)
      return {
        sale: {
          id: response.sale.id,
          operation_number: response.sale.operation_number,
          sale_status: response.sale.sale_status,
          created_at: response.sale.created_at
        },
        vehicle: {
          accesories: accesoriesItems,
          car_color_id: response.application?.application_details?.vehicle_color_id,
          car_fuel_id: response.vehicle?.vehicle_reservation?.car_fuel?.id,
          car_model_id: response.vehicle?.vehicle_reservation?.car_model?.id,
          id: response.vehicle?.vehicle_reservation?.id,
          industrial_code: response.vehicle?.vehicle_reservation?.industrial_code,
          model: response.vehicle?.vehicle_reservation?.car_model?.id,
          vehicle_type: response.vehicle.vehicle_reservation !== null ? response.vehicle.vehicle_reservation.vehicle_type : null,
          vin_code: response.vehicle?.vehicle_reservation?.vin,
          year: response.vehicle?.vehicle_reservation?.car_year,
          domain: response.vehicle?.vehicle_reservation?.domain,
          fuel_type: response.vehicle?.vehicle_reservation?.car_fuel?.id,
          doors: response.vehicle?.vehicle_reservation?.car_doors,
          color: response.application?.application_details?.vehicle_color_id,
          list_price: Helper.transformTwoDecimalFloatToInt(response.payment_method.billing_data.list_price),
          renault_bonus: Helper.transformTwoDecimalFloatToInt(response.payment_method.billing_data.renault_bonus),
          subtotal: Helper.transformTwoDecimalFloatToInt(response.payment_method.billing_data.subtotal),
          lepic_bonification: Helper.transformTwoDecimalFloatToInt(response.payment_method.billing_data.lepic_discount),
          total: Helper.transformTwoDecimalFloatToInt(response.payment_method.billing_data.invoiced_total),
          total_accesories: Helper.transformTwoDecimalFloatToInt(response.payment_method.billing_data.accessories),
          patenting: Helper.transformTwoDecimalFloatToInt(response.payment_method.sale_management.patenting),
          patentType: parseInt(response.payment_method.sale_management.type_patenting),
          ldLgVu: this.$options.filters.magnitude(Helper.transformTwoDecimalFloatToInt(response.payment_method.sale_management.ld_lg_vu)),
          lepic_bonification_management: this.$options.filters.magnitude(Helper.transformTwoDecimalFloatToInt(response.payment_method.sale_management.lepic_discount)),
          credit: this.$options.filters.magnitude(Helper.transformTwoDecimalFloatToInt(response.payment_method.sale_management.credit_management)),
          formsPPE: this.$options.filters.magnitude(Helper.transformTwoDecimalFloatToInt(response.payment_method.sale_management.form_ppe)),
          others: this.$options.filters.magnitude(Helper.transformTwoDecimalFloatToInt(response.payment_method.sale_management.others)),
          total_management: Helper.transformTwoDecimalFloatToInt(response.payment_method.sale_management.total_management),
          total_operation: Helper.transformTwoDecimalFloatToInt(response.payment_method.sale_management.total_operation)
        },
        payment_method: {
          billing_data_id: response.payment_method.billing_data.id,
          insurance_id: insurance.id,
          coverage_id: insurance.coverage_id,
          coverageSelected: insurance.coverage_id,
          payment_method_details_id: !isNaN(response.payment_method.payment_method_details.id) ? response.payment_method.payment_method_details.id : null,
          sale_management_id: response.payment_method.sale_management.id,
          sale_payment_method_id: response.payment_method.sale_payment_method.id,
          used_vehicle_id: response.payment_method.used_vehicle.id,
          financed_detail: response.payment_method.payment_method_details.financed !== null ? this.$options.filters.magnitude(Helper.transformTwoDecimalFloatToInt(response.payment_method.payment_method_details.financed)) : 0,
          credit: response.payment_method.payment_method_details.credit_to_manage !== null ? this.$options.filters.magnitude(Helper.transformTwoDecimalFloatToInt(response.payment_method.payment_method_details.credit_to_manage)) : '',
          dues: response.payment_method.payment_method_details.dues,
          bank: response.payment_method.payment_method_details.bank,
          plan: response.payment_method.payment_method_details.plan,
          insurable_sum: insurance.insurable_sum !== null ? this.$options.filters.magnitude(Helper.transformTwoDecimalFloatToInt(insurance.insurable_sum)) : 0,
          aproximate_fee: insurance.aproximate_fee !== null ? this.$options.filters.magnitude(Helper.transformTwoDecimalFloatToInt(insurance.aproximate_fee)) : 0,
          coverage: insurance.coverage,
          company: insurance.company,
          deposit: this.$options.filters.magnitude(Helper.transformTwoDecimalFloatToInt(response.payment_method.sale_payment_method.deposit)),
          cash: this.$options.filters.magnitude(Helper.transformTwoDecimalFloatToInt(response.payment_method.sale_payment_method.cash)),
          financed: this.$options.filters.magnitude(Helper.transformTwoDecimalFloatToInt(response.payment_method.payment_method_details.financed)),
          used_value: this.$options.filters.magnitude(Helper.transformTwoDecimalFloatToInt(response.payment_method.sale_payment_method.used_value)),
          total_operation: this.$options.filters.magnitude(Helper.transformTwoDecimalFloatToInt(response.payment_method.sale_payment_method.operation_total)),
          sale_management: this.$options.filters.magnitude(Helper.transformTwoDecimalFloatToInt(response.payment_method.sale_management)),
          used_vehicle: usedVehicles
        },
        client_data: {
          client_id: response.client_data.client_details.client_id,
          client_firstname: response.client_data.client_details.client_firstname,
          client_lastname: response.client_data.client_details.client_lastname,
          client_dni: response.client_data.client_details.client_dni,
          client_email: response.client_data.client_details.client_email,
          client_birth_date: response.client_data.client_details.client_birth_date,
          client_marital_status: response.client_data.client_details.client_marital_status,
          client_adress: response.client_data.client_details.client_adress,
          client_adress_number: response.client_data.client_details.client_adress_number,
          client_adress_floor: response.client_data.client_details.client_adress_floor,
          client_adress_department: response.client_data.client_details.client_adress_department,
          client_adress_locality: response.client_data.client_details.client_adress_locality.id,
          client_locality: response.client_data.client_details.client_adress_locality,
          client_adress_zip_code: response.client_data.client_details.client_adress_zip_code,
          client_phone: response.client_data.client_details.client_phone,
          client_mobile: response.client_data.client_details.client_mobile,
          client_type: response.client_data.client_details.client_type,
          client_trade_name: Object.keys(client).length === 0 ? null : client.trade_name,
          client_cuit: Object.keys(client).length === 0 ? null : client.cuit
        },
        application: {
          id: response.application.application_details.id,
          estimated_delivery_date: response.application.application_details.estimated_delivery_date,
          is_final_review_done: !!response.application.application_details.is_final_review_done,
          is_documents_attached: !!response.application.application_details.is_documents_attached,
          is_printed_and_signed_by_client: !!response.application.application_details.is_printed_and_signed_by_client,
          is_approval_request_sent: !!response.application.application_details.is_approval_request_sent,
          is_drive_test_offered: !!response.application.application_details.is_drive_test_offered,
          is_drive_test_done: !!response.application.application_details.is_drive_test_done,
          observations: response.application.application_details.observations !== 'null' ? response.application.application_details.observations : '',
          related_documentation: response.application.related_documentation,
          vehicle_color_id: response.application?.application_details?.vehicle_color_id,
          vehicle_id: response.application?.application_details?.vehicle_id,
          vehicle_model_id: response.application?.application_details?.vehicle_model_id !== null ? response.application?.application_details?.vehicle_model_id : '',
          condominium: condominium === {} ? null : condominium
        }
      }
    },
    transformResponseAccesoriesToSaleDataAccesories (accesories) {
      const accesoriesSaleData = []
      accesories.forEach(a => {
        accesoriesSaleData.push({
          accesory: a.accesorie_data,
          cant: a.quantity
        })
      })
      return accesoriesSaleData
    },
    formatSalesTable (sales) {
      this.tableData.items = []
      if (this.userDb.role.id === 4) {
        sales.forEach(s => { this.tableData.items.push(this.getFormatSaleTableDistributor(s)) })
      } else if (this.userDb.role.id === 2) {
        sales.forEach(s => { this.tableData.items.push(this.getFormatSaleTableManager(s)) })
      } else if (this.userDb.role.id === 5) {
        sales.forEach(s => { this.tableData.items.push(this.getFormatSaleTableSupservisor(s)) })
      } else {
        sales.forEach(s => { this.tableData.items.push(this.getFormatSaleTable(s)) })
      }
    },
    async getSalesStatuses () {
      try {
        const res = await getAllSaleStatuses(this.userToken)
        this.allStatuses = res.data.data
        this.alertMessage = res.data.message
        this.alertColor = 'success'
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
        this.alertMessage = error?.data?.message ? error.data.message : error
        this.alertColor = 'error'
      } finally {
        this.handleAlert({
          value: true,
          text: this.alertMessage,
          color: this.alertColor
        })
      }
    },
    filter () {
      this.filteredSales = this.sales.filter(s => SaleHelper.filterByStatus(s, this.searchData.status) &&
        SaleHelper.filterByClient(s, this.searchData.client) &&
        SaleHelper.filterByModel(s, this.searchData.car_model)
      )
      this.formatSalesTable(this.filteredSales)
      this.$store.dispatch('handleAdvancedFilter', false)
    },
    getFormatSaleTable (s) {
      const c = Helper.getArrayValueFromId(s.reservation.client_id, this.allClients)
      return {
        id: s.id,
        application_number: '00000' + s.id,
        date: DateHelper.transformDateToFormat(s.created_at, 'L'),
        vehicle: s.model_brand + ' - ' + s.model_name,
        client: c.name + ' ' + c.lastname,
        saler: s.user.name + ' ' + s.user.last_name,
        status: s.sale_status?.name,
        status_color: s.sale_status?.color,
        indicators: {
          autoLogicIndicator: s.operation_number !== null,
          insuranceIndicator: s.insurance === true,
          accessoriesIndicator: s.accesories === true,
          creditIndicator: s.manage_credit === true
        },
        actions: [
          {
            text: 'Cambiar estado',
            fn: (item) => {
              this.changeStatus(item.data)
            },
            classes: ''
          },
          {
            // icon: 'mdi-pencil',
            text: 'Editar',
            fn: (item) => {
              this.editSale(item)
            },
            classes: ''
          },
          {
            text: 'Imprimir Solicitud de Reserva',
            fn: (item) => {
              this.printApplication(item)
            },
            classes: ''
          },
          {
            text: 'Enviar al cliente',
            fn: (item) => {
              this.sendApplicationToClient(item)
            },
            classes: ''
          },
          {
            // icon: 'mdi-delete',
            text: 'Eliminar',
            fn: (item) => {
              this.deleteSale(item)
            },
            classes: ''
          }
        ],
        data: s
      }
    },
    getFormatSaleTableDistributor (s) {
      const c = Helper.getArrayValueFromId(s.reservation.client_id, this.allClients)
      return {
        id: s.id,
        application_number: '00000' + s.id,
        date: DateHelper.transformDateToFormat(s.created_at, 'L'),
        vehicle: s.model_brand + ' - ' + s.model_name,
        client: c.name + ' ' + c.lastname,
        saler: s.user.name + ' ' + s.user.last_name,
        status: this.getStatusInfo(s.approval_distributor).name,
        status_color: this.getStatusInfo(s.approval_distributor).color,
        indicators: {
          autoLogicIndicator: s.operation_number !== null,
          insuranceIndicator: s.insurance === true,
          accessoriesIndicator: s.accesories === true,
          creditIndicator: s.manage_credit === true
        },
        actions: [
          {
            // icon: 'mdi-pencil',
            text: 'Editar',
            fn: (item) => {
              this.editSale(item)
            },
            classes: ''
          },
          {
            text: 'Cambiar Estado',
            fn: (item) => {
              this.changeStatus(item.data)
            },
            classes: ''
          }
        ],
        data: s
      }
    },
    getFormatSaleTableSupservisor (s) {
      const c = Helper.getArrayValueFromId(s.reservation.client_id, this.allClients)
      return {
        id: s.id,
        application_number: '00000' + s.id,
        date: DateHelper.transformDateToFormat(s.created_at, 'L'),
        vehicle: s.model_brand + ' - ' + s.model_name,
        client: c.name + ' ' + c.lastname,
        saler: s.user.name + ' ' + s.user.last_name,
        status: this.getStatusInfo(s.approval_supervisor).name,
        status_color: this.getStatusInfo(s.approval_supervisor).color,
        indicators: {
          autoLogicIndicator: s.operation_number !== null,
          insuranceIndicator: s.insurance === true,
          accessoriesIndicator: s.accesories === true,
          creditIndicator: s.manage_credit === true
        },
        actions: [
          {
            text: 'Cambiar Estado',
            fn: (item) => {
              this.changeStatus(item.data)
            },
            classes: ''
          }
        ],
        data: s
      }
    },
    getFormatSaleTableManager (s) {
      const c = Helper.getArrayValueFromId(s.reservation.client_id, this.allClients)
      return {
        id: s.id,
        application_number: '00000' + s.id,
        date: DateHelper.transformDateToFormat(s.created_at, 'L'),
        vehicle: s.model_brand + ' - ' + s.model_name,
        client: c.name + ' ' + c.lastname,
        saler: s.user.name + ' ' + s.user.last_name,
        status: this.getStatusInfo(s.approval_manager).name,
        status_color: this.getStatusInfo(s.approval_manager).color,
        indicators: {
          autoLogicIndicator: s.operation_number !== null,
          insuranceIndicator: s.insurance === true,
          accessoriesIndicator: s.accesories === true,
          creditIndicator: s.manage_credit === true
        },
        actions: [
          {
            text: 'Cambiar Estado',
            fn: (item) => {
              this.changeStatus(item.data)
            },
            classes: ''
          }
        ],
        data: s
      }
    },
    handleAddOrUpdate (value) {
      this.handleCurrentStep(1)
      this.openAddOrUpdateItem = value
    },
    handlePrintModal (value) {
      this.showPrintSaleModal = value
    },
    selectEditedItem (item) {
      this.editedIndex = this.sales.findIndex(s => s.id === item.id)
      if (this.editedIndex !== -1) {
        this.editedItem = item
      }
    },
    handleDialogEditStatus (typeId, value) {
      if (!typeId) {
        this.dialogEditStatus = value
      } else {
        if (typeId === 'distributor') {
          this.dialogEditDistributorStatus = value
        }
      }
    },
    closeDialogEditStatus () {
      this.dialogEditStatus = false
      this.isChangingStatus = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    changeStatus (item) {
      this.selectEditedItem(item)
      if (this.editedIndex !== -1) {
        this.isChangingStatus = true
        this.dialogEditStatus = true
      }
    },
    editItemStatusConfirm () {
      this.updateThisSaleStatus()
      this.closeDialogEditStatus()
    },
    editItemStatusDistributorConfirm () {
      this.updateThisSaleStatusDistributor()
    },
    editItemStatusSupervisorConfirm () {
      this.updateThisSaleStatusSupervisor()
    },
    editItemStatusGeneralManagerConfirm () {
      this.updateThisSaleStatusGeneralManager()
    },
    async updateThisSaleStatus () {
      try {
        this.handleLoading(true)
        const res = await updateSaleStatus(this.userToken, this.editedItem.sale_status.id, this.editedItem.id)
        this.sales[this.editedIndex] = this.editedItem
        this.formatSalesTable(this.sales)
        this.alertMessage = res.data.message
        this.alertColor = 'success'
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
        this.alertMessage = error?.data?.message ? error.data.message : error
        this.alertColor = 'error'
      } finally {
        this.handleLoading(false)
        this.handleAlert({
          value: true,
          text: this.alertMessage,
          color: this.alertColor
        })
      }
    },
    isCreditPdfUploaded (sale) {
      return sale.application.related_documentation.some(doc => doc.type.attachments_types_id === 5)
    },
    async updateThisSaleStatusDistributor () {
      try {
        this.handleLoading(true)
        await this.getSaleById(this.editedItem.id)
        if (this.editedItem.approval_distributor.id === 1) {
        // cargado en autológica? (hay operation_number asignado?)
          if (this.editedItem.operation_number !== null) {
            await this.getSaleById(this.editedItem.id)
            // hay crédito cargado?
            if (this.saleSelected.payment_method.credit !== '') {
              // hay pdf de credito cargado?
              if (this.isCreditPdfUploaded(this.saleSelected)) {
                const res = await updateSaleStatusDistributor(this.userToken, this.editedItem.approval_distributor.id, this.editedItem.id)
                this.sales[this.editedIndex].approval_distributor = res.data.data.approval_distributor
                this.formatSalesTable(this.sales)
                this.alertMessage = res.data.message
                this.alertColor = 'success'
              } else {
                this.alertMessage = 'Por favor adjunte comprobante de crédito antes de aprobar la reserva.'
                this.alertColor = 'error'
              }
            } else {
              const res = await updateSaleStatusDistributor(this.userToken, this.editedItem.approval_distributor.id, this.editedItem.id)
              this.sales[this.editedIndex].approval_distributor = res.data.data.approval_distributor
              this.formatSalesTable(this.sales)
              this.alertMessage = res.data.message
              this.alertColor = 'success'
            }
            this.saleSelected = null
          } else {
            this.alertMessage = 'Por favor agregue número de operación de auto lógica antes de aprobar la reserva.'
            this.alertColor = 'error'
          }
        } else {
          const res = await updateSaleStatusDistributor(this.userToken, this.editedItem.approval_distributor.id, this.editedItem.id)
          this.sales[this.editedIndex].approval_distributor = res.data.data.approval_distributor
          this.formatSalesTable(this.sales)
          this.alertMessage = res.data.message
          this.alertColor = 'success'
        }
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
        this.alertMessage = error?.data?.message ? error.data.message : error
        this.alertColor = 'error'
      } finally {
        this.handleLoading(false)
        this.handleAlert({
          value: true,
          text: this.alertMessage,
          color: this.alertColor
        })
        this.closeDialogEditStatus()
      }
    },
    async updateThisSaleStatusSupervisor () {
      try {
        this.handleLoading(true)
        const res = await updateSaleStatusSupervisor(this.userToken, this.editedItem.approval_supervisor.id, this.editedItem.id)
        this.sales[this.editedIndex].approval_supervisor = res.data.data.approval_supervisor
        this.formatSalesTable(this.sales)
        this.alertMessage = res.data.message
        this.alertColor = 'success'
        this.closeDialogEditStatus()
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
        this.alertMessage = error?.data?.message ? error.data.message : error
        this.alertColor = 'error'
      } finally {
        this.handleLoading(false)
        this.handleAlert({
          value: true,
          text: this.alertMessage,
          color: this.alertColor
        })
      }
    },
    async updateThisSaleStatusGeneralManager () {
      try {
        this.handleLoading(true)
        const res = await updateSaleStatusGeneralManager(this.userToken, this.editedItem.approval_manager.id, this.editedItem.id)
        this.sales[this.editedIndex].approval_manager = res.data.data.approval_manager
        this.formatSalesTable(this.sales)
        this.alertMessage = res.data.message
        this.alertColor = 'success'
        this.closeDialogEditStatus()
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
        this.alertMessage = error?.data?.message ? error.data.message : error
        this.alertColor = 'error'
      } finally {
        this.handleLoading(false)
        this.handleAlert({
          value: true,
          text: this.alertMessage,
          color: this.alertColor
        })
      }
    },
    async createThisSale (sale) {
      try {
        this.handleLoading(true)
        const res = await createSale(this.userToken, sale)
        this.sales.unshift(res.data.data)
        await this.getClients()
        this.formatSalesTable(this.sales)
        this.alertMessage = res.data.message || 'Cargado correctamente'
        this.alertColor = 'success'
        this.stopEditing()
        this.$store.dispatch('handleCreateSale', false)
        this.close()
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
        this.alertMessage = error?.data?.message ? error.data.message : error
        this.alertColor = 'error'
      } finally {
        this.handleLoading(false)
        this.handleAlert({
          value: true,
          text: this.alertMessage,
          color: this.alertColor
        })
      }
    },
    async updateThisSale (sale, id, status) {
      const saleToUpload = sale
      saleToUpload.append('_method', 'PUT')
      try {
        this.handleLoading(true)
        if (this.userDb.role.id === 4) {
          const res = await updateSaleDistributor(this.userToken, saleToUpload, id)
          this.sales[this.editedIndex] = res.data.data
          this.formatSalesTable(this.sales)
          this.alertMessage = res.data.message
        } else {
          const res = await updateSale(this.userToken, saleToUpload, id)
          this.sales[this.editedIndex] = res.data.data
          this.formatSalesTable(this.sales)
          this.alertMessage = res.data.message
        }
        this.alertColor = 'success'
        this.stopEditing()
        this.$store.dispatch('handleCreateSale', false)
        this.close()
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
        this.alertMessage = error?.data?.message ? error.data.message : error
        this.alertColor = 'error'
      } finally {
        this.handleLoading(false)
        this.handleAlert({
          value: true,
          text: this.alertMessage,
          color: this.alertColor
        })
      }
    },
    stopEditing () {
      this.edit = false
      this.$refs.createOrEditSale.setInitialState()
      this.typeSale = '1'
    },
    async editSale (item) {
      await this.selectEditedItem(item)
      await this.handleSetSaleData(item)
      this.handleCurrentStep(1)
      if (this.editedIndex !== -1) {
        await this.getSaleById(item.id)
        if (this.saleSelected.vehicle.vehicle_type !== null) {
          this.saleSelected.vehicle.vehicle_type.id === 1
            ? this.typeSale = '1'
            : this.typeSale = '2'
        }
        this.edit = true
        this.start()
      }
    },
    saveSaleData (currentStep, payload) {
      this.handleSetSaleDataByStep(currentStep, payload)
    },
    async sendApplicationToClient (item) {
      try {
        this.handleLoading(true)
        await sendAnApplicationToClient(this.userToken, item.id)
        this.alertMessage = 'Solicitud enviada al cliente'
        this.alertColor = 'success'
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
        this.alertMessage = error?.data?.message ? error.data.message : error
        this.alertColor = 'error'
      } finally {
        this.handleLoading(false)
        this.handleAlert({
          value: true,
          text: this.alertMessage,
          color: this.alertColor
        })
      }
    },
    async printApplication (item) {
      await this.getSalePdfById(item.id)
    },
    getStatusInfo (id) {
      if (id === null) {
        return this.approval_statuses[0]
      } else {
        return this.approval_statuses.find(status => status.id === id)
      }
    },
    async getSalePdfById (id) {
      try {
        this.handleLoading(true)
        await getASalePdfById(this.userToken, id)
        this.alertMessage = 'Pdf descargado'
        this.alertColor = 'success'
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
        this.alertMessage = error?.data?.message ? error.data.message : error
        this.alertColor = 'error'
      } finally {
        this.handleLoading(false)
        this.handleAlert({
          value: true,
          text: this.alertMessage,
          color: this.alertColor
        })
      }
    },
    close () {
      this.showPrintSaleModal = false
      this.handleAddOrUpdate(false)
      this.$store.dispatch('handlePrintSaleModal', false)
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    deleteSale (item) {
      this.selectEditedItem(item)
      if (this.editedIndex !== -1) {
        this.dialogDelete = true
      }
    },
    handleDialogDelete (value) {
      this.dialogDelete = value
    },
    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    async deleteItemConfirm () {
      try {
        this.handleLoading(true)
        const res = await deleteSale(this.userToken, this.editedItem.id)
        this.sales.splice(this.editedIndex, 1)
        this.formatSalesTable(this.sales)
        this.alertMessage = res.data.message
        this.alertColor = 'success'
      } catch (error) {
        this.handleLoading(false)
        AuthHelper.checkErrorExpiredToken(error)
        this.alertMessage = error?.data?.message ? error.data.message : error
        this.alertColor = 'error'
      } finally {
        this.handleLoading(false)
        this.handleAlert({
          value: true,
          text: this.alertMessage,
          color: this.alertColor
        })
        this.closeDelete()
      }
    },
    start () {
      this.openAddOrUpdateItem = false
      this.$store.dispatch('handleCreateSale', true)
    },
    searchSales (sales, search) {
      if (search !== '') {
        search = search.toString().toLocaleLowerCase()
        this.filteredSales = sales.filter(s => this.filterSalesBySearchParam(s, search))
      } else {
        this.filteredSales = this.sales
      }
      this.formatSalesTable(this.filteredSales)
    },
    filterSalesBySearchParam (sale, search) {
      return Helper.searchItemByField(sale, 'id', search)
    }
  },
  async mounted () {
    this.handleLoading(true)
    this.$store.dispatch('handleCreateSale', false)
    await this.getSalesStatuses()
    await this.getClients()
    await this.getSales()
    await this.getModels()
    this.userDb = this.getUserDb
    this.formatSalesTable(this.sales)
    this.handleLoading(false)
    EventBus.$on('searchSales', (input) => this.searchSales(this.sales, input))
    if (!UserHelper.isSaler(this.userDb.role.id)) {
      this.tableData.headers = [
        {
          text: 'Nro Solicitud',
          value: 'application_number',
          class: 'white'
        },
        {
          text: 'Fecha',
          value: 'date',
          class: 'white'
        },
        {
          text: 'Cliente',
          value: 'client',
          class: 'white'
        },
        {
          text: 'Vehículo',
          value: 'vehicle',
          class: 'white'
        },
        {
          text: 'Estado',
          value: 'status',
          class: 'white'
        },
        {
          text: 'Indicadores',
          value: 'indicators',
          class: 'white'
        },
        {
          text: 'Vendedor',
          value: 'saler',
          class: 'white'
        },
        {
          text: '',
          value: 'actions',
          sortable: 'false',
          class: 'white'
        }
      ]
      // gerente general
      if (UserHelper.isGeneralManager(this.userDb.role.id)) {
        const salesFromThisRole = this.sales.filter(s => s.approval_distributor === 1 && s.approval_supervisor === 1)
        this.sales = salesFromThisRole
        this.formatSalesTable(this.sales)
        this.sortOptions = {
          sortBy: ['status', 'application_number'],
          sortDesc: [false, true]
        }
      }
      // administración
      if (UserHelper.isAdministration(this.userDb.role.id)) {
        const salesFromThisRole = this.sales.filter(s => s.approval_manager === 1)
        this.sales = salesFromThisRole
        this.formatSalesTable(this.sales)
      }
      // distribuidor
      if (UserHelper.isDistribution(this.userDb.role.id)) {
        const salesFromThisRole = this.sales.filter(s => s.approval_supervisor === 1)
        this.sales = salesFromThisRole
        this.formatSalesTable(this.sales)
        this.sortOptions = {
          sortBy: ['status', 'application_number'],
          sortDesc: [false, true]
        }
      }
      // supervisor
      if (UserHelper.isSupervisor(this.userDb.role.id)) {
        const salesFromThisRole = this.sales.filter(s => s.approval_supervisor === 0 || s.approval_supervisor === null)
        this.sales = salesFromThisRole
        this.formatSalesTable(this.sales)
        this.sortOptions = {
          sortBy: ['status', 'application_number'],
          sortDesc: [false, true]
        }
      }
      // vendedor
    } else if (UserHelper.isSaler(this.userDb.role.id)) {
      const salesFromThisRole = this.sales.filter(s => s.user.id === this.userDb.id)
      this.sales = salesFromThisRole
      this.formatSalesTable(this.sales)
      this.sortOptions = {
        sortBy: ['status', 'application_number'],
        sortDesc: [false, true]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
