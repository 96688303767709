<template>
  <v-card-text class="pa-0 d-flex flex-column" style="height: 90%;min-height: 600px;">
    <Questions
      ref="sqBasic"
      @goToNextQuestion="goToNextQuestion"
      :dataSeted="dataSeted"
      :btnWidth="btnWidth"
      :question="questionData.questions[0]"
      :screens_question="questionData.question"
      :sq_aclaration="questionData.aclaration"
      @selectOption="selectOption"
      @sendAnswer="sendAnswer"
      @changeDataSeted="changeDataSeted">
    </Questions>
    <transition name="ques">
      <NextQuestionButton
        v-if="showButton"
        @goToNextQuestion="goToNextQuestion">
      </NextQuestionButton>
    </transition>
    <v-btn
      v-if="continueScoringButton"
      @click="goToPreviousQues"
      outlined
      width="250"
      class="mt-10 rounded"
      style="background-color: #FFF8E1;border: 1px solid #FFB70B;">
      <span style="color: #FFB70B;font-weight: bold;">Continuar scoring</span>
    </v-btn>
    <v-dialog v-model="pendigAlert" max-width="600px">
      <PendingAlert
        :showDescription="true"
        :pendingMessage="questionData.questions[0].options[0]"
        @exitAlert="exitAlert">
      </PendingAlert>
    </v-dialog>
  </v-card-text>
</template>

<script>
import Questions from '@/components/savings/blocks/Questions'
import NextQuestionButton from '@/components/savings/blocks/NextQuestionButton'
import PendingAlert from '@/components/savings/blocks/PendingAlert'
import { mapGetters } from 'vuex'
import Helper from '../../../helpers/Helper'

export default {
  name: 'SingleSQ',
  components: {
    Questions,
    NextQuestionButton,
    PendingAlert
  },
  props: {
    questionData: Object,
    btnWidth: Number,
    oneOption: Boolean,
    pendingOption: Boolean,
    sq_numberData: String,
    sqOptions: Array
  },
  data () {
    return {
      showButton: false,
      dataSeted: false,
      pendigAlert: false,
      decisionQuestionFirstValue: '',
      historyFirstValue: [],
      historyScrap: [],
      deleteData: [],
      sq_data: [
        {
          question_id: this.questionData.questions[0].id,
          ponderation: '',
          updated_at: ''
        }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'getOperationNumber',
      'scoringData',
      'isBrowsingInScoringHistory',
      'continueScoring',
      'getIndexOfCurrentSQ',
      'getHistory',
      'SQNameByNumber',
      'sq_number',
      'continueScoringButton'
    ])
  },
  methods: {
    setData () {
      if (this.scoringData[this.sq_numberData] !== null) {
        if (this.pendingOption) {
          this.showButton = false
        } else {
          this.showButton = true
        }
        this.$emit('handleProgress', 3)
        this.$refs.sqBasic.setAnswer(this.scoringData[this.sq_numberData][0].ponderation)
        this.sq_data[0].ponderation = this.scoringData[this.sq_numberData][0].ponderation
        this.sq_data[0].updated_at = this.scoringData[this.sq_numberData][0].updated_at
        if (this.continueScoring) {
          if (this.scoringData[this.sq_numberData][0].id !== undefined) {
            this.sq_data[0].id = this.scoringData[this.sq_numberData][0].id
          }
        }
        if (this.isBrowsingInScoringHistory) {
          this.decisionQuestionFirstValue = this.scoringData[this.sq_numberData][0].ponderation
          this.historyFirstValue = this.getHistory
        }
      }
    },
    selectOption (value, ponder, time) {
      if (this.pendingOption) {
        ponder = 'ponderation'
        time = 'updated_at'
        this.sq_data[0][ponder] = value
        this.sq_data[0][time] = Helper.setDateAndTime()
        this.$store.dispatch('handleScoringData', this.sq_data)
        this.pendigAlert = true
      } else {
        this.dataFromQuestions(value, 'ponderation', 'updated_at')
      }
    },
    sendAnswer (value) {
      this.dataFromQuestions(value, 'ponderation', 'updated_at')
    },
    useButton (value) {
      if (value !== null) {
        this.showButton = true
      }
    },
    dataFromQuestions (value, ponder, time) {
      this.sq_data[0][ponder] = value
      this.sq_data[0][time] = Helper.setDateAndTime()
      this.useButton(value)
    },
    changeDataSeted (value) {
      this.dataSeted = value
    },
    exitAlert () {
      this.pendigAlert = false
    },
    handleIfEditQues (index, ques) {
      if (this.sq_data[index].id !== undefined && (ques !== this.sq_data[index].ponderation)) {
        this.$emit('editQuestion', this.sq_data[index])
      }
    },
    goToPreviousQues () {
      this.$store.dispatch('handleShowContinueScoringButton', false)
      this.$emit('goBackInScoringHistory')
    },
    goToNextQuestion () {
      if (this.isBrowsingInScoringHistory) {
        this.$emit('reduceDecreasing', 1)
        if (this.decisionQuestionFirstValue !== this.sq_data[0].ponderation) {
          this.$emit('reduceDecreasing', 0)
          this.handleIfEditQues(0, this.decisionQuestionFirstValue)
          if (!this.oneOption) {
            this.historyScrap = this.historyFirstValue.splice(this.getIndexOfCurrentSQ + 1)
            this.historyScrap.forEach(e => {
              if (e !== 2 && e !== 32 && e !== 41) {
                if (this.scoringData[this.SQNameByNumber[e]] !== null) {
                  if (this.scoringData[this.SQNameByNumber[e]][0].id === undefined) {
                    this.$store.dispatch('handleResetScoringData', e)
                  } else {
                    this.scoringData[this.SQNameByNumber[e]].forEach(q => {
                      this.deleteData.push(q.id)
                    })
                    this.$store.dispatch('handleResetScoringData', e)
                  }
                }
              }
            })
            this.$emit('deleteQuestion', this.deleteData)
            this.$store.dispatch('handleHistoryData', this.historyFirstValue)
            this.$store.dispatch('handleIfIsBrowsingInScoringHistory', false)
            this.$store.dispatch('handleHistoryIndexOfCurrentSQ', null)
          }
        }
      }
      this.$store.dispatch('handleScoringData', this.sq_data)
      if (this.oneOption) {
        this.$emit('handleProgress', 1)
        if (this.isBrowsingInScoringHistory) {
          this.$store.dispatch('handleOnlyCurrentSQ', this.sqOptions[0])
        } else {
          this.$store.dispatch('handleCurrentSQ', this.sqOptions[0])
        }
      } else {
        if (this.sq_data[0].ponderation === this.questionData.questions[0].options[0]) {
          this.$emit('handleProgress', 1)
          if (this.isBrowsingInScoringHistory) {
            this.$store.dispatch('handleOnlyCurrentSQ', this.sqOptions[0])
          } else {
            this.$store.dispatch('handleCurrentSQ', this.sqOptions[0])
          }
        } else {
          this.$emit('handleProgress', 2)
          if (this.isBrowsingInScoringHistory) {
            this.$store.dispatch('handleOnlyCurrentSQ', this.sqOptions[1])
          } else {
            this.$store.dispatch('handleCurrentSQ', this.sqOptions[1])
          }
        }
      }
      this.$emit('checkIfHistoryModeContinues')
    }
  },
  mounted () {
    this.setData()
  }
}
</script>

<style lang="scss" scoped>
.ques-enter-active {
  transition: opacity .5s;
}
.ques-enter {
  opacity: 0;
}
</style>
