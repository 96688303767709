<template>
    <v-btn
      elevation="0"
      class="text-capitalize btn__customized"
      color="rgba(0, 0, 0, 0.02)"
      width="98"
      height="30"
      @click="showDrawerR(true)">
        <v-icon class="mr-2 grey--text">mdi-tune</v-icon>
          Filtrar
    </v-btn>
</template>

<script>
export default {
  name: 'TableFilterButton',
  methods: {
    showDrawerR (val) {
      this.$store.dispatch('handleAdvancedFilter', val)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .btn__customized {
    @include fontStyle(normal, 15px, normal, $grey);
  }
}
</style>
