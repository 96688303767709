import axios from './instance'

const configToken = (token) => {
  return {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
}

export const getQuestionnaire = async (number, token) => {
  try {
    const res = await axios.get('/api/questionnaires/' + number, configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}

export const getAllTypesQuestionsOptions = async (token) => {
  try {
    const res = await axios.get('/api/types-questions-options', configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}

export const getAllRenaultModels = async (token) => {
  try {
    const res = await axios.get('/api/vehicles-models?brands=1', configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}

export const getAllScorings = async (token) => {
  try {
    const res = await axios.get('/api/scorings', configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}

export const getScoringId = async (token, statusId) => {
  try {
    const res = await axios.post('/api/scorings/', statusId, configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}

export const deleteScoring = async (token, id) => {
  try {
    const res = await axios.delete(`/api/scorings/${id}`, configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}

export const createScoring = async (token, scoring) => {
  try {
    const res = await axios.post('/api/questions-ponderated/', scoring, configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}

export const createClient = async (token, client) => {
  try {
    const res = await axios.post('/api/clients', client, configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}

export const updateQuestion = async (token, data, id) => {
  try {
    const res = await axios.put(`/api/questions-ponderated/${id}`, data, configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}

export const deleteQuestion = async (token, array) => {
  const header = configToken(token)
  header.data = array
  try {
    const res = await axios.delete('/api/questions-ponderated/', header)
    return res
  } catch (err) {
    throw err.response
  }
}

export const createScheduledCall = async (token, data) => {
  try {
    const res = await axios.post('/api/schedules/', data, configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}

export const getAllSchedulesCalls = async (token) => {
  try {
    const res = await axios.get('/api/schedules', configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}

export const getAllScoringStatus = async (token) => {
  try {
    const res = await axios.get('/api/scorings/status/', configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}

export const getPostponedIndicator = async (token) => {
  try {
    const res = await axios.get('/api/schedules/pendingWithoutAppointment', configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}

const anuraToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzZWNyZXRJZCI6IjEzOSIsInRlbmFudElkIjo1NjYzLCJ0eXBlIjoiZGVmYXVsdCIsImlhdCI6MTY0ODY3MjA3MX0.JlPIrfODoZ4QL9Cy2WNqMzV6sUUsVHnOZJitkkTvYKE'

export const clickToDial = async (phone, token, scoringId) => {
  const tokenBearer = `Bearer ${token}`
  try {
    const res = await axios.post('https://api.anura.com.ar/adapter/default/click2call', { called: `${phone}`, extension: 100, customs: [tokenBearer, scoringId] }, configToken(anuraToken))
    return res
  } catch (err) {
    throw err.response
  }
}

export const getAllRecordings = async (token) => {
  try {
    const res = await axios.get('/api/scorings/recordings/', configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}

export const getRecordingsByScoringId = async (token, scorinId) => {
  try {
    const res = await axios.get(`/api/scorings/recordings?scoring=${scorinId}`, configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}

/* export const downloadFile = (url) => axios({
  url: url,
  method: 'GET',
  responseType: 'blob'
}).then((response) => {
  const fileURL = window.URL.createObjectURL(new Blob([response.data]))
  const fileLink = document.createElement('a')

  fileLink.href = fileURL
  fileLink.setAttribute('download', 'file.pdf')
  document.body.appendChild(fileLink)

  fileLink.click()
}) */
