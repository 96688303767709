<template>
  <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.5827 0.306091C7.02249 0.306091 0.900391 6.44095 0.900391 14C0.900391 21.559 7.02249 27.6939 14.5827 27.6939C22.1565 27.6939 28.2923 21.559 28.2923 14C28.2923 6.44095 22.1565 0.306091 14.5827 0.306091ZM14.5964 24.9551C8.54274 24.9551 3.63958 20.0527 3.63958 14C3.63958 7.94728 8.54274 3.04487 14.5964 3.04487C20.65 3.04487 25.5531 7.94728 25.5531 14C25.5531 20.0527 20.65 24.9551 14.5964 24.9551ZM15.2812 7.15303H13.2268V15.3694L20.4171 19.6829L21.4443 17.9986L15.2812 14.3423V7.15303Z" fill="#9D9D9D"/>
  </svg>
</template>

<script>
export default {
  name: 'Clock'
}
</script>
