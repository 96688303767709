import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import es from 'vuetify/lib/locale/es'
import Awarded from '@/components/icons/Awarded'
import SaleNewCar from '@/components/icons/SaleNewCar'
import SaleUsedCar from '@/components/icons/SaleUsedCar'
import Savings from '@/components/icons/Savings'
import User from '@/components/icons/User'
import Arrow from '@/components/icons/Arrow'
import Exit from '@/components/icons/Exit'
import Search from '@/components/icons/Search'
import MenuHamb from '@/components/icons/MenuHamb'
import ExitForm from '@/components/icons/ExitForm'
import CarCog from '@/components/icons/CarCog'
import ArrowDown from '@/components/icons/ArrowDown'
import AddImage from '@/components/icons/AddImage'
import EvalIcon from '@/components/icons/EvalIcon'
import Info from '@/components/icons/Info'
import AddPhoto from '@/components/icons/AddPhoto'
import PhotoCollection from '@/components/icons/PhotoCollection'
import PenEdit from '@/components/icons/PenEdit'
import ChevronLeftImg from '@/components/icons/ChevronLeftImg'
import ChevronRightImg from '@/components/icons/ChevronRightImg'
import TrashCan from '@/components/icons/TrashCan'
import Camera from '@/components/icons/Camera'
import Accessories from '@/assets/icons/accessories-icon'
import Autologic from '@/assets/icons/auto-logic-icon'
import Credit from '@/assets/icons/credit-icon'
import Insurance from '@/assets/icons/insurance-icon'
import Calendar from '@/components/icons/Calendar'
import Phone from '@/components/icons/Phone'
import Email from '@/components/icons/Email'
import Clock from '@/components/icons/Clock'
import Newspaper from '@/components/icons/Newspaper'
import Play from '@/components/icons/Play'
import Volume from '@/components/icons/Volume'
import DownloadAudio from '@/components/icons/DownloadAudio'
import CallDescription from '@/components/icons/CallDescription'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#ffb70b',
        secondary: '#000000',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107'
      }
    }
  },
  icons: {
    values: {
      awarded: {
        component: Awarded
      },
      new: {
        component: SaleNewCar
      },
      used: {
        component: SaleUsedCar
      },
      savings: {
        component: Savings
      },
      user: {
        component: User
      },
      arrow: {
        component: Arrow
      },
      arrow_down: {
        component: ArrowDown
      },
      exit: {
        component: Exit
      },
      search: {
        component: Search
      },
      menuh: {
        component: MenuHamb
      },
      exitform: {
        component: ExitForm
      },
      carcog: {
        component: CarCog
      },
      addimage: {
        component: AddImage
      },
      evalicon: {
        component: EvalIcon
      },
      info: {
        component: Info
      },
      addphoto: {
        component: AddPhoto
      },
      collection: {
        component: PhotoCollection
      },
      pen: {
        component: PenEdit
      },
      chevronleft: {
        component: ChevronLeftImg
      },
      chevronright: {
        component: ChevronRightImg
      },
      trashcan: {
        component: TrashCan
      },
      camera: {
        component: Camera
      },
      accessories: {
        component: Accessories
      },
      autologic: {
        component: Autologic
      },
      credit: {
        component: Credit
      },
      insurance: {
        component: Insurance
      },
      calendar: {
        component: Calendar
      },
      phone: {
        component: Phone
      },
      email: {
        component: Email
      },
      clock: {
        component: Clock
      },
      newspaper: {
        component: Newspaper
      },
      play: {
        component: Play
      },
      volume: {
        component: Volume
      },
      downloadaudio: {
        component: DownloadAudio
      },
      calldescription: {
        component: CallDescription
      }
    }
  },
  lang: {
    locales: { es },
    current: 'es'
  }
})
