<template>
  <v-card-text class="pa-0 d-flex flex-column" style="height: 90%;">
    <Questions
      :question="{}"
      :screens_question="questionData.question"
      :sq_aclaration="questionData.aclaration"
    ></Questions>
    <v-btn
      v-if="continueScoringButton"
      @click="goToPreviousQues"
      outlined
      width="250"
      class="mt-10 rounded"
      style="background-color: #FFF8E1;border: 1px solid #FFB70B;"
      ><span style="color: #FFB70B;font-weight: bold;"
        >Continuar scoring</span
      ></v-btn
    >
  </v-card-text>
</template>

<script>
import Questions from '@/components/savings/blocks/Questions'
import { mapGetters } from 'vuex'

export default {
  name: 'SQuesTwo',
  components: {
    Questions
  },
  props: {
    questionData: Object
  },
  computed: {
    ...mapGetters(['continueScoringButton'])
  },
  methods: {
    setData () {
      this.$emit('handleProgress', 4)
    },
    goToPreviousQues () {
      this.$store.dispatch('handleShowContinueScoringButton', false)
      this.$emit('goBackInScoringHistory')
    }
  },
  mounted () {
    this.setData()
  }
}
</script>
