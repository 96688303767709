<template>
  <v-btn
      color="transparent"
      elevation="0"
      fab
      class="text-capitalize text-subtitle-2 text--secondary ml-2"
      @click.stop="startCalling"
    >
      <v-icon size="26">$phone</v-icon>
    </v-btn>
</template>

<script>
export default {
  name: 'TableShowButton',
  props: {
    action: {
      type: String
    },
    ItemId: Number
  },
  methods: {
    startCalling () {
      this.$emit('startCalling', this.ItemId)
    }
  }
}
</script>
