<template>
  <v-stepper-content :step="step" class="mt-5">
    <v-card
      elevation="0"
      class="ml-10 mr-8 rounded"
      width="93%"
      color="#F5F5F7">
      <v-card-title>
        <h2 style="font-size: 14px;letter-spacing: 0.01em;">VALOR Y ESTADO DEL VEHICULO</h2>
      </v-card-title>
      <v-card-text class="d-flex justify-space-between pr-0 pb-0">
        <div style="width: 50%;" class="mt-8">
          <v-form ref="stepFour">
            <v-container>
              <v-row>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="valuation.purchase_value"
                    :rules="[valiadtionsRules.required]"
                    @focus="setGlobalFilter('valuation', 'purchase_value', 'number')"
                    @change="setGlobalFilter('valuation', 'purchase_value', 'magnitude')"
                    :disabled="!enableCarValuation"
                    dense
                    prefix="$"
                    label="Valor de compra"
                    placeholder="Valor de compra"></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="valuation.sale_value"
                    :rules="[valiadtionsRules.required]"
                    @focus="setGlobalFilter('valuation', 'sale_value', 'number')"
                    @change="setGlobalFilter('valuation', 'sale_value', 'magnitude')"
                    :disabled="!enableCarValuation"
                    dense
                    prefix="$"
                    label="Valor de venta"
                    placeholder="Valor de venta"></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="totalExpenses"
                    readonly
                    dense
                    prefix="$"
                    label="Gasto total"
                    placeholder="Gasto total"></v-text-field>
                </v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col cols="12">
                  <v-textarea
                    v-model="valuation.observations"
                    dense
                    rows="3"
                    label="Observaciones"></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </div>
        <div>
          <v-progress-circular
            :rotate="360"
            style="font-size: 26px;"
            :size="134"
            :width="10"
            :value="score"
            color="black"
          >
            {{ score }}
          </v-progress-circular>
          <div class="d-flex align-center mt-2">
            <p class="mb-0 mr-3">
              <span
                style="font-size: 14px;letter-spacing: 0.01em;font-weight: bold;"
                v-if="stars === 3"
              >Bueno</span>
              <span
                style="font-size: 14px;letter-spacing: 0.01em;font-weight: bold;"
                v-if="stars === 2"
              >Regular</span>
              <span
                style="font-size: 14px;letter-spacing: 0.01em;font-weight: bold;"
                v-if="stars === 1"
              >Malo</span>
            </p>
            <v-rating
              color="black"
              background-color="black"
              length="3"
              readonly
              size="14"
              :value="stars"
            ></v-rating>
          </div>
        </div>
        <div>
          <v-img class="d-none d-sm-block" src="../../assets/img/valuation_final-img.svg" width="308" height="255"></v-img>
        </div>
      </v-card-text>
    </v-card>
  </v-stepper-content>
</template>

<script>
export default {
  name: 'StepValuation',
  props: {
    step: Number,
    valuation: Object,
    score: String,
    stars: Number,
    enableCarValuation: Boolean,
    totalExpenses: String
  },
  data () {
    return {
      valiadtionsRules: {
        required: value => !!value || 'Dato requerido'
      }
    }
  },
  methods: {
    noValidatedStep () {
      this.$refs.stepFour.validate()
    },
    setGlobalFilter (form, prop, filter) {
      this.$emit('setGlobalFilter', form, prop, filter)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
