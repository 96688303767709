import axios from './instance'

const configToken = (token) => {
  return {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
}

export const getAllAccessories = async (token) => {
  try {
    const res = await axios.get('/api/vehicle-accesories', configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}

export const createAccessory = async (token, accessory) => {
  const config = configToken(token)
  config.headers['Content-Type'] = 'multipart/form-data'
  try {
    const res = await axios.post('/api/vehicle-accesories', accessory, config)
    return res
  } catch (err) {
    throw err.response
  }
}

export const updateAccessory = async (token, accessory, id) => {
  const config = configToken(token)
  config.headers['Content-Type'] = 'multipart/form-data'
  try {
    const res = await axios.post(`/api/vehicle-accesories/${id}`, accessory, config)
    return res
  } catch (err) {
    throw err.response
  }
}

export const deleteAccessory = async (token, id) => {
  try {
    const res = await axios.delete(`/api/vehicle-accesories/${id}`, configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}
