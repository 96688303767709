<template>
  <v-list min-width="211">
    <v-list-item-group>
      <v-list-item
      v-for="(action, index) in item.actions"
      :key="'action-btn-' + index" @click="action.fn(item)"
      :class="action.classes"
      >
        <v-icon class="ml-4 grey--text" v-if="action.icon">{{action.icon}}</v-icon>
        <v-list-item-title class="text-left ml-2">{{action.text}}</v-list-item-title>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
export default {
  name: 'TableActionOptions',
  props: {
    item: Object
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .v-list-item {
    .v-list-item__title {
      @include fontStyle(normal, 13px, normal, $grey);
    }

    &.option-delete {
      border-top: 1px solid $grey !important;
      .v-icon, .v-list-item__title {
        color: red !important;
      }
    }
  }
}
</style>
