<template>
  <v-card
    elevation="0"
    class="rounded"
    color="#F5F5F7">
    <v-card-title>
      <h2 style="font-size: 14px;letter-spacing: 0.01em;">CHECKLIST</h2>
    </v-card-title>
    <v-card-text>
      <div
        class="d-flex justify-space-between align-center"
        style="border-bottom: 2px solid #e0e0e0;height: 55px;">
        <span
          style="font-size: 16px; letter-spacing: 0.01em;"
          class="black--text"
          >¿Es el primer dueño?</span>
        <v-switch
          v-model="checklist.is_first_owner"
          flat
          :disabled="editEvaluation"
          inset
          color="primary"
          ></v-switch>
      </div>
      <div
        class="d-flex justify-space-between align-center"
        style="border-bottom: 2px solid #e0e0e0;height: 55px;">
        <span
          style="font-size: 16px; letter-spacing: 0.01em;"
          class="black--text text-start"
          >¿El historial del servicio contiene todos los sellos del concesionario?</span>
        <v-switch
          v-model="checklist.has_stamps_in_service_history"
          inset
          :disabled="editEvaluation"
          flat
          color="primary"
          ></v-switch>
      </div>
      <div
        class="d-flex justify-space-between align-center"
        style="border-bottom: 2px solid #e0e0e0;height: 55px;">
        <span
          style="font-size: 16px; letter-spacing: 0.01em;"
          class="black--text"
          >¿Tiene el duplicado de la llave?</span>
        <v-switch
          v-model="checklist.has_key_duplicate"
          inset
          :disabled="editEvaluation"
          flat
          color="primary"
          ></v-switch>
      </div>
      <div
        class="d-flex justify-space-between align-center"
        style="border-bottom: 2px solid #e0e0e0;height: 55px;">
        <span
          style="font-size: 16px; letter-spacing: 0.01em;"
          class="black--text"
          >¿Tiene el historial de servicio?</span>
        <v-switch
          v-model="checklist.has_service_history"
          inset
          :disabled="editEvaluation"
          flat
          color="primary"
          ></v-switch>
      </div>
      <div
        class="d-flex justify-space-between align-center"
        style="height: 55px;">
        <span
          style="font-size: 16px; letter-spacing: 0.01em;"
          class="black--text"
          >¿Tiene el manual de propietario?</span>
        <v-switch
          v-model="checklist.has_handbook"
          inset
          :disabled="editEvaluation"
          flat
          color="primary"
          ></v-switch>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Checklist',
  props: {
    checklist: Object
  },
  computed: {
    ...mapGetters(['editEvaluation'])
  }
}
</script>

<style lang="scss" scoped>
</style>
