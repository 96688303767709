<template>
  <v-stepper-content :step="step" class="mt-5">
    <v-form ref="stepOne" class="d-flex align-start">
      <v-container fluid>
        <v-row>
          <v-col cols="12" sm="9">
            <VehicleData
              :vehicle_data="vehicle_data"
              :modelsItems="modelsItems"
              :valiadationsRules="valiadationsRules"
              @setGlobalFilter="setGlobalFilter"
              @setEvaluationByParts="setEvaluationByParts"
              @setFuelInForm="setFuelInForm"
              @setColorInForm="setColorInForm"
              @setTransmissionInForm="setTransmissionInForm"
              @setBrandsAndModels="setBrandsAndModels">
            </VehicleData>
          </v-col>
          <v-col cols="12" sm="3">
            <ClientData
              @setGlobalFilter="setGlobalFilter"
              @resetClientId="resetClientId"
              @setDataInFields="setDataInFields"
              :valiadationsRules="valiadationsRules"
              :client_data="client_data">
            </ClientData>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-stepper-content>
</template>

<script>
import VehicleData from '@/components/evaluations/forms/VehicleData'
import ClientData from '@/components/evaluations/forms/ClientData'

export default {
  name: 'StepVehicle',
  components: {
    VehicleData,
    ClientData
  },
  props: {
    step: Number,
    vehicle_data: Object,
    client_data: Object,
    modelsItems: Array
  },
  data () {
    return {
      valiadationsRules: {
        required: value => !!value || 'Dato requerido'
      }
    }
  },
  methods: {
    noValidatedStep () {
      this.$refs.stepOne.validate()
    },
    setGlobalFilter (form, prop, filter) {
      this.$emit('setGlobalFilter', form, prop, filter)
    },
    setEvaluationByParts () {
      this.$emit('setEvaluationByParts')
    },
    setDataInFields (data) {
      this.$emit('setDataInFields', data)
    },
    setBrandsAndModels (data) {
      this.$emit('setDataWhenIsEditing', data)
    },
    resetClientId () {
      this.$emit('resetClientId')
    },
    setFuelInForm () {
      this.$emit('setFuelInForm')
    },
    setColorInForm () {
      this.$emit('setColorInForm')
    },
    setTransmissionInForm () {
      this.$emit('setTransmissionInForm')
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
