export default class VehicleHelper {
  static getDoorsOptions () { return [2, 3, 4, 5] }
  static getYearsOptions () {
    const years = []
    const actualYear = new Date().getFullYear()
    for (let i = 1950; i <= actualYear; i++) {
      years.push(i)
    }
    return years.reverse()
  }

  static filterByModel (vehicles, modelId) {
    return vehicles.filter(v => v.vehicle_model_id === modelId)
  }
}
