<template v-slot:[`item.actions`]="{ item }">
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="btn-table-options my-4"
        color="grey lighten-2 pa-0"
        x-small
        elevation="0"
        width="40px"
        height="40px"
        v-bind="attrs"
        v-on="on">
        <v-icon class="">mdi-dots-vertical</v-icon>
      </v-btn>
    </template>
    <TableActionOptions :item='item'>
    </TableActionOptions>
  </v-menu>
</template>

<script>
import TableActionOptions from '@/components/tables/TableActionOptions'
export default {
  name: 'TableActionsMenu',
  components: {
    TableActionOptions
  },
  props: {
    item: Object
  }
}
</script>

<style lang="scss" scoped>
</style>
