import Vue from 'vue'
import Vuex from 'vuex'
import user from './user'
import sale from './sale'
import evaluations from './evaluations'
import accesories from './accesories'
import savings from './savings'
import vehicles from './vehicles'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    sale,
    evaluations,
    accesories,
    savings,
    vehicles
  },
  state: {
    userToken: '',
    userDb: {},
    userModules: [],
    showMobileMenu: null,
    showMiniMenu: null,
    showAdvancedFilter: false,
    showLoading: false,
    alertOptions: { value: false, text: '', color: 'green darken-1' },
    alertExit: false,
    showAddOrEditAccessory: false,
    showAddOrEditVehicle: false,
    editAccessory: false,
    editVehicle: false,
    model: null,
    showAddOrEditCatalog: false,
    editCatalog: false
  },
  mutations: {
    setMobileMenu (state, val) {
      state.showMobileMenu = val
    },

    setMiniMenu (state, val) {
      state.showMiniMenu = val
    },

    setAdvancedFilter (state, val) {
      state.showAdvancedFilter = val
    },

    setUserStatus (state, payload) {
      if (payload === '') {
        state.userToken = ''
        state.userDb = {}
      } else {
        state.userToken = payload.token
        state.userDb = payload.user
        state.userModules = payload.modules
      }
    },

    setLoading (state, payload) {
      state.showLoading = payload
    },

    setAlert (state, val) {
      state.alertOptions.value = val.value ? val.value : false
      state.alertOptions.text = val.text ? val.text : ''

      const colors = { success: 'green darken-1', error: 'red darken-1' }
      if (val.color) {
        state.alertOptions.color = colors[val.color] || colors.success
      }
    },
    setExitForm (state, payload) {
      state.alertExit = payload
    },
    setShowAddOrEditAccessory (state, payload) {
      state.showAddOrEditAccessory = payload
    },
    setShowAddOrEditVehicle (state, payload) {
      state.showAddOrEditVehicle = payload
    },
    setEditAccessory (state, payload) {
      state.editAccessory = payload
    },
    setEditVehicle  (state, payload) {
      state.editVehicle = payload
    },
    setModel (state, payload) {
      state.model = payload
    },
    setShowAddOrEditCatalog (state, payload) {
      state.showAddOrEditCatalog = payload
    },
    setEditCatalog  (state, payload) {
      state.editCatalog = payload
    }
  },
  actions: {
    handleMobileMenu ({ commit }, payload) {
      commit('setMobileMenu', payload)
    },

    handleMiniMenu ({ commit }, payload) {
      commit('setMiniMenu', payload)
    },

    handleAdvancedFilter ({ commit }, payload) {
      commit('setAdvancedFilter', payload)
    },

    saveUser ({ commit }, payload) {
      localStorage.setItem('userInfoToken', JSON.stringify(payload))
      commit('setUserStatus', payload)
    },

    signout ({ commit }) {
      commit('setUserStatus', '')
      localStorage.removeItem('userInfoToken')
    },

    checkUserLogged ({ commit }) {
      const payload = localStorage.getItem('userInfoToken')

      if (payload) {
        commit('setUserStatus', JSON.parse(payload))
      } else {
        commit('setUserStatus', '')
      }
    },
    handleLoading ({ commit }, payload) {
      commit('setLoading', payload)
    },

    handleAlert ({ commit }, payload) {
      commit('setAlert', payload)
    },
    handleExitForm ({ commit }, payload) {
      commit('setExitForm', payload)
    },
    handleShowAddOrEditAccessory ({ commit }, payload) {
      commit('setShowAddOrEditAccessory', payload)
    },
    handleEditAccessory ({ commit }, payload) {
      commit('setEditAccessory', payload)
    },
    handleShowAddOrEditVehicle ({ commit }, payload) {
      commit('setShowAddOrEditVehicle', payload)
    },
    handleEditVehicle ({ commit }, payload) {
      commit('setEditVehicle', payload)
    },
    findThisModel ({ commit }, payload) {
      commit('setModel', payload)
    },
    handleShowAddOrEditCatalog ({ commit }, payload) {
      commit('setShowAddOrEditCatalog', payload)
    },
    handleEditCatalog ({ commit }, payload) {
      commit('setEditCatalog', payload)
    }
  },
  getters: {
    mobileOpen: state => state.showMobileMenu,
    miniOpen: state => state.showMiniMenu,
    getShowLoading: state => state.showLoading,
    filterOpen: state => state.showAdvancedFilter,
    userLogged: state => !!state.userToken,
    alertOpen: state => state.alertOptions,
    printFullName: state => state.userDb,
    alertExitForm: state => state.alertExit,
    addOrEditAccessory: state => state.showAddOrEditAccessory,
    editignAccessory: state => state.editAccessory,
    addOrEditVehicle: state => state.showAddOrEditVehicle,
    editingVehicle: state => state.editVehicle,
    findModel: state => state.model,
    userModules: state => state.userModules,
    getUserDb: state => state.userDb,
    addOrEditCatalog: state => state.showAddOrEditCatalog,
    editingCatalog: state => state.editCatalog
  }
})
