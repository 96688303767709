<template>
  <v-btn
      elevation="0"
      color="rgba(0, 0, 0, 0.02)"
      width="38px"
      min-height="30"
      x-small
      class="rounded px-0 mr-4"
      @click="showCalendar(true)">
        <v-icon>$calendar</v-icon>
    </v-btn>
</template>

<script>
export default {
  name: 'TableCalendarButton',
  methods: {
    showCalendar (value) {
      this.$emit('showCalendar', value)
    }
  }
}
</script>
