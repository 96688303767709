<template>
  <v-container fluid style="margin: 0 auto">
    <v-row class="d-flex justify-space-between ma-0 mb-1">
      <v-col cols="12" sm="8" class="d-flex align-center pa-0">
        <div class="d-flex align-center">
          <v-btn
            elevation="0"
            fab
            color="transparent"
            class="mr-4"
            @click.stop="exitForm(true)">
            <v-icon>$exitform</v-icon>
          </v-btn>
          <v-btn
            v-if="!getOperationNumber"
            elevation="0"
            class="text-capitalize"
            small
            style="font-size: 20px;font-weight: bold;letter-spacing: 0.01em;"
            >Ingrese Nr solicitud</v-btn>
          <span
            v-if="getOperationNumber"
            class="number-font mr-9 text-no-wrap"
            style="font-weight: bold;font-size: 22px;letter-spacing: 0.01em;"
            >Nº {{getOperationNumber | magnitude}}</span>
        </div>
        <!-- <div>
          <v-form>
            <input class="mx-5 pl-2" style="width: 50px;background-color: orange;" type="number" v-model="sqToTest">
            <v-btn @click.stop="testSQ">Test en SQ {{ sqToTest }}</v-btn>
            <span class="ml-4">Current SQ: {{ sq_number }}</span>
          </v-form>
        </div> -->
      </v-col>
      <v-col cols="12" sm="4" class="d-flex justify-end align-center pa-0">
        <v-btn
          elevation="0"
          style="border: 1px solid black;font-size: 10px;letter-spacing: 0.01em;"
          class="mr-3"
          @click="doNextStep"
        >SIGUIENTE</v-btn>
        <v-btn
          elevation="0"
          :disabled="getScoringInProgress"
          style="border: 1px solid black;font-size: 10px;letter-spacing: 0.01em;"
          class="mr-9 my-4"
          @click="askIfExitScoring"
          >FINALIZAR</v-btn>
      </v-col>
    </v-row>
    <v-dialog v-model="alertExitForm" max-width="500px">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <h2
            class="font-weight-bold my-4 black--text"
            style="font-family: 'NouvelR';letter-spacing: 0.01em;font-size: 22px;"
            >¿Desea salir del formulario?</h2>
          <p
            class="black--text"
            style="font-family: 'NouvelR';letter-spacing: 0.01em;font-size: 18px;"
            >Perderá todos los cambios</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            style="font-size: 12px;font-family: 'NouvelR';"
            class="mr-3"
            text
            @click="exitForm(false)"
            >Cancelar</v-btn>
          <v-btn
            style="background: rgba(245, 245, 247, 0.5);border: 1px solid #000000;font-size: 12px;font-family: 'NouvelR';"
            class="rounded mr-5 mt-2 mb-3 black--text"
            elevation="0"
            width="100"
            height="34"
            @click="acceptExitForm(false)"
            >Salir</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="confirmModal" width="500">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <h1
            class="font-weight-bold my-4 black--text"
            style="font-family: 'NouvelR';letter-spacing: 0.01em;font-size: 22px;"
            >¿Desea finalizar el Scoring?</h1>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            style="font-size: 12px;font-family: 'NouvelR';"
            class="mr-3"
            text
            @click="cancelFinishScoring">Cancelar</v-btn>
          <v-btn
            style="background: rgba(245, 245, 247, 0.5);border: 1px solid #000000;font-size: 12px;font-family: 'NouvelR';"
            class="rounded mr-5 mt-2 mb-3 black--text"
            elevation="0"
            width="100"
            height="34"
            @click="finishScoring">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { deleteScoring } from '@/services/savings'
import AuthHelper from '@/helpers/AuthHelper'

export default {
  name: 'CreateScoringHeader',
  data () {
    return {
      scoringInProgress: true,
      confirmModal: false,
      alertExitForm: false,
      sqToTest: 0
    }
  },
  computed: {
    ...mapGetters([
      'getOperationNumber',
      'getScoringInProgress',
      'sq_number',
      'getScoringId',
      'continueScoring'
    ]),
    ...mapState(['userToken'])
  },
  methods: {
    doNextStep () {
      this.$emit('thisStep', 2)
    },
    exitForm (val) {
      this.alertExitForm = val
    },
    acceptExitForm (val) {
      if (!this.continueScoring) {
        localStorage.removeItem('scoringClientData')
        localStorage.removeItem('scoringData')
        localStorage.removeItem('scoringHistory')
      }
      if (this.getScoringId !== null) {
        this.deleteScoringById(this.getScoringId)
      } else {
        this.closeCreateScoring()
      }
      this.alertExitForm = val
    },
    closeCreateScoring () {
      this.$store.dispatch('handleOnlyCurrentSQ', 1)
      this.$store.dispatch('handleOperationNumber', null)
      this.$store.dispatch('handleScoringInProgress', true)
      this.$store.dispatch('handleContinueScoring', false)
      this.$store.dispatch('handleScoringIdData', null)
      this.$store.dispatch('handleAddOrEditScoring', false)
    },
    askIfExitScoring () {
      this.confirmModal = true
    },
    cancelFinishScoring () {
      this.confirmModal = false
    },
    finishScoring () {
      this.$emit('saveScoring', 1)
    },
    testSQ () {
      this.$store.dispatch('handleOnlyCurrentSQ', parseInt(this.sqToTest))
    },
    async deleteScoringById (id) {
      try {
        await deleteScoring(this.userToken, id)
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
      } finally {
        this.$emit('redoTableData')
        this.closeCreateScoring()
      }
    }
  },
  destroyed () {
    this.$store.dispatch('handleScoringInProgress', true)
  }
}
</script>
