<template>
  <v-container fluid class="mt-3 mb-5 ml-2" style="margin: 0 auto">
    <v-row class="d-flex justify-space-between ma-0">
        <v-col cols="8" class="d-flex align-center d-xs-block">
            <v-btn
                elevation="0"
                fab
                color="transparent"
                class="mr-4"
                @click.stop="exitForm(true)">
                <v-icon>$exitform</v-icon>
            </v-btn>
            <h1 class="h1-font text-no-wrap text-truncate">Modelo</h1>
        </v-col>
        <v-col cols="4" class="d-flex justify-end align-center d-xs-block">
            <v-btn
                elevation="0"
                class="mr-9 my-4 btn-send__custom"
                @click="saveVehicle"
                >GUARDAR CAMBIOS</v-btn>
        </v-col>
    </v-row>
    <v-dialog v-model="alertExitForm" max-width="600px">
      <AlertExitForm
        @confirmExit="confirmExit"
      >
      </AlertExitForm>
    </v-dialog>
    <v-dialog v-model="confirmModal" width="500">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <h1
            class="font-weight-bold mt-4 mb-8 black--text"
            style="font-family: 'NouvelR';letter-spacing: 0.01em;font-size: 22px;"
            >¿DESEA CONFIRMAR LOS CAMBIOS EN EL MODELO {{ vehicleSelected.name }}?</h1>
          <p
            class="black--text"
            style="font-family: 'NouvelR';letter-spacing: 0.01em;font-size: 18px;"
            >Descripción: {{ vehicleSelected.description }}</p>
          <p
            class="black--text"
            style="font-family: 'NouvelR';letter-spacing: 0.01em;font-size: 18px;"
            >Valor: ${{ vehicleSelected.value }}</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            style="font-size: 12px;font-family: 'NouvelR';"
            class="mr-3"
            color="grey"
            text
            @click="cancelSaveVehicle">Cancelar</v-btn>
          <v-btn
            style="background: rgba(245, 245, 247, 0.5);border: 1px solid #000000;font-size: 12px;font-family: 'NouvelR';"
            class="rounded mr-5 mt-2 mb-3 black--text"
            elevation="0"
            width="100"
            height="34"
            @click="saveVehicle">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import AlertExitForm from '@/components/layout/alerts/AlertExitForm'
import { mapGetters } from 'vuex'

export default {
  name: 'AddOrEditCatalogHeader',
  components: {
    AlertExitForm
  },
  props: {
    vehicleSelected: Object
  },
  data () {
    return {
      confirmModal: false
    }
  },
  computed: {
    ...mapGetters(['alertExitForm', 'editingCatalog'])
  },
  methods: {
    exitForm (val) {
      this.$emit('setValueFilterWhenCancel')
      this.$store.dispatch('handleExitForm', val)
    },
    confirmExit () {
      this.$store.dispatch('handleEditCatalog', false)
      this.$emit('exitEditing')
    },
    cancelSaveVehicle () {
      this.confirmModal = false
    },
    saveVehicle () {
      if (this.editingCatalog) {
        this.$emit('editVehicle')
      } else {
        this.$emit('createNewVehicle')
      }
      this.confirmModal = false
    }
  }
}
</script>

<style lang="scss" scoped>
.h1-font {
  @include fontStyle(bold, 22px, 0.01em, $black)
}
@media (max-width: 400px) {
  .h1-font {
    font-size: 18px;
  }
}
::v-deep {
  .btn-send__custom {
        font-size: 10px;
        letter-spacing: 0.01em;
        border: 1px solid $black !important;
        background-color: #e9e9eb !important;
    }
}
</style>
