<template>
<v-container class="ml-2">
  <v-autocomplete
      class="mx-2 my-0"
      v-model="status"
      :items="itemStatuses"
      label="Estado"></v-autocomplete>
    <v-autocomplete
      class="mx-2 my-0"
      v-model="client"
      :items="itemClients"
      label="Cliente"></v-autocomplete>
    <v-autocomplete
      class="mx-2 my-0"
      v-model="car_model"
      :items="itemCarModels"
      label="Modelo de auto"></v-autocomplete>
</v-container>
</template>

<script>
import Helper from '@/helpers/Helper'

export default {
  name: 'FilterOptions',
  props: {
    statuses: {
      type: Array,
      required: true
    },
    clients: {
      type: Array,
      required: true
    },
    car_models: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    itemStatuses: [],
    itemClients: [],
    itemCarModels: []
  }),
  computed: {
    status: {
      get () {
        return this.$store.state.sale.search.status
      },
      set (value) {
        this.$store.dispatch('handleSearchStatus', value)
      }
    },
    client: {
      get () {
        return this.$store.state.sale.search.client
      },
      set (value) {
        this.$store.dispatch('handleSearchClient', value)
      }
    },
    car_model: {
      get () {
        return this.$store.state.sale.search.car_model
      },
      set (value) {
        this.$store.dispatch('handleSearchCarModel', value)
      }
    }
  },
  methods: {
    transformArrayForAutocomplete (array, itemArray, initOption) {
      itemArray.push(initOption)
      array.forEach(item => {
        itemArray.push(item)
      })
    }
  },
  watch: {
    statuses: {
      deep: true,
      handler () {
        this.itemStatuses = Helper.transformToSelect(this.statuses, 'name', { value: '', text: 'Todos los estados' })
      }
    },
    clients: {
      deep: true,
      handler () {
        this.itemClients = Helper.transformToSelect(this.clients, ['name', 'lastname'], { value: '', text: 'Todos los clientes' })
      }
    },
    car_models: {
      deep: true,
      handler () {
        this.itemCarModels = Helper.transformToSelect(this.car_models, 'model', { value: '', text: 'Todos los modelos' })
      }
    }
  }
}
</script>
