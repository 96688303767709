<template>
  <div class="d-flex flex-column justify-center align-center fill-height px-2 login__bg-img">
      <div class="opacity"></div>
      <v-card
        class="mx-auto py-8 card"
        :class="$vuetify.breakpoint.xs ? 'px-2' : 'px-8'"
        width="378"
        elevation="0"
        rounded="md"
      >
        <div class="d-flex flex-column align-center mt-5">
          <v-img width="249" height="79" src="@/assets/icons/lepic-icon.svg"></v-img>
        </div>

        <v-card-text class="mt-12">
          <LoginForm />
        </v-card-text>
      </v-card>
      <div class="login__copyright">
        <LoginCopyright />
      </div>
      <AppLoading />
  </div>
</template>

<script>
import LoginForm from '@/components/forms/LoginForm'
import LoginCopyright from '@/components/login/LoginCopyright'
import AppLoading from '@/components/layout/loading/AppLoading'
import { mapActions } from 'vuex'

export default {
  name: 'Login',

  components: {
    LoginForm,
    LoginCopyright,
    AppLoading
  },
  methods: {
    ...mapActions(['handleLoading'])
  },
  mounted () {
    this.handleLoading = false
  }
}
</script>

<style lang="scss" scoped>
.login__bg-img {
  position: relative;
  @include bg_img("../assets/img/wallpaper-duster_HD.jpg");
  .opacity {
    @include backgroundTransparency($white, 0.75);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
  .card {
    @include backgroundTransparency($white, 0.95);
    border: 1px solid black;
  }
  .login__copyright {
    position: relative;
    z-index: 1;
  }
}
</style>
