<template>
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path class="st0" d="M19.8,4.8C17.7,2.7,15,1.5,12,1.5c0,0,0,0,0,0c-6,0-11,4.9-11,11c0,2.9,1.1,5.7,3.2,7.8
      c2.1,2.1,4.8,3.2,7.8,3.2c0,0,0,0,0,0c6,0,11-4.9,11-11C23,9.6,21.9,6.8,19.8,4.8z M12,21.6C12,21.6,12,21.6,12,21.6
      c-5,0-9.1-4.1-9.1-9.1c0-2.4,0.9-4.7,2.7-6.4c1.7-1.7,4-2.7,6.4-2.7c0,0,0,0,0,0c5,0,9.1,4.1,9.1,9.1C21.1,17.5,17,21.6,12,21.6z"
      />
    <path class="st0" d="M12.8,11.6c-0.2-0.1-0.4-0.1-0.7-0.1c-0.1,0-0.2,0-0.4,0c-0.8-0.1-1.3-0.7-1.3-1.5c0-0.8,0.6-1.4,1.4-1.5
      c0.8,0,1.5,0.6,1.5,1.4c0,0.3,0.1,0.6,0.3,0.8c0.2,0.2,0.5,0.3,0.7,0.3c0.3,0,0.5-0.1,0.6-0.3c0.2-0.2,0.3-0.6,0.2-0.9
      c-0.2-1.3-0.8-2.3-1.9-2.7c-0.5-0.2-0.6-0.4-0.6-0.8c0-0.2-0.1-0.4-0.2-0.6c-0.2-0.2-0.4-0.3-0.7-0.3c-0.5,0-0.9,0.3-0.9,0.8
      c0,0.1,0,0.3,0,0.5c0,0.2,0,0.3-0.3,0.4c-1.3,0.6-2,1.6-2.1,2.9c-0.1,1.4,0.6,2.5,1.8,3.1c0.4,0.2,1,0.3,1.4,0.4l0.3,0
      c0.9,0.1,1.4,0.8,1.3,1.6c0,0.8-0.7,1.3-1.4,1.4c-0.8,0-1.4-0.5-1.5-1.4c-0.1-0.6-0.5-1.1-1-1c-0.3,0-0.5,0.1-0.7,0.3
      c-0.2,0.2-0.3,0.5-0.2,0.9c0.1,1.3,0.8,2.3,2,2.8c0.3,0.1,0.4,0.3,0.4,0.6c0,0.3,0.1,0.7,0.4,0.9c0.1,0.1,0.4,0.2,0.6,0.2
      c0.1,0,0.2,0,0.3,0c0.4-0.1,0.6-0.5,0.6-1c0-0.3,0-0.4,0.4-0.6c1.3-0.5,2.2-1.9,2.1-3.3C15.3,13.2,14.3,12,12.8,11.6z"/>
  </svg>
</template>

<script>
export default {
  name: 'Accessories'
}
</script>
