<template>
  <v-stepper-content :step="step">
    <v-container fluid>
      <v-row>
        <Questionnaire
          ref="questionnaire"
          :renaultModelItems="renaultModelItems"
          :users="users"
          :questions="questions"
          :itemToEditData="itemToEditData"
          :clientData="clientData"
        ></Questionnaire>
        <ScoringActions
          :clientData="clientData"
          :calendarMounted="calendarMounted"
          :itemToEditData="itemToEditData"
          @setCalendarInfo="setCalendarInfo"
        ></ScoringActions>
      </v-row>
    </v-container>
  </v-stepper-content>
</template>

<script>
import Questionnaire from '@/components/savings/Questionnaire.vue'
import ScoringActions from '@/components/savings/ScoringActions.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'StepScoring',
  components: {
    Questionnaire,
    ScoringActions
  },
  props: {
    step: Number,
    clientData: Object,
    questions: Object,
    renaultModelItems: Array,
    users: Array,
    itemToEditData: Object,
    calendarMounted: Boolean
  },
  data () {
    return {
      copyScoring: 0
    }
  },
  computed: {
    ...mapGetters(['showingCalendar'])
  },
  methods: {
    setCalendarInfo () {
      this.$emit('setCalendarInfo')
    },
    setOperationNumber () {
      this.$refs.questionnaire.setOperationNumber()
    }
  }
}
</script>
