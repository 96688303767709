<template>
  <v-card-text
    class="pa-0 d-flex flex-column"
    style="height: 90%;min-height: 600px;"
  >
    <p
      style="text-align: left;font-weight: bold;font-size: 24px;letter-spacing: 0.01em;color: #000000;line-height: 34px;"
      class="mt-2"
    >
      {{ questionData.question }}
    </p>
    <transition name="ques">
      <Questions
        ref="sq13q0"
        @goToNextQuestion="goToNextQuestion"
        :isCurrency="true"
        :dataSeted="dataSeted"
        v-show="currentQues === 0"
        :question="questionData.questions[0]"
        :screens_question="null"
        :sq_aclaration="questionData.aclaration"
        @sendAnswer="answerAboutFirstPayment"
        @changeDataSeted="changeDataSeted"
      ></Questions>
    </transition>
    <transition name="ques">
      <Questions
        ref="sq13q1"
        @goToNextQuestion="goToNextQuestion"
        :dataSeted="dataSeted"
        v-show="currentQues === 1"
        :btnWidth="270"
        :question="questionData.questions[1]"
        :screens_question="null"
        :sq_aclaration="null"
        @selectOption="answerPaymentMethod"
      ></Questions>
    </transition>
    <transition name="ques">
      <NextQuestionButton
        v-if="showButton"
        @goToNextQuestion="goToNextQuestion"
      ></NextQuestionButton>
    </transition>
  </v-card-text>
</template>

<script>
import Questions from '@/components/savings/blocks/Questions'
import NextQuestionButton from '@/components/savings/blocks/NextQuestionButton'
import { mapGetters } from 'vuex'
import { answerData } from '@/mixins/answerData'

export default {
  name: 'SQuesThirteen',
  components: {
    Questions,
    NextQuestionButton
  },
  props: {
    questionData: Object
  },
  data () {
    return {
      showButton: false,
      alwaysShowButton: false,
      currentQues: 0,
      dataSeted: false,
      question0: '',
      question1: '',
      historyFirstValue: [],
      historyScrap: [],
      sq_data: [
        {
          question_id: this.questionData.questions[0].id,
          ponderation: '',
          updated_at: ''
        },
        {
          question_id: this.questionData.questions[1].id,
          ponderation: '',
          updated_at: ''
        }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'getOperationNumber',
      'scoringData',
      'isBrowsingInScoringHistory',
      'continueScoring',
      'getIndexOfCurrentSQ',
      'getHistory',
      'SQNameByNumber',
      'sq_number'
    ])
  },
  methods: {
    setData (sqdata) {
      if (this.scoringData[sqdata] !== null) {
        this.dataSeted = true
        this.showButton = true
        this.alwaysShowButton = true
        this.$emit('handleProgress', 75)
        this.$refs.sq13q0.setAnswer(
          this.$options.filters.magnitude(
            this.scoringData[sqdata][0].ponderation
          )
        )
        this.$refs.sq13q1.setAnswer(this.scoringData[sqdata][1].ponderation)
        for (let i = 0; i < 2; i++) {
          this.sq_data[i].ponderation = this.scoringData[sqdata][i].ponderation
          this.sq_data[i].updated_at = this.scoringData[sqdata][i].updated_at
          if (this.continueScoring) {
            if (this.scoringData[sqdata][0].id !== undefined) {
              this.sq_data[i].id = this.scoringData[sqdata][i].id
            }
          }
        }
        if (this.isBrowsingInScoringHistory) {
          this.question0 = this.scoringData[sqdata][0].ponderation
          this.question1 = this.scoringData[sqdata][1].ponderation
          this.historyFirstValue = this.getHistory
        }
      }
    },
    answerAboutFirstPayment (value) {
      this.dataFromQuestions(value, 0)
    },
    answerPaymentMethod (value) {
      this.dataFromQuestions(value, 1)
    },
    goToNextQuestion () {
      this.handleIfEditQuesInSQWithoutCurrentQues(0, this.question0)
      this.handleIfEditQuesInSQWithoutCurrentQues(1, this.question1)
      if (this.currentQues === 0) {
        this.currentQues = 1
        this.$emit('handleProgress', 76)
        if (this.alwaysShowButton) {
          this.showButton = true
        } else {
          this.showButton = false
        }
      } else if (this.currentQues === 1) {
        this.sq_data[0].ponderation = this.$options.filters.number(
          this.sq_data[0].ponderation
        )
        this.$store.dispatch('handleScoringData', this.sq_data)
        this.$emit('handleProgress', 77)
        if (this.isBrowsingInScoringHistory) {
          this.$emit('reduceDecreasing', 1)
          this.$store.dispatch('handleOnlyCurrentSQ', 14)
        } else {
          this.$store.dispatch('handleCurrentSQ', 14)
        }
        this.$emit('checkIfHistoryModeContinues')
      }
    }
  },
  mixins: [answerData],
  mounted () {
    this.setData('sq_thirteenData')
  }
}
</script>

<style lang="scss" scoped>
.ques-enter-active {
  transition: opacity 0.5s;
}
.ques-enter {
  opacity: 0;
}
</style>
