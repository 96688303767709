<template>
  <div class="d-flex ">
    <img :src="imgPath" alt="" ref="img">
  </div>
</template>

<script>
import defaultImg from '@/assets/img/default-img.png'
export default {
  name: 'TableShowImg',
  props: {
    img: {
      type: String,
      default: '@/assets/img/default-img.png'
    }
  },
  data () {
    return {
      defaultImg: defaultImg
    }
  },
  computed: {
    imgPath () {
      return this.img || this.defaultImg
    }
  }
}
</script>

<style lang="scss" scoped>
img {
  width: 40px;
  height: 40px;
  border-radius: 100%;
}
</style>
