<template>
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22.6445 6.02588C22.6445 4.92588 21.7445 4.02588 20.6445 4.02588H4.64453C3.54453 4.02588 2.64453 4.92588 2.64453 6.02588V18.0259C2.64453 19.1259 3.54453 20.0259 4.64453 20.0259H20.6445C21.7445 20.0259 22.6445 19.1259 22.6445 18.0259V6.02588ZM20.6445 6.02588L12.6445 11.0259L4.64453 6.02588H20.6445ZM20.6445 18.0259H4.64453V8.02588L12.6445 13.0259L20.6445 8.02588V18.0259Z" fill="#9D9D9D"/>
  </svg>
</template>

<script>
export default {
  name: 'Email.vue'
}
</script>
