<template>
  <svg width="60" height="59" viewBox="0 0 60 59" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M44.9417 49.1667H10.5251V14.75H32.6501V9.83334H10.5251C7.8209 9.83334 5.6084 12.0458 5.6084 14.75V49.1667C5.6084 51.8708 7.8209 54.0833 10.5251 54.0833H44.9417C47.6459 54.0833 49.8584 51.8708 49.8584 49.1667V27.0417H44.9417V49.1667ZM25.7913 41.3738L20.973 35.5721L14.2126 44.25H41.2542L32.5517 32.6713L25.7913 41.3738ZM49.8584 9.83334V2.45834H44.9417V9.83334H37.5667C37.5913 9.85793 37.5667 14.75 37.5667 14.75H44.9417V22.1004C44.9663 22.125 49.8584 22.1004 49.8584 22.1004V14.75H57.2334V9.83334H49.8584Z" fill="#9D9D9D"/>
  </svg>
</template>

<script>
export default {
  name: 'AddImage'
}
</script>
