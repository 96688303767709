<template>
  <div>
    <v-dialog
      v-model="deleteModelModal"
      width="500"
    >
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text class="mt-6 mb-2">
          <h2 style="letter-spacing: 0.01em;font-size: 18px;" class="black--text">¿Está seguro que desea quitar la asociación del modelo {{ modelToDelete.model }}?</h2>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="closeAlertDelete"
            elevation="0"
            color="grey"
            style="font-size: 12px;"
            class="mr-3"
            text
            >Cancelar</v-btn>
          <v-btn
            @click="deleteModelSelected()"
            class="bor-btn rounded mr-5 mt-2 mb-3 black--text"
            width="100"
            height="34"
            elevation="0"
            style="background: rgba(245, 245, 247, 0.5);border: 1px solid #000000;font-size: 12px;"
            >Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <AddOrEditAccessoryHeader
      @createNewAccessory="createNewAccessory"
      @editAccessory="editAccessory"
      @exitEditing="exitEditing"
      @setValueFilterWhenCancel="setValueFilterWhenCancel"
      :accessorySelected="accessorySelected"
    >
    </AddOrEditAccessoryHeader>
    <div class="d-flex justify-space-between form-container direction">
      <div class="left-side">
        <v-card
          outlined
          color="grey lighten-4"
          class="mb-6">
          <v-card-title class="mx-1 pt-6 pb-4">
            <span class="title_font font-weight-bold pl-4">ACCESORIO</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="6" md="6">
                  <v-text-field
                    class="mx-6"
                    v-model="accessorySelected.code"
                    label="Código">
                  </v-text-field>
                </v-col>
                <v-col cols="6" md="6">
                  <v-text-field
                    class="mr-6"
                    v-model="accessorySelected.value"
                    prefix="$"
                    @focus="resetFilterToEditValue"
                    @change="setValueFilter"
                    label="Valor">
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    class="mx-6"
                    v-model="accessorySelected.name"
                    label="Nombre">
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    class="mx-6 mb-3"
                    v-model="accessorySelected.description"
                    label="Descripción">
                  </v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
        <v-card outlined color="grey lighten-4">
          <v-card-title class="mx-1 pt-6 pb-4">
            <span class="title_font font-weight-bold pl-4">ASOCIACION DE MODELOS</span>
          </v-card-title>
          <v-card-text class="pb-5">
            <v-form>
              <div class="d-flex box-width align-center">
                <v-autocomplete
                  class="mx-6"
                  v-model="lineSelected"
                  :items="itemLines"
                  return-object
                  label="Por linea">
                </v-autocomplete>
                <v-btn
                  elevation="0"
                  class="mr-6 mb-4 btn-add__custom"
                  @click="addModelsByLineSelected"
                >AGREGAR</v-btn>
              </div>
              <div class="d-flex box-width align-center">
                <v-autocomplete
                  class="mx-6"
                  v-model="modelSelected"
                  :items="itemModels"
                  return-object
                  label="Por modelo">
                </v-autocomplete>
                <v-btn
                  elevation="0"
                  class="mr-6 mb-4 btn-add__custom"
                  @click="addModelSelected"
                  >AGREGAR</v-btn>
              </div>
            </v-form>
            <div>
              <h3 class="text-start list-title_font ml-6 mt-10">Modelo</h3>
              <div
                v-for="(model, index) in accesoryModelsAdded"
                :key="'model-asociated-' + index"
                class="d-flex flex-column files-width mx-6 mb-5">
                <div class="list-style d-flex justify-space-between align-end pb-1">
                  <ul class="left">
                    <li
                      class="text-start mt-4"
                      >{{ model.model }}</li>
                  </ul>
                  <div class="right">
                     <v-btn
                        icon
                        elevation="0"
                        x-small
                        @click.prevent="openAlertDelete(model)"
                      ><v-icon>mdi-close-circle-outline</v-icon></v-btn>
                  </div>
                </div>
                <hr class="hr-style">
              </div>
            </div>
          </v-card-text>
        </v-card>
      </div>
      <div class="right-side">
        <v-card
          color="grey lighten-4"
          elevation="0">
          <v-card-title class="mx-1 pt-6 pb-4">
            <span class="title_font font-weight-bold pl-4">IMAGEN RELACIONADA</span>
          </v-card-title>
          <v-card-text class="pb-8">
            <div class="drop-container d-flex flex-column align-center justify-center">
              <div
                @click="selectFiles"
                @dragenter.prevent="toggleActive"
                @dragleave.prevent="toggleActive"
                @dragover.prevent
                @drop.prevent="dropFiles"
                :class="active ? 'active' : ''"
                class="drop-area rounded d-flex align-center justify-center"
              >
                <v-icon x-large>$addimage</v-icon>
                <input
                  type="file"
                  name=""
                  ref="inputFile"
                  id="input-file"
                  @change="gettingFiles"
                  hidden
                  accept=".jpg, .jpeg, .png, .gif"
                  >
              </div>
              <div class="d-flex align-center mt-4">
                <p class="mb-0">{{ imgName }}</p>
                <v-btn
                        icon
                        elevation="0"
                        x-small
                        class="mx-4"
                        @click.prevent="deleteImg"
                        v-if="accessorySelected.images.length > 0"
                      ><v-icon>mdi-close-circle-outline</v-icon></v-btn>
              </div>
              <div ref="preview" class="preview mt-4">
                <img :src="accessorySelected.images[0]" alt="" ref="image">
              </div>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import AddOrEditAccessoryHeader from '@/components/accessories/AddOrEditAccessoryHeader'
import { createAccessory, updateAccessory } from '@/services/accessories'
import { mapState, mapGetters, mapActions } from 'vuex'
import AuthHelper from '@/helpers/AuthHelper'

export default {
  name: 'AddOrEditAccessory',
  components: {
    AddOrEditAccessoryHeader
  },
  props: {
    models: {
      type: Array,
      required: true
    },
    accessoryToEdit: {
      type: Object
    }
  },
  data () {
    return {
      accessorySelected: {
        id: '',
        code: '',
        name: '',
        description: '',
        value: null,
        models: [],
        images: []
      },
      resetAccessorySelected: {
        id: '',
        code: '',
        name: '',
        description: '',
        value: null,
        models: [],
        images: []
      },
      lines: [],
      itemLines: [],
      lineSelected: '',
      modelSelected: '',
      modelToDelete: '',
      modelsShowedInList: [],
      active: false,
      itemModels: [],
      deleteModelModal: false,
      imgName: '',
      fileImg: ''
    }
  },
  computed: {
    ...mapState(['userToken']),
    ...mapGetters(['editignAccessory']),
    accesoryModelsAdded () {
      return this.accessorySelected.models.filter(a => !a.delete)
    }
  },
  methods: {
    ...mapActions(['handleAlert', 'handleLoading']),
    setValueFilter () {
      this.accessorySelected.value = this.$options.filters.magnitude(this.accessorySelected.value)
    },
    resetFilterToEditValue () {
      this.accessorySelected.value = this.$options.filters.number(this.accessorySelected.value)
    },
    setValueFilterWhenCancel () {
      this.$emit('setValueFilterWhenCancel')
    },
    selectFiles () {
      this.$refs.inputFile.click()
    },
    toggleActive () {
      this.active = !this.active
    },
    getLinesFromModels (models) {
      const lines = []
      models.forEach(m => {
        if (!lines.some(l => l.id === m.vehicle_line.id)) {
          lines.push(m.vehicle_line)
        }
      })
      return lines
    },
    transformArrayForAutocomplete (array, field) {
      const itemArray = []
      array.forEach(item => {
        itemArray.push({
          value: item.id,
          text: item[field]
        })
      })
      return itemArray
    },
    dropFiles (e) {
      this.handleFile(e.dataTransfer.files)
    },
    gettingFiles () {
      this.handleFile(this.$refs.inputFile.files)
    },
    handleFile (files) {
      this.deleteImg()
      this.accessorySelected.images = files
      this.fileImg = files
      this.active = false
      files.forEach(file => {
        this.imgName = file.name
      })
      this.showPreviewImgs(files)
    },
    showPreviewImgs (files) {
      const reader = new FileReader()
      reader.readAsDataURL(files[0])
      reader.onload = () => {
        const image = this.$refs.image
        image.src = reader.result
      }
    },
    deleteImg () {
      this.accessorySelected.images = []
      this.imgName = ''
      this.$refs.image.src = ''
    },
    addModelSelected () {
      if (this.modelSelected) {
        const index = this.models.findIndex(e => e.id === this.modelSelected.value)
        if (index !== -1) {
          this.addModelToAccesorySelected(this.modelSelected.value, this.modelSelected.text)
          this.modelSelected = ''
        }
      }
    },
    addModelsByLineSelected () {
      if (this.lineSelected) {
        const index = this.lines.findIndex(l => l.id === this.lineSelected.value)
        if (index !== -1) {
          this.models.forEach(model => {
            if (model.vehicle_line.id === this.lineSelected.value) {
              this.addModelToAccesorySelected(model.id, model.model)
            }
          })
        }
      }
    },
    addModelToAccesorySelected (modelId, modelText) {
      if (!this.accessorySelected.models.some(m => m.id === modelId)) {
        this.accessorySelected.models.push({
          model: modelText,
          id: modelId,
          delete: false
        })
      } else {
        this.accessorySelected.models.forEach(m => {
          if (m.id === modelId) {
            m.delete = false
          }
        })
      }
    },
    transformAccesoryToFormData () {
      const formData = new FormData()
      if (this.accessorySelected.images.length > 0) {
        this.accessorySelected.images.forEach((file, index) => {
          formData.append('images[' + index + ']', file)
        })
      } else {
        if (this.accessoryToEdit.id !== undefined && this.accessoryToEdit.id !== null && this.accessoryToEdit.id !== '') {
          formData.append('images[delete]', true)
        }
      }
      formData.append('code', this.accessorySelected.code)
      formData.append('name', this.accessorySelected.name)
      formData.append('value', this.$options.filters.number(this.accessorySelected.value))
      formData.append('description', this.accessorySelected.description)
      formData.append('models', JSON.stringify(this.accessorySelected.models))
      return formData
    },
    async createNewAccessory () {
      this.handleLoading(true)
      const data = this.transformAccesoryToFormData()
      try {
        const res = await createAccessory(this.userToken, data)
        this.$store.dispatch('handleShowAddOrEditAccessory', false)
        this.$emit('addAccesory', res.data.data)
        this.alertMessage = res.data.message
        this.alertColor = 'success'
        this.handleLoading(false)
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
        this.alertMessage = error?.data?.message
        this.alertColor = 'error'
        this.handleLoading(false)
      } finally {
        this.editingAccessorySelected(this.resetAccessorySelected)
        this.handleAlert({
          value: true,
          text: this.alertMessage,
          color: this.alertColor
        })
      }
    },
    async editAccessory () {
      this.handleLoading(true)
      const data = this.transformAccesoryToFormData()
      data.append('_method', 'PUT')
      try {
        const res = await updateAccessory(this.userToken, data, this.accessorySelected.id)
        this.$store.dispatch('handleShowAddOrEditAccessory', false)
        this.$store.dispatch('handleEditAccessory', false)
        this.$emit('updateAccesory', res.data.data)
        this.editingAccessorySelected(this.resetAccessorySelected)
        this.alertMessage = res.data.message
        this.alertColor = 'success'
        this.handleLoading(false)
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
        this.alertMessage = error?.data?.message
        this.alertColor = 'error'
        this.handleLoading(false)
      } finally {
        this.handleAlert({
          value: true,
          text: this.alertMessage,
          color: this.alertColor
        })
      }
    },
    exitEditing () {
      this.editingAccessorySelected(this.resetAccessorySelected)
      this.$emit('stopEditing')
    },
    editingAccessorySelected (prop) {
      this.accessorySelected.id = prop.id
      this.accessorySelected.code = prop.code
      this.accessorySelected.name = prop.name
      this.accessorySelected.description = prop.description
      this.accessorySelected.value = prop.value
      this.accessorySelected.images = []
      if (prop.img) { this.accessorySelected.images.push(prop.img) }
      this.accessorySelected.models = []
      if (prop === this.resetAccessorySelected) {
        this.accessorySelected.models = prop.models
      }
      if (prop === this.accessoryToEdit && this.accessoryToEdit.models.length > 0) {
        this.accessoryToEdit.models.forEach(model => {
          const index = this.models.findIndex(e => e.id === model.id)
          this.accessorySelected.models.push({
            id: model.id,
            model: this.models[index].model,
            delete: false
          })
        })
      }
    },
    openAlertDelete (model) {
      this.modelToDelete = model
      this.deleteModelModal = true
    },
    closeAlertDelete () {
      this.deleteModelModal = false
    },
    deleteModelSelected () {
      const index = this.accessorySelected.models.findIndex(e => e.id === this.modelToDelete.id)
      if (index !== -1) {
        this.accessorySelected.models[index].delete = true
        this.deleteModelModal = false
      }
    },
    getAccesoryModelsNotAdded () {
      return this.models.filter(m => !this.accesoryModelIsAdded(m))
    },
    accesoryModelIsAdded (model) {
      return this.accesoryModelsAdded.some(m => m.id === model.id)
    }
  },
  mounted () {
    this.lines = this.getLinesFromModels(this.models)
    this.itemLines = this.transformArrayForAutocomplete(this.lines, 'line')
    this.itemModels = this.transformArrayForAutocomplete(this.models, 'model')
    if (this.editignAccessory) {
      this.editingAccessorySelected(this.accessoryToEdit)
    }
  },
  watch: {
    accesoryModelsAdded: {
      deep: true,
      handler () {
        const accessoryModelsNotAddes = this.getAccesoryModelsNotAdded()
        this.itemModels = this.transformArrayForAutocomplete(accessoryModelsNotAddes, 'model')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
ul {
  margin: 0;
  padding: 0;
  li {
    @include fontStyle(500, 14px, 0.01em, $black);
    list-style: none;
  }
}
.hr-style {
  border: 1px dashed rgba(157, 157, 157, 0.5);
}
.title_font {
  @include fontStyle(bold, 14px, 0.01em, $black)
}
.box-width {
  width: 100%;
}
.left-side {
  width: 57%;
  margin-left: 28px;
}
.right-side {
  width: 35%;
  margin-right: 28px;
}
.list-title_font {
  @include fontStyle(bold, 14px, 0.01em, $grey)
}
.drop-container {
  width: 100%;
}
.drop-area {
  border: 1px dashed $grey;
  width: 88%;
  height: 170px;
  cursor: pointer;
}
.drop-area {
  &.active {
  background-color: $white;
  border: 1px solid orange;
  }
}
.preview {
  img {
    max-width: 100px;
    max-height: 100px;
  }
}
@media (max-width: 750px) {
  .direction {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .left-side {
    width: 90%;
    margin-bottom: 24px;
    margin-left: 0px;
  }
  .right-side {
    width: 90%;
    margin-right: 0px;
  }
}
::v-deep {
  .btn-add__custom {
        font-size: 10px;
        letter-spacing: 0.01em;
        border: 1px solid $black !important;
        background-color: #e9e9eb !important;
    }
}
</style>
