<template>
  <v-card-text
    class="pa-0 d-flex flex-column"
    style="height: 90%;min-height: 600px;"
  >
    <p
      style="text-align: left;font-weight: bold;font-size: 24px;letter-spacing: 0.01em;color: #000000;line-height: 34px;"
      class="mt-4"
    >
      {{ questionData.question }}
    </p>
    <div class="d-flex align-end">
      <span
        class="d-inline-block mb-6"
        style="text-align: left;font-weight: bold;font-size: 18px;letter-spacing: 0.01em;color: #9D9D9D;"
        >{{ questionData.aclaration }}</span
      >
    </div>
    <input
      type="text"
      ref="inv32"
      @keypress.enter="goToNextQuestion"
      style="outline-style: none;caret-color: #F5F5F7;color: #F5F5F7;"
    />
    <NextQuestionButton
      @goToNextQuestion="goToNextQuestion"
    ></NextQuestionButton>
  </v-card-text>
</template>

<script>
import NextQuestionButton from '@/components/savings/blocks/NextQuestionButton'
import { mapGetters } from 'vuex'

export default {
  name: 'SQuesThirtyTwo',
  components: {
    NextQuestionButton
  },
  props: {
    questionData: Object
  },
  computed: {
    ...mapGetters(['isBrowsingInScoringHistory'])
  },
  methods: {
    setData () {
      this.$emit('handleProgress', 68)
    },
    goToNextQuestion () {
      this.$emit('handleProgress', 71)
      if (this.isBrowsingInScoringHistory) {
        this.$emit('reduceDecreasing', 1)
        this.$store.dispatch('handleOnlyCurrentSQ', 11)
      } else {
        this.$store.dispatch('handleCurrentSQ', 11)
      }
      this.$emit('checkIfHistoryModeContinues')
    }
  },
  mounted () {
    this.setData()
    this.$refs.inv32.focus()
  }
}
</script>
