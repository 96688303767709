<template>
  <v-container class="ml-2">
    <v-select
      class="mx-2 my-0"
      v-model="line"
      :items="linesItems"
      item-text="text"
      item-value="value"
      label="Líneas">
    </v-select>
    <v-autocomplete
      :items="modelsItems"
      label="Modelo"
      v-model="model"
      class="mx-2 mb-4">
    </v-autocomplete>
  </v-container>
</template>

<script>
import Helper from '@/helpers/Helper'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'EvaluationsFilterOptions',
  props: {
    lines: {
      type: Array,
      required: true
    },
    models: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      linesItems: [],
      modelsItems: []
    }
  },
  computed: {
    ...mapState(['userToken']),
    ...mapGetters(['searchEvaluationsData']),
    line: {
      get () {
        return this.searchEvaluationsData.line
      },
      set (value) {
        this.$store.dispatch('handleSearchEvaluationLine', value)
      }
    },
    model: {
      get () {
        return this.searchEvaluationsData.model
      },
      set (value) {
        this.$store.dispatch('handleSearchEvaluationModel', value)
      }
    }
  },
  mounted () {
    this.linesItems = Helper.transformToSelect(this.lines, 'line', { value: '', text: 'Todas las lineas' })
    this.modelsItems = Helper.transformToSelect(this.models, 'model', { value: '', text: 'Todos los modelos' })
  },
  watch: {
    lines: {
      handler () {
        this.linesItems = Helper.transformToSelect(this.lines, 'line', { value: '', text: 'Todas las lineas' })
      }
    },
    models: {
      handler () {
        this.modelsItems = Helper.transformToSelect(this.models, 'model', { value: '', text: 'Todos los modelos' })
      }
    }
  }
}
</script>
