<template>
  <div>
    <p
      v-if="screens_question !== null"
      style="text-align: left;font-weight: bold;font-size: 24px;letter-spacing: 0.01em;color: #000000;line-height: 34px;"
      class="mt-4"
      >{{ screens_question }}</p>
    <div class="d-flex align-end" v-if="sq_aclaration !== null">
      <span
        class="d-inline-block mb-6"
        style="text-align: left;font-weight: bold;font-size: 18px;letter-spacing: 0.01em;color: #9D9D9D;"
        >{{ sq_aclaration }}</span>
    </div>
    <p
      v-if="question.question !== null && question.type_question_option_id !== 6"
      style="text-align: left;font-weight: bold;font-size: 24px;letter-spacing: 0.01em;color: #000000;line-height: 34px;"
      class="mt-8"
      >{{ question.question }}</p>
    <div class="d-flex align-end" v-if="question.aclaration !== null && question.type_question_option_id !== 6">
      <span
        class="d-inline-block mb-6"
        style="text-align: left;font-weight: bold;font-size: 18px;letter-spacing: 0.01em;color: #9D9D9D;"
        >{{ question.aclaration }}</span>
    </div>
    <div style="width: 100%;" v-if="question.type_question_option_id === 1">
      <v-row>
        <v-col cols="12">
          <v-form ref="answerTextField">
            <v-text-field
              ref="answer1"
              :autofocus="!isBrowsingInScoringHistory"
              placeholder="Escriba aquí su respuesta..."
              :rules="[valiadationsRules.required]"
              @focus="setFilter('number')"
              @keypress.enter.prevent="goToNextQuestion"
              clearable
              :prefix="isCurrency ? '$' : ''"
              v-model="answer"></v-text-field>
          </v-form>
        </v-col>
      </v-row>
    </div>
    <div class="d-flex flex-column justify-stat" v-if="question.type_question_option_id === 2">
      <input
        type="text"
        ref="inv"
        @keypress.enter="goToNextQuestion"
        style="outline-style: none;caret-color: #F5F5F7;color: #F5F5F7;">
      <v-btn
        elevation="0"
        v-if="question.options.length > 0"
        @click="selectOption(question.options[0], 0)"
        :color="selectedButton === 0 ? 'rgba(157, 157, 157, 0.15)' : '#F5F5F7'"
        style="border: 1px solid #9D9D9D;"
        :width="btnWidth"
        class="d-flex justify-start rounded-0 mt-4 text-truncate">
        <span style="font-size: 18px;color: #9D9D9D" class="mr-4">A</span>
        <span style="font-size: 18px;letter-spacing: 0.01em;color: #000000;">{{ question.options[0] }}</span>
      </v-btn>
      <v-btn
        elevation="0"
        v-if="question.options.length > 1"
        @click="selectOption(question.options[1], 1)"
        :color="selectedButton === 1 ? 'rgba(157, 157, 157, 0.15)' : '#F5F5F7'"
        style="border: 1px solid #9D9D9D;"
        :width="btnWidth"
        class="d-flex justify-start rounded-0 mt-4 text-truncate">
        <span style="font-size: 18px;color: #9D9D9D" class="mr-4">B</span>
        <span style="font-size: 18px;letter-spacing: 0.01em;color: #000000;">{{ question.options[1] }}</span>
      </v-btn>
      <v-btn
        elevation="0"
        v-if="question.options.length > 2"
        @click="selectOption(question.options[2], 2)"
        :color="selectedButton === 2 ? 'rgba(157, 157, 157, 0.15)' : '#F5F5F7'"
        style="border: 1px solid #9D9D9D;"
        :width="btnWidth"
        class="d-flex justify-start rounded-0 mt-4 text-truncate">
        <span style="font-size: 18px;color: #9D9D9D" class="mr-4">C</span>
        <span style="font-size: 18px;letter-spacing: 0.01em;color: #000000;">{{ question.options[2] }}</span>
      </v-btn>
      <v-btn
        elevation="0"
        v-if="question.options.length > 3"
        @click="selectOption(question.options[3], 3)"
        :color="selectedButton === 3 ? 'rgba(157, 157, 157, 0.15)' : '#F5F5F7'"
        style="border: 1px solid #9D9D9D;"
        :width="btnWidth"
        class="d-flex justify-start rounded-0 mt-4 text-truncate">
        <span style="font-size: 18px;color: #9D9D9D" class="mr-4">D</span>
        <span style="font-size: 18px;letter-spacing: 0.01em;color: #000000;">{{ question.options[3] }}</span>
      </v-btn>
      <v-btn
        elevation="0"
        v-if="question.options.length > 4"
        @click="selectOption(question.options[4], 4)"
        :color="selectedButton === 4 ? 'rgba(157, 157, 157, 0.15)' : '#F5F5F7'"
        style="border: 1px solid #9D9D9D;"
        :width="btnWidth"
        class="d-flex justify-start rounded-0 mt-4 text-truncate">
        <span style="font-size: 18px;color: #9D9D9D" class="mr-4">E</span>
        <span style="font-size: 18px;letter-spacing: 0.01em;color: #000000;">{{ question.options[4] }}</span>
      </v-btn>
      <v-btn
        elevation="0"
        v-if="question.options.length > 5"
        @click="selectOption(question.options[5], 5)"
        :color="selectedButton === 5 ? 'rgba(157, 157, 157, 0.15)' : '#F5F5F7'"
        style="border: 1px solid #9D9D9D;"
        :width="btnWidth"
        class="d-flex justify-start rounded-0 mt-4 text-truncate">
        <span style="font-size: 18px;color: #9D9D9D" class="mr-4">F</span>
        <span style="font-size: 18px;letter-spacing: 0.01em;color: #000000;">{{ question.options[5] }}</span>
      </v-btn>
      <v-btn
        elevation="0"
        v-if="question.options.length > 6"
        @click="selectOption(question.options[6], 6)"
        :color="selectedButton === 6 ? 'rgba(157, 157, 157, 0.15)' : '#F5F5F7'"
        style="border: 1px solid #9D9D9D;"
        :width="btnWidth"
        class="d-flex justify-start rounded-0 mt-4 text-truncate">
        <span style="font-size: 18px;color: #9D9D9D" class="mr-4">G</span>
        <span style="font-size: 18px;letter-spacing: 0.01em;color: #000000;">{{ question.options[6] }}</span>
      </v-btn>
      <v-btn
        elevation="0"
        v-if="question.options.length > 7"
        @click="selectOption(question.options[7], 7)"
        :color="selectedButton === 7 ? 'rgba(157, 157, 157, 0.15)' : '#F5F5F7'"
        style="border: 1px solid #9D9D9D;"
        :width="btnWidth"
        class="d-flex justify-start rounded-0 mt-4 text-truncate">
        <span style="font-size: 18px;color: #9D9D9D" class="mr-4">H</span>
        <span style="font-size: 18px;letter-spacing: 0.01em;color: #000000;">{{ question.options[7] }}</span>
      </v-btn>
    </div>
    <div v-if="question.type_question_option_id === 3">
      <v-radio-group
        v-model="answer"
        @change="secondRadioB ? sendAnswer() : selectOption(question.options[0], 0)"
        class="mt-10">
        <v-radio
          :label="question.options[0]"
          color="primary"
          :value="question.options[0]">
        </v-radio>
        <v-radio
          v-if="secondRadioB"
          :label="question.options[1]"
          color="primary"
          class="mt-5"
          :value="question.options[1]">
        </v-radio>
      </v-radio-group>
    </div>
    <div v-if="question.type_question_option_id === 4">
      <input
        type="text"
        ref="inv4"
        @keypress.enter="goToNextQuestion"
        style="outline-style: none;caret-color: #F5F5F7;color: #F5F5F7;">
      <v-menu
        v-model="calendar"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="answer"
            placeholder="Escriba aquí su respuesta..."
            append-icon="mdi-calendar"
            readonly
            clearable
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="date"
          no-title
          locale="es-ar"
          @input="setDate"
        ></v-date-picker>
      </v-menu>
    </div>
    <div v-if="question.type_question_option_id === 6">
      <input
        type="text"
        ref="inv6"
        @keypress.enter="goToNextQuestion"
        style="outline-style: none;caret-color: #F5F5F7;color: #F5F5F7;">
      <v-checkbox :label="question.question" @click="focusOnInv6"></v-checkbox>
    </div>
    <div v-if="question.type_question_option_id === 7">
      <v-autocomplete
        :items="renaultModelItems"
        @keypress.enter.native="goToNextQuestion"
        placeholder="Ingrese el modelo..."
        v-if="question.options === '/api/vehicles-models'"
        v-model="answer"
        @change="sendAnswer"></v-autocomplete>
      <v-autocomplete
        :items="users"
        @keypress.enter.native="goToNextQuestion"
        placeholder="Escriba el nombre..."
        v-if="question.options === '/api/users'"
        v-model="answer"
        @change="sendAnswer"></v-autocomplete>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Questions',
  props: {
    question: Object,
    screens_question: String,
    sq_aclaration: String,
    btnWidth: Number,
    dataSeted: Boolean,
    renaultModelItems: Array,
    users: Array,
    isCurrency: Boolean,
    secondRadioB: Boolean
  },
  data () {
    return {
      calendar: false,
      date: '',
      selectedButton: null,
      answer: '',
      valiadationsRules: {
        required: value => !!value || 'Dato requerido'
      }
    }
  },
  computed: {
    ...mapGetters(['isBrowsingInScoringHistory'])
  },
  methods: {
    goToNextQuestion () {
      if (this.question.type_question_option_id === 1 && this.answer === '') {
        this.$refs.answerTextField.validate()
      } else {
        this.$emit('goToNextQuestion')
      }
    },
    sendAnswer () {
      if (!this.dataSeted) {
        this.$emit('sendAnswer', this.answer)
      }
      this.$emit('changeDataSeted', false)
    },
    setFilter (filter) {
      this.answer = this.$options.filters[filter](this.answer)
    },
    inputSelect () {
      this.$refs.answer1.focus()
    },
    setDate () {
      this.calendar = false
      const str = [...this.date]
      const localFormat = []
      this.newOrder(localFormat, str, 8)
      this.newOrder(localFormat, str, 9)
      this.newOrder(localFormat, str, 7)
      this.newOrder(localFormat, str, 5)
      this.newOrder(localFormat, str, 6)
      this.newOrder(localFormat, str, 7)
      this.newOrder(localFormat, str, 0)
      this.newOrder(localFormat, str, 1)
      this.newOrder(localFormat, str, 2)
      this.newOrder(localFormat, str, 3)
      const newDate = localFormat.join('')
      // Dete with format to be used in frontend
      this.answer = newDate
      this.sendAnswer()
      this.$refs.inv4.focus()
    },
    newOrder (arr, str, n) {
      arr.push(str[n])
    },
    setAnswer (value) {
      this.answer = value
      if (this.question.type_question_option_id === 2) {
        this.selectedButton = this.question.options.indexOf(this.answer)
      }
    },
    setSelectedButton (value) {
      this.selectedButton = value
    },
    selectOption (val, index) {
      this.selectedButton = index
      this.$emit('selectOption', val)
      if (this.question.type_question_option_id === 2) {
        this.$refs.inv.focus()
      }
    },
    focusOnInv () {
      this.$refs.inv2.focus()
    },
    focusOnInv6 () {
      this.$refs.inv6.focus()
    }
  },
  watch: {
    answer () {
      if (this.question.type_question_option_id === 1) {
        this.sendAnswer()
      }
    }
  }
}
</script>
