<template>
  <div>
    <AdvancedFilter @filter="filter">
      <template v-slot:filterOptions>
        <FilterOptions
          :roles="roles"
          :concessionaires="concessionaires"
          :departments="departments">
        </FilterOptions>
      </template>
    </AdvancedFilter>
    <Table
      :title="tableData.title"
      :headers="tableData.headers"
      :items="tableData.items"
      @rowClicked="rowClicked"
      >

      <template v-slot:tableToolbar="slotProps">
        <TableToolbar
          :title="tableData.title"
          :btnAdd="tableData.btnAdd"
          :openAddOrUpdateDialog="openAddOrUpdateItem"
          v-if="tableData.items.length > 0"
          @handleAddOrUpdate="handleAddOrUpdate"
          @clickOutside="close"
          :pagination="slotProps.pagination"
          :options="slotProps.options"
          :updateOptions="slotProps.updateOptions"
          >
          <template v-slot:addOrEditItem>
            <AddOrEditItem
              :editedItem="userToEdit"
              :edit="edit"
              :departments="departments"
              :concessionaires="concessionaires"
              :roles="roles"
              @close="close"
              @save="save" />
          </template>

          <template v-slot:toolbarFilterButton>
            <TableFilterButton @filter="filter"></TableFilterButton>
          </template>

          <template v-slot:paginationTable="slotProps">
            <TablePagination
              :pagination="slotProps.pagination"
              :options="slotProps.options"
              :updateOptions="slotProps.updateOptions" >

            </TablePagination>
          </template>
        </TableToolbar>
      </template>

      <template v-slot:componentDelete>
        <TableDeleteItem
          :dialogDelete="dialogDelete"
          @handleDialogDelete="handleDialogDelete"
          @closeDelete="closeDelete"
          @deleteItemConfirm="deleteItemConfirm" />
      </template>

      <template v-slot:no-data>
        <TableNoData />
      </template>
    </Table>
  </div>
</template>
<script>
import Table from '@/components/tables/Table'
import AddOrEditItem from '@/components/users/AddOrEditItem'
import TableDeleteItem from '@/components/tables/TableDeleteItem'
import TableNoData from '@/components/tables/TableNoData'
import TableToolbar from '@/components/tables/TableToolbar'
import TablePagination from '@/components/tables/TablePagination'
import TableFilterButton from '../components/tables/TableFilterButton'
import AdvancedFilter from '@/components/tables/AdvancedFilter'
import FilterOptions from '@/components/users/FilterOptions'
import { EventBus } from '@/EventBus.js'
import Helper from '@/helpers/Helper'
import AuthHelper from '@/helpers/AuthHelper'
import { mapGetters, mapActions, mapState } from 'vuex'
import { getAllUsers, createUser, updateUser, deleteUser } from '@/services/users'
import { getAllConcessionaries, getAllDepartments, getAllRoles } from '@/services/globals'

export default {
  name: 'Users',
  components: {
    Table,
    AddOrEditItem,
    TableDeleteItem,
    TableNoData,
    TableToolbar,
    TablePagination,
    TableFilterButton,
    AdvancedFilter,
    FilterOptions
  },
  data () {
    return {
      allUsers: [],
      users: [],
      tableData: {
        title: 'Usuarios',
        headers: [
          {
            text: 'nombre y rol',
            value: 'firstcolumnbot'
          },
          {
            text: 'email',
            value: 'email'
          },
          {
            text: 'teléfono',
            value: 'phone',
            sortable: 'false'
          },
          {
            text: 'concesionario',
            value: 'concessionaire',
            sortable: 'false'
          },
          {
            text: '',
            value: 'actions',
            sortable: 'false'
          }
        ],
        items: [],
        btnAdd: {
          title: 'Nuevo Usuario'
        }
      },
      editedIndex: -1,
      editedItem: {},
      userToEdit: null,
      openAddOrUpdateItem: false,
      edit: false,
      dialogDelete: false,
      filteredUsers: [],
      concessionaires: [],
      departments: [],
      roles: []
    }
  },
  computed: {
    ...mapState(['userToken']),
    ...mapGetters({
      userTypeofuser: 'searchTypeofuser',
      userConsessionaire: 'searchConsessionaire',
      userDepartament: 'searchDepartament'
    })
  },
  methods: {
    ...mapActions(['saveUser', 'handleLoading', 'handleAlert', 'handleAdvancedFilter']),
    rowClicked (item, rowData) {
      this.editUser(item)
    },
    async getUsers () {
      this.handleLoading(true)
      try {
        const res = await getAllUsers(this.userToken)
        this.allUsers = res.data.data
        this.users = this.allUsers.filter(u => u.status === 1)
        this.filteredUsers = [...this.users]
        this.formatUsersTable(this.users)
        this.alertMessage = res.data.message
        this.alertColor = 'success'
        this.handleLoading(false)
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
        this.alertMessage = error?.data?.message
        this.alertColor = 'error'
        this.handleLoading(false)
      } finally {
        this.handleAlert({
          value: true,
          text: this.alertMessage,
          color: this.alertColor
        })
      }
    },
    formatUsersTable (users) {
      this.tableData.items = []
      users.forEach(u => {
        this.tableData.items.push(this.getFormatUserTable(u))
      })
    },
    filter () {
      this.filteredUsers = this.users
      if (!this.allFilteredOptionsEmpty()) {
        this.filteredUsers = this.users.filter(u => {
          return (Helper.searchItemByField(u, 'role', this.userTypeofuser, true) &&
            Helper.searchItemByField(u, 'concessionaire', this.userConsessionaire, true) &&
            Helper.searchItemByFieldInsideArray(u, 'departments', this.userDepartament, true))
        })
      }
      this.tableData.items = []
      this.formatUsersTable(this.filteredUsers)
      this.handleAdvancedFilter(false)
    },
    allFilteredOptionsEmpty () {
      return (this.userTypeofuser === '' && this.userConsessionaire === '' && this.userDepartament === '')
    },
    getFormatUserTable (u) {
      return {
        id: u.id,
        firstcolumntop: u.name + ' ' + u.last_name,
        firstcolumnbot: ((u.role !== undefined && u.role !== null) ? u.role.name : 'Sin rol asignado'),
        name: (u.name !== null ? u.name : ''),
        last_name: (u.last_name !== null ? u.last_name : ''),
        email: (u.email !== null ? u.email : ''),
        phone: (u.phone !== null ? u.phone : ''),
        concessionaire: ((u.concessionaire !== undefined && u.concessionaire !== null) ? u.concessionaire.name : ''),
        departament: (u.departament !== null ? u.departament : ''),
        actions: [
          {
            icon: 'mdi-pencil',
            text: 'Editar',
            fn: (item) => {
              this.editUser(item)
            },
            classes: ''
          },
          {
            icon: 'mdi-delete',
            text: 'Eliminar',
            fn: (item) => {
              this.deleteUser(item)
            },
            classes: 'option-delete'
          }
        ],
        data: u
      }
    },
    handleAddOrUpdate (value) {
      this.openAddOrUpdateItem = value
    },

    selectEditedItem (item) {
      this.editedIndex = this.tableData.items.indexOf(item)
      if (this.editedIndex !== -1) {
        this.editedItem = Object.assign({}, item)
      }
    },
    findUserFromSelectedItem () {
      this.userToEdit = this.users.find(u => u.id === this.editedItem.id)
    },
    editUser (item) {
      this.selectEditedItem(item)
      this.findUserFromSelectedItem()
      if (this.editedIndex !== -1) {
        this.edit = true
        this.openAddOrUpdateItem = true
      }
    },
    deleteUser (item) {
      this.selectEditedItem(item)
      if (this.editedIndex !== -1) {
        this.dialogDelete = true
      }
    },

    close () {
      this.openAddOrUpdateItem = false
      this.edit = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    async save (item) {
      if (this.editedIndex > -1) {
        await this.updateThisUser(item)
      } else {
        await this.createNewUser(item)
      }
      this.close()
    },
    async createNewUser (item) {
      try {
        this.handleLoading(true)
        const res = await createUser(this.userToken, item)
        this.alertMessage = res.data.message
        this.alertColor = 'success'
        this.users.push(res.data.data)
        this.tableData.items = []
        this.formatUsersTable(this.users)
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
        this.alertMessage = error?.data?.message
        this.alertColor = 'error'
      } finally {
        this.handleLoading(false)
        this.handleAlert({
          value: true,
          text: this.alertMessage,
          color: this.alertColor
        })
      }
    },
    async updateThisUser (item) {
      try {
        this.handleLoading(true)
        const res = await updateUser(this.userToken, item)
        const index = this.users.findIndex(u => u.id === item.id)
        Object.assign(this.users[index], res.data.data)
        this.formatUsersTable(this.users)
        this.alertMessage = res.data.message
        this.alertColor = 'success'
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
        this.alertMessage = error?.data?.message
        this.alertColor = 'error'
      } finally {
        this.handleLoading(false)
        this.handleAlert({
          value: true,
          text: this.alertMessage,
          color: this.alertColor
        })
      }
    },

    handleDialogDelete (value) {
      this.dialogDelete = value
    },
    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    async deleteItemConfirm () {
      try {
        this.handleLoading(true)
        const res = await deleteUser(this.userToken, this.editedItem.id)
        this.alertMessage = res.data.message
        this.alertColor = 'success'
        const index = this.users.findIndex(u => u.id === this.editedItem.id)
        if (index !== -1) {
          this.users.splice(index, 1)
          this.tableData.items.splice(this.editedIndex, 1)
        }
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
        this.alertMessage = error.data.message
        this.alertColor = 'error'
      } finally {
        this.handleLoading(false)
        this.handleAlert({
          value: true,
          text: this.alertMessage,
          color: this.alertColor
        })
        this.closeDelete()
      }
    },
    searchUsers (users, search) {
      this.filteredUsers = this.users
      if (search !== '') {
        search = search.toString().toLocaleLowerCase()
        this.filteredUsers = users.filter(u => this.filterUserBySearchParam(u, search)) // esto se puede obviar, pero no esta mal almacenar los usuarios que resultaron del filtro
      }
      this.tableData.items = []
      this.formatUsersTable(this.filteredUsers)
    },
    filterUserBySearchParam (user, search) {
      return this.filterUserByCompleteName(user, search) || Helper.searchItemByField(user, 'email', search)
    },
    filterUserByCompleteName (user, search) {
      return Helper.searchItemByField(user, 'firstname', search) || Helper.searchItemByField(user, 'lastname', search)
    },

    async getConcessionaires () {
      try {
        const res = await getAllConcessionaries(this.userToken)
        this.concessionaires = res.data.data
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
      }
    },
    async getDepartments () {
      try {
        const res = await getAllDepartments(this.userToken)
        this.departments = res.data.data
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
      }
    },
    async getRoles () {
      try {
        const res = await getAllRoles(this.userToken)
        this.roles = res.data.data
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
      }
    }
  },
  mounted () {
    this.handleLoading(true)
    this.getUsers()
    this.getConcessionaires()
    this.getDepartments()
    this.getRoles()
    EventBus.$on('searchUsers', (input) => this.searchUsers(this.filteredUsers, input))
  }
}
</script>

<style scoped>

</style>
