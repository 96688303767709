<template>
  <v-dialog v-model="controlDialogDelete">
    <v-card>
      <v-card-title></v-card-title>
      <v-card-text
        class="font-weight-bold my-4 black--text"
        style="font-family: 'NouvelR';letter-spacing: 0.01em;font-size: 22px;"
        >{{text}}</v-card-text>
      <!-- Revisar error de status undefined previo a mostrar el componente (comparar con components/users/AddOrEditItem) -->
      <v-select
        :items="itemsToSelect"
        return-object
        v-model="statusSelected"
        :menu-props="{ maxHeight: 'none' }"
      >
      </v-select>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          style="font-size: 12px;font-family: 'NouvelR';"
          class="mr-3"
          color="grey"
          text
          @click="$emit('closeDialogEditStatus')">Cancelar</v-btn>
        <v-btn
          style="background: rgba(245, 245, 247, 0.5);border: 1px solid #000000;font-size: 12px;font-family: 'NouvelR';"
          class="rounded mr-5 mt-2 mb-3 black--text"
          elevation="0"
          width="100"
          height="34"
          @click="$emit('editItemStatusConfirm')">OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'TableEditItemStatus',
  data () {
    return {
      saleState: '',
      itemsToSelect: [],
      statusSelected: ''
    }
  },
  props: {
    roleID: Number,
    approval_statuses: Array,
    dialogEditStatus: Boolean,
    text: {
      type: String,
      default: 'Estado de venta'
    },
    items: [],
    item: {
      type: Object
    }
  },
  computed: {
    controlDialogDelete: {
      get () {
        return this.dialogEditStatus
      },
      set (value) {
        this.$emit('handleDialogEditStatus', value)
      },
      ...mapGetters({ userLogged: 'userLogged' })
    }
  },
  methods: {
    transformItemsToSelect () {
      if (this.roleID !== 4) {
        this.itemsToSelect = [{
          value: '',
          text: 'Elija un estado'
        }]
      }
      this.items.forEach(item => {
        this.itemsToSelect.push({
          value: item.id,
          text: item.name
        })
      })
    },
    setStatusSelected (statusID) {
      const index = this.items.findIndex(item => item.id === statusID)
      if (index !== -1) {
        if (this.roleID === 2) {
          this.item.approval_manager = this.items[index]
        } else if (this.roleID === 4) {
          this.item.approval_distributor = this.items[index]
        } else if (this.roleID === 5) {
          this.item.approval_supervisor = this.items[index]
        } else {
          this.item.sale_status = this.items[index]
        }
      }
    }
  },
  mounted () {
    this.transformItemsToSelect()
    if (this.roleID === 2) {
      this.statusSelected = {
        value: this.item.approval_manager,
        text: ''
      }
    } else if (this.roleID === 4) {
      this.statusSelected = {
        value: this.item.approval_distributor,
        text: ''
      }
    } else if (this.roleID === 5) {
      this.statusSelected = {
        value: this.item.approval_supervisor,
        text: ''
      }
    } else {
      this.statusSelected = {
        value: this.item.sale_status.id,
        text: this.item.sale_status.name
      }
    }
  },
  watch: {
    statusSelected: {
      handler () {
        this.setStatusSelected(this.statusSelected.value)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .v-dialog, .v-dialog--active {
    width: 50%;
    overflow-x: hidden;
    position: absolute;
    top: 20%;
  }
  .v-select{
    margin-left: auto;
    margin-right: auto;
    padding: 0px 24px;
  }
  .v-list {
    height: 100%;
  }
  .v-menu__content {
    max-height: 100%;
    overflow-y: hidden;
  }
}
</style>
