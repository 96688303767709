<template>
  <div class="pl-5" style="max-width: 460px; width: 460px;word-break: break-all;white-space: normal;">
    <div class="d-flex flex_dir-media_q">
      <div style="width: 260px;" class="d-flex align-center pt-3">
        <h2
          class="text-start text-no-wrap text-truncate h2-text"
          style="font-size: 16px;letter-spacing: 0.01em;"
          >{{item.name}}
        </h2>
      </div>
      <div class="d-flex align-center qualy-pt-0">
        <p
          style="font-size: 16px;letter-spacing: 0.01em;"
          class="text-start mb-0"
          >{{ item.qualification.value !== 0 ? item.qualification.text : 'Sin calificación' }}
        </p>
        <v-rating
          v-if="item.qualification.value !== 0"
          color="black"
          background-color="black"
          length="3"
          readonly
          size="16"
          :value="item.qualification.stars"
        ></v-rating>
      </div>
    </div>
    <p
      class="mb-0 text-start mt-1"
      style="width: 100%;line-height: 16px;font-size: 14px;letter-spacing: 0.01em;"
      >{{ item.observations !== '' ? item.observations : 'Sin observaciones'}}
    </p>
  </div>
</template>

<script>
export default {
  name: 'ResultByParts',
  props: {
    item: Object
  }
}
</script>

<style lang="scss" scoped>
.h2-text {
  @include fontStyle(normal, 16px, 0.01em, #000000)
}
.qualy-pt-0 {
  padding-top: 12px;
}
@media (max-width: 800px) {
  .flex_dir-media_q {
    flex-direction: column;
  }
  .qualy-pt-0 {
    padding-top: 0;
  }
}
</style>
