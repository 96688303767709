import axios from './instance'

const configToken = (token) => {
  return {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
}

export const getAllUsers = async (token) => {
  try {
    const res = await axios.get('/api/users', configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}

export const createUser = async (token, user) => {
  try {
    const res = await axios.post('/api/users', user, configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}

export const updateUser = async (token, user) => {
  try {
    const res = await axios.put(`/api/users/${user.id}`, user, configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}

export const deleteUser = async (token, id) => {
  try {
    const res = await axios.delete(`/api/users/${id}`, configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}
