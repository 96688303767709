<template>
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24.9996 15L21.4746 18.525L32.9246 30L21.4746 41.475L24.9996 45L39.9996 30L24.9996 15Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'ChevronRightImg'
}
</script>
