export default class SaleHelper {
  static filterSalesByStatus (sales, statusId) {
    return sales.filter(s => SaleHelper.filterByStatus(s, statusId))
  }

  static filterByStatus (sale, statusId) {
    return statusId === '' || sale.sale_status.id === statusId
  }

  static filterSalesByClient (sales, clientId) {
    return sales.filter(s => SaleHelper.filterByClient(s, clientId))
  }

  static filterByClient (sale, clientId) {
    return clientId === '' || sale.reservation.client_id === clientId
  }

  static filterSalesByModel (sales, modelId) {
    return sales.filter(s => SaleHelper.filterByModel(s, modelId))
  }

  static filterByModel (sale, modelId) {
    return modelId === '' || ((Object.prototype.hasOwnProperty.call(sale.reservation, 'vehicle_model') &&
    Object.prototype.hasOwnProperty.call(sale.reservation.vehicle_model, 'id'))
      ? sale.reservation.vehicle_model.id === modelId : false)
  }
}
