<template>
  <SingleSQ
    :questionData="questionData"
    :btnWidth="500"
    :oneOption="false"
    :pendingOption="true"
    :sq_numberData="'sq_thirtyData'"
    :sqOptions="[]"
    @goBackInScoringHistory="goBackInScoringHistory">
  </SingleSQ>
</template>

<script>
import SingleSQ from '@/components/savings/blocks/SingleSQ'

export default {
  name: 'SQuesThirty',
  components: {
    SingleSQ
  },
  props: {
    questionData: Object
  },
  methods: {
    setData () {
      this.$emit('handleProgress', 62)
    },
    goBackInScoringHistory () {
      this.$emit('goBackInScoringHistory')
    }
  },
  mounted () {
    this.setData()
  }
}
</script>
