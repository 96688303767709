<template>
<v-container class="ml-2">
    <v-select
      class="mx-2 my-0"
      v-model="typeofuser"
      :items="itemRoles"
      item-text="name"
      item-value="id"
      label="Tipo de usuario"></v-select>
    <v-select
      class="mx-2 my-0"
      v-model="consessionaire"
      :items="itemConcessionaires"
      item-text="name"
      item-value="id"
      label="Concesionario"></v-select>
    <v-select
      class="mx-2 my-0"
      v-model="departament"
      :items="itemDepartments"
      item-text="name"
      item-value="id"
      label="Departamento"></v-select>
</v-container>
</template>

<script>
export default {
  name: 'FilterOptions',
  props: {
    roles: {
      type: Array,
      required: true
    },
    concessionaires: {
      type: Array,
      required: true
    },
    departments: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    itemDepartments: [],
    itemConcessionaires: [],
    itemRoles: []
  }),
  computed: {
    typeofuser: {
      get () {
        return this.$store.state.user.search.typeofuser
      },
      set (value) {
        this.$store.dispatch('handleTypeOfUser', value)
      }
    },
    consessionaire: {
      get () {
        return this.$store.state.user.search.consessionaire
      },
      set (value) {
        this.$store.dispatch('handleConsessionaire', value)
      }
    },
    departament: {
      get () {
        return this.$store.state.user.search.departament
      },
      set (value) {
        this.$store.dispatch('handleDepartament', value)
      }
    }
  },
  methods: {
    transformArrayForAutocomplete (array, itemArray, initOption) {
      itemArray.push(initOption)
      array.forEach(item => {
        itemArray.push(item)
      })
    }
  },
  watch: {
    roles: {
      deep: true,
      handler () {
        this.transformArrayForAutocomplete(this.roles, this.itemRoles, { id: '', name: 'Todos los roles' })
      }
    },
    departments: {
      deep: true,
      handler () {
        this.transformArrayForAutocomplete(this.departments, this.itemDepartments, { id: '', name: 'Todos los departamentos' })
      }
    },
    concessionaires: {
      deep: true,
      handler () {
        this.transformArrayForAutocomplete(this.concessionaires, this.itemConcessionaires, { id: '', name: 'Todos los concesionarios' })
      }
    }
  }
}
</script>
