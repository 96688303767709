<template>
  <v-navigation-drawer
    v-model="showAdvancedFilter"
    width="400"
    absolute
    overlay-opacity="0.15"
    mobile-breakpoint="5000"
    right>
    <v-card elevation="0">
      <v-card-title class="py-7 pl-8 font-weight-bold font-title">Filtrar listado</v-card-title>
      <v-divider></v-divider>
      <v-card-text class="grey lighten-4">
        <slot name="filterOptions"></slot>
      </v-card-text>
      <v-card-actions class="d-flex justify-center">
        <v-btn
          color="primary"
          width="87%"
          height="50"
          rounded-lg
          elevation="0"
          class="mt-8 font-weight-bold font-btn"
          @click.stop="$emit('filter')">APLICAR FILTROS</v-btn>
      </v-card-actions>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'AdvancedFilter',

  computed: {
    ...mapGetters({
      isDrawerOpen: 'filterOpen'
    }),
    showAdvancedFilter: {
      get () {
        return this.isDrawerOpen
      },
      set (value) {
        this.handleAdvancedFilter(value)
      }
    }
  },
  methods: {
    ...mapActions(['handleAdvancedFilter'])
  }
}
</script>

<style lang="scss" scoped>
.font-title {
    font-size: 22px;
}
.font-btn {
    font-size: 20px;
    letter-spacing: 0.01em;
}
::v-deep {
    .v-card__title {
        border-bottom: $border-bottom;
    }
}
</style>
