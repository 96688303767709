<template>
  <v-stepper-content :step="step" class="mt-5">
    <div style="width: 100%;" class="pl-9 pr-6">
      <Info />
      <h2
        class="movil font-weight-bold text-start mt-8 ml-4 mb-5"
        style="font-size: 14px;letter-spacing: 0.01em;color: #9D9D9D;"
      >Artículos agregados</h2>
      <div
        style="width: 100%;"
        class="rounded"
        v-for="(evaluation, index) in evaluations"
        :key="index">
        <div style="background-color: #F5F5F7;width: 100%;min-height: 75px"
          class="d-flex justify-space-between mb-2 rounded flex_dir-media_q">
          <ResultByParts :item="evaluation"></ResultByParts>
          <EditionOfPart
            :item="evaluation"
            :index="index"
            @addImgDeleted="addImgDeleted"
            @addEvaluationOfPart="addEvaluationOfPart"
            @addPhotosFromCamera="addPhotosFromCamera">
          </EditionOfPart>
        </div>
      </div>
    </div>
  </v-stepper-content>
</template>

<script>
import Info from '@/components/evaluations/forms/Info'
import ResultByParts from '@/components/evaluations/forms/ResultByParts'
import EditionOfPart from '@/components/evaluations/forms/EditionOfPart'
import { getAllEvaluationsParts } from '@/services/evaluations'
import AuthHelper from '@/helpers/AuthHelper'
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: 'StepInspection',
  components: {
    Info,
    ResultByParts,
    EditionOfPart
  },
  props: {
    step: Number,
    expertiseEvalToBeEdited: Array
  },
  data () {
    return {
      hideDefaultTableHeader: true,
      evaluationParts: [],
      evaluations: [],
      allImagesDeleted: []
    }
  },
  computed: {
    ...mapState(['userToken']),
    ...mapGetters(['makeEvaluation', 'editEvaluation'])
  },
  methods: {
    ...mapActions(['handleLoading']),
    async getEvaluationsType () {
      this.handleLoading(true)
      this.evaluationParts = []
      this.evaluations = []
      try {
        const res = await getAllEvaluationsParts(this.userToken)
        this.evaluationParts = res.data.data
        this.formatEvaluationByPartsTable(this.evaluationParts)
        this.handleLoading(false)
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
        this.handleLoading(false)
      } finally {
        this.fillWithPreviouslyLoadedData()
      }
    },
    formatEvaluationByPartsTable (parts) {
      parts.forEach(e => {
        this.evaluations.push(this.getFormatTable(e))
      })
    },
    getFormatTable (e) {
      return {
        type_id: e.id,
        name: e.name,
        value: parseInt(e.value),
        expense: '',
        qualification: {
          text: '',
          value: 0,
          stars: 0
        },
        observations: '',
        images: [],
        recheck: false,
        expertise_evaluation_id: 0
      }
    },
    fillWithPreviouslyLoadedData () {
      if (this.expertiseEvalToBeEdited.length > 0) {
        for (let i = 0; i < this.evaluations.length; i++) {
          const index = this.expertiseEvalToBeEdited.findIndex(e => e.evaluation_type_id === this.evaluations[i].type_id)
          if (index !== -1) {
            this.evaluations[i].expertise_evaluation_id = this.expertiseEvalToBeEdited[index].id
            this.evaluations[i].observations = this.expertiseEvalToBeEdited[index].observations === null ? '' : this.expertiseEvalToBeEdited[index].observations
            if (this.expertiseEvalToBeEdited[index].recheck === 1) {
              this.evaluations[i].recheck = true
            } else {
              this.evaluations[i].recheck = false
            }
            this.evaluations[i].expense = this.expertiseEvalToBeEdited[index].spending === null ? '' : this.$options.filters.magnitude(this.expertiseEvalToBeEdited[index].spending)
            switch (this.expertiseEvalToBeEdited[index].calification) {
              case '100':
                this.evaluations[i].qualification.text = 'Bueno'
                this.evaluations[i].qualification.value = 100
                this.evaluations[i].qualification.stars = 3
                break
              case '66':
                this.evaluations[i].qualification.text = 'Regular'
                this.evaluations[i].qualification.value = 66
                this.evaluations[i].qualification.stars = 2
                break
              case '33':
                this.evaluations[i].qualification.text = 'Malo'
                this.evaluations[i].qualification.value = 33
                this.evaluations[i].qualification.stars = 1
                break
              case '':
                this.evaluations[i].qualification.text = ''
                this.evaluations[i].qualification.value = 0
                this.evaluations[i].qualification.stars = 0
                break
            }
            if (this.expertiseEvalToBeEdited[index].attachments.length > 0) {
              this.expertiseEvalToBeEdited[index].attachments.forEach(a => {
                this.evaluations[i].images.push({
                  expertise_evaluation: i,
                  src: a.attachment,
                  id: a.id,
                  delete: 0,
                  type: 'attachment'
                })
              })
            }
          }
        }
      }
      this.$emit('copyEvaluationToSend', this.evaluations)
    },
    addEvaluationOfPart (item, index) {
      this.evaluations[index] = item
      this.$emit('copyEvaluationToSend', this.evaluations)
    },
    addPhotosFromCamera (images, index) {
      this.evaluations[index].images = images
      this.$emit('copyEvaluationToSend', this.evaluations)
    },
    addImgDeleted (img) {
      this.allImagesDeleted.push(img)
      this.$store.dispatch('handleImgDeleted', this.allImagesDeleted)
    }
  }
}
</script>

<style lang="scss" scoped>
@media (max-width: 800px) {
  .movil {
    display: none;
  }
  .flex_dir-media_q {
    flex-direction: column;
  }
}
</style>
