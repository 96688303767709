export default {
  state: {
    addOrEditScoring: false,
    scoringInProgress: true,
    continueScoring: false,
    sentScoringNumber: false,
    indicatorsRowInTableToolbar: false,
    showCalendar: {
      view: false,
      edit: false
    },
    currentSQ: 1,
    operationNumber: null,
    history: [1],
    browsingInScoringHistory: false,
    indexOfCurrentSQ: null,
    showContinueScoringButton: false,
    clientDataInScoring: null,
    scoringId: null,
    isRecoveringData: false,
    screenQuestionNumber: {
      1: 'sq_oneData',
      3: 'sq_threeData',
      4: 'sq_fourData',
      5: 'sq_fiveData',
      6: 'sq_sixData',
      7: 'sq_sevenData',
      8: 'sq_eightData',
      9: 'sq_nineData',
      10: 'sq_tenData',
      11: 'sq_elevenData',
      12: 'sq_twelveData',
      13: 'sq_thirteenData',
      14: 'sq_fourteenData',
      15: 'sq_fifteenData',
      16: 'sq_sixteenData',
      17: 'sq_seventeenData',
      18: 'sq_eighteenData',
      19: 'sq_nineteenData',
      20: 'sq_twentyData',
      21: 'sq_twentyOneData',
      22: 'sq_twentyTwoData',
      23: 'sq_twentyThreeData',
      24: 'sq_twentyFourData',
      25: 'sq_twentyFiveData',
      28: 'sq_twentyEightData',
      29: 'sq_twentyNineData',
      30: 'sq_thirtyData',
      31: 'sq_thirtyOneData',
      33: 'sq_thirtyTreeData',
      34: 'sq_thirtyFourData',
      35: 'sq_thirtyFiveData',
      36: 'sq_thirtySixData',
      37: 'sq_thirtySevenData',
      38: 'sq_thirtyEightData',
      39: 'sq_thirtyNineData',
      40: 'sq_fortyData',
      42: 'sq_fortyTwoData',
      43: 'sq_fortyThreeData',
      44: 'sq_fortyFourData',
      45: 'sq_fortyFiveData',
      46: 'sq_fortySixData',
      47: 'sq_fortySevenData',
      48: 'sq_fortyEightData',
      49: 'sq_fortyNineData',
      50: 'sq_fiftyData',
      51: 'sq_fiftyOneData',
      52: 'sq_fiftyTwoData',
      53: 'sq_fiftyThreeData',
      54: 'sq_fiftyFourData'
    },
    scoringData: {
      sq_oneData: null,
      sq_threeData: null,
      sq_fourData: null,
      sq_fiveData: null,
      sq_sixData: null,
      sq_sevenData: null,
      sq_eightData: null,
      sq_nineData: null,
      sq_tenData: null,
      sq_elevenData: null,
      sq_twelveData: null,
      sq_thirteenData: null,
      sq_fourteenData: null,
      sq_fifteenData: null,
      sq_sixteenData: null,
      sq_seventeenData: null,
      sq_eighteenData: null,
      sq_nineteenData: null,
      sq_twentyData: null,
      sq_twentyOneData: null,
      sq_twentyTwoData: null,
      sq_twentyThreeData: null,
      sq_twentyFourData: null,
      sq_twentyFiveData: null,
      sq_twentyEightData: null,
      sq_twentyNineData: null,
      sq_thirtyData: null,
      sq_thirtyOneData: null,
      sq_thirtyTreeData: null,
      sq_thirtyFourData: null,
      sq_thirtyFiveData: null,
      sq_thirtySixData: null,
      sq_thirtySevenData: null,
      sq_thirtyEightData: null,
      sq_thirtyNineData: null,
      sq_fortyData: null,
      sq_fortyTwoData: null,
      sq_fortyThreeData: null,
      sq_fortyFourData: null,
      sq_fortyFiveData: null,
      sq_fortySixData: null,
      sq_fortySevenData: null,
      sq_fortyEightData: null,
      sq_fortyNineData: null,
      sq_fiftyData: null,
      sq_fiftyOneData: null,
      sq_fiftyTwoData: null,
      sq_fiftyThreeData: null,
      sq_fiftyFourData: null
    },
    filterSavingsData: {
      client: '',
      vehicle: '',
      scoringStatus: '',
      dateSelected: ''
    }
  },
  mutations: {
    setAddOrEditScoring (state, payload) {
      state.addOrEditScoring = payload
    },
    setIndicatorsRowInTableToolbar (state, payload) {
      state.indicatorsRowInTableToolbar = payload
    },
    setShowCalendar (state, payload) {
      state.showCalendar.view = payload.view
      state.showCalendar.edit = payload.edit
    },
    setCurrentSQ (state, payload) {
      state.currentSQ = payload
      state.history.push(payload)
      if (!state.continueScoring) {
        localStorage.setItem('scoringData', JSON.stringify(state.scoringData))
        localStorage.setItem('scoringHistory', JSON.stringify(state.history))
      }
    },
    setOperationNumber (state, payload) {
      state.operationNumber = payload
    },
    setScoringData (state, payload) {
      state.scoringData[state.screenQuestionNumber[state.currentSQ]] = payload
    },
    setScoringInProgress (state, payload) {
      state.scoringInProgress = payload
    },
    setContinueScoring (state, payload) {
      state.continueScoring = payload
    },
    setOnlyCurrentSQ (state, payload) {
      state.currentSQ = payload
      console.log('sq', state.currentSQ)
    },
    setHistoryData (state, payload) {
      state.history = payload
    },
    setIfIsBrowsingInScoringHistory (state, payload) {
      state.browsingInScoringHistory = payload
    },
    setResetScoringData (state, payload) {
      state.scoringData[state.screenQuestionNumber[payload]] = null
    },
    setHistoryIndexOfCurrentSQ (state, payload) {
      state.indexOfCurrentSQ = payload
    },
    setShowContinueScoringButton (state, payload) {
      state.showContinueScoringButton = payload
    },
    setClientDataInScoring (state, payload) {
      state.clientDataInScoring = payload
    },
    setScoringIdData (state, payload) {
      state.scoringId = payload
    },
    setIfSentScoringNumber (state, payload) {
      state.sentScoringNumber = payload
    },
    setRecoveringData (state, payload) {
      state.isRecoveringData = payload
    },
    setRecoveredScoringData (state, payload) {
      state.scoringData = payload
    },
    setClientDataToFilter (state, payload) {
      state.filterSavingsData.client = payload
    },
    setVehicleToFilter (state, payload) {
      state.filterSavingsData.vehicle = payload
    },
    setStatusToFilter (state, payload) {
      state.filterSavingsData.scoringStatus = payload
    },
    setDateToFilter (state, payload) {
      state.filterSavingsData.dateSelected = payload
    }
  },
  actions: {
    handleAddOrEditScoring ({ commit }, payload) {
      commit('setAddOrEditScoring', payload)
    },
    handleIndicatorsRowTableToolbar ({ commit }, payload) {
      commit('setIndicatorsRowInTableToolbar', payload)
    },
    handleShowCalendar ({ commit }, payload) {
      commit('setShowCalendar', payload)
    },
    handleCurrentSQ ({ commit }, payload) {
      commit('setCurrentSQ', payload)
    },
    handleOperationNumber ({ commit }, payload) {
      commit('setOperationNumber', payload)
    },
    handleScoringData ({ commit }, payload) {
      commit('setScoringData', payload)
    },
    handleScoringInProgress ({ commit }, payload) {
      commit('setScoringInProgress', payload)
    },
    handleContinueScoring ({ commit }, payload) {
      commit('setContinueScoring', payload)
    },
    handleOnlyCurrentSQ ({ commit }, payload) {
      commit('setOnlyCurrentSQ', payload)
    },
    handleHistoryData ({ commit }, payload) {
      commit('setHistoryData', payload)
    },
    handleIfIsBrowsingInScoringHistory ({ commit }, payload) {
      commit('setIfIsBrowsingInScoringHistory', payload)
    },
    handleResetScoringData ({ commit }, payload) {
      commit('setResetScoringData', payload)
    },
    handleHistoryIndexOfCurrentSQ ({ commit }, payload) {
      commit('setHistoryIndexOfCurrentSQ', payload)
    },
    handleShowContinueScoringButton ({ commit }, payload) {
      commit('setShowContinueScoringButton', payload)
    },
    handleClientDataInScoring ({ commit }, payload) {
      commit('setClientDataInScoring', payload)
    },
    handleScoringIdData ({ commit }, payload) {
      commit('setScoringIdData', payload)
    },
    handleIfSentScoringNumber ({ commit }, payload) {
      commit('setIfSentScoringNumber', payload)
    },
    hanldeRecoveringData ({ commit }, payload) {
      commit('setRecoveringData', payload)
    },
    recoverScoringData ({ commit }, payload) {
      commit('setRecoveredScoringData', payload)
    },
    handleClientDataToFilter ({ commit }, payload) {
      commit('setClientDataToFilter', payload)
    },
    handleVehicleToFilter ({ commit }, payload) {
      commit('setVehicleToFilter', payload)
    },
    handleStatusToFilter ({ commit }, payload) {
      commit('setStatusToFilter', payload)
    },
    handleDateToFilter ({ commit }, payload) {
      commit('setDateToFilter', payload)
    }
  },
  getters: {
    addOrEditScoring: state => state.addOrEditScoring,
    IndicatorsRow: state => state.indicatorsRowInTableToolbar,
    showingCalendar: state => state.showCalendar,
    getOperationNumber: state => state.operationNumber,
    sq_number: state => state.currentSQ,
    scoringData: state => state.scoringData,
    getScoringInProgress: state => state.scoringInProgress,
    continueScoring: state => state.continueScoring,
    getHistory: state => state.history,
    isBrowsingInScoringHistory: state => state.browsingInScoringHistory,
    getIndexOfCurrentSQ: state => state.indexOfCurrentSQ,
    SQNameByNumber: state => state.screenQuestionNumber,
    continueScoringButton: state => state.showContinueScoringButton,
    clientDataInScoring: state => state.clientDataInScoring,
    getScoringId: state => state.scoringId,
    sentScoringNumber: state => state.sentScoringNumber,
    recoveringData: state => state.isRecoveringData,
    filterSavingsData: state => state.filterSavingsData
  }
}
