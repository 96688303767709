<template>
  <v-card-text
    class="pa-0 d-flex flex-column"
    style="height: 90%;min-height: 600px;"
  >
    <Questions
      ref="sq42q0"
      :question="questionData.questions[0]"
      :screens_question="questionData.question"
      :sq_aclaration="questionData.aclaration"
      :btnWidth="165"
      @selectOption="pendingOrEnding"
    ></Questions>
    <v-dialog v-model="pendigAlert" max-width="600px">
      <PendingAlert
        :showDescription="false"
        :pendingMessage="''"
        @exitAlert="exitAlert"
      ></PendingAlert>
    </v-dialog>
    <h2 v-if="exitMessage" class="mt-2">
      Por favor, presione el botón <b>FINALIZAR</b> para salir del Scoring
    </h2>
  </v-card-text>
</template>

<script>
import Questions from '@/components/savings/blocks/Questions'
import PendingAlert from '@/components/savings/blocks/PendingAlert'
import Helper from '../../../../helpers/Helper'
import { mapGetters } from 'vuex'

export default {
  name: 'SQuesFortyTwo',
  components: {
    Questions,
    PendingAlert
  },
  props: {
    questionData: Object
  },
  data () {
    return {
      pendigAlert: false,
      exitMessage: false,
      decisionQuestionFirstValue: '',
      question0: '',
      sq_data: [
        {
          question_id: this.questionData.questions[0].id,
          ponderation: '',
          updated_at: ''
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['scoringData', 'continueScoring'])
  },
  methods: {
    setData (sqdata) {
      this.$emit('handleProgress', 100)
      if (this.scoringData[sqdata] !== null) {
        this.$refs.sq42q0.setAnswer(this.scoringData[sqdata][0].ponderation)
        this.sq_data[0].ponderation = this.scoringData[sqdata][0].ponderation
        this.sq_data[0].updated_at = this.scoringData[sqdata][0].updated_at
        if (this.continueScoring) {
          if (this.scoringData[sqdata][0].id !== undefined) {
            this.sq_data[0].id = this.scoringData[sqdata][0].id
            this.question0 = this.scoringData[sqdata][0].ponderation
          }
        }
      }
    },
    handleIfEditQues (index, ques) {
      if (
        this.sq_data[index].id !== undefined &&
        ques !== this.sq_data[index].ponderation
      ) {
        this.$emit('editQuestion', this.sq_data[index])
      }
    },
    pendingOrEnding (value) {
      this.sq_data[0].ponderation = value
      this.sq_data[0].updated_at = Helper.setDateAndTime()
      this.handleIfEditQues(0, this.question0)
      this.$store.dispatch('handleScoringData', this.sq_data)
      if (value === this.questionData.questions[0].options[0]) {
        this.$store.dispatch('handleScoringInProgress', false)
        this.exitMessage = true
        this.pendigAlert = false
      }
      if (value === this.questionData.questions[0].options[1]) {
        this.$store.dispatch('handleScoringInProgress', true)
        this.exitMessage = false
        this.pendigAlert = true
      }
    },
    exitAlert () {
      this.pendigAlert = false
    }
  },
  mounted () {
    this.setData('sq_fortyTwoData')
  }
}
</script>
