export const singleQuesMethods = {
  methods: {
    editQuestion (data) {
      this.$emit('editQuestion', data)
    },
    deleteQuestion (id) {
      this.$emit('deleteQuestion', id)
    },
    checkIfHistoryModeContinues () {
      this.$emit('checkIfHistoryModeContinues')
    },
    reduceDecreasing (value) {
      this.$emit('reduceDecreasing', value)
    }
  }
}
