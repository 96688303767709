export default {
  state: {
    search: {
      typeofuser: '',
      consessionaire: '',
      departament: '',
      client: ''
    }
  },
  mutations: {
    setTypeofuser (state, payload) {
      state.search.typeofuser = payload
    },
    setConsessionaire (state, payload) {
      state.search.consessionaire = payload
    },
    setDepartament (state, payload) {
      state.search.departament = payload
    },
    setClient (state, payload) {
      state.search.client = payload
    }
  },
  actions: {
    handleTypeOfUser ({ commit }, payload) {
      commit('setTypeofuser', payload)
    },
    handleConsessionaire ({ commit }, payload) {
      commit('setConsessionaire', payload)
    },
    handleDepartament ({ commit }, payload) {
      commit('setDepartament', payload)
    },
    handleClient ({ commit }, payload) {
      commit('setClient', payload)
    }
  },
  getters: {
    searchTypeofuser: state => state.search.typeofuser,
    searchConsessionaire: state => state.search.consessionaire,
    searchDepartament: state => state.search.departament,
    salesClient: state => state.search.client
  }
}
