<template>
  <SingleSQ
    :questionData="questionData"
    :btnWidth="260"
    :oneOption="false"
    :pendingOption="false"
    :sq_numberData="'sq_sixData'"
    :sqOptions="[ 7, 21]"
    @editQuestion="editQuestion"
    @deleteQuestion="deleteQuestion"
    @checkIfHistoryModeContinues="checkIfHistoryModeContinues"
    @reduceDecreasing="reduceDecreasing"
    @handleProgress="handleProgress">
  </SingleSQ>
</template>

<script>
import SingleSQ from '@/components/savings/blocks/SingleSQ'
import { singleQuesMethods } from '@/mixins/singleQuesMethods'

export default {
  name: 'SQuesSix',
  components: {
    SingleSQ
  },
  props: {
    questionData: Object
  },
  methods: {
    handleProgress (value) {
      if (value === 1) {
        this.$emit('handleProgress', 58)
      } else if (value === 2) {
        this.$emit('handleProgress', 53)
      } else if (value === 3) {
        this.$emit('handleProgress', 45)
      }
    }
  },
  mixins: [singleQuesMethods]
}
</script>
