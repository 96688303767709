<template>
  <v-navigation-drawer
    app
    width="300"
    v-model="controlMobileOpen"
    :mini-variant.sync="isMini"
    color="secondary"
    >
      <MenuNavHeader />
      <MenuNavModules />
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import MenuNavHeader from './MenuNavHeader'
import MenuNavModules from './MenuNavModules'

export default {
  name: 'MenuNav',

  components: {
    MenuNavHeader,
    MenuNavModules
  },
  computed: {
    ...mapGetters({
      isMobileOpen: 'mobileOpen',
      isMiniOpen: 'miniOpen'
    }),
    controlMobileOpen: {
      get () {
        return this.isMobileOpen
      },
      set (value) {
        this.handleMobileMenu(value)
      }
    },
    isMini: {
      get () {
        return this.isMiniOpen
      },
      set (value) {
        this.handleMiniMenu(value)
      }
    }
  },
  methods: {
    ...mapActions(['handleMobileMenu', 'handleMiniMenu'])
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .v-navigation-drawer__content::-webkit-scrollbar{
    width: 5px;
  }
  .v-navigation-drawer__content::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  }
  .v-navigation-drawer__content::-webkit-scrollbar-thumb {
    background-color: rgba(256,256,256,0.75);
    outline: 1px solid $black;
  }
}
</style>
