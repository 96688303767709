<template>
  <v-stepper-content :step="step">
    <v-form ref="stepOneScoring">
      <v-container fluid>
        <v-row class="d-flex justify-center px-4">
          <v-col cols="12">
            <v-card elevation="0" class="rounded" color="#F5F5F7">
              <v-card-title>
                <h2
                  style="font-size: 14px;letter-spacing: 0.01em;"
                  class="text-no-wrap text-truncate"
                  >DETALLE CLIENTE</h2>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="3">
                      <v-text-field
                        dense
                        :autofocus="!continueScoring && !clientData.id"
                        v-model="clientData.dni"
                        :rules="[valiadationsRules.required]"
                        label="DNI"
                        placeholder="DNI"
                        @focus="removeGlobalFilter"
                        @change="getClientByDNI(1)">
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-text-field
                        dense
                        v-model="clientData.name"
                        :rules="[valiadationsRules.required]"
                        :disabled="clientData.id !== ''"
                        label="Nombre"
                        placeholder="Nombre">
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-text-field
                        dense
                        v-model="clientData.surname"
                        :rules="[valiadationsRules.required]"
                        :disabled="clientData.id !== ''"
                        label="Apellido"
                        placeholder="Apellido">
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-menu
                        v-model="calendar"
                        :close-on-content-click="false"
                        :nudge-left="110"
                        transition="slide-y-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="clientData.dateOfBirth"
                            :rules="[valiadationsRules.required]"
                            :disabled="clientData.id !== ''"
                            label="Fecha Nacimiento"
                            readonly
                            dense
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="date"
                          no-title
                          locale="es-ar"
                          @input="setDate"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="3">
                      <v-select
                        dense
                        v-model="clientData.maritalStatus"
                        :rules="[valiadationsRules.required]"
                        :disabled="clientData.id !== ''"
                        return-object
                        :items="maritalStatusItems"
                        label="Est. Civil"
                        placeholder="Est. Civil">
                      </v-select>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-text-field
                        dense
                        v-model="clientData.email"
                        :rules="[valiadationsRules.required]"
                        :disabled="clientData.id !== ''"
                        label="Mail"
                        placeholder="Mail">
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-text-field
                        dense
                        v-model="clientData.address"
                        :rules="[valiadationsRules.required]"
                        :disabled="clientData.id !== ''"
                        label="Dirección"
                        placeholder="Dirección">
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-text-field
                        dense
                        v-model="clientData.number"
                        :rules="[valiadationsRules.required]"
                        :disabled="clientData.id !== ''"
                        label="Nº"
                        placeholder="Nº">
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="3">
                      <v-text-field
                        dense
                        v-model="clientData.floor"
                        :disabled="clientData.id !== ''"
                        label="Piso"
                        placeholder="Piso">
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-text-field
                        dense
                        v-model="clientData.apartment"
                        :disabled="clientData.id !== ''"
                        label="Depto"
                        placeholder="Depto">
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-autocomplete
                        dense
                        return-object
                        :items="provincesItems"
                        v-model="clientDataState"
                        :rules="[valiadationsRules.required]"
                        :disabled="clientData.id !== ''"
                        label="Provincia"
                        placeholder="Provincia">
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-autocomplete
                        dense
                        return-object
                        :items="citiesItems"
                        v-model="clientData.city"
                        :rules="[valiadationsRules.required]"
                        :disabled="clientDataState === '' || clientData.id !== ''"
                        label="Localidad"
                        placeholder="Localidad">
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="3">
                      <v-text-field
                        dense
                        v-model="clientData.zipCode"
                        :rules="[valiadationsRules.required]"
                        :disabled="clientData.id !== ''"
                        label="Codigo Postal"
                        placeholder="Codigo Postal">
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-text-field
                        dense
                        v-model="clientData.phoneNumber"
                        :rules="[valiadationsRules.required]"
                        :disabled="clientData.id !== ''"
                        label="Teléfono"
                        placeholder="Teléfono">
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-text-field
                        dense
                        v-model="clientData.cellphoneNumber"
                        :rules="[valiadationsRules.required]"
                        :disabled="clientData.id !== ''"
                        label="Celular"
                        placeholder="Celular">
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-select
                        dense
                        return-object
                        v-model="clientData.clientTypeId"
                        :rules="[valiadationsRules.required]"
                        :disabled="clientData.id !== ''"
                        :items="clientTypeItems"
                        label="Tipo de Cliente"
                        placeholder="Tipo de Cliente">
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row v-if="clientData.clientTypeId.value === 2">
                    <v-col cols="12" sm="3">
                      <v-text-field
                        dense
                        v-model="clientData.cuit"
                        :rules="[valiadationsRules.required]"
                        :disabled="clientData.id !== ''"
                        label="CUIT"
                        placeholder="CUIT">
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-text-field
                        dense
                        v-model="clientData.businessName"
                        :rules="[valiadationsRules.required]"
                        :disabled="clientData.id !== ''"
                        label="Razón Social"
                        placeholder="Razón Social">
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-stepper-content>
</template>

<script>
import { getByDNI, getAllClientTypes } from '@/services/clients'
import { getAllProvinces, getAllMaritalStatus } from '@/services/globals'
import AuthHelper from '@/helpers/AuthHelper'
import Helper from '@/helpers/Helper'
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  name: 'StepClient',
  props: {
    clientData: Object,
    step: Number
  },
  data () {
    return {
      date: '',
      calendar: false,
      maritalStatus: [],
      maritalStatusItems: [],
      provinces: [],
      provincesItems: [],
      clientDataState: '',
      citiesItems: [],
      clientTypes: [],
      clientTypeItems: [],
      valiadationsRules: {
        required: value => !!value || 'Dato requerido'
      }
    }
  },
  computed: {
    ...mapState(['userToken']),
    ...mapGetters(['continueScoring'])
  },
  methods: {
    ...mapActions(['handleLoading', 'handleAlert']),
    async getClientByDNI (type) {
      const dni = this.clientData.dni
      try {
        this.handleLoading(true)
        const res = await getByDNI(this.userToken, type, dni)
        this.$emit('setClientStatus', true)
        this.$emit('setDataInFields', res.data.data)
        this.alertMessage = res.data.message
        this.alertColor = 'success'
      } catch (error) {
        this.$emit('resetClientId')
        AuthHelper.checkErrorExpiredToken(error)
        this.alertMessage = error?.data?.message
        this.alertColor = 'error'
        this.$emit('setClientStatus', false)
      } finally {
        this.handleLoading(false)
        this.handleAlert({
          value: true,
          text: this.alertMessage,
          color: this.alertColor
        })
      }
    },
    async getMaritalStatus () {
      try {
        const res = await getAllMaritalStatus(this.userToken)
        this.maritalStatus = res.data.data
        this.maritalStatusItems = Helper.transformToSelect(this.maritalStatus, 'name')
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
      } finally {
      }
    },
    async getProvinces () {
      try {
        this.handleLoading(true)
        const res = await getAllProvinces(this.userToken)
        this.provinces = res.data.data
        this.provincesItems = Helper.sortArrayByTextField(Helper.transformToSelect(this.provinces, 'name'))
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
      } finally {
        this.handleLoading(false)
        if (this.continueScoring) {
          this.getClientByDNI(1)
        }
      }
    },
    async getClientTypes () {
      try {
        const res = await getAllClientTypes(this.userToken)
        this.clientTypes = res.data.data
        this.clientTypeItems = Helper.transformToSelect(this.clientTypes, 'name')
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
      }
    },
    setDate () {
      this.calendar = false
      const str = [...this.date]
      const localFormat = []
      this.newOrder(localFormat, str, 8)
      this.newOrder(localFormat, str, 9)
      this.newOrder(localFormat, str, 7)
      this.newOrder(localFormat, str, 5)
      this.newOrder(localFormat, str, 6)
      this.newOrder(localFormat, str, 7)
      this.newOrder(localFormat, str, 0)
      this.newOrder(localFormat, str, 1)
      this.newOrder(localFormat, str, 2)
      this.newOrder(localFormat, str, 3)
      const newDate = localFormat.join('')
      this.$emit('setDateOfBirth', newDate, this.date)
    },
    newOrder (arr, str, n) {
      arr.push(str[n])
    },
    setState () {
      this.clientDataState = {}
      this.clientDataState.value = this.clientData.state.value
    },
    removeGlobalFilter () {
      this.$emit('removeGlobalFilter')
    },
    resetStateData () {
      this.clientDataState = ''
    },
    noValidateStepOne () {
      this.$refs.stepOneScoring.validate()
    }
  },
  watch: {
    clientDataState: {
      handler () {
        if (this.clientDataState !== '') {
          const state = Helper.getItemFromArray(this.clientDataState.value, this.provinces)
          this.citiesItems = Helper.sortArrayByTextField(Helper.transformToSelect(state.localities, 'name'))
          this.$emit('setState', this.clientDataState)
        }
      }
    }
  },
  mounted () {
    this.getMaritalStatus()
    this.getProvinces()
    this.getClientTypes()
  }
}
</script>
