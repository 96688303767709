<template>
  <v-stepper-content :step="step">
    <v-form ref="saleStepOne">
      <div class="fluid mx-8">
        <div class="d-flex flex-column">
          <v-card color="grey lighten-4"
                  elevation="0"
                  class="pa-6 my-4 mx-4 d-flex flex-column justify-start fluid">
            <span class="text-uppercase text-no-wrap font-weight-bold align-self-start pb-4 mt-4">Cliente</span>
              <v-row class="mt-4">
                <v-col>
                  <v-text-field
                    :disabled="userDb.role.id === 4"
                    dense
                    label="DNI"
                    placeholder="DNI"
                    v-model="data.client_dni"
                    :rules="[valiadationsRules.required]"
                    @change="searchDataByDNI(1)"
                    class="pr-6 mt-4"
                  />
                  <v-text-field
                    :disabled="userDb.role.id === 4"
                    v-if="(data.client_type.value === 2)"
                    dense
                    label="CUIT"
                    placeholder="CUIT"
                    v-model="data.client_cuit"
                    :rules="[valiadationsRules.required]"
                    class="pr-6 mt-4"
                  />
                  <v-text-field
                    :disabled="userDb.role.id === 4"
                    dense
                    label="Nombre"
                    placeholder="Nombre"
                    v-model="data.client_firstname"
                    :rules="[valiadationsRules.required]"
                    class="pr-6 mt-4"
                  />
                  <v-select
                    :disabled="userDb.role.id === 4"
                    dense
                    return-object
                    label="Est. Civil"
                    placeholder="Est. Civil"
                    :items="maritalStatusItems"
                    v-model="clientMaritalStatusModel"
                    :rules="[valiadationsRules.required]"
                    class="pr-6 mt-4"
                  />
                  <v-text-field
                    :disabled="userDb.role.id === 4"
                    dense
                    label="Mail"
                    placeholder="Mail"
                    v-model="data.client_email"
                    :rules="[valiadationsRules.required]"
                    class="pr-6 mt-4"
                  />
                  <v-select
                    :disabled="userDb.role.id === 4"
                    dense
                    return-object
                    label="Localidad"
                    placeholder="Localidad"
                    :items="localitiesClientItems"
                    v-model="localityClientModel"
                    :rules="[valiadationsRules.required]"
                    class="pr-6 mt-4"
                  />
                  <v-text-field
                    :disabled="userDb.role.id === 4"
                    dense
                    label="Direccion"
                    placeholder="Direccion"
                    v-model="data.client_adress"
                    :rules="[valiadationsRules.required]"
                    class="pr-6 mt-4"
                  />
                  <v-text-field
                    :disabled="userDb.role.id === 4"
                    dense
                    label="Piso"
                    placeholder="Piso"
                    v-model="data.client_adress_floor"
                    class="pr-6 mt-4"
                  />
                  <v-text-field
                    :disabled="userDb.role.id === 4"
                    dense
                    label="Teléfono"
                    placeholder="Teléfono"
                    v-model="data.client_phone"
                    :rules="[valiadationsRules.required]"
                    class="pr-6 mt-4"
                  />
                </v-col>
                <v-col>
                  <v-select
                    :disabled="userDb.role.id === 4"
                    dense
                    return-object
                    label="Tipo de Cliente"
                    placeholder="Tipo de Cliente"
                    :items="clientTypesItems"
                    v-model="clientTypeModel"
                    :rules="[valiadationsRules.required]"
                    class="mt-4"
                  />
                  <v-text-field
                    :disabled="userDb.role.id === 4"
                    v-if="(data.client_type.value === 2)"
                    dense
                    label="Razón social"
                    placeholder="Razón social"
                    v-model="data.client_trade_name"
                    :rules="[valiadationsRules.required]"
                    class="mt-4"
                  />
                  <v-text-field
                    :disabled="userDb.role.id === 4"
                    dense
                    label="Apellido"
                    placeholder="Apellido"
                    v-model="data.client_lastname"
                    :rules="[valiadationsRules.required]"
                    class="mt-4"
                  />
                  <v-menu
                    :disabled="userDb.role.id === 4"
                    v-model="isClientCalendarExpanded"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :disabled="userDb.role.id === 4"
                        clearable
                        readonly
                        hide-details
                        v-bind="attrs"
                        v-on="on"
                        id="datepicker1"
                        label="Fecha de nacimiento"
                        :rules="[valiadationsRules.required]"
                        v-model="data.client_birth_date"
                      >
                      </v-text-field>
                    </template>
                    <v-date-picker
                      :disabled="userDb.role.id === 4"
                      v-model="data.client_birth_date"
                      @input="isClientCalendarExpanded = false"
                    ></v-date-picker>
                  </v-menu>
                  <v-select
                    :disabled="userDb.role.id === 4"
                    dense
                    return-object
                    label="Provincia"
                    placeholder="Provincia"
                    :items="provincesItems"
                    v-model="provinceClientModel"
                    :rules="[valiadationsRules.required]"
                    class="nextToDatepicker"
                  />
                  <v-text-field
                    :disabled="userDb.role.id === 4"
                    dense
                    label="Código Postal"
                    placeholder="Código Postal"
                    v-model="data.client_adress_zip_code"
                    :rules="[valiadationsRules.required]"
                    class="mt-4"
                  />
                  <v-text-field
                    :disabled="userDb.role.id === 4"
                    dense
                    label="N°"
                    placeholder="N°"
                    v-model="data.client_adress_number"
                    :rules="[valiadationsRules.required]"
                    class="mt-4"
                  />
                  <v-text-field
                    :disabled="userDb.role.id === 4"
                    dense
                    label="Depto"
                    placeholder="Depto"
                    v-model="data.client_adress_department"
                    class="mt-4"
                  />
                  <v-text-field
                    :disabled="userDb.role.id === 4"
                    dense
                    label="Celular"
                    placeholder="Celular"
                    v-model="data.client_mobile"
                    :rules="[valiadationsRules.required]"
                    class="mt-4"
                  />
                </v-col>
              </v-row>
          </v-card>
        </div>
      </div>
    </v-form>
  </v-stepper-content>
</template>

<script>
import { getByDNI } from '../../../services/clients'
import { mapActions, mapState } from 'vuex'
import AuthHelper from '../../../helpers/AuthHelper'
import Helper from '../../../helpers/Helper'

export default {
  name: 'StepClient',
  props: {
    userDb: Object,
    step: Number,
    saleData: {
      type: Object,
      required: true
    },
    edit: Boolean,
    valiadationsRules: Object,
    provinces: Array,
    provincesItems: Array,
    maritalStatus: Array,
    clientTypes: Array,
    clientTypesItems: Array,
    maritalStatusItems: Array,
    modelId: Number,
    vehicleId: [Number, String, null]
  },
  data () {
    return {
      localities: [],
      localitiesClientItems: [],
      provinceClientModel: '',
      localityClientModel: '',
      clientMaritalStatusModel: '',
      clientTypeModel: '',
      isClientCalendarExpanded: false
    }
  },
  computed: {
    ...mapState(['userToken']),
    data: {
      get () {
        return this.saleData
      },
      set (value) {
        this.$emit('update:data', value)
      }
    }
  },
  methods: {
    ...mapActions(['handleLoading', 'handleAlert']),
    saleStepClientValidate () {
      this.$refs.saleStepOne.validate()
    },
    async searchDataByDNI (type) {
      const dni = this.data.client_dni
      try {
        this.handleLoading(true)
        const res = await getByDNI(this.userToken, type, dni)
        this.setData(res.data.data)
        this.alertMessage = res.data.message
        this.alertColor = 'success'
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
        this.alertMessage = error?.data?.message ? error.data.message : error
        this.alertColor = 'error'
        console.error(error)
        if (error.status === 404) {
          this.data.client_id = ''
          this.data.client_firstname = ''
          this.data.client_lastname = ''
          this.data.client_birth_date = ''
          this.data.client_marital_status = ''
          this.data.client_email = ''
          this.data.client_adress = ''
          this.data.client_adress_number = ''
          this.data.client_adress_floor = ''
          this.data.client_adress_department = ''
          this.data.client_adress_locality = ''
          this.data.client_adress_zip_code = ''
          this.data.client_phone = ''
          this.data.client_mobile = ''
          this.data.client_trade_name = ''
          this.data.client_cuit = ''
          this.provinceClientModel = ''
          this.localityClientModel = ''
          this.clientMaritalStatusModel = ''
          this.clientTypeModel = ''
        }
      } finally {
        this.handleLoading(false)
        this.handleAlert({
          value: true,
          text: this.alertMessage,
          color: this.alertColor
        })
      }
    },
    setData (data) {
      this.data.client_id = data.id
      this.data.client_firstname = data.name
      this.data.client_lastname = data.lastname
      this.data.client_birth_date = data.birth_date
      this.data.client_marital_status = data.marital_status.id
      this.data.client_email = data.email
      this.data.client_adress = data.adress
      this.data.client_adress_number = data.adress_number
      this.data.client_adress_floor = data.address_floor
      this.data.client_adress_department = data.address_department
      this.data.client_adress_locality = data.locality
      this.data.client_adress_zip_code = data.postcode
      this.data.client_phone = data.phone
      this.data.client_mobile = data.mobile_phone
      this.data.client_type = data.type.id
      // revisar como llegan y rectificar datos
      this.data.client_trade_name = data.social_reason
      this.data.client_cuit = data.cuit

      this.provinceClientModel = this.setModelSelected(Helper.getArrayValueFromId(data.locality.province_id, this.provinces))
      this.localityClientModel = this.setModelSelected(Helper.getArrayValueFromId(data.locality.id, Helper.getArrayValueFromId(data.locality.province_id, this.provinces).localities))
      this.clientMaritalStatusModel = this.setModelSelected(Helper.getArrayValueFromId(data.marital_status_id, this.maritalStatus))
      this.clientTypeModel = this.setModelSelected(Helper.getArrayValueFromId(data.type.id, this.clientTypes))
    },
    setModelSelected (model) {
      return {
        value: Object.prototype.hasOwnProperty.call(model, 'value') ? model.value : model.id,
        text: model.name
      }
    }
  },
  async mounted () {
    if (this.edit) {
      this.provinceClientModel = this.setModelSelected(Helper.getArrayValueFromId(this.saleData.client_locality.province_id, this.provinces))
      this.localityClientModel = this.setModelSelected(Helper.getArrayValueFromId(this.saleData.client_locality.id, Helper.getArrayValueFromId(this.saleData.client_locality.province_id, this.provinces).localities))
      this.clientMaritalStatusModel = this.setModelSelected(Helper.getArrayValueFromId(this.saleData.client_marital_status.id, this.maritalStatus))
      this.clientTypeModel = this.setModelSelected(Helper.getArrayValueFromId(this.saleData.client_type.id, this.clientTypes))
    }
  },
  watch: {
    provinceClientModel: {
      handler () {
        const province = Helper.getItemFromArray(this.provinceClientModel.value, this.provinces)
        this.localitiesClientItems = Helper.sortArrayByTextField(Helper.transformToSelect(province.localities, 'name'))
      }
    },
    localityClientModel: {
      handler () {
        this.data.client_adress_locality = this.localityClientModel
      }
    },
    clientMaritalStatusModel: {
      handler () {
        this.data.client_marital_status = this.clientMaritalStatusModel
      }
    },
    clientTypeModel: {
      handler () {
        this.data.client_type = this.clientTypeModel
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .v-picker {
    margin-top: 0px !important;
  }
  #datepicker1, #datepicker2 {
    margin-top: 10px;
  }
  label[for=datepicker1], label[for=datepicker2] {
    top: 20px;
  }
  .nextToDatepicker {
    margin-top: 34px;
  }
}
</style>
