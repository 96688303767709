import { render, staticRenderFns } from "./SQuesTwo.vue?vue&type=template&id=9947d4d0&"
import script from "./SQuesTwo.vue?vue&type=script&lang=js&"
export * from "./SQuesTwo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardText } from 'vuetify/lib/components/VCard';
installComponents(component, {VBtn,VCardText})
