import Helper from '../helpers/Helper'

export const answerData = {
  methods: {
    useButton (value) {
      if (value !== null) {
        this.showButton = true
      }
    },
    dataFromQuestions (value, index) {
      this.sq_data[index].ponderation = value
      this.sq_data[index].updated_at = Helper.setDateAndTime()
      this.useButton(value)
    },
    changeDataSeted (value) {
      this.dataSeted = value
    },
    handleIfEditQues (index, ques) {
      if (this.isBrowsingInScoringHistory && this.currentQues === index) {
        if (this.sq_data[index].id !== undefined && (ques !== this.sq_data[index].ponderation)) {
          this.$emit('editQuestion', this.sq_data[index])
          this.$store.dispatch('handleScoringData', this.sq_data)
        }
      }
    },
    handleIfEditDecisionQues (index, ques) {
      if (this.sq_data[index].id !== undefined && (ques !== this.sq_data[index].ponderation)) {
        this.$emit('editQuestion', this.sq_data[index])
        this.$store.dispatch('handleScoringData', this.sq_data)
      }
    },
    handleIfEditQuesInSQWithoutCurrentQues (index, ques) {
      if (this.isBrowsingInScoringHistory) {
        if (this.sq_data[index].id !== undefined && (ques !== this.sq_data[index].ponderation)) {
          this.$emit('editQuestion', this.sq_data[index])
          this.$store.dispatch('handleScoringData', this.sq_data)
        }
      }
    }
  }
}
