<template>
  <v-container class="ml-2">
    <v-autocomplete
      class="mx-4 mb-3 mt-4"
      v-model="client"
      :items="clientItems"
      label="Cliente">
    </v-autocomplete>
    <v-autocomplete
      class="mx-4 mb-4"
      v-model="vehicle"
      :items="vehicleItems"
      dense
      label="Vehículo">
    </v-autocomplete>
    <v-select
      class="mx-4 mb-3"
      v-model="scoringStatus"
      dense
      :items="statusItems"
      label="Estado">
    </v-select>
    <v-menu
      v-model="calendar"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="auto">
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          class="mx-4 mb-7"
          v-model="newDate"
          label="Fecha"
          append-icon="mdi-calendar"
          readonly
          clearable
          dense
          v-bind="attrs"
          v-on="on">
        </v-text-field>
      </template>
      <v-date-picker
        v-model="date"
        no-title
        locale="es-ar"
        @input="setDate">
      </v-date-picker>
    </v-menu>
  </v-container>
</template>

<script>
import Helper from '@/helpers/Helper'
import { mapGetters } from 'vuex'

export default {
  name: 'SavingsFilterOptions',
  props: {
    clients: {
      type: Array,
      required: true
    },
    vehicles: {
      type: Array,
      required: true
    },
    status: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      clientItems: [],
      vehicleItems: [],
      statusItems: [],
      calendar: false,
      date: '',
      newDate: ''
    }
  },
  computed: {
    ...mapGetters(['filterSavingsData']),
    client: {
      get () {
        return this.filterSavingsData.client
      },
      set (value) {
        this.$store.dispatch('handleClientDataToFilter', value)
      }
    },
    vehicle: {
      get () {
        return this.filterSavingsData.vehicle
      },
      set (value) {
        this.$store.dispatch('handleVehicleToFilter', value)
      }
    },
    scoringStatus: {
      get () {
        return this.filterSavingsData.scoringStatus
      },
      set (value) {
        this.$store.dispatch('handleStatusToFilter', value)
      }
    },
    dateSelected: {
      get () {
        return this.filterSavingsData.date
      },
      set (value) {
        this.$store.dispatch('handleDateToFilter', value)
      }
    }
  },
  methods: {
    setDate () {
      this.calendar = false
      this.$store.dispatch('handleDateToFilter', this.date)
      const str = [...this.date]
      const localFormat = []
      this.newOrder(localFormat, str, 8)
      this.newOrder(localFormat, str, 9)
      this.newOrder(localFormat, str, 7)
      this.newOrder(localFormat, str, 5)
      this.newOrder(localFormat, str, 6)
      this.newOrder(localFormat, str, 7)
      this.newOrder(localFormat, str, 0)
      this.newOrder(localFormat, str, 1)
      this.newOrder(localFormat, str, 2)
      this.newOrder(localFormat, str, 3)
      const dateWithNewOrder = localFormat.join('')
      // Dete with format to be used in frontend
      this.newDate = dateWithNewOrder
    },
    newOrder (arr, str, n) {
      arr.push(str[n])
    },
    transformToSelect (array, fieldName1, fieldName2, initItem) {
      const arrayItems = []
      if (initItem) {
        arrayItems.push(initItem)
      }
      array.forEach(data => {
        const text = data[fieldName1] + ' ' + data[fieldName2]
        arrayItems.push({
          value: data.id,
          text: text
        })
      })
      return arrayItems
    },
    createVehicleItems () {
      this.vehicleItems.push({ value: '', text: 'Todos los vehículos' })
      this.vehicleItems = [...this.vehicleItems, ...this.vehicles]
    }
  },
  mounted () {
    this.clientItems = this.transformToSelect(this.clients, 'name', 'lastname', { value: '', text: 'Todos los clientes' })
    this.createVehicleItems()
    this.statusItems = Helper.transformToSelect(this.status, 'name', { value: '', text: 'Todos los estados' })
  },
  watch: {
    clients: {
      handler () {
        this.clientItems = this.transformToSelect(this.clients, 'name', 'lastname', { value: '', text: 'Todos los clientes' })
      }
    },
    vehicles: {
      handler () {
        this.createVehicleItems()
      }
    },
    status: {
      handler () {
        this.statusItems = Helper.transformToSelect(this.status, 'name', { value: '', text: 'Todos los estados' })
      }
    }
  }
}
</script>
