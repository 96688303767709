<template>
  <div>
    <CreateScoringHeader
      @thisStep="thisStep"
      @saveScoring="createNewScoring"
      @redoTableData="redoTableData">
    </CreateScoringHeader>
    <v-stepper v-model="step" elevation="0">
      <v-stepper-header class="mb-4 d-flex justify-start" style="height: 50px;">
        <v-stepper-step
          class="ml-7"
          style="font-weight: bold;font-size: 14px;letter-spacing: 0.01em;"
          :complete="step > 1"
          step="1"
          @click="thisStep(1);"
        >
          Datos Cliente
        </v-stepper-step>
        <v-stepper-step
          class="ml-16 pl-16"
          step="2"
          style="font-weight: bold;font-size: 14px;letter-spacing: 0.01em;"
          @click="thisStep(2);"
        >
          Scoring
        </v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <StepClient
          ref="stepClientScoring"
          :clientData="clientData"
          :step="1"
          @resetClientId="resetClientId"
          @setDataInFields="setDataInFields"
          @setDateOfBirth="setDateOfBirth"
          @setState="setState"
          @removeGlobalFilter="removeGlobalFilter"
          @setClientStatus="setClientStatus"
        />
        <StepScoring
          ref="stepScoring"
          :clientData="clientData"
          :renaultModelItems="renaultModelItems"
          :users="users"
          :questions="questions"
          :itemToEditData="itemToEditData"
          :step="2"
          :calendarMounted="calendarMounted"
          @setCalendarInfo="setCalendarInfo"
        />
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import CreateScoringHeader from '@/components/savings/CreateScoringHeader'
import StepClient from '@/components/savings/StepClient'
import StepScoring from '@/components/savings/StepScoring'
import { createScoring, getScoringId, createClient } from '@/services/savings'
import { mapActions, mapState, mapGetters } from 'vuex'
import AuthHelper from '@/helpers/AuthHelper'

export default {
  name: 'CreateOrEditScoring',
  components: {
    CreateScoringHeader,
    StepClient,
    StepScoring
  },
  props: {
    questions: Object,
    renaultModelItems: Array,
    users: Array,
    itemToEditData: Object,
    calendarMounted: Boolean,
    recoveredDataFromLS: Object
  },
  data () {
    return {
      step: 1,
      alertMessage: '',
      alertColor: '',
      scoring: [],
      isClient: false,
      toStepTwo: false,
      clientData: {
        id: '',
        clientTypeId: '',
        cuit: '',
        businessName: '',
        dni: '',
        name: '',
        surname: '',
        dateOfBirth: '',
        dateToBeSend: '',
        maritalStatus: '',
        email: '',
        address: '',
        number: '',
        floor: '',
        apartment: '',
        state: '',
        city: '',
        zipCode: '',
        phoneNumber: '',
        cellphoneNumber: ''
      },
      infoForCreateScoring: {}
    }
  },
  computed: {
    ...mapState(['userToken']),
    ...mapGetters([
      'scoringData',
      'continueScoring',
      'getHistory',
      'getScoringId',
      'recoveringData'
    ])
  },
  methods: {
    ...mapActions(['handleLoading', 'handleAlert']),
    thisStep (value) {
      this.validateStepOne()
      if (value === 2 && !this.isClient && this.toStepTwo) {
        this.createNewClient()
      }
      if (value === 2 && !this.continueScoring && this.toStepTwo && !this.getScoringId) {
        this.startScoringWithId()
      }
      if (value === 2) {
        if (!this.toStepTwo) {
          this.$refs.stepClientScoring.noValidateStepOne()
        } else if (this.toStepTwo) {
          this.$store.dispatch('handleClientDataInScoring', this.clientData)
          this.step = value
        }
      } else if (value === 1) {
        this.step = value
      }
    },
    setClientStatus (value) {
      this.isClient = value
      if (value === false) {
        this.clientData.id = ''
        this.clientData.clientTypeId = ''
        this.clientData.cuit = ''
        this.clientData.businessName = ''
        this.clientData.name = ''
        this.clientData.surname = ''
        this.clientData.dateOfBirth = ''
        this.clientData.dateToBeSend = ''
        this.clientData.maritalStatus = ''
        this.clientData.email = ''
        this.clientData.address = ''
        this.clientData.number = ''
        this.clientData.floor = ''
        this.clientData.apartment = ''
        this.clientData.state = ''
        this.clientData.city = ''
        this.clientData.zipCode = ''
        this.clientData.phoneNumber = ''
        this.clientData.cellphoneNumber = ''
        this.$refs.stepClientScoring.resetStateData()
      }
    },
    validateStepOne () {
      if (this.clientData.clientTypeId === '' ||
        this.clientData.dni === '' ||
        this.clientData.name === '' ||
        this.clientData.surname === '' ||
        this.clientData.dateOfBirth === '' ||
        this.clientData.maritalStatus === '' ||
        this.clientData.email === '' ||
        this.clientData.address === '' ||
        this.clientData.number === '' ||
        this.clientData.state === '' ||
        this.clientData.city === '' ||
        this.clientData.zipCode === '' ||
        this.clientData.phoneNumber === '' ||
        this.clientData.cellphoneNumber === '') {
        this.toStepTwo = false
      } else {
        this.toStepTwo = true
      }
      if (this.clientData.clientTypeId === 2) {
        if (this.clientData.cuit === '' ||
        this.clientData.businessName === '') {
          this.toStepTwo = false
        }
      }
    },
    setCalendarInfo () {
      this.$emit('setCalendarInfo')
    },
    async startScoringWithId () {
      this.handleLoading(true)
      try {
        const res = await getScoringId(this.userToken, 2)
        this.$store.dispatch('handleScoringIdData', res.data.data.id)
        this.handleLoading(false)
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
        this.handleLoading(false)
      }
    },
    async createNewScoring (schedule) {
      this.handleLoading(true)
      this.prepareScorinToSend()
      const info = this.infoForCreateScoring
      if (schedule !== 1 && schedule !== 2) {
        this.infoForCreateScoring.appointment = {
          name: schedule.name,
          color: '#F5F5F7',
          start: schedule.start,
          end: schedule.end
        }
        this.infoForCreateScoring.scoring_status_id = 2
      } else if (schedule === 1) {
        this.infoForCreateScoring.scoring_status_id = 1
      } else if (schedule === 2) {
        this.infoForCreateScoring.scoring_status_id = 2
      }
      try {
        const res = await createScoring(this.userToken, info)
        this.$emit('addScoringInTable')
        this.alertMessage = res.data.message
        this.alertColor = 'success'
        if (!this.continueScoring) {
          localStorage.removeItem('scoringClientData')
          localStorage.removeItem('scoringData')
          localStorage.removeItem('scoringHistory')
        }
        this.handleLoading(false)
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
        this.alertMessage = error?.data?.message
        this.alertColor = 'error'
        this.handleLoading(false)
      } finally {
        this.$store.dispatch('handleOnlyCurrentSQ', 1)
        this.$store.dispatch('handleOperationNumber', null)
        this.$store.dispatch('handleScoringInProgress', true)
        this.$store.dispatch('handleContinueScoring', false)
        this.$store.dispatch('handleScoringIdData', false)
        this.$store.dispatch('handleIfSentScoringNumber', false)
        this.$store.dispatch('hanldeRecoveringData', false)
        this.handleAlert({
          value: true,
          text: this.alertMessage,
          color: this.alertColor
        })
        this.$store.dispatch('handleAddOrEditScoring', false)
      }
    },
    async createNewClient () {
      this.handleLoading(true)
      const data = this.prepareInfoClientToSend()
      try {
        const res = await createClient(this.userToken, data)
        this.clientData.id = res.data.data.id
        this.alertMessage = res.data.message
        this.alertColor = 'success'
        this.handleLoading(false)
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
        this.alertMessage = error?.data?.message
        this.alertColor = 'error'
        this.handleLoading(false)
      } finally {
        this.handleAlert({
          value: true,
          text: this.alertMessage,
          color: this.alertColor
        })
      }
    },
    prepareScorinToSend () {
      this.scoring = []
      if (this.scoringData.sq_oneData === null) {
        this.scoring.push({
          question_id: 1,
          ponderation: '',
          updated_at: '2020-12-12 11:11:11'
        })
      }
      this.infoToSend('sq_oneData')
      this.scoring[0].history = this.getHistory
      this.infoToSend('sq_threeData')
      this.infoToSend('sq_fourData')
      this.infoToSend('sq_fiveData')
      this.infoToSend('sq_sixData')
      this.infoToSend('sq_sevenData')
      this.infoToSend('sq_eightData')
      this.infoToSend('sq_nineData')
      this.infoToSend('sq_tenData')
      this.infoToSend('sq_elevenData')
      this.infoToSend('sq_twelveData')
      this.infoToSend('sq_thirteenData')
      this.infoToSend('sq_fourteenData')
      this.infoToSend('sq_fifteenData')
      this.infoToSend('sq_sixteenData')
      this.infoToSend('sq_seventeenData')
      this.infoToSend('sq_eighteenData')
      this.infoToSend('sq_nineteenData')
      this.infoToSend('sq_twentyData')
      this.infoToSend('sq_twentyOneData')
      this.infoToSend('sq_twentyTwoData')
      this.infoToSend('sq_twentyThreeData')
      this.infoToSend('sq_twentyFourData')
      this.infoToSend('sq_twentyFiveData')
      this.infoToSend('sq_twentyEightData')
      this.infoToSend('sq_twentyNineData')
      this.infoToSend('sq_thirtyData')
      this.infoToSend('sq_thirtyOneData')
      this.infoToSend('sq_thirtyTreeData')
      this.infoToSend('sq_thirtyFourData')
      this.infoToSend('sq_thirtyFiveData')
      this.infoToSend('sq_thirtySixData')
      this.infoToSend('sq_thirtySevenData')
      this.infoToSend('sq_thirtyEightData')
      this.infoToSend('sq_thirtyNineData')
      this.infoToSend('sq_fortyData')
      this.infoToSend('sq_fortyTwoData')
      this.infoToSend('sq_fortyThreeData')
      this.infoToSend('sq_fortyFourData')
      this.infoToSend('sq_fortyFiveData')
      this.infoToSend('sq_fortySixData')
      this.infoToSend('sq_fortySevenData')
      this.infoToSend('sq_fortyEightData')
      this.infoToSend('sq_fortyNineData')
      this.infoToSend('sq_fiftyData')
      this.infoToSend('sq_fiftyOneData')
      this.infoToSend('sq_fiftyTwoData')
      this.infoToSend('sq_fiftyThreeData')
      this.infoToSend('sq_fiftyFourData')
      this.infoForCreateScoring.client_id = this.clientData.id
      if (this.continueScoring) {
        this.infoForCreateScoring.scoring_id = this.itemToEditData.id
      } else {
        this.infoForCreateScoring.scoring_id = this.getScoringId
      }
      this.infoForCreateScoring.questions = this.scoring
      this.resetScoringData()
    },
    resetScoringData () {
      for (let i = 1; i < 54; i++) {
        if (i !== 2 || i !== 26 || i !== 27 || i !== 32 || i !== 41) {
          this.$store.dispatch('handleResetScoringData', i)
        }
      }
    },
    infoToSend (sqdata) {
      if (this.scoringData[sqdata] !== null) {
        this.scoringData[sqdata].forEach(e => {
          let data = {}
          if (this.continueScoring) {
            data = this.removeId(e)
            this.scoring.push(data)
          } else {
            this.scoring.push(e)
          }
        })
      }
    },
    removeId (element) {
      return {
        ponderation: element.ponderation,
        updated_at: element.updated_at,
        question_id: element.question_id

      }
    },
    prepareInfoClientToSend () {
      const formData = new FormData()
      formData.append('client_type_id', this.clientData.clientTypeId.value)
      formData.append('dni', this.$options.filters.number(this.clientData.dni))
      formData.append('cuit', this.clientData.cuit === '' ? null : this.clientData.cuit)
      formData.append('social_reason', this.clientData.businessName === '' ? null : this.clientData.businessName)
      formData.append('name', this.clientData.name)
      formData.append('lastname', this.clientData.surname)
      formData.append('birth_date', this.clientData.dateToBeSend)
      formData.append('marital_status_id', this.clientData.maritalStatus.value)
      formData.append('email', this.clientData.email)
      formData.append('adress', this.clientData.address)
      formData.append('adress_number', this.clientData.number)
      formData.append('address_floor', this.clientData.floor)
      formData.append('address_department', this.clientData.apartment)
      formData.append('locality_id', this.clientData.city.value)
      formData.append('postcode', this.clientData.zipCode)
      formData.append('phone', this.clientData.phoneNumber)
      formData.append('mobile_phone', this.clientData.cellphoneNumber)
      return formData
    },
    setDataInFields (data) {
      this.clientData.id = data.id
      this.clientData.clientTypeId = data.client_type_id
      this.clientData.cuit = data.cuit === null ? '' : data.cuit
      this.clientData.businessName = data.social_reason === null ? '' : data.social_reason
      this.clientData.dni = this.$options.filters.magnitude(this.clientData.dni)
      this.clientData.name = this.$options.filters.capitalize(data.name)
      this.clientData.surname = this.$options.filters.capitalize(data.lastname)
      this.clientData.dateOfBirth = this.transformDate(data.birth_date)
      this.clientData.maritalStatus = {}
      this.clientData.maritalStatus.value = data.marital_status.id
      this.clientData.maritalStatus.text = data.marital_status.name
      this.clientData.email = data.email
      this.clientData.address = data.adress
      this.clientData.number = data.adress_number
      this.clientData.floor = data.address_floor
      this.clientData.apartment = data.address_department
      this.clientData.state = {}
      this.clientData.state.value = data.locality.province_id
      this.$refs.stepClientScoring.setState()
      this.clientData.city = {}
      this.clientData.city.value = data.locality.id
      this.clientData.city.text = data.locality.name
      this.clientData.zipCode = data.postcode
      this.clientData.phoneNumber = data.phone
      this.clientData.cellphoneNumber = data.mobile_phone
    },
    transformDate (date) {
      const str = [...date]
      const localFormat = []
      this.newOrder(localFormat, str, 8)
      this.newOrder(localFormat, str, 9)
      this.newOrder(localFormat, str, 7)
      this.newOrder(localFormat, str, 5)
      this.newOrder(localFormat, str, 6)
      this.newOrder(localFormat, str, 7)
      this.newOrder(localFormat, str, 0)
      this.newOrder(localFormat, str, 1)
      this.newOrder(localFormat, str, 2)
      this.newOrder(localFormat, str, 3)
      const newDate = localFormat.join('')
      return newDate
    },
    newOrder (arr, str, n) {
      arr.push(str[n])
    },
    resetClientId () {
      this.clientData.id = 0
      this.clientData.dni = this.$options.filters.magnitude(this.clientData.dni)
    },
    setDateOfBirth (newDate, dateToSend) {
      this.clientData.dateOfBirth = newDate
      this.clientData.dateToBeSend = dateToSend
    },
    setState (value) {
      this.clientData.state = value
    },
    removeGlobalFilter () {
      this.clientData.dni = this.$options.filters.number(this.clientData.dni)
    },
    setDataWhenContinueScoring () {
      if (this.recoveringData) {
        this.$store.dispatch('handleScoringIdData', this.recoveredDataFromLS.client.scoringId)
        this.clientData = this.recoveredDataFromLS.client.clientData
        this.$store.dispatch('handleClientDataInScoring', this.clientData)
        this.isClient = true
        this.$store.dispatch('handleHistoryData', this.recoveredDataFromLS.history)
        this.$store.dispatch('handleOperationNumber', this.recoveredDataFromLS.client.operationNumber)
        this.$store.dispatch('recoverScoringData', this.recoveredDataFromLS.data)
        this.$store.dispatch('handleOnlyCurrentSQ', this.recoveredDataFromLS.history[this.recoveredDataFromLS.history.length - 1])
        this.step = 2
      }
      if (this.continueScoring) {
        this.clientData.dni = this.itemToEditData.client.dni
        this.$store.dispatch('handleHistoryData', this.itemToEditData.questions_ponderated[0].history)
        const opNumber = this.getQuestion(1)
        if (opNumber !== undefined) {
          this.$store.dispatch('handleOperationNumber', opNumber.ponderation)
          if (this.getHistory[this.getHistory.length - 1] === 1) {
            this.$refs.stepScoring.setOperationNumber()
          }
        }
        this.settingSQ_withTwoQues(1, 2, 1)
        this.settingSQ_withSevenQues(3, 4, 5, 6, 7, 8, 9, 3)
        this.settingSQ_withTwoQues(78, 10, 4)
        this.settingSQ_withOneQues(11, 5)
        this.settingSQ_withOneQues(12, 6)
        this.settingSQ_withOneQues(13, 7)
        this.settingSQ_withOneQues(14, 8)
        this.settingSQ_withTwoQues(15, 16, 9)
        this.settingSQ_withOneQues(17, 10)
        this.settingSQ_withOneQues(18, 11)
        this.settingSQ_withTwoQues(19, 20, 12)
        this.settingSQ_withTwoQues(21, 22, 13)
        this.settingSQ_withOneQues(23, 14)
        this.settingSQ_withOneQues(24, 15)
        this.settingSQ_withThreeQues(25, 26, 27, 16)
        this.settingSQ_withOneQues(28, 17)
        this.settingSQ_withTwoQues(29, 30, 18)
        this.settingSQ_withOneQues(31, 19)
        this.settingSQ_withOneQues(32, 20)
        this.settingSQ_withOneQues(33, 21)
        this.settingSQ_withTwoQues(34, 35, 22)
        this.settingSQ_withOneQues(36, 23)
        this.settingSQ_withOneQues(37, 24)
        this.settingSQ_withOneQues(38, 25)
        this.settingSQ_withOneQues(42, 28)
        this.settingSQ_withTwoQues(43, 44, 29)
        this.settingSQ_withOneQues(45, 30)
        this.settingSQ_withOneQues(46, 31)
        this.settingSQ_withThreeQues(48, 49, 50, 33)
        this.settingSQ_withOneQues(51, 34)
        this.settingSQ_withOneQues(52, 35)
        this.settingSQ_withOneQues(53, 36)
        this.settingSQ_withOneQues(54, 37)
        this.settingSQ_withOneQues(55, 38)
        this.settingSQ_withOneQues(56, 39)
        this.settingSQ_withTwoQues(57, 58, 40)
        this.settingSQ_withOneQues(63, 42)
        this.settingSQ_withOneQues(64, 43)
        this.settingSQ_withOneQues(65, 44)
        this.settingSQ_withTwoQues(66, 67, 45)
        this.settingSQ_withOneQues(68, 46)
        this.settingSQ_withOneQues(69, 47)
        this.settingSQ_withThreeQues(70, 71, 72, 48)
        this.settingSQ_withOneQues(73, 49)
        this.settingSQ_withOneQues(74, 50)
        this.settingSQ_withOneQues(75, 51)
        this.settingSQ_withOneQues(76, 52)
        this.settingSQ_withOneQues(77, 53)
        this.$store.dispatch('handleOnlyCurrentSQ', this.getHistory[this.getHistory.length - 1])
      }
    },
    settingSQ_withOneQues (ques, sq) {
      this.$store.dispatch('handleOnlyCurrentSQ', sq)
      const q0data = this.getQuestion(ques)
      const sqData = []
      let ques0 = null
      if (q0data !== undefined) {
        ques0 = this.getData(q0data)
        sqData.push(ques0)
        this.$store.dispatch('handleScoringData', sqData)
      }
    },
    settingSQ_withTwoQues (quesOne, quesTwo, sq) {
      this.$store.dispatch('handleOnlyCurrentSQ', sq)
      const q0data = this.getQuestion(quesOne)
      const q1data = this.getQuestion(quesTwo)
      const sqData = []
      let ques0 = null
      let ques1 = null
      if (q0data !== undefined || q1data !== undefined) {
        ques0 = this.selectDataToBeSend(q0data, quesOne)
        ques1 = this.selectDataToBeSend(q1data, quesTwo)
        sqData.push(ques0)
        sqData.push(ques1)
        this.$store.dispatch('handleScoringData', sqData)
      }
    },
    settingSQ_withThreeQues (quesOne, quesTwo, quesThree, sq) {
      this.$store.dispatch('handleOnlyCurrentSQ', sq)
      const q0data = this.getQuestion(quesOne)
      const q1data = this.getQuestion(quesTwo)
      const q2data = this.getQuestion(quesThree)
      const sqData = []
      let ques0 = null
      let ques1 = null
      let ques2 = null
      if (q0data !== undefined || q1data !== undefined || q2data !== undefined) {
        ques0 = this.selectDataToBeSend(q0data, quesOne)
        ques1 = this.selectDataToBeSend(q1data, quesTwo)
        ques2 = this.selectDataToBeSend(q2data, quesThree)
        sqData.push(ques0)
        sqData.push(ques1)
        sqData.push(ques2)
        this.$store.dispatch('handleScoringData', sqData)
      }
    },
    settingSQ_withSevenQues (quesOne, quesTwo, quesThree, quesFour, quesFive, quesSix, quesSeven, sq) {
      this.$store.dispatch('handleOnlyCurrentSQ', sq)
      const q0data = this.getQuestion(quesOne)
      const q1data = this.getQuestion(quesTwo)
      const q2data = this.getQuestion(quesThree)
      const q3data = this.getQuestion(quesFour)
      const q4data = this.getQuestion(quesFive)
      const q5data = this.getQuestion(quesSix)
      const q6data = this.getQuestion(quesSeven)
      const sqData = []
      let ques0 = null
      let ques1 = null
      let ques2 = null
      let ques3 = null
      let ques4 = null
      let ques5 = null
      let ques6 = null
      if (q0data !== undefined ||
        q1data !== undefined ||
        q2data !== undefined ||
        q3data !== undefined ||
        q4data !== undefined ||
        q5data !== undefined ||
        q6data !== undefined) {
        ques0 = this.selectDataToBeSend(q0data, quesOne)
        ques1 = this.selectDataToBeSend(q1data, quesTwo)
        ques2 = this.selectDataToBeSend(q2data, quesThree)
        ques3 = this.selectDataToBeSend(q3data, quesFour)
        ques4 = this.selectDataToBeSend(q4data, quesFive)
        ques5 = this.selectDataToBeSend(q5data, quesSix)
        ques6 = this.selectDataToBeSend(q6data, quesSeven)
        sqData.push(ques0)
        sqData.push(ques1)
        sqData.push(ques2)
        sqData.push(ques3)
        sqData.push(ques4)
        sqData.push(ques5)
        sqData.push(ques6)
        this.$store.dispatch('handleScoringData', sqData)
      }
    },
    getQuestion (question) {
      const qp = this.itemToEditData.questions_ponderated.find(q => {
        return q.question_id === question
      })
      return qp
    },
    getData (data) {
      return {
        question_id: data.question_id,
        ponderation: data.ponderation,
        updated_at: data.updated_at,
        id: data.id
      }
    },
    selectDataToBeSend (data, ques) {
      let info = null
      if (data !== undefined) {
        info = this.getData(data)
      } else {
        info = this.setDataWhenUndefined(ques)
      }
      return info
    },
    setDataWhenUndefined (question) {
      return {
        question_id: question,
        ponderation: '',
        updated_at: ''
      }
    },
    redoTableData () {
      this.$emit('addScoringInTable')
    }
  },
  mounted () {
    this.setDataWhenContinueScoring()
  },
  destroyed () {
    this.$store.dispatch('handleAddOrEditScoring', false)
    this.$store.dispatch('handleOperationNumber', null)
    this.$store.dispatch('handleOnlyCurrentSQ', 1)
    this.$store.dispatch('handleContinueScoring', false)
    this.$store.dispatch('handleIfSentScoringNumber', false)
    this.$store.dispatch('hanldeRecoveringData', false)
    this.resetScoringData()
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .v-stepper__label:hover {
    cursor: pointer;
  }
  .v-stepper__header {
    box-shadow: none;
    border-bottom: 1px solid #eeeeee;
  }
}
</style>
