<template>
  <v-card
    elevation="0"
    class="rounded"
    color="#F5F5F7">
    <v-card-title>
      <h2
        style="font-size: 14px;letter-spacing: 0.01em;"
        >DATOS  DEL VEHICULO</h2>
    </v-card-title>
    <v-card-text class="px-3">
      <v-container>
        <v-row>
          <v-col cols="12" sm="4">
            <v-text-field
              v-model="vehicle_data.car_license_number"
              :rules="[valiadationsRules.required]"
              @change="setGlobalFilter('vehicle', 'car_license_number', 'uppercase')"
              dense
              :disabled="editEvaluation || makeEvaluation"
              label="Dominio"
              placeholder="Dominio">
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="4">
            <v-select
              v-model="vehicle_data.brand"
              :rules="[valiadationsRules.required]"
              return-object
              dense
              :disabled="editEvaluation || makeEvaluation"
              :items="brandItems"
              @change="getModels"
              label="Marca"
              placeholder="Marca">
            </v-select>
          </v-col>
          <v-col cols="12" sm="4">
            <v-select
              v-model="vehicle_data.model"
              :rules="[valiadationsRules.required]"
              return-object
              dense
              :disabled="editEvaluation || makeEvaluation"
              :items="modelItems"
              label="Modelo"
              placeholder="Modelo">
            </v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <v-select
              v-model="vehicle_data.manufacturing_year"
              :rules="[valiadationsRules.required]"
              dense
              :disabled="editEvaluation || makeEvaluation"
              :items="yearItems"
              label="Año de fabricación"
              placeholder="Año de fabricación">
            </v-select>
          </v-col>
          <v-col cols="4">
            <v-select
              v-model="vehicle_data.release_year"
              :rules="[valiadationsRules.required]"
              dense
              :disabled="editEvaluation || makeEvaluation"
              :items="yearItems"
              label="Año de lanzamiento"
              placeholder="Año de lanzamiento">
            </v-select>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="vehicle_data.mileage"
              :rules="[valiadationsRules.required]"
              @focus="setGlobalFilter('vehicle', 'mileage', 'number')"
              @change="setGlobalFilter('vehicle', 'mileage', 'magnitude')"
              dense
              :disabled="editEvaluation"
              label="Kilometraje"
              placeholder="Kilometraje">
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <v-select
              v-model="vehicle_data.color"
              :rules="[valiadationsRules.required]"
              return-object
              dense
              :disabled="editEvaluation || makeEvaluation"
              :items="colorItems"
              label="Color"
              placeholder="Color">
            </v-select>
          </v-col>
          <v-col cols="4">
            <v-select
              v-model="vehicle_data.fuel"
              :rules="[valiadationsRules.required]"
              return-object
              dense
              :disabled="editEvaluation || makeEvaluation"
              :items="fuelItems"
              label="Combustible"
              placeholder="Combustible">
            </v-select>
          </v-col>
          <v-col cols="4">
            <v-select
              v-model="vehicle_data.transmission"
              :rules="[valiadationsRules.required]"
              return-object
              dense
              :disabled="editEvaluation || makeEvaluation"
              :items="transmissionItems"
              label="Transmisión"
              placeholder="Transmisión">
            </v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <v-select
              v-model="vehicle_data.doors"
              :rules="[valiadationsRules.required]"
              dense
              :disabled="editEvaluation || makeEvaluation"
              :items="doorItems"
              label="Puertas"
              placeholder="Puertas">
            </v-select>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="vehicle_data.origin"
              :rules="[valiadationsRules.required]"
              @change="setGlobalFilter('vehicle', 'origin', 'capitalize')"
              dense
              :disabled="editEvaluation"
              label="Procedencia"
              placeholder="Procedencia">
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="pt-0">
            <v-textarea
              v-model="vehicle_data.observations"
              dense
              :disabled="editEvaluation"
              rows="2"
              label="Observaciones">
            </v-textarea>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import { getAllVehicleBrands, getAllFuelsTypes, getAllVehicleColors, getAllVehicleTransmissions } from '@/services/vehicles'
import AuthHelper from '@/helpers/AuthHelper'
import VehicleHelper from '@/helpers/VehicleHelper'
import Helper from '@/helpers/Helper'

export default {
  name: 'VehicleData',
  props: {
    vehicle_data: Object,
    modelsItems: Array,
    valiadationsRules: Object
  },
  data () {
    return {
      brands: [],
      brandItems: [],
      models: [],
      modelItems: [],
      yearItems: [],
      doorItems: [],
      colors: [],
      colorItems: [],
      fuels: [],
      fuelItems: [],
      transmissions: [],
      transmissionItems: []
    }
  },
  computed: {
    ...mapGetters(['editEvaluation', 'makeEvaluation']),
    ...mapState(['userToken'])
  },
  methods: {
    ...mapActions(['handleLoading']),
    async getVehicleBrands () {
      try {
        const res = await getAllVehicleBrands(this.userToken)
        this.brands = res.data.data
        this.brandItems = Helper.transformToSelect(this.brands, 'brand')
        if (this.editEvaluation || this.makeEvaluation) {
          this.$emit('setBrandsAndModels', this.brands)
        } else {
          this.$emit('setEvaluationByParts')
        }
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
        this.handleLoading(false)
      }
    },
    async getFuelsTypes () {
      try {
        const res = await getAllFuelsTypes(this.userToken)
        this.fuels = res.data.data
        this.fuelItems = Helper.transformToSelect(this.fuels, 'fuel')
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
      } finally {
        if (this.editEvaluation || this.makeEvaluation) {
          this.$emit('setFuelInForm')
        }
      }
    },
    async getColors () {
      try {
        const res = await getAllVehicleColors(this.userToken)
        this.colors = res.data.data
        this.colorItems = Helper.transformToSelect(this.colors, 'color')
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
      } finally {
        if (this.editEvaluation || this.makeEvaluation) {
          this.$emit('setColorInForm')
        }
      }
    },
    async getTransmission () {
      try {
        const res = await getAllVehicleTransmissions(this.userToken)
        this.transmissions = res.data.data
        this.transmissionItems = Helper.transformToSelect(this.transmissions, 'name')
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
      } finally {
        if (this.editEvaluation || this.makeEvaluation) {
          this.$emit('setTransmissionInForm')
        }
      }
    },
    getModels () {
      const array = Helper.getItemFromArray(this.vehicle_data.brand.value, this.brands)
      this.modelItems = Helper.transformToSelect(array.vehicles_models, 'model')
    },
    setGlobalFilter (form, prop, filter) {
      this.$emit('setGlobalFilter', form, prop, filter)
    }
  },
  watch: {
    modelsItems () {
      this.modelItems = this.modelsItems
      this.handleLoading(false)
    }
  },
  mounted () {
    this.getVehicleBrands()
    this.getFuelsTypes()
    this.getColors()
    this.getTransmission()
    this.yearItems = VehicleHelper.getYearsOptions()
    this.doorItems = VehicleHelper.getDoorsOptions()
  }
}
</script>
