import axios from './instance'

const configToken = (token) => {
  return {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
}

export const getAllVehicles = async (token) => {
  try {
    const res = await axios.get('/api/vehicles', configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}

export const getAllSaleUsedVehiclesById = async (token, id) => {
  try {
    const res = await axios.get(`/api/sale/vehicles-expertises/${id}`, configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}

export const getAllVehiclesByModel = async (token, modelID) => {
  try {
    const res = await axios.get('/api/vehicles-models/vehicles/' + modelID, configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}

export const getAllBrandsVehicles = async (token) => {
  try {
    const res = await axios.get('/api/vehicles-models', configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}

export const getAllModelsVehicles = async (token) => {
  try {
    const res = await axios.get('/api/vehicles-models', configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}

export const getAllAccesoriesByModel = async (token, modelID) => {
  try {
    const res = await axios.get('/api/vehicle/accesories/' + modelID, configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}

export const getAllBrands = async (token) => {
  try {
    const res = await axios.get('/api/vehicle-brands', configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}

export const getAllTransmitions = async (token) => {
  try {
    const res = await axios.get('/api/transmitions', configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}

export const getAllColors = async (token) => {
  try {
    const res = await axios.get('/api/colors/', configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}

export const getAllTypes = async (token) => {
  try {
    const res = await axios.get('/api/vehicles-types/', configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}

export const getAllColorsByModel = async (token, modelID) => {
  try {
    const res = await axios.get('/api/models/colors/' + modelID, configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}

export const getAllFuelsTypes = async (token) => {
  try {
    // arreglar endpoint
    const res = await axios.get('/api/fuels/', configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}

export const getAllVehicleBrands = async (token) => {
  try {
    const res = await axios.get('/api/vehicle-brands/', configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}

export const getAllVehicleStatuses = async (token) => {
  try {
    const res = await axios.get('/api/vehicle-status/', configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}

export const getAllVehicleColors = async (token) => {
  try {
    const res = await axios.get('/api/colors/', configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}

export const getAllUsedVehicleColors = async (token) => {
  try {
    const res = await axios.get('/api/colors?usados/', configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}

export const getAllVehicleTransmissions = async (token) => {
  try {
    const res = await axios.get('/api/transmitions/', configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}

export const getAllVehicleLines = async (token) => {
  try {
    const res = await axios.get('/api/vehicle-lines/', configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}
// revisar si está ok el endpoint después de que lo haga Alfred

export const createVehicle = async (token, vehicle) => {
  const config = configToken(token)
  try {
    const res = await axios.post('/api/vehicles', vehicle, config)
    return res
  } catch (err) {
    throw err.response
  }
}
// revisar si está ok el endpoint después de que lo haga Alfred

export const updateVehicle = async (token, vehicle, id) => {
  const config = configToken(token)
  // config.headers['Content-Type'] = 'multipart/form-data'
  try {
    const res = await axios.put(`/api/vehicles/${id}`, vehicle, config)
    return res
  } catch (err) {
    throw err.response
  }
}
// revisar si está ok el endpoint después de que lo haga Alfred
export const deleteVehicle = async (token, id) => {
  try {
    const res = await axios.delete(`/api/vehicles/${id}`, configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}
