<template>
    <svg version="1.1" id="logo" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 60 79" xml:space="preserve">
        <defs>
            <clipPath id="ri">
                <path d="M27,0c-0.8,1.5-1.5,2.8-2.2,4.2C18.6,15.8,12.4,27.3,6.3,38.9c-0.2,0.4-0.3,0.7,0,1.2C12,50.9,17.8,61.7,23.6,72.5
                        c0.1,0.2,0.2,0.4,0.4,0.7c0.1-0.2,0.3-0.4,0.3-0.5C30.1,61.8,35.9,51,41.7,40.2c0.3-0.5,0.3-0.8,0-1.3c-3.3-6.1-6.5-12.2-9.8-18.3
                        c-0.3-0.6-0.3-1,0-1.6c0.9-1.5,1.7-3.1,2.6-4.8c0.1,0.2,0.3,0.4,0.3,0.5c4.3,8,8.6,16.1,12.9,24.1c0.3,0.5,0.3,0.8,0,1.3
                        C41,52.9,34.2,65.6,27.4,78.4c-0.2,0.4-0.5,0.6-1,0.6c-1.6,0-3.2,0-4.8,0c-0.5,0-0.7-0.2-1-0.6C15.3,68.2,9.9,58.1,4.5,48
                        c-1.4-2.7-2.9-5.4-4.3-8c-0.2-0.4-0.2-0.7,0-1C7,26.2,13.8,13.4,20.6,0.6c0.2-0.5,0.5-0.6,1-0.6C23.4,0,25.1,0,27,0z"/>
            </clipPath>
            <clipPath id="rd">
                <path d="M36,5.8c-0.8,1.4-1.5,2.8-2.2,4.1c-5.2,9.7-10.3,19.4-15.5,29c-0.2,0.5-0.2,0.8,0,1.2c3.3,6.2,6.6,12.3,9.9,18.5
                    c0.3,0.5,0.3,0.9,0,1.3c-0.9,1.6-1.8,3.2-2.7,4.9c-0.1-0.2-0.3-0.4-0.3-0.6c-4.3-8.1-8.6-16.1-12.9-24.2c-0.2-0.4-0.3-0.7,0-1.2
                    c6.9-12.8,13.7-25.6,20.5-38.4C32.9,0.1,33.2,0,33.6,0c1.6,0,3.3,0,4.9,0c0.5,0,0.7,0.2,0.9,0.5c4,7.4,7.9,14.9,11.9,22.3
                    c2.9,5.4,5.7,10.7,8.6,16.1c0.2,0.4,0.2,0.7,0,1.2C53,52.9,46.2,65.6,39.4,78.4c-0.2,0.4-0.5,0.5-0.9,0.5c-1.8,0-3.5,0-5.4,0
                    c0.1-0.3,0.2-0.5,0.3-0.7C40.2,65.5,47,52.8,53.8,40.1c0.2-0.3,0.3-0.6,0.1-1C48,28.1,42.2,17.2,36.4,6.3C36.3,6.2,36.2,6,36,5.8z
                    "/>
            </clipPath>
        </defs>
        <g clip-path="url(#ri)" stroke-width="20">
            <path class="path path-i" fill="none" stroke="#000" stroke-miterlimit="10" d="M32.5,16.5 45.1,39.5 23.9,79 2.6,39.5 24.6,-1.2 "/>
        </g>
        <g clip-path="url(#rd)" stroke-width="20">
            <path class="path path-d" fill="none" stroke="#000" stroke-miterlimit="10" d="M27.4,62.1 14.8,39.5 36.1,0 57.1,39.5 35.5,80.3 "/>
        </g>
    </svg>
</template>

<script>
export default {
  name: 'LogoAnimation'
}
</script>

<style scoped>
.path {
  stroke: #fff;
  stroke-width: 7;
  stroke-dasharray: 160;
  stroke-dashoffset: 160;
}
.path-i, #ri{
    transform: translateX(-6px);
}
.path-d, #rd{
    transform: translateX(6px);
}
.path, #ri, #rd{
    animation: animation 1.5s linear infinite;
}
@keyframes animation {
  0% {
    stroke-dashoffset: 140;
  }
  70% {
    transform: translateX(0px);
  }
  100% {
    stroke-dashoffset: 0;
    transform: translateX(0px);
  }
}
</style>
