<template>
  <v-dialog v-model="controlDialogDelete" max-width="600px">
    <v-card>
      <v-card-title></v-card-title>
      <v-card-text class="black--text font-weight-bold my-4" style="font-family: 'NouvelR';letter-spacing: 0.01em;font-size: 22px;">{{text}}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          style="font-size: 12px;font-family: 'NouvelR';"
          class="mr-3"
          color="grey"
          text
          @click="$emit('closeDelete')">Cancelar</v-btn>
        <v-btn
          style="background: rgba(245, 245, 247, 0.5);border: 1px solid #000000;font-size: 12px;font-family: 'NouvelR';"
          class="rounded mr-5 mt-2 mb-3 black--text"
          elevation="0"
          width="100"
          height="34"
          @click="$emit('deleteItemConfirm')">OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  // deleteItemConfirm funcion de borrado, pasar ID de venta
  name: 'TableDeleteItem',
  props: {
    dialogDelete: Boolean,
    text: {
      type: String,
      default: 'Se eliminará el registro. ¿Desea continuar?'
    }
  },
  computed: {
    controlDialogDelete: {
      get () {
        return this.dialogDelete
      },
      set (value) {
        this.$emit('handleDialogDelete', value)
      }
    }
  }
}
</script>

<style scoped>
</style>
