<template>
  <div>
    <div class="d-flex flex-column align-start">
      <div class="d-flex justify-space-between align-center nav-header pa-4 pa-sm-3 w-100">
        <div class="d-flex align-center">
          <v-img width="32" src="@/assets/icons/logo-icon.svg"></v-img>
          <v-img v-if="!miniOpen" width="93" height="17" src="@/assets/icons/logo-text.svg" class="ml-3"></v-img>
        </div>
        <v-btn icon color="secondary" class="menu-btn" @click.stop="showingMini(true)">
          <v-icon>$menuh</v-icon>
        </v-btn>
      </div>
      <div class="nav-profile mt-4 ml-4 d-flex flex-column">
        <p v-if="!miniOpen" class="mb-1 nav--header__name-font text-no-wrap">
          {{ printFullName.name | capitalize }} {{ printFullName.last_name | capitalize }}
        </p>
        <p v-if="!miniOpen" class="nav--header__email-font"> {{ printFullName.email }} </p>
      </div>
    </div>
    <hr class="divider mt-5 mb-4">
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'MenuNavHeader',
  computed: {
    ...mapGetters(['printFullName', 'miniOpen'])
  },
  methods: {
    showingMini (val) {
      this.$store.dispatch('handleMiniMenu', val)
    }
  }
}
</script>

<style lang="scss" scoped>
.nav-header {
  height: 90px;
  width: 100%;
}

.v-navigation-drawer--mini-variant{
  .menu-btn{
    display: none;
  }
}

.divider {
  @include backgroundTransparency($grey, 0.5);
  border: 0;
  height: 0.5px;
}
.nav--header__name-font {
  @include fontStyle(bold, 16px, 0.01em, $white);
  text-align: left;
}
.nav--header__email-font {
  @include fontStyle(normal, 12px, 0.01em, $grey);
}
</style>
