<template>
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M35.0004 45L38.5254 41.475L27.0754 30L38.5254 18.525L35.0004 15L20.0004 30L35.0004 45Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'ChevronLeftImg'
}
</script>
