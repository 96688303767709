<template>
  <div
    style="width: 828px;height: 75px;background-color: #F5F5F7;"
    class="rounded d-flex align-center mb-3">
    <span
      class="d-inline-block text-truncate black--text ml-5"
      ref="spaninvfor"
      style="text-align: left;width: 200px;font-family: 'NouvelR';font-weight: 200;font-size: 16px;letter-spacing: 0.01em;"
    >{{ record.user.name | capitalize }} {{ record.user.last_name | capitalize }}</span>
    <span
      style="text-align: left;width: 200px;font-family: 'NouvelR';font-size: 16px;letter-spacing: 0.01em;"
      class="black--text"
    >{{ callDate }}</span>
    <!-- Start Audioplayer -->
    <div
      class="rounded d-flex align-center justify-space-between px-1 mr-15"
      style="background: #EDEDED;width: 273px;height: 35px;">
      <audio
        :src="record.link"
        ref="audio"
        @canplay="setAudioDuration"
        @timeupdate="onTimeUpdate"
        @ended="playAudio(false)">
      </audio>
      <v-btn
        v-if="!showPauseButton"
        icon
        small
        @click="playAudio(true)">
        <v-icon>$play</v-icon>
      </v-btn>
      <v-btn
        v-if="showPauseButton"
        icon
        small
        color="black"
        @click="playAudio(false)">
        <v-icon>mdi-pause</v-icon>
      </v-btn>
      <span
        class="d-inline-block text-truncate mr-3 ml-1"
        style="width: 40px;text-align: right;font-family: 'NouvelR';font-size: 14px;letter-spacing: 0.01em;color: #000000;"
        >{{ formatedCurrentTime }}</span>
      <v-slider
        style="width: 43px;height: 31px;"
        :max="audioDuration"
        v-model="currentTime"
        min="0"
        @change="handleCurrentTimeWithSlider"
        track-color="#9D9D9D">
      </v-slider>
      <v-btn
        v-if="!showMuteButton"
        class="ml-2"
        icon
        @click="handleAudioMuted(true)">
        <v-icon size="21">$volume</v-icon>
      </v-btn>
      <v-btn
        v-if="showMuteButton"
        class="ml-2"
        icon
        @click="handleAudioMuted(false)"
        color="black">
        <v-icon size="21">mdi-volume-off</v-icon>
      </v-btn>
      <v-slider
        style="width: 12px;height: 31px;"
        track-color="#9D9D9D"
        max="100"
        min="0"
        v-model="volume"
        @change="handleVolume">
      </v-slider>
        <v-btn icon small @click="download">
          <v-icon size="20">$downloadaudio</v-icon>
        </v-btn>
    </div>
    <!-- End Audioplayer -->
    <!-- <v-btn icon @click="showingDescrition"><v-icon>$calldescription</v-icon></v-btn>
    <v-dialog v-model="showDescription" max-width="500">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <p>{{ record.description }}</p>
        </v-card-text>
      </v-card>
    </v-dialog> -->
  </div>
</template>

<script>
export default {
  name: 'CallData',
  props: {
    record: Object,
    index: Number,
    showAudioplayerModal: Boolean
  },
  data () {
    return {
      showPauseButton: false,
      showMuteButton: false,
      /* showDescription: false, */
      formatedCurrentTime: '0:00',
      audioDuration: 1,
      currentTime: 0,
      volume: 100
    }
  },
  computed: {
    callDate () {
      const array = this.record.datetime.split('')
      const newArray = []
      function assignOrder (init, end) {
        for (let i = init; i < end; i++) {
          newArray.push(array[i])
        }
      }
      assignOrder(8, 10)
      newArray.push('/')
      assignOrder(5, 7)
      newArray.push('/')
      assignOrder(0, 4)
      newArray.push(' ')
      assignOrder(11, 16)
      return newArray.join('')
    }
  },
  methods: {
    playAudio (data) {
      this.showPauseButton = data
      if (data) {
        this.audioDuration = this.$refs.audio.duration
        this.$refs.audio.play()
      } else {
        this.$refs.audio.pause()
      }
    },
    stopAudioWhenExit () {
      this.$refs.audio.pause()
      this.showPauseButton = false
    },
    onTimeUpdate () {
      this.currentTime = this.$refs.audio.currentTime
      this.formatedCurrentTime = this.formatCurrentTime(this.currentTime)
    },
    formatCurrentTime (currentTime) {
      const minutes = parseInt(currentTime / 60)
      const seconds = parseInt(currentTime - minutes)
      const completeSeconds = seconds < 10 ? '0' + seconds : seconds
      return minutes + ':' + completeSeconds
    },
    handleCurrentTimeWithSlider () {
      if (this.$refs.audio.paused) {
        this.$refs.audio.currentTime = this.currentTime
      } else {
        this.$refs.audio.pause()
        this.$refs.audio.currentTime = this.currentTime
        this.$refs.audio.play()
      }
    },
    handleAudioMuted (value) {
      this.$refs.audio.muted = value
      this.showMuteButton = value
      if (value) {
        this.volume = 0
      } else {
        this.volume = 100
      }
    },
    handleVolume () {
      this.$refs.audio.volume = this.volume / 100
      if (this.volume === 0) {
        this.showMuteButton = true
      } else {
        this.showMuteButton = false
      }
    },
    setAudioDuration () {
      this.audioDuration = this.$refs.audio.duration
    },
    download () {
      const a = Object.assign(document.createElement('a'), {
        href: this.record.link,
        style: 'display:none',
        download: `${this.record.datetime}.mp3`,
        target: '_blanck'
      })
      document.body.appendChild(a)
      a.click()
    }
    /* showingDescrition () {
      this.showDescription = true
    } */
  },
  watch: {
    volume () {
      this.handleVolume()
    },
    showAudioplayerModal () {
      this.stopAudioWhenExit()
    }
  }
}
</script>
