<template>
  <v-stepper-content :step="step" class="mt-5">
    <v-form ref="stepTwo" class="d-flex align-start">
      <v-container fluid>
        <v-row>
          <v-col cols="12" sm="6">
          <ApplicationDetail
            :valiadtionsRules="valiadtionsRules"
            :application_detail="application_detail"
            @setGlobalFilter="setGlobalFilter"
            @setLastServicesDate="setLastServicesDate"
            @setDateToBeSend="setDateToBeSend">
          </ApplicationDetail>
          </v-col>
          <v-col cols="12" sm="6">
          <Checklist
            :checklist="checklist">
          </Checklist>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-stepper-content>
</template>

<script>
import ApplicationDetail from '@/components/evaluations/forms/ApplicationDetail'
import Checklist from '@/components/evaluations/forms/Checklist'

export default {
  name: 'StepComplement',
  components: {
    ApplicationDetail,
    Checklist
  },
  props: {
    step: Number,
    application_detail: Object,
    checklist: Object
  },
  data () {
    return {
      valiadtionsRules: {
        required: value => !!value || 'Dato requerido'
      }
    }
  },
  methods: {
    noValidatedStep () {
      this.$refs.stepTwo.validate()
    },
    setLastServicesDate (date) {
      this.$emit('lastServiceDate', date)
    },
    setDateToBeSend (date) {
      this.$emit('setLastServicesDateForServer', date)
    },
    setGlobalFilter (form, prop, filter) {
      this.$emit('setGlobalFilter', form, prop, filter)
    }
  }
}
</script>
