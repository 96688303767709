<template>
  <v-dialog v-model="controlDocumentOwnershipModal" width="671">
    <v-card>
      <v-card-title class="font-weight-bold">Documentación relacionada</v-card-title>
      <hr class="hr-color">
      <v-card-text class="mt-7">
        <div
          class="doc-container d-flex"
          v-for="(file, index) in files" :key="index"
        >
          <v-container>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="file.name"
                  class="doc-left mr-7"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-select
                  v-model="files[index].type"
                  :items="items"
                  label="Perteneciente"
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-card-text>
      <hr class="hr-color">
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="grey"
          text
          @click="cancel"
        >
          Cancelar
        </v-btn>
        <v-btn
          class="bor-btn rounded mx-9 mt-2 mb-3 black--text"
          width="129"
          height="44"
          elevation="0"
          @click="save"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'DocumentOwnershipModal',
  props: {
    files: Array,
    items: Array
  },
  computed: {
    ...mapGetters(['documentOwnershipModal']),
    controlDocumentOwnershipModal: {
      get () {
        return this.documentOwnershipModal
      },
      set (value) {
        this.handleDocumentOwnershipModal(value)
      }
    }
  },
  methods: {
    ...mapActions(['handleAlert']),
    cancel () {
      this.$emit('cancelFiles')
      this.closeModal(false)
    },
    save () {
      if (this.files.some(f => f.type === '')) {
        this.handleAlert({
          value: true,
          text: 'Por favor seleccione a quien pertenecen todos los archivos',
          color: 'error'
        })
      } else {
        this.$emit('addFiles')
        this.closeModal(false)
      }
    },
    closeModal (val) {
      this.$store.dispatch('handleDocumentOwnershipModal', val)
    }
  }
}
</script>

<style lang="scss" scoped>
.hr-color {
  border-bottom: 0.5px solid rgb(240, 238, 238);
}
.doc-container {
    .doc-left {
        @include fontStyle(normal, 18px, 0.01em, $black);
        width: 365px;
    }
}
::v-deep {
    .v-btn {
    font-size: 12px;
    letter-spacing: 0.01em;
    }
    .bor-btn {
    border: 1px solid $black !important;
    }
}
</style>
