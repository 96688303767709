<template>
  <div>
    <v-row class="fill-height">
      <v-col>
        <v-sheet height="10vh">
          <div v-if="showingCalendar.edit" class="d-flex align-center justify-space-between pa-5">
            <h2
              style="font-family: NouvelR;font-size: 22px;letter-spacing: 0.01em;"
              class="text-no-wrap text-truncate">Reprogramar llamado</h2>
            <div class="d-flex align-center">
              <v-icon class="mr-3">$clock</v-icon>
              <v-menu bottom right v-if="listMade">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="mr-3"
                    elevation="0"
                    color="rgba(0, 0, 0, 0.02)"
                    v-bind="attrs"
                    v-on="on">
                    <span
                      style="font-family: NouvelR;font-size: 16px;letter-spacing: 0.01em;color: #9D9D9D;"
                      class="text-capitalize">{{ daySelected !== null ?  daySelected.text : daysItems[0].text}}</span>
                  </v-btn>
                </template>
                <v-list max-height="400px" class="overflow-y-auto">
                  <div v-for="(day, index) in daysItems" :key="index">
                    <v-list-item dense @click="selectDay(day)"><v-list-item-title>{{ day.text }}</v-list-item-title></v-list-item>
                  </div>
                </v-list>
              </v-menu>
              <v-menu bottom right :disabled="daySelected === null" v-if="listHourMade">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="mr-3"
                    elevation="0"
                    color="rgba(0, 0, 0, 0.02)"
                    v-bind="attrs"
                    v-on="on">
                    <span
                      style="font-family: NouvelR;font-size: 16px;letter-spacing: 0.01em;color: #9D9D9D;"
                      class="text-lowercase">{{ hourSelected !== null ? hourSelected.text : hourItems[0].text }}</span>
                  </v-btn>
                </template>
                <v-list max-height="500px" class="overflow-y-auto" v-if="daySelected !== null">
                  <div v-for="(hour, index) in hourItems" :key="index">
                    <v-list-item dense :disabled="events.findIndex(e => e.start === (daySelected.value + hour.value)) !== -1" @click="selectHour(hour)"><v-list-item-title>{{ hour.text }}</v-list-item-title></v-list-item>
                  </div>
                </v-list>
              </v-menu>
            </div>
          </div>
          <hr style="border: 1px solid #EEEEEE;">
        </v-sheet>
        <v-sheet height="10vh" min-height="64" class="px-6">
          <v-toolbar flat>
            <v-btn
              outlined
              class="mr-4"
              color="grey darken-2"
              @click="setToday">Hoy</v-btn>
            <v-btn
              fab
              text
              small
              color="grey darken-2"
              @click="prev">
              <v-icon small>mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn
              fab
              text
              small
              color="grey darken-2"
              @click="next">
              <v-icon small>mdi-chevron-right</v-icon>
            </v-btn>
            <v-toolbar-title v-if="$refs.calendar">{{ $refs.calendar.title }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-menu bottom right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  outlined
                  color="grey darken-2"
                  v-bind="attrs"
                  v-on="on">
                  <span>{{ typeToLabel[type] }}</span>
                  <v-icon right>mdi-menu-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="type = 'day'"><v-list-item-title>{{ typeToLabel.day }}</v-list-item-title></v-list-item>
                <v-list-item @click="type = 'week'"><v-list-item-title>{{ typeToLabel.week }}</v-list-item-title></v-list-item>
                <v-list-item @click="type = 'month'"><v-list-item-title>{{ typeToLabel.month }}</v-list-item-title></v-list-item>
                <v-list-item @click="type = '4day'"><v-list-item-title>{{ typeToLabel['4day'] }}</v-list-item-title></v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
        </v-sheet>
        <v-sheet :height="showingCalendar.edit ? '70vh' : '90vh'" min-height="600" class="px-6">
          <v-calendar
            ref="calendar"
            first-interval="7"
            interval-count="12"
            interval-height="40"
            v-model="focus"
            :weekdays="weekday"
            :short-weekdays="false"
            locale="es"
            color="primary"
            :events="events"
            :event-text-color="'black'"
            :event-color="getEventColor"
            :type="type"
            @click:event="showEvent"
            @click:more="viewDay"
            @click:date="viewDay"
          ></v-calendar>
        </v-sheet>
        <v-sheet height="10vh">
          <div
            v-if="showingCalendar.edit"
            class="d-flex align-center justify-end pa-5">
            <v-btn
              color="#9D9D9D"
              text class="mr-10"
              style="font-family: NouvelR;font-size: 12px;letter-spacing: 0.01em;"
              @click="returnScoring">Cancelar</v-btn>
            <v-btn
              elevation="0"
              width="129"
              height="44"
              class="rounded"
              :disabled="!eventAdded"
              style="background: rgba(245, 245, 247, 0.5);border: 1px solid #000000;"
              @click="confirmRescheduleCall">
              <span style="font-family: NouvelR;font-size: 12px;letter-spacing: 0.01em;color: #000000;">Guardar</span>
            </v-btn>
          </div>
        </v-sheet>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import { createScheduledCall, getAllSchedulesCalls } from '@/services/savings'
import AuthHelper from '@/helpers/AuthHelper'

export default {
  name: 'ScoringCalendar',
  props: {
    itemToEditData: Object
  },
  data () {
    return {
      focus: '',
      weekday: [1, 2, 3, 4, 5, 6, 0],
      type: 'month',
      typeToLabel: {
        month: 'Mes',
        week: 'Semana',
        day: 'Día',
        '4day': '4 Días'
      },
      day: 'friday',
      dayToLabel: {
        friday: 'Viernes, 28 de Enero',
        monday: 'Lunes, 31 de Enero',
        tuesday: 'Martes, 1 de Febrero',
        wednesday: 'Miércoles, 2 de Febrero'
      },
      hour: 'twelve',
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      events: [],
      colors: ['orange'],
      names: '',
      daysItems: [],
      hourItems: [],
      daySelected: null,
      hourSelected: null,
      listHourMade: false,
      listMade: false,
      eventAdded: false,
      alertMessage: '',
      alertColor: '',
      eventos: []
    }
  },
  computed: {
    ...mapGetters([
      'showingCalendar',
      'addOrEditScoring',
      'clientDataInScoring',
      'continueScoring',
      'addOrEditScoring'
    ]),
    ...mapState(['userToken'])
  },
  methods: {
    ...mapActions(['handleLoading', 'handleAlert']),
    async getSchedulesCalls () {
      this.handleLoading()
      try {
        const res = await getAllSchedulesCalls(this.userToken)
        this.events = res.data.data
        this.alertMessage = res.data.message
        this.alertColor = 'success'
        this.handleLoading(false)
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
        this.alertMessage = error?.data?.message
        this.alertColor = 'error'
        this.handleLoading(false)
      } finally {
        this.handleAlert({
          value: true,
          text: this.alertMessage,
          color: this.alertColor
        })
      }
    },
    async createNewScheduledCall (data) {
      this.handleLoading(true)
      data.color = '#F5F5F7'
      try {
        const res = await createScheduledCall(this.userToken, data)
        if (!this.addOrEditScoring) {
          this.handleLoading(false)
          this.alertMessage = res.data.message
          this.alertColor = 'success'
        }
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
        this.alertMessage = error?.data?.message
        this.alertColor = 'error'
        this.handleLoading(false)
      } finally {
        if (this.addOrEditScoring && this.continueScoring) {
          this.$emit('saveScoring', 1)
        } else {
          this.$emit('addScoringInTable')
          this.handleAlert({
            value: true,
            text: this.alertMessage,
            color: this.alertColor
          })
        }
      }
    },
    setToday () {
      this.focus = ''
    },
    prev () {
      this.$refs.calendar.prev()
    },
    next () {
      this.$refs.calendar.next()
    },
    getEventColor (event) {
      return event.color
    },
    viewDay ({ date }) {
      this.focus = date
      this.type = 'day'
    },
    doingHourList () {
      for (let i = 8; i < 10; i++) {
        let end = null
        if (i === 8) {
          end = ' 09:00'
        } else {
          end = ' 10:00'
        }
        this.hourItems.push({
          text: ` ${i} hs`,
          value: ` 0${i}:00`,
          end: end
        })
      }
      this.forHourList(10, 13)
      this.forHourList(15, 19)
      this.listHourMade = true
    },
    forHourList (ini, end) {
      for (let i = ini; i < end; i++) {
        this.hourItems.push({
          text: `${i} hs`,
          value: ` ${i}:00`,
          end: ` ${i + 1}:00`
        })
      }
    },
    doingDateList () {
      const options = {
        timeZone: 'America/Argentina/Buenos_Aires',
        weekday: 'long',
        day: 'numeric',
        month: 'long'
      }
      for (let i = 0; i < 180; i++) {
        const today = new Date()
        today.setDate(today.getDate() + i)
        const tomorrowFormated = today.toLocaleDateString('es-AR', options)
        const dateText = tomorrowFormated.charAt(0).toUpperCase() + tomorrowFormated.slice(1)
        today.setHours(today.getHours() - 3)
        const dateValue = today.toISOString().substr(0, 10)
        const date = {
          text: dateText,
          value: dateValue
        }
        if (dateText.charAt(0) !== 'D' && dateText.charAt(0) !== 'S') {
          this.daysItems.push(date)
        }
      }
      this.listMade = true
    },
    selectDay (day) {
      this.daySelected = day
      this.focus = day.value
      this.type = 'day'
    },
    selectHour (hour) {
      this.hourSelected = hour
      this.newEvent()
    },
    showEvent ({ nativeEvent, event }) {
      if (!this.showingCalendar.edit) {
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        this.$emit('continueEditingSelectedEvent', this.selectedEvent.scoring_id)
      }
    },
    newEvent () {
      if (!this.eventAdded) {
        this.events.push({
          name: this.addOrEditScoring
            ? `${this.clientDataInScoring.name} ${this.clientDataInScoring.surname}`
            : `${this.itemToEditData.client.name} ${this.itemToEditData.client.lastname}`,
          start: `${this.daySelected.value}${this.hourSelected.value}`,
          end: `${this.daySelected.value}${this.hourSelected.end}`,
          color: '#FFB70B',
          scoring_id: (this.itemToEditData.id === null || this.itemToEditData.id === undefined)
            ? ''
            : this.itemToEditData.id,
          description: 'Call',
          client_id: this.addOrEditScoring
            ? this.clientDataInScoring.id
            : this.itemToEditData.client.id
        })
        this.eventAdded = true
      } else {
        this.events[this.events.length - 1].start = `${this.daySelected.value}${this.hourSelected.value}`
        this.events[this.events.length - 1].end = `${this.daySelected.value}${this.hourSelected.end}`
      }
    },
    returnScoring () {
      this.events = []
      this.eventAdded = false
      this.daySelected = null
      this.hourSelected = null
      this.type = 'month'
      this.daysItems = []
      this.hourItems = []
      this.$store.dispatch('handleShowCalendar', { view: false, edit: false })
    },
    confirmRescheduleCall () {
      if (this.addOrEditScoring && !this.continueScoring) {
        this.$emit('saveScoring', this.events[this.events.length - 1])
      } else if (this.addOrEditScoring && this.continueScoring) {
        this.$emit('saveScoring', 2)
      } else {
        this.createNewScheduledCall(this.events[this.events.length - 1])
      }
      this.returnScoring()
    },
    settingCalendarInfo () {
      this.getSchedulesCalls()
      this.doingHourList()
      this.doingDateList()
    }
  },
  mounted () {
    this.getSchedulesCalls()
    this.doingHourList()
    this.doingDateList()
    this.$emit('calendarIsMounted', true)
  },
  destroyed () {
    this.returnScoring()
    this.$emit('calendarIsMounted', false)
  }
}
</script>
