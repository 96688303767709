export default {
  state: {
    showAddOrEditEvaluation: false,
    editEvaluation: false,
    makeEvaluation: false,
    currentEvaluationStep: 1,
    imagesDeleted: [],
    line: {},
    newEvaluationData: {
      id: 0,
      date: '',
      operation_number: 0,
      vehicle_id: 0,
      vehicle_data: {
        car_license_number: '',
        brand: { value: 0 },
        model: { text: '', value: '' },
        manufacturing_year: '',
        release_year: '',
        mileage: '',
        color: { value: 0 },
        fuel: { value: 0 },
        transmission: { value: 0 },
        doors: '',
        origin: '',
        observations: ''
      },
      client_data: {
        id: 0,
        firstname: '',
        surname: '',
        dni: '',
        email: ''
      },
      application_detail: {
        timing_belt_last_change: '',
        last_service_date: '',
        last_servcices_date_format_server: '',
        observations: ''
      },
      checklist: {
        is_first_owner: false,
        has_stamps_in_service_history: false,
        has_key_duplicate: false,
        has_service_history: false,
        has_handbook: false
      },
      expertise_evaluation: [],
      valuation: {
        purchase_value: '',
        sale_value: '',
        observations: ''
      }
    },
    search: {
      line: '',
      model: ''
    }
  },
  mutations: {
    setShowAddOrEditEvaluation (state, payload) {
      state.showAddOrEditEvaluation = payload
    },
    setEditEvaluation (state, payload) {
      state.editEvaluation = payload
    },
    setMakeEvaluation (state, payload) {
      state.makeEvaluation = payload
    },
    setCurrentEvaluationStep (state, payload) {
      state.currentEvaluationStep = payload
    },
    setDataForMakeOrEdit (state, payload) {
      state.newEvaluationData.id = payload.id
      state.newEvaluationData.operation_number = payload.id
      state.newEvaluationData.vehicle_id = payload.vehicle_id
    },
    setEvaluationDate (state, payload) {
      state.newEvaluationData.date = payload
    },
    setStepVehicleData (state, payload) {
      state.newEvaluationData.vehicle_data = payload.vehicle
      state.newEvaluationData.client_data = payload.client
    },
    setStepComplementData (state, payload) {
      state.newEvaluationData.application_detail = payload.app
      state.newEvaluationData.checklist = payload.check
    },
    setStepInspectionData (state, payload) {
      state.newEvaluationData.expertise_evaluation = payload
    },
    setImgDeleted (state, payload) {
      state.imagesDeleted = payload
    },
    setSearchLine (state, payload) {
      state.search.line = payload
    },
    setSearchModel (state, payload) {
      state.search.model = payload
    },
    setResetData (state, payload) {
      state.newEvaluationData.vehicle_data = payload.vehicle_data
      state.newEvaluationData.client_data = payload.client_data
      state.newEvaluationData.application_detail = payload.application_detail
      state.newEvaluationData.checklist = payload.checklist
      state.newEvaluationData.expertise_evaluation = []
      state.newEvaluationData.valuation = payload.valuation
    }
  },
  actions: {
    handleShowAddOrEditEvaluation ({ commit }, payload) {
      commit('setShowAddOrEditEvaluation', payload)
    },
    handleEditEvaluation ({ commit }, payload) {
      commit('setEditEvaluation', payload)
    },
    handleMakeEvaluation ({ commit }, payload) {
      commit('setMakeEvaluation', payload)
    },
    handleCurrentEvaluationStep ({ commit }, payload) {
      commit('setCurrentEvaluationStep', payload)
    },
    handleDataForMakeOrEdit ({ commit }, payload) {
      commit('setDataForMakeOrEdit', payload)
    },
    handleEvaluationDate ({ commit }, payload) {
      commit('setEvaluationDate', payload)
    },
    hanldeStepVehicleData ({ commit }, payload) {
      commit('setStepVehicleData', payload)
    },
    hanldeStepComplementData ({ commit }, payload) {
      commit('setStepComplementData', payload)
    },
    hanldeStepInspectionData ({ commit }, payload) {
      commit('setStepInspectionData', payload)
    },
    handleImgDeleted ({ commit }, payload) {
      commit('setImgDeleted', payload)
    },
    handleSearchEvaluationLine ({ commit }, payload) {
      commit('setSearchLine', payload)
    },
    handleSearchEvaluationModel ({ commit }, payload) {
      commit('setSearchModel', payload)
    },
    handleResetData ({ commit }, payload) {
      commit('setResetData', payload)
    }
  },
  getters: {
    addOrEditEvaluation: state => state.showAddOrEditEvaluation,
    getCurrentEvaluationStep: state => state.currentEvaluationStep,
    newEvaluation: state => state.newEvaluationData,
    editEvaluation: state => state.editEvaluation,
    makeEvaluation: state => state.makeEvaluation,
    imagesDeleted: state => state.imagesDeleted,
    searchEvaluationsData: state => state.search
  }
}
