<template>
  <v-snackbar
    v-model="controlAlert"
    :timeout="timeout"
    :color="isAlertOpen.color"
    style="z-index: 2001"
  >
    <p class="text-center pa-0 ma-0 subtitle-2">
      {{ isAlertOpen.text }}
    </p>
  </v-snackbar>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'AppAlert',

  data: () => ({
    timeout: 4000
  }),

  computed: {
    ...mapGetters({
      isAlertOpen: 'alertOpen'
    }),
    controlAlert: {
      get () {
        return this.isAlertOpen.value
      },
      set (val) {
        this.handleAlert({ value: val })
      }
    }
  },
  methods: {
    ...mapActions(['handleAlert'])
  }
}
</script>

<style lang="scss" scoped>
/* ::v-deep .v-snack__action {
  display: none;
} */
</style>
