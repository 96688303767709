import axios from './instance'

const configToken = (token) => {
  return {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
}

export const getAllConcessionaries = async (token) => {
  try {
    const res = await axios('/api/concessionaires', configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}

export const getAllDepartments = async (token) => {
  try {
    const res = await axios.get('/api/departments', configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}

export const getAllRoles = async (token) => {
  try {
    const res = await axios.get('/api/roles', configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}

export const getAllModels = async (token) => {
  try {
    const res = await axios.get('/api/vehicles-models', configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}

export const getAllProvinces = async (token) => {
  try {
    const res = await axios.get('/api/provinces', configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}

export const getAllCoverages = async (token) => {
  try {
    const res = await axios.get('/api/coverages', configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}
export const getAllMaritalStatus = async (token) => {
  try {
    const res = await axios.get('/api/marital-status', configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}
