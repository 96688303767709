<template>
  <v-card-text
    class="pa-0 d-flex flex-column"
    style="height: 90%;min-height: 600px;"
  >
    <Questions
      :question="questionData.questions[0]"
      @goToNextQuestion="goToNextQuestion"
      :screens_question="questionData.question"
      :sq_aclaration="questionData.aclaration"
    ></Questions>
    <Questions
      :question="questionData.questions[1]"
      @goToNextQuestion="goToNextQuestion"
      :screens_question="null"
      :sq_aclaration="null"
    ></Questions>
    <Questions
      :question="questionData.questions[2]"
      @goToNextQuestion="goToNextQuestion"
      :screens_question="null"
      :sq_aclaration="null"
    ></Questions>
    <Questions
      :question="questionData.questions[3]"
      @goToNextQuestion="goToNextQuestion"
      :screens_question="null"
      :sq_aclaration="null"
    ></Questions>
    <NextQuestionButton
      v-if="showButton"
      class="pb-16"
      @goToNextQuestion="goToNextQuestion"
    ></NextQuestionButton>
  </v-card-text>
</template>

<script>
import Questions from '@/components/savings/blocks/Questions'
import NextQuestionButton from '@/components/savings/blocks/NextQuestionButton'
import { mapGetters } from 'vuex'

export default {
  name: 'SQuesFortyOne',
  components: {
    Questions,
    NextQuestionButton
  },
  props: {
    questionData: Object
  },
  data () {
    return {
      showButton: true
    }
  },
  computed: {
    ...mapGetters(['isBrowsingInScoringHistory'])
  },
  methods: {
    setData () {
      this.$emit('handleProgress', 98)
    },
    goToNextQuestion () {
      this.$emit('handleProgress', 100)
      if (this.isBrowsingInScoringHistory) {
        this.$emit('reduceDecreasing', 1)
        this.$store.dispatch('handleOnlyCurrentSQ', 42)
      } else {
        this.$store.dispatch('handleCurrentSQ', 42)
      }
      this.$emit('checkIfHistoryModeContinues')
    }
  },
  mounted () {
    this.setData()
  }
}
</script>
