<template>
  <v-card
    elevation="0"
    class="rounded"
    color="#F5F5F7">
    <v-card-title>
      <h2
        style="font-size: 14px;letter-spacing: 0.01em;"
        >DETALLE DE SOLICITUD</h2>
    </v-card-title>
    <v-card-text>
      <v-col cols="12">
        <v-text-field
          v-model="application_detail.timing_belt_last_change"
          :rules="[valiadtionsRules.required]"
          @focus="setGlobalFilter('app', 'timing_belt_last_change', 'number')"
          @change="setGlobalFilter('app', 'timing_belt_last_change', 'magnitude')"
          dense
          :disabled="editEvaluation"
          label="Último cambio de correa distribución (Km)"
          placeholder="Último cambio de correa distribución (Km)">
        </v-text-field>
      </v-col>
      <v-col cols="12" class="pt-0 pb-2">
        <v-menu
        v-model="calendar"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="application_detail.last_service_date"
            :rules="[valiadtionsRules.required]"
            label="Fecha último servicio realizado"
            append-icon="mdi-calendar"
            readonly
            dense
            :disabled="editEvaluation"
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="date"
          no-title
          locale="es-ar"
          @input="setDate"
        ></v-date-picker>
      </v-menu>
      </v-col>
      <v-col cols="12" class="pt-0">
        <v-textarea
          v-model="application_detail.observations"
          dense
          :disabled="editEvaluation"
          rows="2"
          label="Observaciones"></v-textarea>
      </v-col>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ApplicationDetail',
  props: {
    application_detail: Object,
    valiadtionsRules: Object
  },
  data () {
    return {
      date: '',
      calendar: false
    }
  },
  computed: {
    ...mapGetters(['editEvaluation'])
  },
  methods: {
    setDate () {
      // Date with format for backend
      this.$emit('setDateToBeSend', this.date)
      this.calendar = false
      const str = [...this.date]
      const localFormat = []
      this.newOrder(localFormat, str, 8)
      this.newOrder(localFormat, str, 9)
      this.newOrder(localFormat, str, 7)
      this.newOrder(localFormat, str, 5)
      this.newOrder(localFormat, str, 6)
      this.newOrder(localFormat, str, 7)
      this.newOrder(localFormat, str, 0)
      this.newOrder(localFormat, str, 1)
      this.newOrder(localFormat, str, 2)
      this.newOrder(localFormat, str, 3)
      const newDate = localFormat.join('')
      // Dete with format to be used in frontend
      this.$emit('setLastServicesDate', newDate)
    },
    newOrder (arr, str, n) {
      arr.push(str[n])
    },
    setGlobalFilter (form, prop, filter) {
      this.$emit('setGlobalFilter', form, prop, filter)
    }
  }
}
</script>
