<template>
  <div>
  <CreateEvaluationHeader
    ref="header"
    :status="itemToEditData.status"
    :vehicle_data="vehicle_data"
    @editingEvaluation="editingEvaluation"
    @createNewEvaluation="createNewEvaluation"
    @thisStep="thisStep"
    @validateStepFour="validateStepFour"
  ></CreateEvaluationHeader>
  <v-stepper v-model="step" elevation="0">
      <v-stepper-header class="mb-4">
        <v-stepper-step
          class="ml-11"
          :complete="step > 1"
          step="1"
          @click="thisStep(1);"
        >
          Vehículo
        </v-stepper-step>
        <v-stepper-step
          :complete="step > 2"
          step="2"
          @click="thisStep(2);"
        >
          Complementos
        </v-stepper-step>
        <v-stepper-step
          :complete="step > 3"
          step="3"
          @click="thisStep(3);"
        >
          Inspección
        </v-stepper-step>
        <v-stepper-step
          class="mr-15 pr-15"
          step="4"
          @click="thisStep(4);"
        >
          Valuación
        </v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <StepVehicle
          ref="stepVehicle"
          :vehicle_data="vehicle_data"
          :client_data="client_data"
          :modelsItems="modelsItems"
          :step="1"
          @setGlobalFilter="setGlobalFilter"
          @setEvaluationByParts="setEvaluationByParts"
          @setDataInFields="setDataInFields"
          @setDataWhenIsEditing="setDataWhenIsEditing"
          @resetClientId="resetClientId"
          @setFuelInForm="setFuelInForm"
          @setColorInForm="setColorInForm"
          @setTransmissionInForm="setTransmissionInForm"
        />
        <StepComplement
          ref="stepComplement"
          :step="2"
          @setGlobalFilter="setGlobalFilter"
          @lastServiceDate="lastServiceDate"
          @setLastServicesDateForServer="setLastServicesDateForServer"
          :application_detail="application_detail"
          :checklist="checklist"
        />
        <StepInspection
          ref="stepInspection"
          :step="3"
          @copyEvaluationToSend="copyEvaluationToSend"
          :expertiseEvalToBeEdited="expertiseEvalToBeEdited"
        />
        <StepValuation
          ref="stepValuation"
          :step="4"
          :valuation="valuation"
          :score="score"
          :stars="stars"
          :totalExpenses="totalExpenses"
          :enableCarValuation="enableCarValuation"
          @setGlobalFilter="setGlobalFilter"
        />
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import CreateEvaluationHeader from '@/components/evaluations/CreateEvaluationHeader'
import StepVehicle from '@/components/evaluations/StepVehicle'
import StepComplement from '@/components/evaluations/StepComplement'
import StepInspection from '@/components/evaluations/StepInspection'
import StepValuation from '@/components/evaluations/StepValuation'
import { mapGetters, mapActions, mapState } from 'vuex'
import AuthHelper from '@/helpers/AuthHelper'
import Helper from '@/helpers/Helper'
import { createEvaluation, updateEvaluation } from '@/services/evaluations'

export default {
  name: 'CreateOrEditEvaluation',
  components: {
    CreateEvaluationHeader,
    StepInspection,
    StepValuation,
    StepVehicle,
    StepComplement
  },
  props: {
    itemToEditData: Object
  },
  data () {
    return {
      step: 1,
      toStepTwo: false,
      toStepThree: false,
      deleteFileModal: false,
      enableCarValuation: false,
      vehicle_data: {
        car_license_number: '',
        brand: '',
        model: '',
        manufacturing_year: '',
        release_year: '',
        mileage: '',
        color: '',
        fuel: '',
        transmission: '',
        doors: '',
        origin: '',
        observations: ''
      },
      client_data: {
        id: 0,
        firstname: '',
        surname: '',
        dni: '',
        email: ''
      },
      application_detail: {
        timing_belt_last_change: '',
        last_service_date: '',
        last_servcices_date_format_server: '',
        observations: ''
      },
      checklist: {
        is_first_owner: false,
        has_stamps_in_service_history: false,
        has_key_duplicate: false,
        has_service_history: false,
        has_handbook: false
      },
      expertise_evaluation: [],
      valuation: {
        purchase_value: '',
        sale_value: '',
        observations: ''
      },
      editFormData: '',
      score: '',
      scoreByVehiclePart: [],
      expenseByVehiclePart: [],
      totalExpenses: '0',
      stars: 0,
      brands: [],
      models: [],
      modelsItems: [],
      level: 0,
      expertiseEvalToBeEdited: [],
      indexNewFile: 0
    }
  },
  computed: {
    ...mapGetters(['getCurrentEvaluationStep',
      'editEvaluation',
      'newEvaluation',
      'makeEvaluation',
      'imagesDeleted']),
    ...mapState(['userToken'])
  },
  methods: {
    ...mapActions([
      'handleCurrentEvaluationStep',
      'hanldeStepVehicleData',
      'hanldeStepComplementData',
      'hanldeStepInspectionData',
      'handleAlert',
      'handleLoading',
      'handleShowAddOrEditEvaluation',
      'handleEditEvaluation',
      'handleMakeEvaluation',
      'handleImgDeleted'
    ]),
    thisStep (step) {
      if (step === 1) {
        this.handleCurrentEvaluationStep(1)
      }
      if (step === 2) {
        this.validateStepOne()
        if (!this.toStepTwo) {
          this.handleCurrentEvaluationStep(1)
          this.$refs.stepVehicle.noValidatedStep()
        } else {
          this.hanldeStepVehicleData({ vehicle: this.vehicle_data, client: this.client_data })
          this.handleCurrentEvaluationStep(step)
        }
      }
      if (step === 3) {
        this.validateStepOne()
        if (!this.toStepTwo) {
          this.handleCurrentEvaluationStep(1)
          this.$refs.stepVehicle.noValidatedStep()
        } else {
          this.validateStepTwo()
          if (!this.toStepThree) {
            this.handleCurrentEvaluationStep(2)
            this.$refs.stepComplement.noValidatedStep()
          } else {
            this.hanldeStepComplementData({ app: this.application_detail, check: this.checklist })
            this.hanldeStepVehicleData({ vehicle: this.vehicle_data, client: this.client_data })
            this.handleCurrentEvaluationStep(step)
          }
        }
      }
      if (step === 4) {
        this.validateStepOne()
        if (!this.toStepTwo) {
          this.handleCurrentEvaluationStep(1)
          this.$refs.stepVehicle.noValidatedStep()
        } else {
          this.validateStepTwo()
          if (!this.toStepThree) {
            this.handleCurrentEvaluationStep(2)
            this.$refs.stepComplement.noValidatedStep()
          } else {
            this.hanldeStepInspectionData(this.expertise_evaluation)
            this.hanldeStepComplementData({ app: this.application_detail, check: this.checklist })
            this.hanldeStepVehicleData({ vehicle: this.vehicle_data, client: this.client_data })
            this.getScore()
            this.handleCurrentEvaluationStep(step)
          }
        }
        this.checkEnableCarValuationStatus()
      }
    },
    validateStepOne () {
      if (this.vehicle_data.car_license_number === '' ||
        this.vehicle_data.brand === '' ||
        this.vehicle_data.model === '' ||
        this.vehicle_data.manufacturing_year === '' ||
        this.vehicle_data.release_year === '' ||
        this.vehicle_data.mileage === '' ||
        this.vehicle_data.color === '' ||
        this.vehicle_data.fuel === '' ||
        this.vehicle_data.transmission === '' ||
        this.vehicle_data.doors === '' ||
        this.vehicle_data.origin === '' ||
        this.client_data.dni === '' ||
        this.client_data.firstname === '' ||
        this.client_data.surname === '' ||
        this.client_data.email === '') {
        this.toStepTwo = false
      } else {
        this.toStepTwo = true
      }
    },
    validateStepTwo () {
      if (this.application_detail.timing_belt_last_change === '' ||
        this.application_detail.last_service_date === '') {
        this.toStepThree = false
      } else {
        this.toStepThree = true
      }
    },
    validateStepFour () {
      if (!this.enableCarValuation) {
        this.$refs.header.openAskModal()
      } else if (this.enableCarValuation) {
        if (this.valuation.purchase_value === '' ||
            this.valuation.sale_value === '' ||
            this.valuation.purchase_value === undefined ||
            this.valuation.sale_value === undefined) {
          this.$refs.stepValuation.noValidatedStep()
        } else {
          this.$refs.header.openAskModal()
        }
      }
    },
    setEvaluationByParts () {
      this.$refs.stepInspection.getEvaluationsType()
    },
    lastServiceDate (date) {
      this.application_detail.last_service_date = date
    },
    setLastServicesDateForServer (date) {
      this.application_detail.last_servcices_date_format_server = date
    },
    setDataInFields (data) {
      this.client_data.id = data.id
      this.client_data.firstname = this.$options.filters.capitalize(data.name)
      this.client_data.surname = this.$options.filters.capitalize(data.lastname)
      this.client_data.dni = this.$options.filters.magnitude(this.client_data.dni)
      this.client_data.email = data.email
    },
    copyEvaluationToSend (item) {
      this.expertise_evaluation = item
    },
    checkEnableCarValuationStatus () {
      if (this.expertise_evaluation.length === 0) {
        this.enableCarValuation = false
      } else {
        for (let i = 0; i < this.expertise_evaluation.length; i++) {
          if (this.expertise_evaluation[i].qualification.text === '') {
            this.enableCarValuation = false
            return
          } else {
            this.enableCarValuation = true
          }
        }
      }
    },
    setStepInMakingEvaluation () {
      if (this.editEvaluation) {
        this.$store.dispatch('handleCurrentEvaluationStep', 3)
      }
    },
    resetClientId () {
      this.client_data.id = 0
      this.client_data.dni = this.$options.filters.magnitude(this.client_data.dni)
    },
    setGlobalFilter (form, prop, filter) {
      switch (form) {
        case 'vehicle':
          this.vehicle_data[prop] = this.$options.filters[filter](this.vehicle_data[prop])
          break
        case 'client':
          this.client_data[prop] = this.$options.filters[filter](this.client_data[prop])
          break
        case 'app':
          this.application_detail[prop] = this.$options.filters[filter](this.application_detail[prop])
          break
        case 'valuation':
          this.valuation[prop] = this.$options.filters[filter](this.valuation[prop])
          break
      }
    },
    getModelText (array, value) {
      const index = array.findIndex(m => m.value === value)
      if (index !== -1) {
        return array[index].text
      }
    },
    getBrandText (array, value) {
      const index = array.findIndex(m => m.id === value)
      if (index !== -1) {
        return array[index].brand
      }
    },
    setDataWhenIsEditing (data) {
      this.$store.dispatch('handleEvaluationDate', this.transformDate(this.itemToEditData.created_at))
      this.getScore()
      this.vehicle_data.car_license_number = this.$options.filters.uppercase(this.itemToEditData.vehicle.domain)
      this.vehicle_data.brand.value = this.itemToEditData.vehicle.vehicle_model.vehicle_brand.id
      this.vehicle_data.brand.text = this.getBrandText(data, this.vehicle_data.brand.value)
      const array = Helper.getItemFromArray(this.itemToEditData.vehicle.vehicle_model.vehicle_brand.id, data)
      this.models = array.vehicles_models
      this.modelsItems = Helper.transformToSelect(this.models, 'model')
      this.vehicle_data.model.value = this.itemToEditData.vehicle.vehicle_model_id
      this.vehicle_data.model.text = this.$options.filters.uppercase(this.getModelText(this.modelsItems, this.vehicle_data.model.value))
      this.vehicle_data.manufacturing_year = this.itemToEditData.vehicle.year
      this.vehicle_data.release_year = this.itemToEditData.launch_year
      this.vehicle_data.doors = this.itemToEditData.vehicle.vehicle_model.vehicle_doors
      if (this.itemToEditData.vehicle.observations !== null) {
        this.vehicle_data.observations = this.itemToEditData.vehicle.observations
      } else {
        this.vehicle_data.observations = ''
      }
      this.client_data.id = this.itemToEditData.client_id
      this.client_data.firstname = this.$options.filters.capitalize(this.itemToEditData.client.name)
      this.client_data.surname = this.$options.filters.capitalize(this.itemToEditData.client.lastname)
      this.client_data.dni = this.$options.filters.magnitude(this.itemToEditData.client.dni)
      this.client_data.email = this.itemToEditData.client.email
      this.application_detail.last_servcices_date_format_server = this.itemToEditData.last_service_date
      if (this.itemToEditData.observations !== null) {
        this.application_detail.observations = this.itemToEditData.observations
      } else {
        this.application_detail.observations = ''
      }
      this.checklist.is_first_owner = this.itemToEditData.first_own
      this.checklist.has_stamps_in_service_history = this.itemToEditData.all_consesionaire_stamps
      this.checklist.has_key_duplicate = this.itemToEditData.duplicate_key
      this.checklist.has_service_history = this.itemToEditData.historial_service
      this.checklist.has_handbook = this.itemToEditData.propietary_manual
      if (this.itemToEditData.expertise_evaluation.length > 0) {
        this.expertiseEvalToBeEdited = this.itemToEditData.expertise_evaluation
      }
      if (this.itemToEditData.vehicle.mileage_km !== null) {
        this.vehicle_data.mileage = this.$options.filters.magnitude(this.itemToEditData.vehicle.mileage_km)
      } else {
        this.vehicle_data.mileage = ''
      }
      if (this.itemToEditData.origin !== null) {
        this.vehicle_data.origin = this.$options.filters.capitalize(this.itemToEditData.origin)
      } else {
        this.vehicle_data.origin = ''
      }
      if (this.itemToEditData.last_service_date !== null) {
        this.application_detail.last_service_date = this.transformDate(this.itemToEditData.last_service_date)
      } else {
        this.application_detail.last_service_date = ''
      }
      if (this.itemToEditData.last_belt_change !== null) {
        this.application_detail.timing_belt_last_change = this.$options.filters.magnitude(this.itemToEditData.last_belt_change)
      } else {
        this.application_detail.timing_belt_last_change = ''
      }
      if (this.itemToEditData.buying_value !== null) {
        this.valuation.purchase_value = this.$options.filters.magnitude(this.itemToEditData.buying_value)
      } else {
        this.valuation.purchase_value = ''
      }
      if (this.itemToEditData.selling_value !== null) {
        this.valuation.sale_value = this.$options.filters.magnitude(this.itemToEditData.selling_value)
      } else {
        this.valuation.sale_value = ''
      }
      if (this.itemToEditData.observations_vehicle_state !== null) {
        this.valuation.observations = this.itemToEditData.observations_vehicle_state
      } else {
        this.valuation.observations = ''
      }
      this.$refs.stepInspection.getEvaluationsType()
    },
    setFuelInForm () {
      this.vehicle_data.fuel.value = this.itemToEditData.vehicle.vehicle_model.vehicle_fuel.id
    },
    setColorInForm () {
      this.vehicle_data.color.value = this.itemToEditData.vehicle.vehicles_colors_id
    },
    setTransmissionInForm () {
      this.vehicle_data.transmission.value = this.itemToEditData.vehicle.transmition_id
    },
    transformDate (date) {
      const str = [...date]
      const localFormat = []
      this.newOrder(localFormat, str, 8)
      this.newOrder(localFormat, str, 9)
      this.newOrder(localFormat, str, 7)
      this.newOrder(localFormat, str, 5)
      this.newOrder(localFormat, str, 6)
      this.newOrder(localFormat, str, 7)
      this.newOrder(localFormat, str, 0)
      this.newOrder(localFormat, str, 1)
      this.newOrder(localFormat, str, 2)
      this.newOrder(localFormat, str, 3)
      const newDate = localFormat.join('')
      return newDate
    },
    newOrder (arr, str, n) {
      arr.push(str[n])
    },
    evaluationToFormData () {
      const formData = new FormData()
      if (this.newEvaluation.vehicle_id > 0) {
        formData.append('vehicle_id', this.newEvaluation.vehicle_id)
      }
      // Deleted images
      if (this.imagesDeleted.length > 0) {
        this.imagesDeleted.forEach((i, index) => {
          formData.append(`expertise_evaluation[${i.expertise_evaluation}][attachments][${index}][id]`, i.id)
          formData.append(`expertise_evaluation[${i.expertise_evaluation}][attachments][${index}][delete]`, i.delete)
        })
      }
      // Evaluations
      this.newEvaluation.expertise_evaluation.forEach((evaluation, index1) => {
        formData.append(`expertise_evaluation[${index1}][calification]`, evaluation.qualification.value)
        formData.append(`expertise_evaluation[${index1}][evaluation_type_id]`, evaluation.type_id)
        if ((this.makeEvaluation || this.editEvaluation) && evaluation.expertise_evaluation_id > 0) {
          formData.append(`expertise_evaluation[${index1}][id]`, evaluation.expertise_evaluation_id)
        }
        if (evaluation.images.length > 0) {
          evaluation.images.forEach((file, index2) => {
            if (!this.makeEvaluation && !this.editEvaluation) {
              formData.append(`expertise_evaluation[${index1}][attachments][${index2}]`, file)
            } else if (file?.type !== 'attachment') {
              formData.append(`expertise_evaluation[${index1}][attachments][100][new][${this.indexNewFile}]`, file)
              this.indexNewFile++
            }
          })
        }
        this.indexNewFile = 0
        formData.append(`expertise_evaluation[${index1}][spending]`, this.$options.filters.number(evaluation.expense))
        formData.append(`expertise_evaluation[${index1}][observations]`, evaluation.observations)
        formData.append(`expertise_evaluation[${index1}][recheck]`, evaluation.recheck ? 1 : 0)
      })
      // Vehicle
      formData.append('vehicle[vehicle_model_id]', this.newEvaluation.vehicle_data.model.value)
      formData.append('vehicle[domain]', this.$options.filters.uppercase(this.newEvaluation.vehicle_data.car_license_number))
      if (this.newEvaluation.vehicle_data.brand.value > 0) {
        formData.append('vehicle[vehicle_model][vehicle_brand_id]', this.newEvaluation.vehicle_data.brand.value)
      }
      formData.append('vehicle[vehicle_model][model]', this.newEvaluation.vehicle_data.model.text)
      formData.append('vehicle[year]', this.newEvaluation.vehicle_data.manufacturing_year)
      formData.append('launch_year', this.newEvaluation.vehicle_data.release_year)
      formData.append('vehicle[mileage_km]', this.$options.filters.number(this.newEvaluation.vehicle_data.mileage))
      if (this.newEvaluation.vehicle_data.color.value > 0) {
        formData.append('vehicle[vehicles_colors_id]', this.newEvaluation.vehicle_data.color.value)
      }
      if (this.newEvaluation.vehicle_data.fuel.value > 0) {
        formData.append('vehicle[vehicle_model][vehicle_fuel_id]', this.newEvaluation.vehicle_data.fuel.value)
      }
      if (this.newEvaluation.vehicle_data.transmission.value) {
        formData.append('vehicle[transmition_id]', this.newEvaluation.vehicle_data.transmission.value)
      }
      formData.append('vehicle[vehicle_model][vehicle_doors]', this.newEvaluation.vehicle_data.doors)
      formData.append('origin', this.newEvaluation.vehicle_data.origin)
      formData.append('vehicle[observations]', this.newEvaluation.vehicle_data.observations)
      if (this.newEvaluation.client_data.id > 0) {
        formData.append('client_id', this.newEvaluation.client_data.id)
      } else {
        formData.append('client[name]', this.$options.filters.capitalize(this.newEvaluation.client_data.firstname))
        formData.append('client[lastname]', this.$options.filters.capitalize(this.newEvaluation.client_data.surname))
        formData.append('client[dni]', this.$options.filters.number(this.newEvaluation.client_data.dni))
        formData.append('client[email]', this.newEvaluation.client_data.email)
      }
      formData.append('last_belt_change', this.$options.filters.number(this.newEvaluation.application_detail.timing_belt_last_change))
      formData.append('last_service_date', this.newEvaluation.application_detail.last_servcices_date_format_server)
      formData.append('observations', this.newEvaluation.application_detail.observations)
      formData.append('first_own', this.newEvaluation.checklist.is_first_owner ? 1 : 0)
      formData.append('all_consesionaire_stamps', this.newEvaluation.checklist.has_stamps_in_service_history ? 1 : 0)
      formData.append('duplicate_key', this.newEvaluation.checklist.has_key_duplicate ? 1 : 0)
      formData.append('historial_service', this.newEvaluation.checklist.has_service_history ? 1 : 0)
      formData.append('propietary_manual', this.newEvaluation.checklist.has_handbook ? 1 : 0)
      formData.append('buying_value', this.$options.filters.number(this.valuation.purchase_value))
      formData.append('selling_value', this.$options.filters.number(this.valuation.sale_value))
      formData.append('observations_vehicle_state', this.valuation.observations)
      return formData
    },
    async createNewEvaluation () {
      this.handleLoading(true)
      const data = this.evaluationToFormData()
      try {
        const res = await createEvaluation(this.userToken, data)
        this.$emit('addEvaluation', res.data.data)
        this.alertMessage = res.data.message
        this.alertColor = 'success'
        this.handleLoading(false)
        this.$store.dispatch('handleShowAddOrEditEvaluation', false)
        this.$store.dispatch('handleCurrentEvaluationStep', 1)
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
        this.alertMessage = error?.data?.message
        this.alertColor = 'error'
        this.handleLoading(false)
      } finally {
        this.handleAlert({
          value: true,
          text: this.alertMessage,
          color: this.alertColor
        })
      }
    },
    async editingEvaluation () {
      this.handleLoading(true)
      const data = this.evaluationToFormData()
      data.append('_method', 'PUT')
      try {
        const res = await updateEvaluation(this.userToken, data, this.newEvaluation.id)
        this.$emit('updateEvaluationTable', res.data.data)
        this.alertMessage = res.data.message
        this.alertColor = 'success'
        this.handleLoading(false)
        this.$store.dispatch('handleCurrentEvaluationStep', 1)
        this.$store.dispatch('handleShowAddOrEditEvaluation', false)
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
        this.alertMessage = error?.data?.message
        this.alertColor = 'error'
        this.handleLoading(false)
      } finally {
        this.$store.dispatch('handleEditEvaluation', false)
        this.$store.dispatch('handleMakeEvaluation', false)
        this.handleAlert({
          value: true,
          text: this.alertMessage,
          color: this.alertColor
        })
      }
    },
    getScore () {
      this.scoreByVehiclePart = []
      this.expenseByVehiclePart = []
      this.newEvaluation.expertise_evaluation.forEach(ee => {
        const score = Math.round(ee.value * ee.qualification.value / 100)
        const expe = ee.expense > 0 ? parseInt(this.$options.filters.number(ee.expense)) : 0
        console.log(expe)
        this.scoreByVehiclePart.push(score)
        this.expenseByVehiclePart.push(expe)
      })
      if (this.scoreByVehiclePart.length < 1) {
        this.level = 0
      } else {
        this.level = this.scoreByVehiclePart.reduce((a, b) => a + b)
      }
      if (this.expenseByVehiclePart.length > 0) {
        const expenseSum = this.expenseByVehiclePart.reduce((a, b) => a + b)
        this.totalExpenses = this.$options.filters.magnitude(expenseSum.toString())
      } else {
        this.totalExpenses = '0'
      }
      this.score = this.level + '%'
      if (this.level >= 80) {
        this.stars = 3
      } else if (this.level > 50 && this.level < 80) {
        this.stars = 2
      } else {
        this.stars = 1
      }
    },
    resetStoreData () {
      this.$store.dispatch('handleResetData', {
        vehicle_data: {
          car_license_number: '',
          brand: { value: 0 },
          model: { text: '', value: '' },
          manufacturing_year: '',
          release_year: '',
          mileage: '',
          color: { value: 0 },
          fuel: { value: 0 },
          transmission: { value: 0 },
          doors: '',
          origin: '',
          observations: ''
        },
        client_data: {
          id: 0,
          firstname: '',
          surname: '',
          dni: '',
          email: ''
        },
        application_detail: {
          timing_belt_last_change: '',
          last_service_date: '',
          last_servcices_date_format_server: '',
          observations: ''
        },
        checklist: {
          is_first_owner: false,
          has_stamps_in_service_history: false,
          has_key_duplicate: false,
          has_service_history: false,
          has_handbook: false
        },
        expertise_evaluation: [],
        valuation: {
          purchase_value: '',
          sale_value: '',
          observations: ''
        }
      })
    }
  },
  watch: {
    getCurrentEvaluationStep () {
      this.step = this.getCurrentEvaluationStep
    }
  },
  beforeMount () {
    if (this.makeEvaluation || this.editEvaluation) {
      this.vehicle_data.brand = { text: '' }
      this.vehicle_data.model = { text: '' }
      this.vehicle_data.color = {}
      this.vehicle_data.fuel = {}
      this.vehicle_data.transmission = {}
    }
  },
  mounted () {
    this.setStepInMakingEvaluation()
  },
  destroyed () {
    this.handleShowAddOrEditEvaluation(false)
    this.handleEditEvaluation(false)
    this.handleCurrentEvaluationStep(1)
    this.handleMakeEvaluation(false)
    this.$store.dispatch('handleDataForMakeOrEdit', {
      id: 0,
      vehicle_id: 0
    })
    this.handleImgDeleted([])
    this.resetStoreData()
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .v-stepper__label:hover {
    cursor: pointer;
  }
  .v-stepper__header {
    box-shadow: none;
    border-bottom: 1px solid #eeeeee;
  }
}
</style>
