<template>
  <v-card outlined>
    <v-card-title class="mx-1 pt-6 pb-4">
      <span class="font-weight-bold">{{ formTitle }}</span>
    </v-card-title>
    <hr class="hr-color">
    <v-card-text class="px-4 mt-7">
      <v-container>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              class="mr-4"
              v-model="userSelected.name"
              placeholder="Nombre">
            </v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="userSelected.last_name"
              placeholder="Apellido">
            </v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              class="mt-3"
              v-model="userSelected.email"
              placeholder="Email">
            </v-text-field>
          </v-col>
          <v-col cols="6">
            <v-select
              :items="itemConcessionaires"
              item-text="name"
              item-value="id"
              v-model="userSelected.concessionaire_id"
              class="mr-4 mt-3"
              placeholder="Concesionario">
            </v-select>
          </v-col>
          <v-col cols="6">
            <v-select
              :items="itemRoles"
              item-text="name"
              item-value="id"
              v-model="userSelected.role_id"
              class="mr-4 mt-3"
              placeholder="Rol">
            </v-select>
          </v-col>
          <v-col cols="6">
            <v-text-field
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              @click:append="showPassword = !showPassword"
              class="mr-4 mt-3"
              v-model="userSelected.password"
              placeholder="Contraseña">
          </v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              :append-icon="showPasswordRepeat ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPasswordRepeat ? 'text' : 'password'"
              @click:append="showPasswordRepeat = !showPasswordRepeat"
              class="mr-4 mt-3"
              v-model="userSelected.password_confirmation"
              placeholder="Confirmar contraseña">
          </v-text-field>
          </v-col>
          <v-col cols="12">
            <v-autocomplete
              chips
              clearable
              multiple
              label="Departamentos"
              :items="itemDepartments"
              item-text="name"
              item-value="id"
              return-object
              class="mr-4"
              v-model="userSelected.departments"
              placeholder="    Departamentos">
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <hr class="hr-color">

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="grey"
        text
        @click="$emit('close')"
      >
        Cancelar
      </v-btn>
      <v-btn
        class="bor-btn rounded mx-9 mt-2 mb-3 black--text"
        width="129"
        height="44"
        elevation="0"
        @click="save"
      >
        Guardar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'EditItem',
  props: {
    departments: {
      type: Array,
      required: true
    },
    concessionaires: {
      type: Array,
      required: true
    },
    roles: {
      type: Array,
      required: true
    },
    editedItem: {
      type: Object
    },
    edit: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      userSelected: {
        name: '',
        email: '',
        password: '',
        password_confirmation: '',
        last_name: '',
        status: 1,
        departments: [],
        role_id: '',
        concessionaire_id: ''
      },
      itemDepartments: [],
      itemConcessionaires: [],
      itemRoles: [],
      showPassword: false,
      showPasswordRepeat: false
    }
  },
  computed: {
    formTitle () {
      if (this.newItem) return 'Nuevo usuario'
      if (this.editItem) return 'Edicion de usuario'
      return 'Formulario usuario'
    },
    ...mapGetters({ userLogged: 'userLogged' })
  },
  methods: {
    close () {},
    save () {
      this.$emit('save', this.userSelected)
    },
    transformArrayForAutocomplete (array, itemArray) {
      array.forEach(item => {
        itemArray.push(item)
      })
    },
    setEditedItem () {
      this.userSelected = JSON.parse(JSON.stringify(this.editedItem))
      this.userSelected.concessionaire_id = this.editedItem.concessionaire?.id
      this.userSelected.role_id = this.editedItem.role?.id
    }
  },
  mounted () {
    this.transformArrayForAutocomplete(this.concessionaires, this.itemConcessionaires)
    this.transformArrayForAutocomplete(this.departments, this.itemDepartments)
    this.transformArrayForAutocomplete(this.roles, this.itemRoles)
    if (this.edit) {
      this.setEditedItem()
    }
  },
  watch: {
    edit: {
      handler () {
        if (this.edit) {
          this.setEditedItem()
        }
        if (this.edit === false) {
          this.userSelected = ''
          // this.editedItem = -1
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.hr-color {
  border-bottom: 0.5px solid rgb(240, 238, 238);
}
::v-deep {
  .v-card__title {
    span {
      font-size: 22px;
      letter-spacing: 0.01em;
    }
  }
  .v-btn {
    font-size: 12px;
    letter-spacing: 0.01em;
  }
}
</style>
