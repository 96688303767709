<template>
    <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.82283 13.5413L21.3333 13.5413L21.3333 11.458L8.82283 11.458L8.82283 8.33299L4.66658 12.4997L8.82283 16.6663L8.82283 13.5413Z" fill="#9D9D9D"/>
    </svg>
</template>

<script>
export default {
  name: 'ExitForm'
}
</script>
