export default {
  state: {
    search: {
      line: '',
      model: ''
    }
  },
  mutations: {
    setSearchLine (state, payload) {
      state.search.line = payload
    },
    setSearchModel (state, payload) {
      state.search.model = payload
    }
  },
  actions: {
    handleAccesoriesSearchLine ({ commit }, payload) {
      commit('setSearchLine', payload)
    },
    handleAccesoriesSearchModel ({ commit }, payload) {
      commit('setSearchModel', payload)
    }
  },
  getters: {
    searchAccesoriesData: state => state.search
  }
}
