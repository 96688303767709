<template>
  <v-card width="100%">
    <v-card-title style="border-bottom: 1px solid #EEEEEE;">
      <h2
        class="black--text my-1 ml-1"
        style="font-family: 'NouvelR';font-weight: 700;font-size: 22px;letter-spacing: 0.01em;"
      >Registro de llamadas</h2>
    </v-card-title>
    <v-card-text class="pt-5" style="min-height: 330px">
      <div class="d-flex mb-3">
        <span
          style="margin-right: 127px;font-family: 'NouvelR';font-weight: 700;font-size: 14px;letter-spacing: 0.01em;color: #9D9D9D;margin-left: 20px"
        >Nombre</span>
        <span
          style="margin-right: 140px;font-family: 'NouvelR';font-weight: 700;font-size: 14px;letter-spacing: 0.01em;color: #9D9D9D;margin-left: 20px"
        >Fecha</span>
        <span
          style="margin-right: 260px;font-family: 'NouvelR';font-weight: 700;font-size: 14px;letter-spacing: 0.01em;color: #9D9D9D;margin-left: 20px"
        >Audio</span>
        <!-- <span
          style="font-family: 'NouvelR';font-weight: 700;font-size: 14px;letter-spacing: 0.01em;color: #9D9D9D;margin-left: 20px"
        >Acciones</span> -->
      </div>
      <div
        v-for="(record, index) in calls"
        :key="index">
        <CallData
          :showAudioplayerModal="showAudioplayerModal"
          :record="record"
          :index="index">
        </CallData>
      </div>
    </v-card-text>
    <v-card-actions
      style="height: 80px;border-top: 1px solid #EEEEEE;"
      class="v-flex justify-end">
      <v-btn
        class="rounded mr-2"
        color="rgba(245, 245, 247, 0.5)"
        style="border: 1px solid #000000;"
        elevation="0"
        width="129"
        height="44"
        @click="closeModal">
        <span
          style="font-size: 12px;letter-spacing: 0.01em;color: #000000;font-weight: 400;font-family: 'NouvelR';"
        >Aceptar</span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import CallData from '@/components/savings/blocks/CallData.vue'

export default {
  name: 'AudioplayerModal',
  components: {
    CallData
  },
  props: {
    showAudioplayerModal: Boolean,
    calls: Array
  },
  methods: {
    closeModal () {
      this.$emit('closeModal', false)
    }
  }
}
</script>
