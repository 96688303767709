import axios from './instance'

const configToken = (token) => {
  return {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
}

export const createModel = async (token, vehicle) => {
  const config = configToken(token)
  try {
    const res = await axios.post('/api/vehicles-models', vehicle, config)
    return res
  } catch (err) {
    throw err.response
  }
}
// revisar si está ok el endpoint después de que lo haga Alfred

export const updateModel = async (token, vehicle, id) => {
  const config = configToken(token)
  // config.headers['Content-Type'] = 'multipart/form-data'
  try {
    const res = await axios.put(`/api/vehicles-models/${id}`, vehicle, config)
    return res
  } catch (err) {
    throw err.response
  }
}
// revisar si está ok el endpoint después de que lo haga Alfred
export const deleteModel = async (token, id) => {
  try {
    const res = await axios.delete(`/api/vehicles-models/${id}`, configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}
