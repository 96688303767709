<template>
  <div>
    <AddOrEditVehicleHeader
      @createNewVehicle="createNewVehicle"
      @editVehicle="editVehicle"
      @exitEditing="exitEditing"
      @setValueFilterWhenCancel="setValueFilterWhenCancel"
      :vehicleSelected="vehicleSelected"
    >
    </AddOrEditVehicleHeader>
    <!-- Card Vehículo -->
    <div class="d-flex justify-space-between form-container direction">
      <div class="d-flex flex-column left-side">
        <v-card
          outlined
          color="grey lighten-4"
          class="pa-6 ma-4 d-flex flex-column justify-start">
          <v-card-title class="text-uppercase text-no-wrap font-weight-bold align-self-start pb-4 pl-6">DATOS DEL VECHÍCULO</v-card-title>
          <v-card-text>
              <v-row>
                <v-col>
                  <v-autocomplete
                    class="mx-6"
                    v-model="modelSelected"
                    :items="modelsItems"
                    label="Modelo del vehículo"
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    class="ml-6"
                    v-model="vehicleSelected.year"
                    label="Año"
                  >
                  </v-text-field>
                </v-col>
                <v-col>
                  <v-autocomplete
                    class="mx-6"
                    v-model="statusSelected"
                    :items="statusesItems"
                    label="Estado"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col>
                  <v-text-field
                    class="mr-6"
                    v-model="vehicleSelected.vin_code"
                    label="VIN"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    class="ml-6"
                    v-model="vehicleSelected.domain"
                    label="Dominio"
                  >
                  </v-text-field>
                </v-col>
                <v-col>
                  <v-menu
                    v-model="isAdquisitionDateExpanded"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        class="mx-6"
                        clearable
                        readonly
                        hide-details
                        v-bind="attrs"
                        v-on="on"
                        id="datepicker1"
                        label="Fecha Adquisición"
                        v-model="vehicleSelected.adquisition_date"
                      >
                      </v-text-field>
                    </template>
                    <v-date-picker
                      v-model="vehicleSelected.adquisition_date"
                      @input="isAdquisitionDateExpanded = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col>
                  <v-text-field
                    class="mr-6"
                    v-model="vehicleSelected.serial_number"
                    label="Número de serie"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    class="ml-6"
                    v-model="vehicleSelected.location"
                    label="Ubicación"
                  >
                  </v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    class="mx-6"
                    v-model="vehicleSelected.mileage_km"
                    @change="applyFilterTransformData(vehicleSelected.mileage_km, 'mileage_km', 'magnitude')"
                    @focus="applyFilterTransformData(vehicleSelected.mileage_km, 'mileage_km', 'number')"
                    label="KM"
                  >
                  </v-text-field>
                </v-col>
                <v-col>
                  <v-autocomplete
                    class="mr-6"
                    v-model="transmitionSelected"
                    :items="transmitionItems"
                    label="Transmisión"
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="col-4">
                  <!-- <v-autocomplete
                    class="ml-6"
                    v-model="vehicleTypeSelected"
                    :items="vehicleTypeItems"
                    label="Tipo de vehículo"
                  >
                  </v-autocomplete> -->
                  <v-select
                    dense
                    class="ml-6"
                    label="Color"
                    placeholder="Color"
                    :items="colorItems"
                    v-model="colorSelected"
                  >
                    <template v-slot:item="{ item, attrs, on }">
                      <v-list-item v-on="on" v-bind="attrs" class="px-2">
                        <v-list-item-avatar
                          :color="item.code"
                          :size="24"
                        />
                        <v-list-item-title>
                          {{item.text}}
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
          </v-card-text>
        </v-card>
      </div>
      <div class="d-flex flex-column right-side">
        <v-card
          color="grey lighten-4"
          class="pa-6 ma-4 d-flex flex-column justify-start"
          elevation="0">
          <v-card-title class="text-uppercase text-no-wrap font-weight-bold align-self-start pb-4">PRECIO</v-card-title>
          <v-card-text class="pb-8">
            <v-row>
              <v-col>
                <v-text-field
                  v-model="vehicleSelected.list_price"
                  @change="applyFilterTransformData(vehicleSelected.list_price, 'list_price', 'magnitude')"
                  @focus="applyFilterTransformData(vehicleSelected.list_price, 'list_price', 'number')"
                  label="Precio lista"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="vehicleSelected.rasa_bonification"
                  @change="applyFilterTransformData(vehicleSelected.rasa_bonification, 'rasa_bonification', 'magnitude')"
                  @focus="applyFilterTransformData(vehicleSelected.rasa_bonification, 'rasa_bonification', 'number')"
                  label="Rasa bonificación"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="vehicleSelected.net_price_renault"
                  @change="applyFilterTransformData(vehicleSelected.net_price_renault, 'net_price_renault', 'magnitude')"
                  @focus="applyFilterTransformData(vehicleSelected.net_price_renault, 'net_price_renault', 'number')"
                  label="Precio Neto Renault"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="vehicleSelected.lepic_bonification"
                  @change="applyFilterTransformData(vehicleSelected.lepic_bonification, 'lepic_bonification', 'magnitude')"
                  @focus="applyFilterTransformData(vehicleSelected.lepic_bonification, 'lepic_bonification', 'number')"
                  label="Bonificación Lepic"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="vehicleSelected.percentage_discount"
                  label="Porcentaje descuento"
                  suffix="%"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="vehicleSelected.lepic_price"
                  @change="applyFilterTransformData(vehicleSelected.lepic_price, 'lepic_price', 'magnitude')"
                  @focus="applyFilterTransformData(vehicleSelected.lepic_price, 'lepic_price', 'number')"
                  label="Precio Lepic"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import AddOrEditVehicleHeader from '@/components/vehicles/AddOrEditVehicleHeader'
import initialStateConfig from '@/components/vehicles/initialState'
import { createVehicle, updateVehicle, getAllColorsByModel } from '@/services/vehicles'
import { mapState, mapGetters, mapActions } from 'vuex'
import AuthHelper from '@/helpers/AuthHelper'
import Helper from '@/helpers/Helper'

export default {
  name: 'AddOrEditVehicle',
  components: {
    AddOrEditVehicleHeader
  },
  props: {
    allModels: {
      type: Array,
      required: true
    },
    allStatuses: {
      type: Array,
      required: true
    },
    allVehicleTypes: {
      type: Array,
      required: true
    },
    allTransmitions: {
      type: Array,
      required: true
    },
    vehicleToEdit: {
      type: Object
    }
  },
  data () {
    return {
      vehicleSelected: {
        id: '',
        year: '',
        vin_code: '',
        domain: '',
        adquisition_date: '',
        serial_number: '',
        mileage_km: '',
        list_price: '',
        rasa_bonification: '',
        net_price_renault: '',
        lepic_bonification: '',
        percentage_discount: '',
        lepic_price: '',
        status: '',
        location: '',
        transmition: '',
        type: '',
        color: '',
        observations: ''
      },
      modelSelected: '',
      active: false,
      itemModels: [],
      locationItems: [],
      vehicleTypeItems: [],
      transmitionItems: [],
      modelsItems: [],
      statusesItems: [],
      colorItems: [],
      location: '',
      statusSelected: '',
      // vehicleTypeSelected: '',
      colorSelected: '',
      transmitionSelected: '',
      isAdquisitionDateExpanded: false,
      allLocations: ''
    }
  },
  computed: {
    ...mapState(['userToken']),
    ...mapGetters(['editingVehicle'])
    /* accesoryModelsAdded () {
      return this.vehicleSelected.models.filter(a => !a.delete)
    } */
  },
  methods: {
    ...mapActions(['handleAlert', 'handleLoading']),
    applyFilterTransformData (value, field, filter) {
      this.vehicleSelected[field] = this.$options.filters[filter](value)
    },
    setValueFilter () {
      this.vehicleSelected.value = this.$options.filters.magnitude(this.vehicleSelected.value)
    },
    resetFilterToEditValue () {
      this.vehicleSelected.value = this.$options.filters.number(this.vehicleSelected.value)
    },
    setValueFilterWhenCancel () {
      this.$emit('setValueFilterWhenCancel')
    },
    toggleActive () {
      this.active = !this.active
    },
    transformArrayForAutocomplete (array, field) {
      const itemArray = []
      array.forEach(item => {
        itemArray.push({
          value: item.id,
          text: item[field]
        })
      })
      return itemArray
    },
    transformForSubmitVehicle (vehicle) {
      const data = {
        id: vehicle.id ? vehicle.id : null,
        year: vehicle.year,
        vin_code: vehicle.vin_code,
        domain: vehicle.domain,
        list_price: Number(this.$options.filters.number(vehicle.list_price)),
        rasa_bonification: Number(this.$options.filters.number(vehicle.rasa_bonification)),
        net_price_renault: Number(this.$options.filters.number(vehicle.net_price_renault)),
        lepic_bonification: Number(this.$options.filters.number(vehicle.lepic_bonification)),
        percentage_discount: vehicle.percentage_discount,
        lepic_price: Number(this.$options.filters.number(vehicle.lepic_price)),
        adquisition_date: vehicle.adquisition_date,
        serial_number: vehicle.serial_number,
        location: vehicle.location,
        mileage_km: Number(this.$options.filters.number(vehicle.mileage_km)),
        transmition_id: vehicle.transmition,
        vehicle_type_id: 1,
        vehicle_status_id: vehicle.status,
        vehicle_model_id: vehicle.model,
        vehicles_colors_id: vehicle.color !== '' ? vehicle.color : null,
        observations: vehicle.observations ? vehicle.observations : null
      }
      return data
    },
    async createNewVehicle () {
      this.handleLoading(true)
      const data = await this.transformForSubmitVehicle(this.vehicleSelected)
      try {
        const res = await createVehicle(this.userToken, data)
        this.$store.dispatch('handleShowAddOrEditVehicle', false)
        this.$store.dispatch('handleEditVehicle', false)
        this.$emit('addVehicle', res.data.data)
        this.alertMessage = res.data.message
        this.alertColor = 'success'
        this.handleLoading(false)
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
        this.alertMessage = error?.data?.message ? error.data.message : error
        this.alertColor = 'error'
        this.handleLoading(false)
      } finally {
        this.exitEditing()
        this.handleAlert({
          value: true,
          text: this.alertMessage,
          color: this.alertColor
        })
      }
    },
    async editVehicle () {
      this.handleLoading(true)
      const data = this.transformForSubmitVehicle(this.vehicleSelected)
      this.handleLoading(false)
      // data.append('_method', 'PUT')
      try {
        const res = await updateVehicle(this.userToken, data, data.id)
        this.$store.dispatch('handleShowAddOrEditVehicle', false)
        this.$store.dispatch('handleEditVehicle', false)
        this.$emit('updateVehicle', res.data.data)
        this.editingVehicleSelected(false, this.vehicleSelected)
        this.alertMessage = res.data.message
        this.alertColor = 'success'
        this.handleLoading(false)
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
        this.alertMessage = error?.data?.message ? error.data.message : error
        this.alertColor = 'error'
        this.handleLoading(false)
      } finally {
        this.handleAlert({
          value: true,
          text: this.alertMessage,
          color: this.alertColor
        })
      }
    },
    async exitEditing () {
      this.editingVehicleSelected(false)
      this.$emit('stopEditing')
    },
    // refactorizar
    editingVehicleSelected (editing, prop) {
      if (editing) {
        this.vehicleSelected.id = prop.id
        if (Object.prototype.hasOwnProperty.call(prop, 'data')) {
          this.vehicleSelected.year = Object.prototype.hasOwnProperty.call(prop.data, 'year')
            ? prop.data.year : prop.year
          this.vehicleSelected.vin_code = Object.prototype.hasOwnProperty.call(prop.data, 'vin_code') ? prop.data.vin_code : prop.vin_code
          this.vehicleSelected.domain = prop.data.domain ? prop.data.domain : null
          this.vehicleSelected.adquisition_date = prop.data.adquisition_date ? prop.data.adquisition_date : null
          this.vehicleSelected.serial_number = prop.data.serial_number ? prop.data.serial_number : null
          this.vehicleSelected.mileage_km = prop.data.mileage_km ? prop.data.mileage_km : null
          this.vehicleSelected.list_price = prop.data.list_price ? prop.data.list_price : null
          this.vehicleSelected.rasa_bonification = prop.data.rasa_bonification ? prop.data.rasa_bonification : null
          this.vehicleSelected.net_price_renault = prop.data.net_price_renault ? prop.data.net_price_renault : null
          this.vehicleSelected.lepic_bonification = prop.data.lepic_bonification ? prop.data.lepic_bonification : null
          this.vehicleSelected.percentage_discount = prop.data.percentage_discount ? prop.data.percentage_discount : null
          this.vehicleSelected.lepic_price = prop.data.lepic_price ? prop.data.lepic_price : null
          this.vehicleSelected.location = prop.data.location ? prop.data.location : null
          this.vehicleSelected.transmition = prop.data.transmition ? prop.data.transmition : null
          this.vehicleSelected.color = prop.data.color ? prop.data.color : null
          this.vehicleSelected.type = prop.data.vehicle_type_id ? prop.data.vehicle_type_id : 1
          this.vehicleSelected.vehicle_type_id = prop.data.vehicle_type_id ? prop.data.vehicle_type_id : 1
          this.vehicleSelected.adquisition_date = prop.data.adquisition_date ? prop.data.adquisition_date : null
          this.vehicleSelected.serial_number = prop.data.serial_number ? prop.data.serial_number : null
          this.vehicleSelected.observations = prop.data.observations ? prop.data.observations : null
          this.modelSelected = prop.data.vehicle_model_id
          this.statusSelected = prop.data.vehicle_status_id
          this.colorSelected = prop.data.vehicles_colors_id
          this.transmitionSelected = prop.data.transmition_id
        }
        this.vehicleSelected.domain = prop.domain
      } else {
        this.vehicleSelected = JSON.parse(JSON.stringify(initialStateConfig))
      }
    },
    async getColorsByModel (modelID) {
      try {
        this.handleLoading(true)
        const res = await getAllColorsByModel(this.userToken, modelID)
        this.colors = res.data.data
        this.colorItems = Helper.transformToSelect(this.colors, 'color')
        this.colorItems.forEach(ci => { ci.code = Helper.getItemFromArray(ci.value, this.colors)?.codigo })
        this.alertMessage = res.data.message
        this.alertColor = 'success'
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
        this.alertMessage = error?.data?.message ? error.data.message : error
        this.alertColor = 'error'
        this.colorItems = []
        this.data.color = ''
      } finally {
        this.handleLoading(false)
        this.handleAlert({
          value: true,
          text: this.alertMessage,
          color: this.alertColor
        })
      }
    }
  },
  async mounted () {
    this.modelsItems = Helper.transformToSelect(this.allModels, 'model')
    this.statusesItems = Helper.transformToSelect(this.allStatuses, 'name')
    this.transmitionItems = Helper.transformToSelect(this.allTransmitions, 'name')
    this.vehicleTypeItems = Helper.transformToSelect(this.allVehicleTypes, 'name')
    if (this.editingVehicle) {
      await this.editingVehicleSelected(this.editingVehicle, this.vehicleToEdit)
    }
  },
  watch: {
    accesoryModelsAdded: {
      deep: true,
      handler () {
        const accessoryModelsNotAddes = this.getAccesoryModelsNotAdded()
        this.itemModels = this.transformArrayForAutocomplete(accessoryModelsNotAddes, 'model')
      }
    },
    statusSelected: {
      deep: true,
      handler () {
        this.vehicleSelected.status = this.statusSelected
      }
    },
    colorSelected: {
      deep: true,
      handler () {
        this.vehicleSelected.color = this.colorSelected
      }
    },
    transmitionSelected: {
      deep: true,
      handler () {
        this.vehicleSelected.transmition = this.transmitionSelected
      }
    },
    modelSelected: {
      deep: true,
      handler () {
        this.vehicleSelected.model = this.modelSelected
        this.getColorsByModel(this.modelSelected.value ? this.modelSelected.value : this.modelSelected)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
ul {
  margin: 0;
  padding: 0;
  li {
    @include fontStyle(500, 14px, 0.01em, $black);
    list-style: none;
  }
}
.hr-style {
  border: 1px dashed rgba(157, 157, 157, 0.5);
}
.title_font {
  @include fontStyle(bold, 14px, 0.01em, $black)
}
.box-width {
  width: 100%;
}
.left-side {
  width: 70%;
  margin-left: 28px;
}
.right-side {
  width: 30%;
  margin-right: 28px;
}
.list-title_font {
  @include fontStyle(bold, 14px, 0.01em, $grey)
}
.drop-container {
  width: 100%;
}
.drop-area {
  border: 1px dashed $grey;
  width: 88%;
  height: 170px;
  cursor: pointer;
}
.drop-area {
  &.active {
  background-color: $white;
  border: 1px solid orange;
  }
}
.preview {
  img {
    max-width: 100px;
    max-height: 100px;
  }
}
@media (max-width: 750px) {
  .direction {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .left-side {
    width: 90%;
    margin-bottom: 24px;
    margin-left: 0px;
  }
  .right-side {
    width: 90%;
    margin-right: 0px;
  }
}
::v-deep {
  .btn-add__custom {
        font-size: 10px;
        letter-spacing: 0.01em;
        border: 1px solid $black !important;
        background-color: #e9e9eb !important;
    }
}
</style>
