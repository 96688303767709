<template>
  <v-card-text class="pa-0 d-flex flex-column" style="height: 90%;">
    <div class="d-flex align-end">
      <span
        class="d-inline-block"
        style="text-align: left;font-weight: bold;font-size: 18px;letter-spacing: 0.01em;color: #9D9D9D;"
        >Empecemos con el cuestionario </span
      ><span class="d-inline-block">👍</span>
    </div>
    <transition name="ques">
      <Questions
        ref="sq1q0"
        v-if="currentQues === 0"
        :isCurrency="false"
        :dataSeted="dataSeted"
        :question="questionData.questions[0]"
        :screens_question="questionData.question"
        :sq_aclaration="questionData.aclaration"
        @sendAnswer="setOperationNumber"
        @changeDataSeted="changeDataSeted"
        @goToNextQuestion="goToNextQuestion"
      ></Questions>
    </transition>
    <transition name="ques">
      <Questions
        ref="sq1q1"
        v-if="currentQues === 1"
        :dataSeted="dataSeted"
        :question="questionData.questions[1]"
        :screens_question="null"
        :sq_aclaration="null"
        :btnWidth="160"
        @selectOption="hasTime"
        @goToNextQuestion="goToNextQuestion"
      ></Questions>
    </transition>
    <transition name="ques">
      <NextQuestionButton
        v-if="showButton"
        @goToNextQuestion="goToNextQuestion"
      ></NextQuestionButton>
    </transition>
  </v-card-text>
</template>

<script>
import Questions from '@/components/savings/blocks/Questions'
import NextQuestionButton from '@/components/savings/blocks/NextQuestionButton'
import { mapGetters, mapState, mapActions } from 'vuex'
import Helper from '../../../../helpers/Helper'
import { createScoring } from '@/services/savings'

export default {
  name: 'SQuesOne',
  components: {
    Questions,
    NextQuestionButton
  },
  props: {
    questionData: Object,
    clientData: Object
  },
  data () {
    return {
      showButton: false,
      operationNumber: '',
      alwaysShowButton: false,
      currentQues: 0,
      dataSeted: false,
      question0: '',
      decisionQuestionFirstValue: '',
      historyFirstValue: [],
      historyScrap: [],
      deleteData: [],
      sq_data: [
        {
          question_id: this.questionData.questions[0].id,
          ponderation: '',
          updated_at: ''
        },
        {
          question_id: this.questionData.questions[1].id,
          ponderation: '',
          updated_at: ''
        }
      ]
    }
  },
  computed: {
    ...mapState(['userToken']),
    ...mapGetters([
      'getOperationNumber',
      'scoringData',
      'isBrowsingInScoringHistory',
      'continueScoring',
      'getIndexOfCurrentSQ',
      'getHistory',
      'SQNameByNumber',
      'sq_number',
      'getScoringId'
    ])
  },
  methods: {
    ...mapActions(['handleLoading']),
    setData (sqdata) {
      if (this.continueScoring && (this.getHistory[this.getHistory.length - 1] === 1)) {
        this.dataSeted = true
        this.showButton = true
        this.sq_data[0].ponderation = this.getOperationNumber
        this.$refs.sq1q0.setAnswer(
          this.$options.filters.magnitude(
            this.sq_data[0].ponderation
          )
        )
      }
      if (this.scoringData[sqdata] !== null) {
        this.showButton = true
        this.dataSeted = true
        this.alwaysShowButton = true
        this.$emit('handleProgress', 0)
        this.$refs.sq1q0.setAnswer(
          this.$options.filters.magnitude(
            this.scoringData[sqdata][0].ponderation
          )
        )
        for (let i = 0; i < 2; i++) {
          this.sq_data[i].ponderation = this.scoringData[sqdata][i].ponderation
          this.sq_data[i].updated_at = this.scoringData[sqdata][i].updated_at
          if (this.continueScoring) {
            if (this.scoringData[sqdata][0].id !== undefined) {
              this.sq_data[i].id = this.scoringData[sqdata][i].id
            }
          }
        }
        if (this.isBrowsingInScoringHistory) {
          this.question0 = this.scoringData[sqdata][0].ponderation
          this.decisionQuestionFirstValue = this.scoringData[
            sqdata
          ][1].ponderation
          this.historyFirstValue = this.getHistory
        }
      }
    },
    setOperationNumber (value) {
      this.$store.dispatch('handleOperationNumber', value)
      this.dataFromQuestions(value, 0, 'ponderation', 'updated_at')
    },
    hasTime (value) {
      this.dataFromQuestions(value, 1, 'ponderation', 'updated_at')
    },
    useButton (value) {
      if (value !== null) {
        this.showButton = true
      }
    },
    dataFromQuestions (value, index, ponder, time) {
      this.sq_data[index][ponder] = value
      this.sq_data[index][time] = Helper.setDateAndTime()
      this.useButton(value)
    },
    changeDataSeted (value) {
      this.dataSeted = value
    },
    handleIfEditQues (index, ques) {
      if (
        this.sq_data[index].id !== undefined &&
        ques !== this.sq_data[index].ponderation
      ) {
        this.$emit('editQuestion', this.sq_data[index])
        this.$store.dispatch('handleScoringData', this.sq_data)
      }
    },
    async sendScoringNumberToServer () {
      this.handleLoading(true)
      const body = this.prepareBody()
      const localStorageData = JSON.stringify(this.prepareData())
      try {
        await createScoring(this.userToken, body)
        this.$store.dispatch('handleIfSentScoringNumber', true)
      } catch (error) {
        console.error(error)
      } finally {
        this.handleLoading(false)
        localStorage.setItem('scoringClientData', localStorageData)
      }
    },
    prepareBody () {
      return {
        client_id: this.clientData.id,
        scoring_id: this.getScoringId,
        scoring_status_id: 2,
        questions: [
          {
            question_id: 1,
            ponderation: this.sq_data[0].ponderation,
            updated_at: this.sq_data[0].updated_at,
            history: [1]
          }
        ]
      }
    },
    prepareData () {
      return {
        clientData: this.clientData,
        scoringId: this.getScoringId,
        operationNumber: this.getOperationNumber
      }
    },
    goToNextQuestion () {
      if (this.currentQues === 0 && !this.continueScoring) {
        this.sendScoringNumberToServer()
      }
      if (this.isBrowsingInScoringHistory && this.currentQues === 0) {
        this.handleIfEditQues(0, this.question0)
      }
      if (this.isBrowsingInScoringHistory && this.currentQues === 1) {
        this.$emit('reduceDecreasing', 1)
        if (this.decisionQuestionFirstValue !== this.sq_data[1].ponderation) {
          this.$emit('reduceDecreasing', 0)
          this.handleIfEditQues(1, this.decisionQuestionFirstValue)
          this.historyScrap = this.historyFirstValue.splice(
            this.getIndexOfCurrentSQ + 1
          )
          this.historyScrap.forEach(e => {
            if (e !== 2 && e !== 32 && e !== 41) {
              if (this.scoringData[this.SQNameByNumber[e]] !== null) {
                if (
                  this.scoringData[this.SQNameByNumber[e]][0].id === undefined
                ) {
                  this.$store.dispatch('handleResetScoringData', e)
                } else {
                  this.scoringData[this.SQNameByNumber[e]].forEach(q => {
                    this.deleteData.push(q.id)
                  })
                  this.$store.dispatch('handleResetScoringData', e)
                }
              }
            }
          })
          this.$emit('deleteQuestion', this.deleteData)
          this.$store.dispatch('handleHistoryData', this.historyFirstValue)
          this.$store.dispatch('handleIfIsBrowsingInScoringHistory', false)
          this.$store.dispatch('handleHistoryIndexOfCurrentSQ', null)
        }
      }
      if (this.currentQues === 0) {
        this.currentQues = 1
        this.$emit('handleProgress', 3)
        if (this.alwaysShowButton) {
          this.showButton = true
        } else {
          this.showButton = false
        }
      } else if (this.currentQues === 1) {
        this.sq_data[0].ponderation = this.$options.filters.number(
          this.sq_data[0].ponderation
        )
        this.$store.dispatch('handleScoringData', this.sq_data)
        if (
          this.sq_data[1].ponderation ===
          this.questionData.questions[1].options[0]
        ) {
          this.$emit('handleProgress', 5)
          if (this.isBrowsingInScoringHistory) {
            this.$store.dispatch('handleOnlyCurrentSQ', 3)
          } else {
            this.$store.dispatch('handleCurrentSQ', 3)
          }
        } else {
          this.$emit('handleProgress', 4)
          if (this.isBrowsingInScoringHistory) {
            this.$store.dispatch('handleOnlyCurrentSQ', 2)
          } else {
            this.$store.dispatch('handleCurrentSQ', 2)
          }
        }
        this.$emit('checkIfHistoryModeContinues')
      }
    }
  },
  mounted () {
    this.setData('sq_oneData')
  }
}
</script>

<style lang="scss" scoped>
.ques-enter-active {
  transition: opacity 0.5s;
}
.ques-enter {
  opacity: 0;
}
</style>
