<template>
  <v-container class="ml-2">
    <v-select
      class="mx-2 my-0"
      v-model="line"
      :items="linesItems"
      item-text="text"
      item-value="value"
      label="Líneas"></v-select>
    <v-autocomplete
      :items="modelsItems"
      label="Modelo"
      v-model="model"
      class="mx-2 mb-4">
    </v-autocomplete>
  </v-container>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import Helper from '../../helpers/Helper'

export default {
  name: 'AccessoriesFilterOptions',
  props: {
    lines: {
      type: Array,
      required: true
    },
    models: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    linesItems: [],
    modelsItems: []
  }),
  computed: {
    ...mapState(['userToken']),
    ...mapGetters(['searchAccesoriesData']),
    model: {
      get () {
        return this.searchAccesoriesData.model
      },
      set (value) {
        this.$store.dispatch('handleAccesoriesSearchModel', value)
      }
    },
    line: {
      get () {
        return this.searchAccesoriesData.line
      },
      set (value) {
        this.$store.dispatch('handleAccesoriesSearchLine', value)
      }
    }
  },
  methods: {
    ...mapActions(['handleAccesoriesSearchLine', 'handleAccesoriesSearchModel'])
  },
  watch: {
    lines: {
      handler () {
        this.linesItems = Helper.transformToSelect(this.lines, 'line', { value: '', text: 'Todas las lineas' })
      }
    },
    models: {
      handler () {
        this.modelsItems = Helper.transformToSelect(this.models, 'model', { value: '', text: 'Todos los modelos' })
      }
    }
  },
  mounted () {
    this.linesItems = Helper.transformToSelect(this.lines, 'line', { value: '', text: 'Todas las lineas' })
  }
}
</script>
