<template>
    <v-chip
        :text-color="color"
        :color="color + 26"
        class="font-weight-black text-uppercase rounded"
        label
        >
      {{ text }}
    </v-chip>
</template>

<script>
export default {
  name: 'TableMarkStatus',
  props: {
    item: Object,
    text: String,
    color: {
      type: String,
      default: '#9D9D9D'
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .v-chip__content {
    font-size: 10px;
    margin: 0 18px;
  }
}
</style>
