<template>
  <v-col cols="12" sm="4" class="d-flex flex-column">
    <div
      class="rounded d-flex align-center justify-space-around mb-6 bg-hover"
      style="width: 100%;height: 79px;background-color: rgba(255, 183, 11, 0.15);cursor: pointer;"
      @click="rescheduleCall">
      <img src="../../assets/icons/calendar.svg" alt="Icono de calendario" />
      <span
        class="text-no-wrap text-truncate txt-hover"
        style="font-weight: bold;font-size: 14px;letter-spacing: 0.01em;color: #FFB70B;"
        >REPROGRAMAR LLAMADO</span>
    </div>
    <v-card class="mb-7" width="100%" elevation="0" color="#F5F5F7">
      <v-card-title>
        <h2
          style="font-size: 14px;letter-spacing: 0.01em;"
          class="text-no-wrap text-truncate"
          >DETALLE CLIENTE
        </h2>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                dense
                v-model="clientData.dni"
                label="DNI"
                placeholder="DNI"
                readonly
              ></v-text-field>
              <v-text-field
                dense
                v-model="clientData.name"
                label="Nombre"
                placeholder="Nombre"
                readonly
              ></v-text-field>
              <v-text-field
                dense
                v-model="clientData.surname"
                label="Apellido"
                placeholder="Apellido"
                readonly
              ></v-text-field>
              <v-text-field
                dense
                v-model="clientData.dateOfBirth"
                label="Fecha Nacimiento"
                placeholder="Fecha Nacimiento"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
    <div
      style="width: 100%;height: 74px;background-color: #F5F5F7;"
      class="rounded d-flex align-center justify-space-around">
      <v-btn
        small
        fab
        elevation="0"
        @click="checkIfStartCalling">
        <v-icon>$phone</v-icon>
      </v-btn>
      <a :href="`mailto: ${clientData.email}`">
        <v-btn small fab elevation="0" class="mr-10">
          <v-icon>$email</v-icon>
        </v-btn>
      </a>
      <v-snackbar v-model="showSnackbarAlert" color="primary">
      <span  style="font-weight: bold;font-size: 15px;">Antes de inicir la llamda debe ingresar el Nº de Scoring y avanzar a la siguiente pregunta.</span>
        <template v-slot:action="{ attrs }">
          <v-btn
            color="black"
            text
            v-bind="attrs"
            @click="openAlert(false)"
          >Cerrar</v-btn>
        </template>
      </v-snackbar>
    </div>
    <div
      class="rounded d-flex align-center justify-space-around mt-7 px-14 buttonr"
      style="width: 100%;height: 83px;background-color: #F5F5F7;cursor: pointer;"
      @click="openAudioplayer(true)">
      <img src="../../assets/img/phoneCalling-icon.svg" alt="">
      <span style="font-weight: 700;font-size: 13px;color: #000000;letter-spacing: 0.01em;">VER REGISTRO DE LLAMADAS</span>
    </div>
    <v-dialog v-model="showAudioplayerModal" width="880px">
      <AudioplayerModal :showAudioplayerModal="showAudioplayerModal" :calls="calls" @closeModal="openAudioplayer"></AudioplayerModal>
    </v-dialog>
  </v-col>
</template>

<script>
import AudioplayerModal from '@/components/savings/AudioplayerModal'
import { mapGetters, mapState, mapActions } from 'vuex'
import { clickToDial, getRecordingsByScoringId } from '@/services/savings'
import AuthHelper from '@/helpers/AuthHelper'

export default {
  name: 'ScoringActions',
  components: {
    AudioplayerModal
  },
  props: {
    clientData: Object,
    calendarMounted: Boolean,
    itemToEditData: Object
  },
  data () {
    return {
      showAudioplayerModal: false,
      calls: [],
      alertMessage: '',
      alertColor: '',
      showSnackbarAlert: false
    }
  },
  computed: {
    ...mapGetters(['getScoringId', 'continueScoring', 'sentScoringNumber']),
    ...mapState(['userToken'])
  },
  methods: {
    ...mapActions(['handleLoading', 'handleAlert']),
    rescheduleCall () {
      this.$store.dispatch('handleShowCalendar', { view: true, edit: true })
      if (this.calendarMounted) {
        this.$emit('setCalendarInfo')
      }
    },
    openAudioplayer (value) {
      if (value) {
        this.getRecordings(value)
      } else {
        this.showAudioplayerModal = value
      }
    },
    async getRecordings (value) {
      this.handleLoading(true)
      const scoringId = this.continueScoring ? this.itemToEditData.id : this.getScoringId
      this.calls = []
      try {
        const res = await getRecordingsByScoringId(this.userToken, scoringId)
        this.calls = res.data.data
        this.alertMessage = res.data.message
        this.alertColor = 'success'
        this.handleLoading(false)
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
        this.alertMessage = error?.data?.message
        this.alertColor = 'error'
        this.handleLoading(false)
      } finally {
        this.showAudioplayerModal = value
        this.handleAlert({
          value: true,
          text: this.alertMessage,
          color: this.alertColor
        })
      }
    },
    checkIfStartCalling () {
      if (!this.continueScoring && !this.sentScoringNumber) {
        this.openAlert(true)
      } else {
        this.startCalling()
      }
    },
    async startCalling () {
      const scoringId = this.continueScoring ? this.itemToEditData.id : this.getScoringId
      const phone = this.clientData.cellphoneNumber ? this.clientData.cellphoneNumber : this.clientData.phoneNumber
      try {
        await clickToDial(parseInt(phone), this.userToken, scoringId)
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
      }
    },
    openAlert (value) {
      this.showSnackbarAlert = value
    }
  }
}
</script>
