<template>
  <div class="pt-5">
    <AdvancedFilter @filter="filter">
      <template v-slot:filterOptions>
        <SavingsFilterOptions
          :clients="clients"
          :vehicles="renaultModelItems"
          :status="status">
        </SavingsFilterOptions>
      </template>
    </AdvancedFilter>
    <Table
      v-if="!addOrEditScoring"
      :title="tableData.title"
      :headers="tableData.headers"
      :items="tableData.items"
      :hideDefaultTableHeader="hideDefaultTableHeader"
      @rowClicked="rowClicked"
      >
      <template v-slot:chipStatus="slotProps">
        <TableMarkStatus :text="slotProps.item.status" :color="slotProps.item.color"></TableMarkStatus>
      </template>
      <template v-slot:showButton="slotProps">
        <TableShowButton :ItemId="slotProps.item.id" :action="slotProps.action" @startCalling="startCalling"></TableShowButton>
      </template>
      <template v-slot:tableToolbar="slotProps">
        <TableToolbar
          :title="tableData.title"
          :btnAdd="tableData.btnAdd"
          @handleAddOrUpdate="handleAddOrUpdate(true)"
          :pagination="slotProps.pagination"
          :options="slotProps.options"
          :updateOptions="slotProps.updateOptions"
          >
          <template v-slot:calendarButton>
            <TableCalendarButton @showCalendar="showCalendar"></TableCalendarButton>
          </template>
          <template v-slot:toolbarFilterButton>
            <TableFilterButton @filter="filter"></TableFilterButton>
          </template>

          <template v-slot:paginationTable="slotProps">
            <TablePagination
              :pagination="slotProps.pagination"
              :options="slotProps.options"
              :updateOptions="slotProps.updateOptions" >
            </TablePagination>
          </template>
          <template v-slot:pendingCallIndicator>
            <CallIndicators :CallIndicator="pendingCallIndicator"></CallIndicators>
          </template>
          <template v-slot:postponedCallIndicator>
            <CallIndicators :CallIndicator="postponedCallIndicator"></CallIndicators>
          </template>
        </TableToolbar>
      </template>

      <template v-slot:componentDelete>
        <TableDeleteItem
          :dialogDelete="dialogDelete"
          @handleDialogDelete="handleDialogDelete"
          @closeDelete="closeDelete" />
      </template>

      <template v-slot:no-data>
        <TableNoData />
      </template>
    </Table>
    <CreateOrEditScoring
      ref="createscoring"
      v-if="addOrEditScoring"
      :questions="questions"
      :renaultModelItems="renaultModelItems"
      :users="users"
      :itemToEditData="itemToEditData"
      :calendarMounted="calendarMounted"
      :recoveredDataFromLS="recoveredDataFromLS"
      @addScoringInTable="addScoringInTable"
      @setCalendarInfo="setCalendarInfo">
    </CreateOrEditScoring>
    <v-dialog
      :fullscreen="showingCalendar.edit"
      :persistent="showingCalendar.edit"
      v-model="showingCalendar.view">
      <ScoringCalendar
        ref="scCalendar"
        :itemToEditData="itemToEditData"
        @saveScoring="saveScoring"
        @calendarIsMounted="calendarIsMounted"
        @addScoringInTable="addScoringInTable"
        @continueEditingSelectedEvent="continueEditingSelectedEvent">
      </ScoringCalendar>
    </v-dialog>
  </div>
</template>

<script>
import { getQuestionnaire, getAllScorings, deleteScoring, getAllRenaultModels, clickToDial, getAllScoringStatus } from '@/services/savings'
import { getAllClients } from '@/services/sales'
import { getAllUsers } from '@/services/users'
import AuthHelper from '@/helpers/AuthHelper'
import Table from '@/components/tables/Table'
import TableNoData from '@/components/tables/TableNoData'
import TableToolbar from '@/components/tables/TableToolbar'
import TablePagination from '@/components/tables/TablePagination'
import TableFilterButton from '../components/tables/TableFilterButton'
import TableMarkStatus from '@/components/tables/TableMarkStatus'
import TableDeleteItem from '@/components/tables/TableDeleteItem'
import TableCalendarButton from '@/components/tables/TableCalendarButton'
import TableShowButton from '@/components/tables/TableShowButton'
import AdvancedFilter from '@/components/tables/AdvancedFilter'
import { mapGetters, mapState, mapActions } from 'vuex'
import CallIndicators from '@/components/savings/CallIndicators'
import CreateOrEditScoring from '@/components/savings/CreateOrEditScoring'
import ScoringCalendar from '@/components/savings/ScoringCalendar'
import SavingsFilterOptions from '@/components/savings/SavingsFilterOptions'
import { EventBus } from '@/EventBus.js'

export default {
  name: 'Savings',
  components: {
    Table,
    TableNoData,
    TableToolbar,
    TablePagination,
    TableFilterButton,
    TableMarkStatus,
    TableDeleteItem,
    TableCalendarButton,
    AdvancedFilter,
    CallIndicators,
    TableShowButton,
    CreateOrEditScoring,
    ScoringCalendar,
    SavingsFilterOptions
  },
  data () {
    return {
      hideDefaultTableHeader: false,
      dialogDelete: false,
      calendarMounted: false,
      questions: {},
      allScorings: [],
      renaultModels: [],
      renaultModelItems: [],
      filteredScorings: [],
      itemToEditData: {},
      startWeekIndicators: '',
      endWeekIndicators: '',
      recoveredDataFromLS: {},
      clients: [],
      status: [],
      users: [],
      pendingCallIndicator: {
        background: true,
        leftPosition: true,
        imgSrc: true,
        imgAlt: 'Icono de una persona llamando por teléfono',
        indicator: 0,
        firstLineText: 'Llamadas pendientes',
        secondLineText: 'para esta semana.'
      },
      postponedCallIndicator: {
        background: false,
        leftPosition: false,
        imgSrc: false,
        imgAlt: 'Icono de un calendario',
        indicator: 0,
        firstLineText: 'Llamadas pospuestas',
        secondLineText: 'esta semana.'
      },
      tableData: {
        title: 'Plan de ahorro',
        headers: [
          {
            text: 'Nro',
            value: 'number',
            class: 'white'
          },
          {
            text: 'Solicitante',
            value: 'applicant',
            class: 'white'
          },
          {
            text: 'Vehículo',
            value: 'vehicle',
            class: 'white'
          },
          {
            text: 'Programado',
            value: 'scheduled',
            class: 'white'
          },
          {
            text: 'Estado',
            value: 'status',
            class: 'white'
          },
          {
            text: 'Acciones',
            value: 'action',
            class: 'white'
          },
          {
            text: '',
            value: 'actions',
            class: 'white'
          }
        ],
        items: [],
        btnAdd: {
          title: 'Nuevo Scoring'
        }
      }
    }
  },
  computed: {
    ...mapGetters(['addOrEditScoring', 'showingCalendar', 'filterSavingsData']),
    ...mapState(['userToken'])
  },
  methods: {
    ...mapActions(['handleLoading', 'handleAlert', 'handleAdvancedFilter']),
    dateScheduledFormat (s) {
      const date = s.schedules[s.schedules.length - 1].start
      const array = date.split('')
      const newArray = []
      newArray.push(array[8])
      newArray.push(array[9])
      newArray.push('/')
      newArray.push(array[5])
      newArray.push(array[6])
      newArray.push('/')
      newArray.push(array[0])
      newArray.push(array[1])
      newArray.push(array[2])
      newArray.push(array[3])
      newArray.push(' ')
      if (array[11] === '1') {
        newArray.push('1')
      }
      newArray.push(array[12])
      newArray.push(':00 hs')
      const newDate = newArray.join('')
      return newDate
    },
    saveScoring (data) {
      this.$refs.createscoring.createNewScoring(data)
    },
    async getQuestions () {
      this.handleLoading(true)
      try {
        const res = await getQuestionnaire(1, this.userToken)
        this.questions = res.data.data
        this.handleLoading(false)
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
        this.handleLoading(false)
      } finally {
        this.checkLocaleStorage()
      }
    },
    async getRenaultModels () {
      this.handleLoading(true)
      try {
        const res = await getAllRenaultModels(this.userToken)
        this.renaultModels = res.data.data
        this.renaultModelItems = this.setRenaultModelsItem()
        this.handleLoading(false)
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
        this.handleLoading(false)
      }
    },
    async getSavingPlans () {
      this.handleLoading(true)
      this.allScorings = []
      this.filteredScorings = []
      try {
        const res = await getAllScorings(this.userToken)
        this.allScorings = res.data.data.reverse()
        this.allScorings.forEach(s => {
          if (s.client === null) {
            this.deleteScoringById(s.id)
            const index = this.allScorings.findIndex(e => e.id === s.id)
            this.allScorings.splice(index, 1)
          }
        })
        console.log('all scorings', this.allScorings)
        this.formatSavingsTable(this.allScorings)
        this.alertMessage = res.data.message
        this.alertColor = 'success'
        this.handleLoading(false)
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
        this.alertMessage = error?.data?.message
        this.alertColor = 'error'
        this.handleLoading(false)
      } finally {
        this.getPendingCallIndicator()
        this.handleAlert({
          value: true,
          text: this.alertMessage,
          color: this.alertColor
        })
      }
    },
    async getClients () {
      this.client = []
      try {
        const res = await getAllClients(this.userToken)
        this.clients = res.data.data
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
      }
    },
    async getScoringStatus () {
      this.status = []
      try {
        const res = await getAllScoringStatus(this.userToken)
        this.status = res.data.data
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
      }
    },
    async getUsers () {
      this.users = []
      try {
        const res = await getAllUsers(this.userToken)
        res.data.data.forEach((user) => {
          this.users.push(user.name + ' ' + user.last_name)
        })
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
      }
    },
    async deleteScoringById (id) {
      try {
        await deleteScoring(this.userToken, id)
        this.formatSavingsTable(this.allScorings)
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
      }
    },
    async startCalling (id) {
      const scoring = this.allScorings.find(s => s.id === id)
      const phone = scoring.client.mobile_phone
        ? scoring.client.mobile_phone
        : scoring.client.phone
      try {
        await clickToDial(parseInt(phone), this.userToken, scoring.id)
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
      }
    },
    formatSavingsTable (data) {
      this.tableData.items = []
      data.forEach(s => {
        this.tableData.items.push(this.getFormatSavingsTable(s))
      })
    },
    getFormatSavingsTable (s) {
      const appNumber = this.findObject(s, 'questions_ponderated', 'question_id', 1)
      const modelInForm = this.findObject(s, 'questions_ponderated', 'question_id', 78)
      const modelClientWants = this.findObject(s, 'questions_ponderated', 'question_id', 29)
      let model = ''
      if (modelInForm === undefined) {
        model = 'Scoring no iniciado'
      } else if (modelInForm !== undefined && modelClientWants === undefined) {
        model = modelInForm.ponderation
      } else if (modelClientWants !== undefined) {
        model = modelClientWants.ponderation
      }
      return {
        id: s.id,
        number: appNumber.ponderation !== undefined ? this.$options.filters.magnitude(appNumber.ponderation) : 'Sin datos',
        applicant: s.client.name + ' ' + s.client.lastname,
        vehicle: model,
        scheduled: s.schedules.length > 0 ? this.dateScheduledFormat(s) : 'Sin llamada programada',
        status: s.status.name,
        color: s.status.color,
        action: '',
        actions: [
          {
            text: 'Programar Llamada',
            icon: 'mdi-calendar-cursor',
            fn: (item) => {
              this.scheduleCall(item)
            },
            classes: ''
          },
          {
            text: 'Editar / Continuar',
            icon: 'mdi-format-list-checks',
            fn: (item) => {
              this.continueScoring(item)
            },
            classes: ''
          }
        ]
      }
    },
    rowClicked (item, rowData) {
      this.continueScoring(item)
    },
    setRenaultModelsItem () {
      const models = []
      this.renaultModels.forEach(rm => {
        models.push(rm.model)
      })
      return models
    },
    setWeekForIndicators () {
      const now = Date.now()
      for (let i = 0; i > -1; i += 7) {
        const startWeek = new Date(2022, 1, 28, 1, 0)
        const endWeek = new Date(startWeek)
        if (endWeek.setDate(endWeek.getDate() + i + 7) > now) {
          startWeek.setDate(startWeek.getDate() + i)
          this.startWeekIndicators = startWeek.toISOString()
          this.endWeekIndicators = endWeek.toISOString()
          return
        }
      }
    },
    getPendingCallIndicator () {
      this.setWeekForIndicators()
      const now = Date.now()
      const init = new Date(this.startWeekIndicators)
      const end = new Date(this.endWeekIndicators)
      const postponed = []
      const pending = []
      this.allScorings.forEach(s => {
        if (s.schedules.length > 0 && s.status.id === 2) {
          const event = new Date(s.schedules[s.schedules.length - 1].start)
          if (event > now && event < end) {
            pending.push(event)
          } else if (s.schedules.length > 2) {
            const eventReScheduled = new Date(s.schedules[s.schedules.length - 2].start)
            if (eventReScheduled > init && eventReScheduled < now) {
              postponed.push(eventReScheduled)
            }
          } else if (s.schedules.length === 1) {
            const eventPostponed = new Date(s.schedules[0].start)
            if (eventPostponed > init && eventPostponed < now) {
              postponed.push(eventPostponed)
            }
          }
        }
      })
      console.log('pending', pending, postponed)
      this.pendingCallIndicator.indicator = pending.length
      this.postponedCallIndicator.indicator = postponed.length
    },
    handleAddOrUpdate (value) {
      this.$store.dispatch('handleAddOrEditScoring', value)
    },
    handleDialogDelete (value) {
      this.dialogDelete = value
    },
    closeDelete () {
      this.handleDialogDelete(false)
      this.itemToDelete = {}
    },
    scheduleCall (item) {
      this.$store.dispatch('handleShowCalendar', { view: true, edit: true })
      this.setItemData(item)
      if (this.calendarMounted) {
        this.$refs.scCalendar.settingCalendarInfo()
      }
    },
    calendarIsMounted (data) {
      this.calendarMounted = data
    },
    setCalendarInfo () {
      this.$refs.scCalendar.settingCalendarInfo()
    },
    continueEditingSelectedEvent (scoringId) {
      const item = this.allScorings.find(s => s.id === scoringId)
      this.$store.dispatch('handleShowCalendar', { view: false, edit: false })
      this.continueScoring(item)
    },
    continueScoring (item) {
      this.setItemData(item)
      this.handleAddOrUpdate(true)
      this.$store.dispatch('handleContinueScoring', true)
      const index = this.itemToEditData.questions_ponderated[0].history.length - 1
      if (this.itemToEditData.questions_ponderated[0].history[index] === 2 ||
        this.itemToEditData.questions_ponderated[0].history[index] === 24 ||
        this.itemToEditData.questions_ponderated[0].history[index] === 25 ||
        this.itemToEditData.questions_ponderated[0].history[index] === 30 ||
        this.itemToEditData.questions_ponderated[0].history[index] === 34 ||
        this.itemToEditData.questions_ponderated[0].history[index] === 44 ||
        this.itemToEditData.questions_ponderated[0].history[index] === 46 ||
        this.itemToEditData.questions_ponderated[0].history[index] === 49 ||
        this.itemToEditData.questions_ponderated[0].history[index] === 53) {
        this.$store.dispatch('handleShowContinueScoringButton', true)
      }
    },
    setItemData (i) {
      this.itemToEditData = {}
      const index = this.getItem(i.id)
      this.itemToEditData = this.allScorings[index]
    },
    getItem (id) {
      const index = this.allScorings.findIndex(e => {
        return id === e.id
      })
      return index
    },
    addScoringInTable () {
      this.getSavingPlans()
    },
    filter () {
      this.filteredScorings = this.allScorings
      if (!this.allFilteredOptionsEmpty()) {
        if (this.filterSavingsData.client !== '') {
          this.filteredScorings = this.filteredScorings.filter(fs => fs.client.id === this.filterSavingsData.client)
        }
        if (this.filterSavingsData.vehicle !== '') {
          this.filteredScorings = this.filteredScorings.filter(fs => this.compareVehicle(fs))
        }
        if (this.filterSavingsData.scoringStatus !== '') {
          this.filteredScorings = this.filteredScorings.filter(fs => fs.status.id === this.filterSavingsData.scoringStatus)
        }
        if (this.filterSavingsData.dateSelected !== '') {
          this.filteredScorings = this.filteredScorings.filter(fs => fs.schedules[fs.schedules.length - 1]?.start?.includes(this.filterSavingsData.dateSelected))
        }
      }
      this.formatSavingsTable(this.filteredScorings)
      this.handleAdvancedFilter(false)
    },
    allFilteredOptionsEmpty () {
      return (this.filterSavingsData.client === '' &&
        this.filterSavingsData.vehicle === '' &&
        this.filterSavingsData.scoringStatus === '' &&
        this.filterSavingsData.dateSelected === '')
    },
    compareVehicle (value) {
      const modelClientWants = this.findObject(value, 'questions_ponderated', 'question_id', 29)
      const modelInForm = this.findObject(value, 'questions_ponderated', 'question_id', 78)
      if ((modelClientWants !== undefined && modelClientWants.ponderation === this.filterSavingsData.vehicle) ||
        (modelClientWants === undefined && modelInForm !== undefined && modelInForm.ponderation === this.filterSavingsData.vehicle)) {
        return true
      } else {
        return false
      }
    },
    compareDateSelected (value) {},
    findObject (input, array, prop, id) {
      const toGet = input[array].find(e => {
        return e[prop] === id
      })
      return toGet
    },
    showCalendar () {
      this.$store.dispatch('handleShowCalendar', { view: true, edit: false })
      if (this.calendarMounted) {
        this.$refs.scCalendar.settingCalendarInfo()
      }
    },
    checkLocaleStorage () {
      const storage = localStorage.getItem('scoringClientData')
      if (storage !== null) {
        console.log('dispara el if del check')
        this.recoveredDataFromLS.client = JSON.parse(localStorage.getItem('scoringClientData'))
        this.recoveredDataFromLS.history = JSON.parse(localStorage.getItem('scoringHistory'))
        this.recoveredDataFromLS.data = JSON.parse(localStorage.getItem('scoringData'))
        this.$store.dispatch('hanldeRecoveringData', true)
        this.handleAddOrUpdate(true)
      }
    },
    searchScoring (scorings, search) {
      this.filteredScorings = this.allScorings
      if (search !== '') {
        this.filteredScorings = scorings.filter(s => s.questions_ponderated[0]?.ponderation?.includes(search))
      } else {
        this.filteredScorings = scorings
      }
      this.formatSavingsTable(this.filteredScorings)
    }
  },
  beforeCreate () {
    this.$store.dispatch('handleIndicatorsRowTableToolbar', true)
  },
  mounted () {
    this.getQuestions()
    this.getRenaultModels()
    this.getSavingPlans()
    this.checkLocaleStorage()
    this.getClients()
    this.getScoringStatus()
    this.getUsers()
    EventBus.$on('searchSavings', (input) => this.searchScoring(this.allScorings, input))
  },
  destroyed () {
    this.$store.dispatch('handleIndicatorsRowTableToolbar', false)
  }
}
</script>
