<template>
  <v-overlay :value="getShowLoading" :opacity="bgOpacity" z-index="2000">
    <div>
      <!-- <v-progress-circular
        :size="circleSize"
        :color="circleColor"
        indeterminate
      ></v-progress-circular> -->
      <LogoAnimation class="logo-animation" />
      <p class="mt-5">{{message}}</p>
    </div>
  </v-overlay>
</template>

<script>
import { mapGetters } from 'vuex'
import LogoAnimation from '../../../components/icons/LogoAnimation.vue'

export default {
  name: 'AppLoading',

  components: {
    LogoAnimation
  },

  props: {
    circleSize: { type: Number, default: 50 },
    circleColor: { type: String, default: 'white' },
    message: { type: String, default: 'Cargando, por favor espere...' },
    bgOpacity: { type: Number, default: 0.70 }
  },

  data: () => ({}),

  computed: {
    ...mapGetters(['getShowLoading'])
  }
}
</script>

<style lang="scss" scoped>
#logo{
  width: 80px;
}
</style>
