<template>
    <svg width="16" height="16" fill="none" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 9C5.33 9 0 10.34 0 13V16H16V13C16 10.34 10.67 9 8 9ZM14.1 14.1H1.9V13C1.9 12.36 5.03 10.9 8 10.9C10.97 10.9 14.1 12.36 14.1 13V14.1ZM8 8C10.21 8 12 6.21 12 4C12 1.79 10.21 0 8 0C5.79 0 4 1.79 4 4C4 6.21 5.79 8 8 8ZM8 1.9C9.16 1.9 10.1 2.84 10.1 4C10.1 5.16 9.16 6.1 8 6.1C6.84 6.1 5.9 5.16 5.9 4C5.9 2.84 6.84 1.9 8 1.9Z"/>
    </svg>
</template>

<script>
export default {
  name: 'User'
}
</script>

<style lang="scss" scoped>
</style>
