export default {
  state: {
    search: {
      status: '',
      model: '',
      transmition: '',
      type: ''
    }
  },
  mutations: {
    setPar1 (state, payload) {
      state.search.status = payload
    },
    setPar2 (state, payload) {
      state.search.model = payload
    },
    setPar3 (state, payload) {
      state.search.transmition = payload
    },
    setPar4 (state, payload) {
      state.search.type = payload
    }
  },
  actions: {
    handleVehicleSearchStatus ({ commit }, payload) {
      commit('setPar1', payload)
    },
    handleVehicleSearchModel ({ commit }, payload) {
      commit('setPar2', payload)
    },
    handleVehicleSearchTransmition ({ commit }, payload) {
      commit('setPar3', payload)
    },
    handleVehicleSearchType ({ commit }, payload) {
      commit('setPar4', payload)
    }
  },
  getters: {
    searchVehiclesData: state => state.search
  }
}
