export default class UserHelper {
  static isSuperAdmin (id) {
    return id === 1
  }

  static isGeneralManager (id) {
    return id === 2
  }

  static isAdministration (id) {
    return id === 3
  }

  static isDistribution (id) {
    return id === 4
  }

  static isSupervisor (id) {
    return id === 5
  }

  static isSaler (id) {
    return id === 6
  }

  static isExpert (id) {
    return id === 7
  }
}

// agregar funcion dinámica para chequear si el usuario tiene permiso para una acción en un módulo concreto
