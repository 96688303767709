<template>
<div class="list-style d-flex justify-flex-start pb-1 mb-4">
  <span class="font-weight-bold mr-8 text-start">{{title}}</span>
  <slot>
  </slot>
</div>
</template>

<script>
export default {
  name: 'AttachmentList',
  props: {
    files: Array,
    title: String,
    subtypeId: Number
  },
  data () {
    return {
      index: this.subtypeId - 1
    }
  },
  methods: {
    openAlertDelete () {
      this.$emit('openAlertDelete')
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
}
</style>
