<template>
  <v-card>
    <v-card-title></v-card-title>
    <v-card-text>
      <h2
        class="font-weight-bold my-4 black--text"
        style="font-family: 'NouvelR';letter-spacing: 0.01em;font-size: 22px;"
        >¿Desea salir del formulario?</h2>
      <p
        class="black--text"
        style="font-family: 'NouvelR';letter-spacing: 0.01em;font-size: 18px;"
        >Perderá todos los cambios</p>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        style="font-size: 12px;font-family: 'NouvelR';"
        class="mr-3"
        color="grey"
        text
        @click="exitForm(false)"
        >Cancelar</v-btn>
      <v-btn
        style="background: rgba(245, 245, 247, 0.5);border: 1px solid #000000;font-size: 12px;font-family: 'NouvelR';"
        class="rounded mr-5 mt-2 mb-3 black--text"
        elevation="0"
        width="100"
        height="34"
        @click="acceptExitForm()">Salir</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'AlertExitForm',
  props: {
    actionShowForm: String
  },
  data () {
    return {
    }
  },
  methods: {
    exitForm (val) {
      this.$store.dispatch('handleExitForm', val)
    },
    acceptExitForm () {
      this.$store.dispatch('handleExitForm', false)
      this.$store.dispatch('handleShowAddOrEditAccessory', false)
      this.$store.dispatch('handleShowAddOrEditVehicle', false)
      this.$store.dispatch('handleShowAddOrEditEvaluation', false)
      this.$store.dispatch('handleShowAddOrEditCatalog', false)
      this.$emit('confirmExit')
    }
  }
}
</script>
