import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Vuelidate from 'vuelidate'

Vue.use(Vuelidate)

Vue.config.productionTip = false

Vue.filter('capitalize', (value) => {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter('uppercase', (value) => {
  if (!value) return ''
  return value.toUpperCase()
})

Vue.filter('currency', (value) => {
  if (value === '' || value === null) {
    return
  }
  if (typeof value === 'string' && (value !== '' || value !== null)) {
    value = parseInt(value)
  }
  const formatter = new Intl.NumberFormat('es-AR', {
    style: 'currency',
    currency: 'ARS',
    minimumFractionDigits: 0
  })
  return formatter.format(value)
})

Vue.filter('magnitude', (value) => {
  if (value === '' || value === null) {
    return
  }
  if (typeof value === 'string') {
    value = parseInt(value)
  }
  const formatter = new Intl.NumberFormat('es-AR', {
    style: 'decimal',
    minimumFractionDigits: 0
  })
  return formatter.format(value)
})

Vue.filter('number', (value) => {
  if (typeof value === 'string') {
    value = [...value]
    const numberFormat = []
    value.forEach(l => {
      if (l === '0' ||
        l === '1' ||
        l === '2' ||
        l === '3' ||
        l === '4' ||
        l === '5' ||
        l === '6' ||
        l === '7' ||
        l === '8' ||
        l === '9') {
        numberFormat.push(l)
      }
    })
    value = numberFormat.join('')
  }
  return value
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
