<template>
  <div class="d-flex justify-start align-center mt-9">
    <v-btn
      elevation="0"
      @click="goToNextQuestion"
      dark
      color="#000000"
      class="rounded-0">
      <span style="font-weight: bold;font-size: 18px;letter-spacing: 0.01em;">OK</span>
    </v-btn>
    <span class="ml-6" style="font-size: 14px;letter-spacing: 0.01em;color: #000000;">Press Enter</span>
  </div>
</template>

<script>
export default {
  name: 'NextQuestionButton',
  methods: {
    goToNextQuestion () {
      this.$emit('goToNextQuestion')
    }
  }
}
</script>
