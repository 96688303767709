<template>
  <div class="d-flex align-center justify-media_q">
    <div style="width: 100px">
      <v-icon v-if="item.recheck" color="black">mdi-car-wrench</v-icon>
    </div>
    <v-btn
      color="transparent"
      @click="addPhotoFromCamera(item, index)"
      class="mr-7"
      elevation="0">
      <v-icon class="mr-2">$addphoto</v-icon>
      <span style="font-size: 14px;letter-spacing: 0.01em;" class="text-capitalize">Agregar</span>
    </v-btn>
    <input
      hidden
      type="file"
      ref="inputFile"
      @change="gettingFile">
      <!-- <Camera
        v-if="cameraIsWorking"
        style="position: fixed;top: 0;left: 0;width: 100vw;height: 100vh;z-index: 1000;"
        @closeCamera="closeCamera"
        @savePhotoFromCamera="savePhotoFromCamera">
      </Camera> -->
    <v-btn
      color="transparent"
      fab
      class="mr-7"
      @click="seePhotos(item)"
      elevation="0">
      <v-icon class="mr-2">$collection</v-icon>
      <span style="font-size: 16px;letter-spacing: 0.01em;">{{ numberOfPhotos }}</span>
    </v-btn>
    <v-overlay
      :value="editCollection && item.images.length > 0"
      :z-index="zIndex"
      :opacity="opacity">
      <div class="d-flex justify-end img-responsive" style="width: 100%">
      <v-btn
        x-large
        @click="closeCollection"
        color="transparent"
        elevation="0"
        class="mb-10"
        fab>
        <v-icon>mdi-close-thick</v-icon>
      </v-btn>
      </div>
      <div class="d-flex align-center" style="max-width: 50%;">
        <v-btn
          style="margin-right: 5%"
          color="transparent"
          elevation="0"
          fab>
          <v-icon x-large @click="previousImg">$chevronleft</v-icon>
        </v-btn>
        <v-img
          :src="src"
        ></v-img>
        <v-btn
          style="margin-left: 5%"
          color="transparent"
          elevation="0"
          fab>
          <v-icon x-large @click="nextImg">$chevronright</v-icon>
        </v-btn>
      </div>
      <v-btn
        style="margin-top: 80px"
        color="transparent"
        elevation="0"
        @click="deleteImg">
        <v-icon class="mr-6">$trashcan</v-icon>
        <span class="text-capitalize" style="font-size: 18px;letter-spacing: 0.01em;">Eliminar imagen</span>
      </v-btn>
    </v-overlay>
    <v-btn
      color="transparent"
      class="mr-4"
      fab
      @click="editPartToBeEvaluated(item, index)"
      elevation="0">
      <v-icon>$pen</v-icon>
    </v-btn>
    <v-dialog v-model="editModal" width="670px">
      <EditEvaluationModal
        @cancelEvaluation="cancelEvaluation"
        @confirmEvaluation="confirmEvaluation"
        :itemSelected="itemSelected">
      </EditEvaluationModal>
    </v-dialog>
  </div>
</template>

<script>
import EditEvaluationModal from '@/components/evaluations/forms/EditEvaluationModal'
/* import Camera from '@/components/evaluations/forms/Camera' */
import { mapGetters } from 'vuex'

export default {
  name: 'EditionOfPart',
  components: {
    EditEvaluationModal
    /* Camera */
  },
  props: {
    item: Object,
    index: Number
  },
  data () {
    return {
      editModal: false,
      editCollection: false,
      itemSelected: {},
      imgDeleted: [],
      indexItemSelected: null,
      src: '',
      absolute: true,
      zIndex: 1000,
      opacity: 0.7,
      currentImgIndex: 0,
      cameraIsWorking: false,
      localStoreImge: []
    }
  },
  computed: {
    ...mapGetters(['makeEvaluation', 'editEvaluation']),
    numberOfPhotos () {
      return this.item.images.length
    }
  },
  methods: {
    addPhotoFromCamera (item, index) {
      this.itemSelected = item
      this.indexItemSelected = index
      /* this.cameraIsWorking = true */
      this.$refs.inputFile.click()
    },
    gettingFile () {
      this.localStoreImge = this.$refs.inputFile.files[0]
      this.itemSelected.images.push(this.localStoreImge)
      this.$emit('addPhotosFromCamera', this.itemSelected.images, this.indexItemSelected)
    },
    closeCamera () {
      this.$emit('addPhotosFromCamera', this.itemSelected.images, this.indexItemSelected)
      this.cameraIsWorking = false
      this.indexItemSelected = null
      this.itemSelected = {}
    },
    seePhotos (item) {
      if (this.item.images.length > 0) {
        this.itemSelected = item
        this.showFileOrLinkServer(0)
        this.editCollection = true
      }
    },
    closeCollection () {
      this.editCollection = false
    },
    showImagesFromServer (n) {
      this.src = this.itemSelected.images[n].src
      console.log(this.src)
      this.currentImgIndex = n
    },
    showImages (n) {
      const reader = new FileReader()
      reader.readAsDataURL(this.itemSelected.images[n])
      reader.onload = () => {
        this.src = reader.result
      }
      this.currentImgIndex = n
    },
    showFileOrLinkServer (index) {
      if (!this.makeEvaluation && !this.editEvaluation) {
        this.showImages(index)
      }
      if (this.makeEvaluation || this.editEvaluation) {
        if (this.itemSelected.images[index].type === 'attachment') {
          this.showImagesFromServer(index)
        } else {
          this.showImages(index)
        }
      }
    },
    nextImg () {
      if (this.currentImgIndex === this.itemSelected.images.length - 1) {
        this.showFileOrLinkServer(0)
      } else {
        const next = this.currentImgIndex + 1
        this.showFileOrLinkServer(next)
      }
    },
    previousImg () {
      if (this.currentImgIndex === 0) {
        const last = this.itemSelected.images.length - 1
        this.showFileOrLinkServer(last)
      } else {
        const previous = this.currentImgIndex - 1
        this.showFileOrLinkServer(previous)
      }
    },
    deleteImg () {
      if (this.makeEvaluation || this.editEvaluation) {
        if (this.itemSelected.images[this.currentImgIndex].type === 'attachment') {
          this.itemSelected.images[this.currentImgIndex].delete = 1
          this.$emit('addImgDeleted', this.itemSelected.images[this.currentImgIndex])
          this.itemSelected.images.splice(this.currentImgIndex, 1)
        } else {
          this.itemSelected.images.splice(this.currentImgIndex, 1)
        }
      } else {
        this.itemSelected.images.splice(this.currentImgIndex, 1)
      }
      if (this.itemSelected.images.length > 0) {
        this.showFileOrLinkServer(0)
      } else {
        this.currentImgIndex = 0
        this.editCollection = false
      }
    },
    editPartToBeEvaluated (item, index) {
      this.itemSelected = item
      this.indexItemSelected = index
      this.editModal = true
    },
    cancelEvaluation () {
      this.editModal = false
      this.indexItemSelected = null
      this.itemSelected = {}
    },
    confirmEvaluation (qualy, img, exp, obs, recheck) {
      this.itemSelected.qualification = qualy
      this.itemSelected.expense = exp
      this.itemSelected.images = [...this.itemSelected.images, ...img]
      this.itemSelected.observations = obs
      this.itemSelected.recheck = recheck
      this.$emit('addEvaluationOfPart', this.itemSelected, this.indexItemSelected)
      this.cancelEvaluation()
    },
    savePhotoFromCamera (file) {
      this.itemSelected.images.push(file)
    }
  }
}
</script>

<style lang="scss" scoped>
@media (max-width: 800px) {
  .justify-media_q {
    justify-content: space-between;
  }
}
::v-deep {
  .v-image {
    min-width: 600px;
    max-width: 700px;
    min-height: 429px;
    max-height: 430px;
  }
  @media (max-width: 900px) {
    .v-image {
      min-width: 499px;
      min-height: 306px;
      max-width: 500px;
      max-height: 307px;
    }
  }
  @media (max-width: 650px) {
    .v-image {
      min-width: 199px;
      min-height: 121px;
      max-width: 200px;
      max-height: 122px;
    }
  }
}
</style>
