<template>
  <v-card class="pt-3">
    <v-card-text>
      <h1
        class="font-weight-bold mt-5 mb-8 black--text"
        style="font-family: 'NouvelR';letter-spacing: 0.01em;font-size: 22px;"
        >¿Confirma que el Scoring queda pendiente?</h1>
      <p
        class="black--text"
        v-if="showDescription"
        style="font-family: 'NouvelR';letter-spacing: 0.01em;font-size: 18px;"
        >{{ pendingMessage }}</p>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        style="font-size: 12px;font-family: 'NouvelR';"
        class="mr-3"
        color="grey"
        text
        @click="exitAlert">Cancelar</v-btn>
      <v-btn
        style="background: rgba(245, 245, 247, 0.5);border: 1px solid #000000;font-size: 12px;font-family: 'NouvelR';"
        class="rounded mr-5 mt-2 mb-3 black--text"
        elevation="0"
        width="100"
        height="34"
        @click="confirmPendingScoring">Confirmar</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'PendingAlert',
  props: {
    showDescription: Boolean,
    pendingMessage: String
  },
  methods: {
    exitAlert () {
      this.$emit('exitAlert')
    },
    confirmPendingScoring () {
      this.$store.dispatch('handleShowCalendar', { view: true, edit: true })
      this.exitAlert()
    }
  }
}
</script>
