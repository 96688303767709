<template>
  <div>
  <CreateOrEditSaleHeader
    @saveSaleApplication="saveSaleApplication"
    :application_number="saleData.id"
    :operation_number="saleData.sale.operation_number"
    :status="saleData.sale.sale_status"
    v-on:update:operation_number="saleData.sale.operation_number = $event"
    :editedIndex="editedIndex"
    :edit="edit"
    :saleSelected="saleSelected"
    :valiadationsRules="valiadationsRules"
    @doNextSaleStep="changeStep"
    @exitForm="exitForm"
  ></CreateOrEditSaleHeader>
  <v-stepper v-model="step" elevation="0" v-if="step > 0">
      <v-stepper-header class="mb-4">
        <v-stepper-step
          class="ml-11"
          :complete="step > 1"
          step="1"
          @click="changeStep(1)"
        >
          Datos cliente
        </v-stepper-step>
        <v-stepper-step
          :complete="step > 2"
          step="2"
          @click="changeStep(2)"
        >
          Vehículo
        </v-stepper-step>
        <v-stepper-step
          :complete="step > 3"
          step="3"
          @click="changeStep(3)"
        >
          Forma de pago
        </v-stepper-step>
        <v-stepper-step
          class="mr-15 pr-15"
          step="4"
          @click="changeStep(4)"
        >
          Solicitud
        </v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <StepClient ref="saleStepClient" :valiadationsRules="valiadationsRules" :step="1" :saleData="saleData.client_data" v-on:update:data="saleData.client_data = $event" :edit="edit" :provinces="provinces" :provincesItems="provincesItems" :maritalStatus="maritalStatus" :maritalStatusItems="maritalStatusItems"
                    :clientTypes="clientTypes" :userDb="userDb" :clientTypesItems="clientTypesItems" />
        <StepVehicle ref="saleStepVehicle" :valiadationsRules="valiadationsRules" :userDb="userDb" :step="2" :allModels="allModels" :fuels="fuels" :transmitions="transmitions" :allVehicles="allVehicles" :typeSale="typeSale"
                     :complete-data="saleData" :saleData="saleData.vehicle" v-on:update:data="saleData.vehicle = $event" :edit="edit"/>
        <StepPayment ref="saleStepPayment" :valiadationsRules="valiadationsRules" :userDb="userDb" :edit="edit" :step="3" :fuels="fuels" :complete-data="saleData" :saleData="saleData.payment_method" :typeSale="typeSale" v-on:update:data="saleData.payment_method = $event" />
        <StepApplication ref="saleStepApplication" :valiadationsRules="valiadationsRules" :userDb="userDb" :step="4" :saleData="saleData.application" v-on:update:data="saleData.application = $event"
                         :complete-data="saleData" :pass-validation="saleDataPassValidation" :edit="edit"  :provinces="provinces" :provincesItems="provincesItems" :maritalStatus="maritalStatus" :maritalStatusItems="maritalStatusItems"/>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import CreateSaleStructure from '@/components/sales/CreateSaleStructure'
import CreateOrEditSaleHeader from '@/components/sales/CreateOrEditSaleHeader'
import { mapActions, mapGetters, mapState } from 'vuex'
import initialStateConfig from '@/components/sales/initialState'
import StepVehicle from './createOrEdit/StepVehicle'
import StepPayment from './createOrEdit/StepPayment'
import StepClient from './createOrEdit/StepClient'
import StepApplication from './createOrEdit/StepApplication'
// import { getAllFuelsTypes, getAllVehicles, getAllTransmitions, getAllSaleUsedVehiclesById, getAllPatentTypes } from '../../services/vehicles'
import { getAllFuelsTypes, getAllVehicles, getAllTransmitions, getAllSaleUsedVehiclesById } from '../../services/vehicles'
import { getAllProvinces, getAllMaritalStatus } from '../../services/globals'
import { getAllClientTypes } from '../../services/clients'
import AuthHelper from '../../helpers/AuthHelper'
import Helper from '../../helpers/Helper'
// import VehicleHelper from '../../helpers/VehicleHelper'

export default {
  name: 'CreateOrEditSale',
  components: {
    StepVehicle,
    StepPayment,
    StepClient,
    StepApplication,
    CreateOrEditSaleHeader
  },
  props: {
    userDb: Object,
    edit: Boolean,
    sales: Array,
    editedIndex: Number,
    typeSale: {
      type: String,
      default: '1'
    },
    saleSelected: Object,
    allModels: Array
  },
  data () {
    return {
      allUsedVehicles: [],
      valiadationsRules: {
        required: value => !!value || 'Dato requerido',
        isNotZero: value => parseInt(value) !== 0 || 'Ingrese valor distinto a 0',
        isNumber: value => Number.isInteger(value) || 'Ingrese un valor válido'
      },
      step: 0,
      formData: CreateSaleStructure,
      deleteFileModal: false,
      vehicle: null,
      payment_method: null,
      client_data: null,
      application: null,
      saleData: {
        sale: {
          operation_number: ''
        },
        vehicle: {
          vehicle_id: '',
          industrial_code: '',
          model: '',
          vin_code: '',
          year: '',
          fuel_type: '',
          doors: '',
          color: '',
          accesories: [],
          list_price: '',
          renault_bonus: '',
          subtotal: '',
          lepic_bonification: '',
          total: '',
          total_accesories: '',
          patenting: '',
          ldLgVu: '',
          lepic_bonification_management: '',
          credit: '',
          formsPPE: '',
          others: '',
          total_management: '',
          total_operation: ''
        },
        payment_method: {
          financed_detail: '',
          credit: '',
          dues: '',
          bank: '',
          plan: '',
          insurable_sum: '',
          aproximate_fee: '',
          coverage: '',
          company: '',
          used_vehicle: [
            {
              id: '',
              vehicle_model_id: '',
              vehicle_id: '',
              brand: '',
              model: '',
              car_year: '',
              fuel_type: '',
              doors: '',
              color: '',
              domain: '',
              transmition: '',
              estimated_price: ''
            }
          ],
          deposit: '',
          cash: '',
          financed: '',
          used_value: '',
          total_operation: ''
        },
        client_data: {
          client_id: '',
          client_dni: '',
          client_firstname: '',
          client_lastname: '',
          client_birth_date: '',
          client_marital_status: '',
          client_email: '',
          client_adress: '',
          client_adress_number: '',
          client_adress_floor: '',
          client_adress_department: '',
          client_adress_locality: '',
          client_adress_zip_code: '',
          client_phone: '',
          client_mobile: '',
          client_type: ''
        },
        application: {
          estimated_delivery_date: '',
          is_final_review_done: false,
          is_documents_attached: false,
          is_printed_and_signed_by_client: false,
          is_approval_request_sent: false,
          is_drive_test_offered: false,
          is_drive_test_done: false,
          observations: '',
          condominium_id: '',
          condominium_dni: '',
          condominium_firstname: '',
          condominium_lastname: '',
          condominium_birth_date: '',
          condominium_marital_status: '',
          condominium_email: '',
          condominium_adress: '',
          condominium_adress_number: '',
          condominium_adress_floor: '',
          condominium_adress_department: '',
          condominium_adress_locality: '',
          condominium_adress_zip_code: '',
          condominium_phone: '',
          condominium_mobile: '',
          condominium_type: '',
          related_documentation: {}
        }
      },
      validationProps: ['client_data', 'vehicle', 'payment_method'],
      fieldsToValidate: {
        client_data: [
          // { field: 'client_id', validations: ['hasData'] },
          { field: 'client_dni', validations: ['hasData'] },
          { field: 'client_firstname', validations: ['hasData'] },
          { field: 'client_lastname', validations: ['hasData'] },
          { field: 'client_email', validations: ['hasData'] },
          { field: 'client_birth_date', validations: ['hasData'] },
          { field: 'client_marital_status', validations: ['hasData'] },
          { field: 'client_adress', validations: ['hasData'] },
          { field: 'client_adress_number', validations: ['hasData'] },
          { field: 'client_adress_locality', validations: ['hasData'] },
          { field: 'client_adress_zip_code', validations: ['hasData'] },
          { field: 'client_phone', validations: ['hasData'] },
          { field: 'client_mobile', validations: ['hasData'] },
          { field: 'client_type', validations: ['hasData', 'isNotNull'] }
        ],
        vehicle: [
          // { field: 'industrial_code', validations: ['hasData'] },
          { field: 'model', validations: ['hasData'] },
          // { field: 'vin_code', validations: ['hasData'] },
          // { field: 'year', validations: ['hasData'] },
          { field: 'fuel_type', validations: ['hasData'] },
          { field: 'doors', validations: ['hasData'] },
          // revisar valicación en este select
          // { field: 'color', validations: ['hasData'] },
          { field: 'list_price', validations: ['hasData'] }
          // { field: 'renault_bonification', validations: ['hasData'] },
          // { field: 'lepic_bonification', validations: ['hasData'] },
          // { field: 'patenting', validations: this.typeSale === '1' ? ['hasData', 'isNotZero', 'isNotZeroString'] : [] }
          // { field: 'ldLgVu', validations: ['hasData', 'isNotZero', 'isNotZeroString'] }
          // { field: 'lepic_bonification_management', validations: ['hasData'] },
          // { field: 'credit', validations: ['hasData'] },
          // { field: 'formsPPE', validations: ['hasData'] },
          // { field: 'others', validations: ['hasData'] }

          // { field: 'list_price', validations: ['isNotZero'] },
          // { field: 'renault_bonification', validations: ['isNotZero'] },
          // { field: 'lepic_bonification', validations: ['isNotZero'] },
          // { field: 'ldLgVu', validations: ['isNotZero'] },
          // { field: 'lepic_bonification_management', validations: ['isNotZero'] },
          // { field: 'credit', validations: ['isNotZero'] },
          // { field: 'formsPPE', validations: ['isNotZero'] },
          // { field: 'others', validations: ['isNotZero'] }

          // { field: 'accesories', validations: ['hasData'] },
          // { field: 'subtotal', validations: ['isNotZero'] },
          // { field: 'total', validations: ['isNotZero'] },
          // { field: 'total_accesories', validations: ['isNotZero'] },
          // { field: 'total_management', validations: ['isNotZero'] },
          // { field: 'total_operation', validations: ['isNotZero'] }
        ],
        payment_method: [
          // { field: 'financed_detail', validations: ['hasData'] },
          // { field: 'credit', validations: ['hasData'] },
          // { field: 'dues', validations: ['hasData'] },
          // { field: 'bank', validations: ['hasData'] },
          // { field: 'plan', validations: ['hasData'] }
          // { field: 'insurable_sum', validations: ['hasData'] },
          // { field: 'aproximate_fee', validations: ['hasData'] },
          // { field: 'coverage_id', validations: ['hasData'] },
          // { field: 'company', validations: ['hasData'] },
          // { field: 'brand', validations: ['hasData'] },
          // { field: 'model', validations: ['hasData'] },
          // { field: 'domain', validations: ['hasData'] },
          // { field: 'year', validations: ['hasData'] },
          // { field: 'transmition', validations: ['hasData'] },
          // { field: 'fuel_type', validations: ['hasData'] },
          // { field: 'doors', validations: ['hasData'] },
          // { field: 'color', validations: ['hasData'] },
          // { field: 'deposit', validations: ['hasData'] }
          // { field: 'cash', validations: ['hasData'] },
          // { field: 'financed', validations: ['hasData'] }
          // { field: 'used_value', validations: ['hasData'] }
          // { field: 'total_operation', validations: ['hasData'] }
        ],
        application: []
      },
      saleDataPassValidation: false,
      allVehicles: [],
      alertMessage: '',
      alertColor: '',
      // hardcodeado borrar al tener datos de db
      clientTypeItems: [
        { id: 1, name: 'Persona Física' },
        { id: 2, name: 'Empresa' }
      ]
    }
  },
  methods: {
    ...mapActions(['handleCurrentStep', 'handleSetSaleDataByStep', 'handleLoading', 'handleAlert', 'handleCurrentSale']),
    async getProvinces () {
      try {
        this.handleLoading(true)
        const res = await getAllProvinces(this.userToken)
        this.provinces = res.data.data
        // this.provincesItems = Helper.transformToSelect(this.provinces, 'name')
        this.provincesItems = Helper.sortArrayByTextField(Helper.transformToSelect(this.provinces, 'name'))
        this.alertMessage = res.data.message
        this.alertColor = 'success'
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
        this.alertMessage = error?.data?.message ? error.data.message : error
        this.alertColor = 'error'
      } finally {
        this.handleLoading(false)
        this.handleAlert({
          value: true,
          text: this.alertMessage,
          color: this.alertColor
        })
      }
    },
    async getClientTypes () {
      try {
        this.handleLoading(true)
        const res = await getAllClientTypes(this.userToken)
        this.clientTypes = res.data.data
        this.clientTypesItems = Helper.transformToSelect(this.clientTypes, 'name')
        this.alertMessage = res.data.message
        this.alertColor = 'success'
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
        this.alertMessage = error?.data?.message ? error.data.message : error
        this.alertColor = 'error'
      } finally {
        this.handleLoading(false)
        this.handleAlert({
          value: true,
          text: this.alertMessage,
          color: this.alertColor
        })
      }
    },
    async getMaritalStatus () {
      try {
        this.handleLoading(true)
        const res = await getAllMaritalStatus(this.userToken)
        this.maritalStatus = res.data.data
        this.maritalStatusItems = Helper.transformToSelect(this.maritalStatus, 'name')
        this.alertMessage = res.data.message
        this.alertColor = 'success'
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
        this.alertMessage = error?.data?.message ? error.data.message : error
        this.alertColor = 'error'
      } finally {
        this.handleLoading(false)
        this.handleAlert({
          value: true,
          text: this.alertMessage,
          color: this.alertColor
        })
      }
    },
    async getVehicles () {
      try {
        this.handleLoading(true)
        const res = await getAllVehicles(this.userToken)
        this.allVehicles = res.data.data
        this.alertMessage = res.data.message
        this.alertColor = 'success'
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
        this.alertMessage = error?.data?.message ? error.data.message : error
        this.alertColor = 'error'
      } finally {
        this.handleLoading(false)
        this.handleAlert({
          value: true,
          text: this.alertMessage,
          color: this.alertColor
        })
      }
    },
    async getFuelsTypes () {
      try {
        this.handleLoading(true)
        const res = await getAllFuelsTypes(this.userToken)
        this.fuels = res.data.data
        this.alertMessage = res.data.message
        this.alertColor = 'success'
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
        this.alertMessage = error?.data?.message ? error.data.message : error
        this.alertColor = 'error'
      } finally {
        this.handleLoading(false)
        this.handleAlert({
          value: true,
          text: this.alertMessage,
          color: this.alertColor
        })
      }
    },
    // posiblemente no sea necesario (borrar)
    /* async getPatentTypes () {
      try {
        this.handleLoading(true)
        const res = await getAllPatentTypes(this.userToken)
        this.fuels = res.data.data
        this.alertMessage = res.data.message
        this.alertColor = 'success'
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
        this.alertMessage = error?.data?.message ? error.data.message : error
        this.alertColor = 'error'
      } finally {
        this.handleLoading(false)
        this.handleAlert({
          value: true,
          text: this.alertMessage,
          color: this.alertColor
        })
      }
    }, */
    async getTransmitions () {
      try {
        this.handleLoading(true)
        const res = await getAllTransmitions(this.userToken)
        this.transmitions = res.data.data
        this.alertMessage = res.data.message
        this.alertColor = 'success'
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
        this.alertMessage = error?.data?.message ? error.data.message : error
        this.alertColor = 'error'
      } finally {
        this.handleLoading(false)
        this.handleAlert({
          value: true,
          text: this.alertMessage,
          color: this.alertColor
        })
      }
    },
    async getSaleUsedVehiclesById (id) {
      try {
        this.handleLoading(true)
        const res = await getAllSaleUsedVehiclesById(this.userToken, id)
        this.allUsedVehicles = res.data.data
        await this.allUsedVehicles.forEach(uv => {
          this.addUsedVehicleEvaluationData(uv, uv.id)
        })
        this.alertMessage = res.data.message
        this.alertColor = 'success'
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
        this.alertMessage = error?.data?.message ? error.data.message : error
        console.log('error en getAllSaleUsedVehiclesById', error)
        this.alertColor = 'error'
      } finally {
        this.handleLoading(false)
        this.handleAlert({
          value: true,
          text: this.alertMessage,
          color: this.alertColor
        })
      }
    },
    addUsedVehicleEvaluationData (data, id) {
      const uv = Helper.getItemFromArray(id, this.saleData.payment_method.used_vehicle)
      /* const usedExpertises = []
      if (uv.expertises && uv.expertises.length > 1) {
        console.log('doing')
        uv.expertises.forEach(ex => {
          console.log('ex', ex)
          usedExpertises.push(
            {
              id: ex.id,
              updated_at: ex.updated_at,
              buying_value: ex.buying_value
            }
          )
        })
      } */
      if (this.edit) {
        uv.vehicle_id = data.vehicle.id
        uv.vehicle_status = data.vehicle.expertises[0].status
      }
      if (data.vehicle.expertises[0].status.id !== 1) {
        uv.buying_value = this.$options.filters.magnitude(Helper.transformTwoDecimalFloatToInt(data.vehicle.expertises[0].buying_value))
      } else {
        uv.buying_value = null
      }
    },
    setInitialState (editing, saleSelected) {
      this.step = this.getCurrentStep
      if (editing) {
        this.saleData.id = saleSelected.id
        this.saleData.sale = saleSelected.sale
        this.saleData.vehicle = saleSelected.vehicle
        this.saleData.vehicle.list_price = this.$options.filters.magnitude(this.saleData.vehicle.list_price)
        this.saleData.payment_method = saleSelected.payment_method
        this.saleData.client_data = saleSelected.client_data
        this.saleData.application = saleSelected.application
      } else {
        this.saleData.id = null
        this.saleData.sale = JSON.parse(JSON.stringify(initialStateConfig.sale))
        this.saleData.sale.sale_status = { name: '', color: '' }
        this.saleData.vehicle = JSON.parse(JSON.stringify(initialStateConfig.vehicle))
        this.saleData.payment_method = JSON.parse(JSON.stringify(initialStateConfig.payment_method))
        this.saleData.client_data = JSON.parse(JSON.stringify(initialStateConfig.client_data))
        this.saleData.application = JSON.parse(JSON.stringify(initialStateConfig.application))
      }
    },
    saveNewSale (currentStep, payload) {
      this.$emit('saveNewSale', currentStep, payload)
    },
    changeStep (newStep) {
      const stepName = this.getStepName(this.step)
      if (this.stepValidation(this.getCurrentStep, this.saleData[stepName], this.fieldsToValidate[stepName])) {
        this.handleSetSaleDataByStep(this.saleData[stepName])
        this.handleCurrentStep(newStep)
      } else {
        this.handleAlert({
          value: true,
          text: 'Error: Falta completar campos del formulario.',
          color: 'error'
        })
      }
    },
    stepValidation (step, data, fieldsToValidate) {
      // descomentar solo el comentario de bloque para activar validación de datos
      const valid = []
      // falta agregar mensajes de error al array al generarse
      // y resolver como enviar errores al alert
      const errorMessages = []
      // const fieldsData = []
      if (step === 1) {
        this.$refs.saleStepClient.saleStepClientValidate()
      } if (step === 2) {
        valid.push(this.validateVehicleTotalOperation())
        if (!this.validateVehicleTotalOperation()) { errorMessages.push('El total de la operación no es correcto') }
        this.$refs.saleStepVehicle.saleStepVehicleValidate()
      } if (step === 3) {
        this.$refs.saleStepPayment.saleStepPaymentValidate()
      } /* if (step === 4) {
        this.$refs.saleStepApplication.saleStepApplicationValidate()
      } */
      fieldsToValidate.forEach(f => {
        f.validations.forEach(v => {
          if (v !== 'isNotUndefined') {
            valid.push(this.checkFieldDataCondition(f.field, data[f.field], v))
            // fieldsData.push(f.field + ' ' + data[f.field] + ' ' + v + ' ' + valid)
          } else if (v === 'isNotUndefined') {
            valid.push(this.saleData.sale.operation_number !== '')
          }
        })
      })
      return !fieldsToValidate.some(f => f.validations.some(v => !this.checkFieldDataCondition(f.field, data[f.field], v)))
    },
    validateVehicleTotalOperation () {
      return this.saleData.vehicle.total_operation === this.saleData.vehicle.total + this.saleData.vehicle.total_accesories + this.saleData.vehicle.total_management
    },
    checkFieldDataCondition (field, fieldValue, condition) {
      const conditions = {
        hasData: '',
        isNotZero: 0,
        isNotZeroString: '0',
        isNotNull: null
      }
      return fieldValue !== undefined && fieldValue !== conditions[condition]
    },
    exitForm () {
      this.$emit('stopEditing')
    },
    saveSaleData (currentStep, payload) {
      this.$emit('saveSaleData', currentStep, payload)
    },
    editSale (currentStep, payload) {
      this.$emit('editSale', currentStep, payload)
    },
    handleStepData (value) {
      this.stepData = value
    },
    getStepName (currentStep) {
      const stepName = {
        1: 'client_data',
        2: 'vehicle',
        3: 'payment_method',
        4: 'application'
      }
      return stepName[currentStep]
    },
    async saveSaleApplication () {
      this.changeStep(4)
      const dataSale = this.transformForSubmitSale(this.saleData)
      // refactorizar
      dataSale.sale.operation_number = this.saleData.sale.operation_number !== '' ? this.saleData.sale.operation_number : null
      console.log('sale lista para update', dataSale)
      const formData = new FormData()
      formData.append('id', JSON.stringify(dataSale.id))
      formData.append('vehicle', JSON.stringify(dataSale.vehicle))
      formData.append('payment_method', JSON.stringify(dataSale.payment_method))
      formData.append('client_data', JSON.stringify(dataSale.client_data))
      formData.append('sale', JSON.stringify(dataSale.sale))
      formData.append('application[application_details][id]', dataSale.application.application_details.id)
      formData.append('application[application_details][estimated_delivery_date]', dataSale.application.application_details.estimated_delivery_date)
      formData.append('application[application_details][is_final_review_done]', dataSale.application.application_details.is_final_review_done)
      formData.append('application[application_details][is_documents_attached]', dataSale.application.application_details.is_documents_attached)
      formData.append('application[application_details][is_printed_and_signed_by_client]', dataSale.application.application_details.is_printed_and_signed_by_client)
      formData.append('application[application_details][is_approval_request_sent]', dataSale.application.application_details.is_approval_request_sent)
      formData.append('application[application_details][is_drive_test_offered]', dataSale.application.application_details.is_drive_test_offered)
      formData.append('application[application_details][is_drive_test_done]', dataSale.application.application_details.is_drive_test_done)
      formData.append('application[application_details][observations]', dataSale.application.application_details.observations)
      formData.append('application[application_details][vehicle_id]', dataSale.application.application_details.vehicle_id)
      formData.append('application[application_details][vehicle_model_id]', dataSale.application.application_details.vehicle_model_id)
      formData.append('application[application_details][vehicle_color_id]', dataSale.application.application_details.vehicle_color_id)
      if (dataSale.application.related_documentation) {
        dataSale.application.related_documentation.forEach((f, index) => {
          formData.append('application[related_documentation][' + index + '][file]', f.file)
          formData.append('application[related_documentation][' + index + '][name]', f.name)
          formData.append('application[related_documentation][' + index + '][reserv_attachment_type_id]', f.type)
          formData.append('application[related_documentation][' + index + '][reserv_attachment_subtype_id]', f.subtype)
          if (f.id) { formData.append('application[related_documentation][' + index + '][id]', f.id) }
        })
      }
      if (this.edit) {
        this.$emit('updateThisSale', formData, dataSale.id)
      } else {
        this.$emit('createThisSale', formData)
      /*
      try {
        this.handleLoading(true)
        const res = await createSale(this.userToken, formData)
        this.$emit('saveSaleApplication', res.data.data, this.editedIndex)
        this.alertMessage = res.data.message
        this.alertColor = 'success'
        this.stopEditing()
        this.close()
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
        this.alertMessage = error?.data?.message ? error.data.message : error
        this.alertColor = 'error'
      } finally {
        this.handleLoading(false)
        this.handleAlert({
          value: true,
          text: this.alertMessage,
          color: this.alertColor
        })
      } */
      }
      // this.$emit('saveSaleApplication', sale, this.editedIndex)
    },
    transformForSubmitSale (sale) {
      const usedVehiclesArray = []
      function getUsedVehicles () {
        if (sale.payment_method.used_vehicle.length === 1 && sale.payment_method.used_vehicle[0].domain === '') {
          return null
        } else {
          sale.payment_method.used_vehicle.forEach(uv => {
            usedVehiclesArray.push({
              id: uv.id !== null &&
                  uv.id !== '' &&
                  !isNaN(uv.id)
                ? Number(uv.id)
                : null,
              vehicle_id: uv.vehicle_id !== null &&
                  uv.vehicle_id !== '' &&
                  !isNaN(uv.vehicle_id)
                ? Number(uv.vehicle_id)
                : null,
              vehicle_model_id: Number(uv.vehicle_model_id),
              domain: uv.domain,
              transmition_id: Number(uv.transmition),
              vehicles_colors_id: Number(uv.color),
              year: uv.car_year,
              estimated_price: Number(uv.estimated_price)
            })
          })
          return usedVehiclesArray
        }
      }
      let condominium = {}
      const client = {}
      const insuranceData = {}
      const vehicleReservation = {}
      const vehicleId = {}
      if (sale.client_data.client_type.value === 1) {
        condominium.condominium_id = sale.application.condominium_id !== '' ? sale.application.condominium_id : null
        condominium.condominium_firstname = sale.application.condominium_firstname !== '' ? sale.application.condominium_firstname : null
        condominium.condominium_lastname = sale.application.condominium_lastname !== '' ? sale.application.condominium_lastname : null
        condominium.condominium_dni = sale.application.condominium_dni !== '' ? sale.application.condominium_dni : null
        condominium.condominium_email = sale.application.condominium_email !== '' ? sale.application.condominium_email : null
        condominium.condominium_birth_date = sale.application.condominium_birth_date !== '' ? sale.application.condominium_birth_date : null
        condominium.condominium_marital_status = (sale.application?.condominium_marital_status !== '' && sale.application?.condominium_marital_status !== null) ? sale.application.condominium_marital_status.value : null
        condominium.condominium_adress = sale.application.condominium_adress !== '' ? sale.application.condominium_adress : null
        condominium.condominium_adress_number = sale.application.condominium_adress_number !== '' ? sale.application.condominium_adress_number : null
        condominium.condominium_adress_floor = sale.application.condominium_adress_floor !== '' ? sale.application.condominium_adress_floor : null
        condominium.condominium_adress_department = sale.application.condominium_adress_department !== '' ? sale.application.condominium_adress_department : null
        condominium.condominium_adress_locality = (sale.application.condominium_adress_locality !== '' && sale.application.condominium_adress_locality !== null) ? sale.application.condominium_adress_locality?.value : null
        condominium.condominium_locality = (sale.application.condominium_adress_locality !== '' && sale.application.condominium_adress_locality !== null) ? sale.application.condominium_adress_locality.value : null
        condominium.condominium_adress_zip_code = sale.application.condominium_adress_zip_code !== '' ? sale.application.condominium_adress_zip_code : null
        condominium.condominium_phone = sale.application.condominium_phone !== '' ? sale.application.condominium_phone : null
        condominium.condominium_mobile = sale.application.condominium_mobile !== '' ? sale.application.condominium_mobile : null
        condominium.condominium_type = sale.application.condominium_type !== '' ? sale.application.condominium_type : null
        if (
          condominium.condominium_id === null &&
          condominium.condominium_firstname === null &&
          condominium.condominium_lastname === null &&
          condominium.condominium_dni === null &&
          condominium.condominium_email === null &&
          condominium.condominium_birth_date === null &&
          condominium.condominium_marital_status === null &&
          condominium.condominium_adress === null &&
          condominium.condominium_adress_number === null &&
          condominium.condominium_adress_floor === null &&
          condominium.condominium_adress_department === null &&
          condominium.condominium_adress_locality === null &&
          condominium.condominium_locality === null &&
          condominium.condominium_adress_zip_code === null &&
          condominium.condominium_phone === null &&
          condominium.condominium_mobile === null &&
          condominium.condominium_type === null) {
          condominium = {}
        }
      } else {
        client.trade_name = sale.client_data.client_trade_name
        client.cuit = sale.client_data.client_cuit
      }
      // revisar si es mejor hacer otro condicional para más seguridad
      if (sale.payment_method.insurable_sum !== undefined && sale.payment_method.insurable_sum !== '') {
        insuranceData.id = parseInt(sale.payment_method.insurance_id)
        insuranceData.insurable_sum = parseFloat(this.$options.filters.number(sale.payment_method.insurable_sum))
        insuranceData.estimated_fee = parseFloat(this.$options.filters.number(sale.payment_method.aproximate_fee))
        insuranceData.coverage_id = (sale.payment_method.coverage_id !== 0 && sale.payment_method.coverage_id !== '') ? parseInt(sale.payment_method.coverage_id) : null
        insuranceData.company = sale.payment_method.company
      }
      // revisar si es mejor hacer otro condicional para más seguridad
      if (sale.vehicle.industrial_code !== 0 && sale.vehicle.industrial_code !== '') {
        vehicleReservation.id = sale.application.vehicle_id
        vehicleReservation.industrial_code = sale.vehicle.industrial_code
        vehicleReservation.vin_code = sale.vehicle.vin_code
        vehicleReservation.year = sale.vehicle.year
        vehicleReservation.domain = sale.vehicle.domain
        vehicleId.id = sale.vehicle.vehicle_id
      }
      if (sale.vehicle.color === undefined) {
        sale.vehicle.color = null
        console.log('Definir color')
      }

      const data = {
        vehicle: {
          accesories: this.transformVehicleAccesoriesForFormData(sale.vehicle.accesories),
          billing_data: {
            id: sale.payment_method.billing_data_id,
            list_price: parseFloat(this.$options.filters.number(sale.vehicle.list_price)),
            renault_bonification: parseFloat(this.$options.filters.number(sale.vehicle.renault_bonus)),
            subtotal: parseFloat(this.$options.filters.number(sale.vehicle.subtotal)),
            lepic_discount: parseFloat(this.$options.filters.number(sale.vehicle.lepic_bonification)),
            total: parseFloat(this.$options.filters.number(sale.vehicle.total)),
            accesories: parseFloat(this.$options.filters.number(sale.vehicle.total_accesories))
          },
          management: {
            id: sale.payment_method.sale_management_id,
            type_patenting: parseInt(sale.vehicle.patentType),
            patenting: parseFloat(this.$options.filters.number(sale.vehicle.patenting)),
            ld_lg_vu: parseFloat(this.$options.filters.number(sale.vehicle.ldLgVu)),
            sealed: 0,
            credit_management: parseFloat(this.$options.filters.number(sale.vehicle.credit)),
            form_ppe: parseFloat(this.$options.filters.number(sale.vehicle.formsPPE)),
            others: parseFloat(this.$options.filters.number(sale.vehicle.others)),
            lepic_discount: parseFloat(this.$options.filters.number(sale.vehicle.lepic_bonification_management)),
            total_management: parseFloat(this.$options.filters.number(sale.vehicle.total_management)),
            total_operation: parseFloat(this.$options.filters.number(sale.vehicle.total_operation))
          },
          vehicle_reservation: Object.keys(vehicleReservation).length === 0 ? null : vehicleReservation
        },
        payment_method: {
          payment_method_details: {
            id: !isNaN(sale.payment_method.payment_method_details_id) ? parseInt(sale.payment_method.payment_method_details_id) : null,
            financed: parseInt(this.$options.filters.number(sale.payment_method.financed_detail)),
            credit: parseInt(this.$options.filters.number(sale.payment_method.credit)),
            fees: parseInt(this.$options.filters.number(sale.payment_method.dues)),
            bank: sale.payment_method.bank,
            plan: sale.payment_method.plan
          },
          insurance: Object.keys(insuranceData).length === 0 ? null : insuranceData,
          used_vehicle: getUsedVehicles(),
          sale_payment_method: {
            id: !isNaN(sale.payment_method.sale_payment_method_id) ? parseInt(sale.payment_method.sale_payment_method_id) : null,
            sign: sale.payment_method.deposit !== 0 ? parseInt(this.$options.filters.number(sale.payment_method.deposit)) : 0,
            cash: parseInt(this.$options.filters.number(sale.payment_method.cash)),
            financed: parseInt(this.$options.filters.number(sale.payment_method.financed)),
            total_vehicles_used: parseInt(this.$options.filters.number(sale.payment_method.used_value)),
            total: parseInt(this.$options.filters.number(sale.payment_method.total_operation))
          }
        },
        client_data: {
          client_details: {
            client_id: sale.client_data.client_id,
            client_dni: sale.client_data.client_dni,
            client_firstname: sale.client_data.client_firstname,
            client_lastname: sale.client_data.client_lastname,
            client_birth_date: sale.client_data.client_birth_date,
            client_marital_status: sale.client_data.client_marital_status.value,
            client_email: sale.client_data.client_email,
            client_adress: sale.client_data.client_adress,
            client_adress_number: sale.client_data.client_adress_number,
            client_adress_floor: sale.client_data.client_adress_floor,
            client_adress_department: sale.client_data.client_adress_department,
            client_adress_locality: sale.client_data.client_adress_locality.value,
            client_adress_zip_code: sale.client_data.client_adress_zip_code,
            client_phone: sale.client_data.client_phone,
            client_mobile: sale.client_data.client_mobile,
            client_type: sale.client_data.client_type.value,
            client_trade_name: Object.keys(client).length === 0 ? null : client.trade_name,
            client_cuit: Object.keys(client).length === 0 ? null : client.cuit
          },
          condominium_data: Object.keys(condominium).length === 0 ? null : condominium
        },
        application: {
          application_details: {
            id: sale.application.id,
            vehicle_id: Object.keys(vehicleId).length === 0 ? null : vehicleId.id,
            vehicle_model_id: this.typeSale === '1' ? sale.vehicle.model : sale.vehicle.vehicle_model_id,
            vehicle_color_id: sale.vehicle.color,
            // vehicle_color_id: sale.vehicle.color.value ? sale.vehicle.color.value : sale.vehicle.color,
            estimated_delivery_date: sale.application.estimated_delivery_date,
            is_final_review_done: sale.application.is_final_review_done,
            is_documents_attached: sale.application.is_documents_attached,
            is_printed_and_signed_by_client: sale.application.is_printed_and_signed_by_client,
            is_approval_request_sent: sale.application.is_approval_request_sent,
            is_drive_test_offered: sale.application.is_drive_test_offered,
            is_drive_test_done: sale.application.is_drive_test_done,
            observations: sale.application.observations
          },
          related_documentation: Object.keys(this.saleFiles).length === 0 ? null : this.saleFiles
        },
        sale: {
          sale_type_id: parseInt(this.typeSale),
          operation_number: parseInt(sale.sale.operation_number)
        },
        id: sale.id
      }
      return data
    },
    transformVehicleAccesoriesForFormData (accesories) {
      const accesoriesForFormdata = []
      if (accesories.length > 0) {
        accesories.forEach(a => {
          accesoriesForFormdata.push({
            price: a.accesory.value,
            vehicle_accesorie_id: a.accesory.id,
            quantity: a.cant
          })
        })
        return accesoriesForFormdata
      } else {
        return null
      }
    },
    close () {
      this.$emit('close')
    },
    openDeleteFileModal () {
      this.deleteFileModal = true
    },
    stopEditing () {
      this.$emit('stopEditing')
    },
    validateData (saleData) {
      let passValidation = true
      Object.keys(saleData).forEach((saleDataField) => {
        if (this.validationProps.includes(saleDataField)) {
          const obj = saleData[saleDataField]
          if (Object.values(obj).some(value => Helper.isEmpty(value))) { passValidation = false }
        }
      })
      return passValidation
    }
  },
  computed: {
    ...mapState(['userToken']),
    ...mapGetters(['getCurrentStep', 'newSale', 'currentSale', 'saleFiles']),
    newSaleapplication_number () {
      return this.sales.reduce((acc, sale) => (sale.id > acc) ? sale.id : acc, 0) + 1
    }
  },
  async mounted () {
    await this.handleCurrentSale(this.editedIndex)
    await this.getVehicles()
    await this.getFuelsTypes()
    await this.getClientTypes()
    await this.getTransmitions()
    await this.getProvinces()
    await this.getMaritalStatus()
    this.setInitialState(this.edit, this.saleSelected)
    if (this.edit) {
      await this.getSaleUsedVehiclesById(this.saleData.id)
    }
  },
  watch: {
    saleSelected: {
      handler () {
      }
    },
    getCurrentStep: function () {
      this.step = this.getCurrentStep
      if (this.step === 4) {
        this.saleDataPassValidation = this.validateData(this.saleData)
      }
    },
    'vehicle.billing_data': {
      deep: true,
      handler () {
        this.payment_method.billing_data = this.vehicle.billing_data
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .v-stepper__label:hover {
    cursor: pointer;
  }
  .v-stepper__header {
    box-shadow: none;
    border-bottom: 1px solid #eeeeee;
}
}
</style>
