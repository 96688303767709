<template>
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.1937 3.33283H15.3604V1.66617H13.6937V3.33283H7.02702V1.66617H5.36035V3.33283H4.52702C3.60202 3.33283 2.86868 4.08283 2.86868 4.9995L2.86035 16.6662C2.86035 17.5828 3.60202 18.3328 4.52702 18.3328H16.1937C17.1104 18.3328 17.8604 17.5828 17.8604 16.6662V4.9995C17.8604 4.08283 17.1104 3.33283 16.1937 3.33283ZM16.1937 16.6662H4.52702V8.33284H16.1937V16.6662ZM16.1937 6.66617H4.52702V4.9995H16.1937V6.66617ZM7.86035 11.6662H6.19368V9.9995H7.86035V11.6662ZM11.1937 11.6662H9.52702V9.9995H11.1937V11.6662ZM14.527 11.6662H12.8604V9.9995H14.527V11.6662ZM7.86035 14.9995H6.19368V13.3328H7.86035V14.9995ZM11.1937 14.9995H9.52702V13.3328H11.1937V14.9995ZM14.527 14.9995H12.8604V13.3328H14.527V14.9995Z" fill="#9D9D9D"/>
  </svg>
</template>

<script>
export default {
  name: 'Calendar'
}
</script>
