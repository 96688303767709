<template>
  <v-card-text
    class="pa-0 d-flex flex-column"
    style="height: 90%;min-height: 600px;"
  >
    <p
      style="text-align: left;font-weight: bold;font-size: 24px;letter-spacing: 0.01em;color: #000000;line-height: 34px;"
      class="mt-2"
    >
      {{ questionData.question }}
    </p>
    <transition name="ques">
      <Questions
        ref="sq16q0"
        @goToNextQuestion="goToNextQuestion"
        :dataSeted="dataSeted"
        v-show="currentQues === 0"
        :btnWidth="160"
        :question="questionData.questions[0]"
        :screens_question="null"
        :sq_aclaration="questionData.aclaration"
        @selectOption="rememberDealerName"
      ></Questions>
    </transition>
    <transition name="ques">
      <Questions
        ref="sq16q1"
        @goToNextQuestion="goToNextQuestion"
        :isCurrency="false"
        :dataSeted="dataSeted"
        v-show="currentQues === 1"
        :question="questionData.questions[1]"
        :screens_question="null"
        :sq_aclaration="null"
        @sendAnswer="confirmDealerName"
        @changeDataSeted="changeDataSeted"
      ></Questions>
    </transition>
    <transition name="ques">
      <Questions
        ref="sq16q2"
        @goToNextQuestion="goToNextQuestion"
        :dataSeted="dataSeted"
        v-show="currentQues === 2"
        :btnWidth="500"
        :question="questionData.questions[2]"
        :screens_question="null"
        :sq_aclaration="null"
        @selectOption="contractWithLepic"
      ></Questions>
    </transition>
    <transition name="ques">
      <NextQuestionButton
        v-if="showButton"
        @goToNextQuestion="goToNextQuestion"
      ></NextQuestionButton>
    </transition>
  </v-card-text>
</template>

<script>
import Questions from '@/components/savings/blocks/Questions'
import NextQuestionButton from '@/components/savings/blocks/NextQuestionButton'
import { mapGetters } from 'vuex'
import { answerData } from '@/mixins/answerData'

export default {
  name: 'SQuesSixteen',
  components: {
    Questions,
    NextQuestionButton
  },
  props: {
    questionData: Object
  },
  data () {
    return {
      showButton: false,
      alwaysShowButton: false,
      currentQues: 0,
      dataSeted: false,
      question0: '',
      question1: '',
      decisionQuestionFirstValue: '',
      historyFirstValue: [],
      historyScrap: [],
      deleteData: [],
      progress: 80,
      sq_data: [
        {
          question_id: this.questionData.questions[0].id,
          ponderation: '',
          updated_at: ''
        },
        {
          question_id: this.questionData.questions[1].id,
          ponderation: '',
          updated_at: ''
        },
        {
          question_id: this.questionData.questions[2].id,
          ponderation: '',
          updated_at: ''
        }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'getOperationNumber',
      'scoringData',
      'isBrowsingInScoringHistory',
      'continueScoring',
      'getIndexOfCurrentSQ',
      'getHistory',
      'SQNameByNumber',
      'sq_number'
    ])
  },
  methods: {
    setData (sqdata) {
      if (this.scoringData[sqdata] !== null) {
        this.dataSeted = true
        this.showButton = true
        this.alwaysShowButton = true
        this.$emit('handleProgress', 79)
        this.$refs.sq16q0.setAnswer(this.scoringData[sqdata][0].ponderation)
        this.$refs.sq16q1.setAnswer(this.scoringData[sqdata][1].ponderation)
        this.$refs.sq16q2.setAnswer(this.scoringData[sqdata][2].ponderation)
        for (let i = 0; i < 3; i++) {
          this.sq_data[i].ponderation = this.scoringData[sqdata][i].ponderation
          this.sq_data[i].updated_at = this.scoringData[sqdata][i].updated_at
          if (this.continueScoring) {
            if (this.scoringData[sqdata][0].id !== undefined) {
              this.sq_data[i].id = this.scoringData[sqdata][i].id
            }
          }
        }
        if (this.isBrowsingInScoringHistory) {
          this.question0 = this.scoringData[sqdata][0].ponderation
          this.question1 = this.scoringData[sqdata][1].ponderation
          this.decisionQuestionFirstValue = this.scoringData[
            sqdata
          ][2].ponderation
          this.historyFirstValue = this.getHistory
        }
      }
    },
    rememberDealerName (value) {
      this.dataFromQuestions(value, 0)
    },
    confirmDealerName (value) {
      this.dataFromQuestions(value, 1)
    },
    contractWithLepic (value) {
      this.dataFromQuestions(value, 2)
    },
    goToNextQuestion () {
      this.handleIfEditQues(0, this.question0)
      this.handleIfEditQues(1, this.question1)
      if (this.isBrowsingInScoringHistory && this.currentQues === 2) {
        this.$emit('reduceDecreasing', 1)
        if (this.decisionQuestionFirstValue !== this.sq_data[2].ponderation) {
          this.handleIfEditDecisionQues(2, this.decisionQuestionFirstValue)
          this.$emit('reduceDecreasing', 0)
          this.historyScrap = this.historyFirstValue.splice(
            this.getIndexOfCurrentSQ + 1
          )
          this.historyScrap.forEach(e => {
            if (e !== 2 && e !== 32 && e !== 41) {
              if (this.scoringData[this.SQNameByNumber[e]] !== null) {
                if (
                  this.scoringData[this.SQNameByNumber[e]][0].id === undefined
                ) {
                  this.$store.dispatch('handleResetScoringData', e)
                } else {
                  this.scoringData[this.SQNameByNumber[e]].forEach(q => {
                    this.deleteData.push(q.id)
                  })
                  this.$store.dispatch('handleResetScoringData', e)
                }
              }
            }
          })
          this.$emit('deleteQuestion', this.deleteData)
          this.$store.dispatch('handleHistoryData', this.historyFirstValue)
          this.$store.dispatch('handleIfIsBrowsingInScoringHistory', false)
          this.$store.dispatch('handleHistoryIndexOfCurrentSQ', null)
        }
      }
      if (this.currentQues < 2) {
        this.currentQues += 1
        this.$emit('handleProgress', this.progress)
        this.progress++
        if (
          this.sq_data[0].ponderation ===
          this.questionData.questions[0].options[1]
        ) {
          this.$emit('handleProgress', 83)
          if (this.isBrowsingInScoringHistory) {
            this.$store.dispatch('handleOnlyCurrentSQ', 17)
          } else {
            this.$store.dispatch('handleCurrentSQ', 17)
          }
          this.$emit('checkIfHistoryModeContinues')
        }
        if (this.alwaysShowButton) {
          this.showButton = true
        } else {
          this.showButton = false
        }
      } else if (this.currentQues === 2) {
        this.$store.dispatch('handleScoringData', this.sq_data)
        if (
          this.sq_data[2].ponderation ===
          this.questionData.questions[2].options[0]
        ) {
          this.$emit('handleProgress', 83)
          if (this.isBrowsingInScoringHistory) {
            this.$store.dispatch('handleOnlyCurrentSQ', 17)
          } else {
            this.$store.dispatch('handleCurrentSQ', 17)
          }
        } else {
          this.$emit('handleProgress', 84)
          if (this.isBrowsingInScoringHistory) {
            this.$store.dispatch('handleOnlyCurrentSQ', 35)
          } else {
            this.$store.dispatch('handleCurrentSQ', 35)
          }
        }
        this.$emit('checkIfHistoryModeContinues')
      }
    }
  },
  mixins: [answerData],
  mounted () {
    this.setData('sq_sixteenData')
  }
}
</script>

<style lang="scss" scoped>
.ques-enter-active {
  transition: opacity 0.5s;
}
.ques-enter {
  opacity: 0;
}
</style>
