<template>
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.8027 5C6.8627 5.89 7.0127 6.76 7.2527 7.59L6.0527 8.79C5.6427 7.59 5.3827 6.32 5.2927 5H6.8027ZM16.6627 17.02C17.5127 17.26 18.3827 17.41 19.2627 17.47V18.96C17.9427 18.87 16.6727 18.61 15.4627 18.21L16.6627 17.02ZM7.7627 3H4.2627C3.7127 3 3.2627 3.45 3.2627 4C3.2627 13.39 10.8727 21 20.2627 21C20.8127 21 21.2627 20.55 21.2627 20V16.51C21.2627 15.96 20.8127 15.51 20.2627 15.51C19.0227 15.51 17.8127 15.31 16.6927 14.94C16.5927 14.9 16.4827 14.89 16.3827 14.89C16.1227 14.89 15.8727 14.99 15.6727 15.18L13.4727 17.38C10.6427 15.93 8.3227 13.62 6.8827 10.79L9.0827 8.59C9.3627 8.31 9.4427 7.92 9.3327 7.57C8.9627 6.45 8.7627 5.25 8.7627 4C8.7627 3.45 8.3127 3 7.7627 3Z" fill="#9D9D9D"/>
  </svg>
</template>

<script>
export default {
  name: 'Phone'
}
</script>
