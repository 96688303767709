import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

export const userFirstRoute = {
  1: 'Users',
  2: 'Sales',
  3: 'Savings',
  4: 'Evaluations',
  5: 'Vehicles',
  6: 'Accessories'
}

const routes = [
  {
    path: '/login',
    name: 'Login',
    meta: {
      allowAllUsers: true
    },
    component: () => import('../views/Login.vue')
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import('../views/Users.vue')
  },
  {
    path: '/sales',
    name: 'Sales',
    component: () => import('../views/Sales.vue')
  },
  {
    path: '/evaluations',
    name: 'Evaluations',
    component: () => import('../views/Evaluations.vue')
  },
  {
    path: '/savings',
    name: 'Savings',
    component: () => import('../views/Savings.vue')
  },
  {
    path: '/accessories',
    name: 'Accessories',
    component: () => import('../views/Accessories.vue')
  },
  {
    path: '/vehicles',
    name: 'Vehicles',
    component: () => import('../views/Vehicles.vue')
  },
  {
    path: '/catalog',
    name: 'Catalog',
    component: () => import('../views/Catalog.vue')
  }
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

function isLoggedIn () {
  const authToken = localStorage.getItem('userInfoToken')
  const parseToken = JSON.parse(authToken)
  return !!parseToken?.token
}

router.beforeEach((to, from, next) => {
  const userData = localStorage.getItem('userInfoToken')
  const parsedData = JSON.parse(userData)
  if ((to.path === '/' || to.name === 'Login') && isLoggedIn()) {
    next({ name: userFirstRoute[parsedData.modules[0].id] })
  } else if (!to.meta.allowAllUsers && !isLoggedIn()) {
    next({ name: 'Login' })
  } else {
    next()
  }
})

export default router
