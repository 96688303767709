export default class Helper {
  static searchItemByField (item, field, searchParam, searchIdInsideField) {
    if (searchParam === '') { return true } else {
      if (searchIdInsideField) {
        return Helper.searchItemByAdvanceField(item, field, searchParam)
      }
      return (item[field] !== undefined) ? item[field].toString().toLowerCase().includes(searchParam.toString().toLowerCase()) : false
    }
  }

  static searchItemByAdvanceField (item, field, searchParam) {
    if (searchParam === '') { return true }
    return (parseInt(item[field].id) === parseInt(searchParam))
  }

  static searchItemByFieldInsideArray (item, field, searchParam, searchIdInsideField) {
    if (searchParam === '' || item[field] === undefined) { return true }
    const fieldToCompare = (searchIdInsideField) ? 'id' : ''

    return item[field].some(i => (searchIdInsideField) ? i[fieldToCompare] === parseInt(searchParam) : i[fieldToCompare] === searchParam)
  }

  static getItemFromArray (id, array) {
    const index = array.findIndex(v => v.id === id)
    if (index !== -1) {
      return array[index]
    }
    return null
  }

  static getArrayValueFromId (id, array) {
    const index = array.map(v => v.id).indexOf(id)
    return array[index]
  }

  static getArrayItemFromValue (val, array) {
    const index = array.findIndex(v => v.value === val)
    if (index !== -1) {
      return array[index]
    }
    return null
  }

  static getArrayIndexFromId (id, array) {
    return array.findIndex(v => v.id === id)
  }

  static sortArrayByTextField (arr) {
    const sorted = arr.sort(function (a, b) {
      if (a.text > b.text) {
        return 1
      }
      if (a.text < b.text) {
        return -1
      }
      return 0
    })
    return sorted
  }

  static transformToSelect (array, fieldName, initItem) {
    const arrayItems = []
    if (initItem) {
      arrayItems.push(initItem)
    }
    array.forEach(data => {
      let text = ''
      if (typeof fieldName === 'string') {
        text = data[fieldName]
      } if (typeof fieldName === 'object') {
        fieldName.forEach(f => {
          text += data[f] + ' '
        })
      }
      arrayItems.push({
        value: data.id,
        text: text
      })
    })
    return arrayItems
  }

  static transformTwoDecimalFloatToInt (float) {
    const fString = typeof float !== 'string' ? JSON.stringify(float) : float
    if (fString.substring(fString.length - 3, fString.length) === '.00') {
      return parseInt(fString.slice(0, -3).split('.').join(''))
    }
    return fString.split('.').join('')
  }

  static isEmpty (value) {
    return value === undefined || value === null || value === ''
  }

  static setDateAndTime () {
    const now = new Date()
    const date = now.getFullYear() + '-' + (now.getMonth() + 1) + '-' + now.getDate() + ' ' + now.getHours() + ':' + now.getMinutes() + ':' + now.getSeconds()
    let formated = new Date(date).toISOString().substr(0, 19)
    formated = [...formated]
    const dateFormated = []
    formated.forEach(s => {
      if (s === 'T') {
        dateFormated.push(' ')
      } else {
        dateFormated.push(s)
      }
    })
    const dateFormatedToString = dateFormated.join('')
    return dateFormatedToString
  }
}
