<template>
  <form
    @submit.prevent="submit()"
    class="d-flex flex-column"
    novalidate
    autocomplete="off"
  >
    <v-text-field
      v-model="form.email"
      placeholder="Usuario"
      :error-messages="emailErrors"
      required
      class="font-weight-medium"
    ></v-text-field>

    <v-text-field
      v-model="form.password"
      placeholder="Contraseña"
      :error-messages="passwordErrors"
      required
      class="font-weight-medium"
      :type="showPassword ? 'text' : 'password'"
      @click:append="showPassword = !showPassword"
    ></v-text-field>

    <v-btn
      type="submit"
      large
      block
      dark
      class="rounded-lg body-1 font-weight-bold"
      color="secundary"
    >
      Ingresar
    </v-btn>
  </form>
</template>

<script>
import { mapActions } from 'vuex'
import { userLogin } from '@/services/login'
import { required, email } from 'vuelidate/lib/validators'
import { userFirstRoute } from '../../router/index'

export default {
  name: 'LoginForm',

  props: {
    validationsProps: {
      type: Object,
      default: () => ({
        form: {
          email: {
            required,
            email
          },
          password: { required }
        }
      })
    }
  },

  data: () => ({
    showPassword: false,
    eye: '',
    eye_off: '',
    alertMessage: '',
    alertColor: '',
    form: {
      email: '',
      password: ''
    },
    moduleId: null
  }),

  validations () {
    return this.validationsProps
  },

  computed: {
    emailErrors () {
      const errors = []
      if (!this.$v.form.email.$dirty) return errors
      !this.$v.form.email.email && errors.push('Ingrese un Email válido')
      !this.$v.form.email.required && errors.push('Por favor completa este campo')
      return errors
    },

    passwordErrors () {
      const errors = []
      if (!this.$v.form.password.$dirty) return errors
      !this.$v.form.password.required && errors.push('Por favor completa este campo')
      return errors
    }
  },

  methods: {
    ...mapActions(['saveUser', 'handleLoading', 'handleAlert', 'signout']),

    submit () {
      this.$v.form.$touch()
      if (this.$v.form.$error) return
      this.login()
    },

    async login () {
      try {
        this.handleLoading(true)
        const res = await userLogin({
          email: this.form.email,
          password: this.form.password
        })
        this.moduleId = res.data.data.modules[0].id
        this.saveUser({
          token: res.data.access_token,
          user: res.data.data.user,
          modules: res.data.data.modules
        })
        this.alertMessage = `${res.data.data.user.name} has ingresado con éxito!`
        this.alertColor = 'success'
      } catch (error) {
        this.signout()
        this.alertMessage = error.data?.message
        this.alertColor = 'error'
      } finally {
        this.$router.push({ name: userFirstRoute[this.moduleId] })
        this.handleLoading(false)
        this.handleAlert({
          value: true,
          text: this.alertMessage,
          color: this.alertColor
        })
      }
    }

  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .v-input__slot > div {
    border-bottom: $border-input;
    input[type="password"] {
      @include fontStyle(bolder, 18px, 0.1em, $black);
      font-family: 'Roboto';
      &::placeholder {
        @include fontStyle(normal, 18px, 0.01em, $grey);
        font-family: 'NouvelR';
        font-style: normal;
        padding-left: 10px;
      }
    }
      input::placeholder {
        @include fontStyle(normal, 18px, 0.01em, $grey);
        font-style: normal;
        padding-left: 10px;
      }
  }
}
</style>
