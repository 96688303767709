<template>
  <v-stepper-content :step="step">
    <v-form ref="saleStepTwo">
    <div class="d-flex mx-8">
        <div class="d-flex flex-column" style="width: 70%;">
          <v-card color="grey lighten-4"
                  elevation="0"
                  class="pa-6 my-4 mx-4 d-flex flex-column justify-start">
            <span class="text-uppercase text-no-wrap font-weight-bold align-self-start pb-4">Reserva de vehiculo</span>
            <div class="d-flex flex-column">
              <v-autocomplete
                v-if="typeSale === '1'"
                :disabled="userDb.role.id === 4"
                return-object
                label="Elegir modelo"
                :items="modelsAvailabiltyItems"
                v-model="modelSelected"
                :rules="[valiadationsRules.required]"
              />
              <v-autocomplete
                :disabled="(userDb.role.id === 4 || modelSelected==='') && typeSale === '1'"
                return-object
                clearable
                label="Verificar disponibilidad de vehiculo"
                :items="vehiclesAvailabiltyItems"
                :rules="isVehicleRequired"
                v-model="vehicleSelected"
                @change="resetAccessories"
                @click:clear="clearVehiclesAutocomplete"
              />
              <v-row class="mt-4">
                <v-col>
                  <v-text-field
                    :disabled="userDb.role.id === 4 || typeSale === '2'"
                    dense
                    label="Codigo industrial"
                    placeholder="Código industrial"
                    v-model="data.industrial_code"
                    class="pr-6"
                    />
                </v-col>
                <v-col>
                  <v-select
                    dense
                    disabled
                    return-object
                    label="Modelo"
                    placeholder="Modelo"
                    :items="modelsItems"
                    v-model="data.model"
                    class="pr-6"
                    />
                </v-col>
                <v-col>
                  <v-text-field
                    :disabled="userDb.role.id === 4 || typeSale === '2'"
                    dense
                    label="VIN"
                    placeholder="Codigo VIN"
                    v-model="data.vin_code"
                  />
                </v-col>
              </v-row>
              <v-row class="mt-4">
                <v-col cols="4">
                  <v-select
                    dense
                    disabled
                    label="Año"
                    placeholder="Año"
                    :items="VehicleHelper.getYearsOptions()"
                    v-model="data.year"
                    class="pr-6"
                  />
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    :disabled="userDb.role.id === 4 || typeSale === '2'"
                    dense
                    label="Dominio"
                    placeholder="Dominio"
                    v-model="data.domain"
                    class="pr-6"
                  />
                </v-col>
                <v-col cols="4">
                  <v-select
                    dense
                    disabled
                    return-object
                    label="Transmisión"
                    placeholder="Transmisión"
                    :items="transmitionsItems"
                    v-model="data.transmition"
                    />
                </v-col>
              </v-row>
              <v-row class="mt-4">
                <v-col cols="4">
                  <v-select
                    dense
                    disabled
                    return-object
                    label="Combustible"
                    placeholder="Combustible"
                    :items="fuelItems"
                    v-model="data.fuel_type"
                    class="pr-6"
                  />
                </v-col>
                <v-col cols="4">
                  <v-select
                    dense
                    disabled
                    label="Puertas"
                    placeholder="Puertas"
                    :items="VehicleHelper.getDoorsOptions()"
                    v-model="data.doors"
                    class="pr-6"
                  />
                </v-col>
                <v-col cols="4">
                  <v-select
                    :disabled="userDb.role.id === 4 || typeSale === '2'"
                    dense
                    label="Color"
                    placeholder="Color"
                    :items="colorItems"
                    v-model="data.color"
                  >
                    <template v-slot:item="{ item, attrs, on }">
                      <v-list-item v-on="on" v-bind="attrs">
                        <v-list-item-avatar
                          :color="item.code"
                          :size="24"
                        />
                        <v-list-item-title>
                          {{item.text}}
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
            </div>
          </v-card>
          <v-card v-if="typeSale === '1'" color="grey lighten-4" elevation="0" class="pa-6 mx-4 d-flex flex-column justify-start accesories-container">
            <span class="text-uppercase text-no-wrap font-weight-bold align-self-start pb-4">ACCESORIOS</span>
            <v-row class="flex">
                <v-col cols="6">
                  <v-autocomplete
                    :disabled="userDb.role.id === 4 || typeSale === '2'"
                    return-object
                    label="Accesorios"
                    :items="accesoriesItems"
                    v-model="accesorySelected"
                    class=""
                    >
                  </v-autocomplete>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    :disabled="userDb.role.id === 4 || typeSale === '2'"
                    label="Cantidad"
                    placeholder="Cantidad"
                    v-model="accesoryCant"
                  />
                </v-col>
                <v-col cols="3">
                  <v-btn
                    :disabled="userDb.role.id === 4 || typeSale === '2'"
                    class="mt-4 btn-add__custom"
                    elevation="0"
                    @click="addAccesory"
                  >AGREGAR</v-btn>
                </v-col>
              </v-row>
            <div class="d-flex">
              <div class="d-flex box-width align-center">
              </div>
            </div>
            <div class="d-flex flex-column" v-if="data.accesories.length > 0">
              <div class="d-flex">
                <span class="font-weight-bold" style="width: 10%;">Cant</span>
                <span class="font-weight-bold" style="width: 40%;">Descripcion</span>
                <span class="font-weight-bold" style="width: 20%;">Precio</span>
                <span class="font-weight-bold" style="width: 20%;">Total</span>
                <span class="font-weight-bold" style="width: 10%;"></span>
              </div>
              <div class="d-flex" v-for="(accesory, index) in data.accesories" :key="index">
                <span style="width: 10%; margin-top: 2px;">{{ accesory.cant }}</span>
                <span style="width: 40%; margin-top: 2px;">{{ accesory.accesory.name }}</span>
                <span style="width: 20%; margin-top: 2px;">${{ $options.filters.magnitude(parseInt(accesory.accesory.value)) }}</span>
                <span style="width: 20%; margin-top: 2px;">${{ $options.filters.magnitude(parseInt(accesory.accesory.value) * parseInt(accesory.cant)) }}</span>
                <span style="width: 10%;">
                  <v-btn
                    :disabled="userDb.role.id === 4 || typeSale === '2'"
                    icon
                    elevation="0"
                    x-small
                    class="pa-3"
                    @click.prevent="deleteAccesory(index)"
                  ><v-icon>mdi-close-circle-outline</v-icon></v-btn>
                </span>
              </div>
            </div>
          </v-card>
        </div>
        <div class="d-flex flex-column" style="width: 30%;">
          <v-card color="grey lighten-4" elevation="0" class="pa-6 my-4 mx-4 d-flex flex-column justify-start">
            <span class="text-uppercase text-no-wrap font-weight-bold align-self-start pb-4">Datos de facturacion</span>
            <div class="d-flex flex-column">
              <v-text-field
                disabled
                dense
                prefix="$"
                label="Precio de Lista"
                placeholder="Precio de lista"
                v-model="data.list_price"
                :rules="[valiadationsRules.required]"
                @change="applyFilterTransformData(data.list_price, 'list_price', 'magnitude')"
                @focus="applyFilterTransformData(data.list_price, 'list_price', 'number')"
                class="mt-4"
              />
              <v-text-field
                disabled
                dense
                prefix="$"
                label="Bonficacion Renault"
                placeholder="Bonficacion Renault"
                v-model="data.renault_bonus"
                @change="applyFilterTransformData(data.renault_bonus, 'renault_bonus', 'magnitude')"
                @focus="applyFilterTransformData(data.renault_bonus, 'renault_bonus', 'number')"
                class="mt-4"
                />
              <v-text-field
                dense
                disabled
                prefix="$"
                label="Subtotal"
                placeholder="Subtotal"
                v-model="data.subtotal"
                :rules="[valiadationsRules.required]"
                @change="applyFilterTransformData(data.subtotal, 'subtotal', 'magnitude')"
                @focus="applyFilterTransformData(data.subtotal, 'subtotal', 'number')"
                class="mt-4"
              />
              <v-text-field
                :disabled="userDb.role.id === 4 || typeSale === '2'"
                dense
                prefix="$"
                label="Descuento Lepic"
                placeholder="Descuento Lepic"
                v-model="data.lepic_bonification"
                :rules="[valiadationsRules.required]"
                @change="applyFilterTransformData(data.lepic_bonification, 'lepic_bonification', 'magnitude')"
                @focus="applyFilterTransformData(data.lepic_bonification, 'lepic_bonification', 'number')"
                class="mt-4"
              />
              <v-text-field
                dense
                disabled
                prefix="$"
                label="Total Facturado"
                placeholder="Total Facturado"
                v-model="data.total"
                @change="applyFilterTransformData(data.total, 'total', 'magnitude')"
                @focus="applyFilterTransformData(data.total, 'total', 'number')"
                class="mt-4"
              />
            </div>
          </v-card>
          <v-card  v-if="typeSale === '1'" color="grey lighten-4" elevation="0" class="pa-6 my-4 mx-4 d-flex flex-column justify-start">
            <span class="text-uppercase text-no-wrap font-weight-bold align-self-start">Total accesorios</span>
            <div class="d-flex flex-column">
              <v-text-field
                dense
                disabled
                prefix="$"
                v-model="data.total_accesories"
                @change="applyFilterTransformData(data.total_accesories, 'total_accesories', 'magnitude')"
                @focus="applyFilterTransformData(data.total_accesories, 'total_accesories', 'number')"
                class="mt-4"
              />
            </div>
          </v-card>
          <v-card color="grey lighten-4" elevation="0" class="pa-6 my-4 mx-4 d-flex flex-column align-end justify-start">
            <div class="d-flex justify-start align-end mt-4">
              <div class="text-uppercase text-no-wrap font-weight-bold pb-3 pr-4">Gestoría</div>
              <v-autocomplete
                :disabled="userDb.role.id === 4"
                dense
                label="Tipo Patent."
                :items="patentTypeItems"
                :rules="isPatentTypeRequired"
                v-model="patentTypeSelected"
                ></v-autocomplete>
            </div>
            <div class="d-flex flex-column" style="width: 100%">
              <v-text-field
                :disabled="userDb.role.id === 4"
                dense
                prefix="$"
                label="Patentamiento/Transf."
                placeholder="Patentamiento/Transf."
                v-model="data.patenting"
                :rules="isPatentRequiredNotZero"
                @change="applyFilterTransformData(data.patenting, 'patenting', 'magnitude')"
                @focus="applyFilterTransformData(data.patenting, 'patenting', 'number')"
                class="mt-6"
              />
              <v-text-field
                :disabled="userDb.role.id === 4"
                dense
                prefix="$"
                label="L.D/L.G. y V.U."
                placeholder="L.D/L.G. y V.U."
                v-model="data.ldLgVu"
                @change="applyFilterTransformData(data.ldLgVu, 'ldLgVu', 'magnitude')"
                @focus="applyFilterTransformData(data.ldLgVu, 'ldLgVu', 'number')"
                class="mt-4"
              />
              <v-text-field
                :disabled="userDb.role.id === 4"
                dense
                prefix="$"
                label="Descuento Lepic"
                placeholder="Descuento Lepic"
                v-model="data.lepic_bonification_management"
                @change="applyFilterTransformData(data.lepic_bonification_management, 'lepic_bonification_management', 'magnitude')"
                @focus="applyFilterTransformData(data.lepic_bonification_management, 'lepic_bonification_management', 'number')"
                class="mt-4"
              />
              <v-text-field
                :disabled="userDb.role.id === 4"
                dense
                prefix="$"
                label="Gestión de Crédito"
                placeholder="Gestión de Crédito"
                v-model="data.credit"
                @change="applyFilterTransformData(data.credit, 'credit', 'magnitude')"
                @focus="applyFilterTransformData(data.credit, 'credit', 'number')"
                class="mt-4"
              />
              <v-text-field
                :disabled="userDb.role.id === 4"
                dense
                prefix="$"
                label="Flete, Formularios y PPE"
                placeholder="Flete, Formularios y PPE"
                v-model="data.formsPPE"
                @change="applyFilterTransformData(data.formsPPE, 'formsPPE', 'magnitude')"
                @focus="applyFilterTransformData(data.formsPPE, 'formsPPE', 'number')"
                class="mt-4"
              />
              <v-text-field
                :disabled="userDb.role.id === 4"
                dense
                prefix="$"
                label="Otros"
                placeholder="Otros"
                v-model="data.others"
                @change="applyFilterTransformData(data.others, 'others', 'magnitude')"
                @focus="applyFilterTransformData(data.others, 'others', 'number')"
                class="mt-4"
              />
              <v-text-field
                dense
                disabled
                prefix="$"
                label="Total Gestoría"
                placeholder="Total Gestoría"
                v-model="data.total_management"
                @change="applyFilterTransformData(data.total_management, 'total_management', 'magnitude')"
                @focus="applyFilterTransformData(data.total_management, 'total_management', 'number')"
                class="mt-4"
              />
            </div>
          </v-card>
          <v-card color="grey lighten-4" elevation="0" class="pa-6 my-4 mx-4 d-flex flex-column justify-start">
              <span class="text-uppercase text-no-wrap font-weight-bold align-self-start">Total operación</span>
              <div class="d-flex flex-column">
                <v-text-field
                  dense
                  disabled
                  prefix="$"
                  v-model="data.total_operation"
                  @change="applyFilterTransformData(data.total_operation, 'total_operation', 'magnitude')"
                  @focus="applyFilterTransformData(data.total_operation, 'total_operation', 'number')"
                  class="mt-4"
                />
              </div>
          </v-card>
        </div>
      </div>
    </v-form>
  </v-stepper-content>
</template>

<script>
import { getAllAccesoriesByModel, getAllColorsByModel, getAllUsedVehicleColors } from '../../../services/vehicles'
import Helper from '../../../helpers/Helper'
import AuthHelper from '../../../helpers/AuthHelper'
import VehicleHelper from '../../../helpers/VehicleHelper'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'StepVehicle',
  props: {
    userDb: Object,
    step: Number,
    fuels: Array,
    transmitions: Array,
    typeSale: String,
    saleData: {
      type: Object,
      required: true
    },
    completeData: Object,
    allVehicles: Array,
    allModels: Array,
    edit: Boolean,
    valiadationsRules: Object
  },
  data () {
    return {
      initialPatent: false,
      patentType: '',
      patenting_lepic: '',
      patenting_client: '',
      VehicleHelper,
      vehicles: [],
      accesories: [],
      accesorySelected: '',
      accesoryCant: '',
      accesoriesItems: [],
      transmitionsItems: [],
      transmition: '',
      modelsAvailabiltyItems: [],
      vehiclesAvailabiltyItems: [],
      patentTypeItems: [
        {
          value: 1,
          text: 'Cliente'
        },
        {
          value: 2,
          text: 'Lepic'
        }
      ],
      modelsItems: [],
      fuelItems: [],
      colorItems: [],
      color: '',
      vehiclesItems: [],
      modelSelected: '',
      vehicleSelected: '',
      patentTypeSelected: '',
      colorSelected: '',
      finishInitData: false,
      list_price: 0,
      lepic_bonification: 0,
      lepic_bonification_management: 0,
      renault_bonus: 0,
      previousValues: {},
      isVehicleRequired: [],
      isPatentTypeRequired: [],
      isPatentRequiredNotZero: []
    }
  },
  computed: {
    ...mapState(['userToken']),
    data: {
      get () {
        return this.saleData
      },
      set (value) {
        this.$emit('update:data', value)
      }
    }
  },
  methods: {
    ...mapActions(['handleLoading', 'handleAlert']),
    saleStepVehicleValidate () {
      this.$refs.saleStepTwo.validate()
    },
    async clearVehiclesAutocomplete () {
      const model = Helper.getItemFromArray(this.data.model, this.allModels)
      this.data.vin_code = ''
      this.data.vehicle_id = ''
      this.data.color = ''
      this.data.year = ''
      this.data.industrial_code = ''
      this.data.subtotal = ''
      this.data.domain = ''
      if (this.typeSale === '1') {
        this.data.list_price = model.list_price !== null ? this.$options.filters.magnitude(Helper.transformTwoDecimalFloatToInt(model.list_price)) : 0
        this.data.lepic_bonification = model.lepic_bonification !== null ? this.$options.filters.magnitude(Helper.transformTwoDecimalFloatToInt(model.lepic_bonification)) : 0
        this.data.renault_bonus = model.rasa_bonification !== null ? this.$options.filters.magnitude(Helper.transformTwoDecimalFloatToInt(model.rasa_bonification)) : 0
        this.data.fuel_type = model.vehicle_fuel_id
        this.data.doors = model.vehicle_doors
        this.data.model = model.id
        await this.getAccesoriesByModel(this.data.model)
        await this.getColorsByModel(this.data.model)
      } else {
        this.data.list_price = ''
        this.data.lepic_bonification = ''
        this.data.renault_bonus = ''
        this.data.fuel_type = ''
        this.data.doors = ''
        this.data.model = ''
      }
      this.data.vehicleSelected = ''
    },
    applyFilterTransformData (value, field, filter) {
      this.data[field] = this.$options.filters[filter](value)
    },
    transformVehiclesToAutocomplete () {
      this.vehiclesAvailabiltyItems = []
      if (this.typeSale === '1' && this.data.vehicle_model_id !== null && this.data.vehicle_model_id !== '') {
        this.allVehicles.forEach(v => {
          if (v.vehicle_type.id === 1 && v.vehicle_model_id === this.data.model) {
            this.vehiclesAvailabiltyItems.push({
              value: v.id,
              text: this.getCompleteVehicleName(v)
            })
          }
        })
      } else if (this.typeSale === '2') {
        this.allVehicles.forEach(v => {
          if (v.vehicle_type.id === 2 && v.expertises.length !== 0) {
            if (v.expertises[v.expertises.length - 1].expertise_status_id === 2) {
              this.vehiclesAvailabiltyItems.push({
                value: v.id,
                text: this.getCompleteVehicleName(v)
              })
            }
          }
        })
      }
    },
    getCompleteVehicleName (model) {
      return model?.vehicle_model.vehicle_brand?.brand + ' ' +
              model?.vehicle_model.model.toUpperCase() + ' - ' +
              model?.vehicle_model.vehicle_line.line + ' | ' +
              model?.vehicle_model.vehicle_doors + ' puertas ' +
              model?.vehicle_model.vehicle_fuel?.fuel +
              (model?.vehicle_color?.color === undefined ? '' : ' - ' + model?.vehicle_color?.color)
    },
    transformModelsToAutocomplete () {
      // this.modelsItems = []
      this.allModels.forEach(m => {
        this.modelsAvailabiltyItems.push({
          value: m.id,
          text: this.getCompleteModelName(m)
        })
      })
    },
    getCompleteModelName (model) {
      return model?.vehicle_brand?.brand + ' ' +
              model?.model.toUpperCase() + ' - ' +
              model?.vehicle_line.line + ' | ' +
              model?.vehicle_doors + ' puertas ' +
              model?.vehicle_fuel?.fuel
    },
    addAccesory () {
      const index = this.accesories.findIndex(a => a.id === this.accesorySelected.value)
      if (index !== -1) {
        this.data.accesories.push({
          accesory: this.accesories[index],
          cant: this.accesoryCant
        })
        this.accesorySelected = ''
        this.accesoryCant = ''
      }
    },
    resetAccessories () {
      this.data.accesories = []
    },
    refreshAccesoriesItems () {
      const accesoriesFiltered = this.accesories.filter(accesory => !this.isAccesoryAdded(accesory))
      this.accesoriesItems = Helper.transformToSelect(accesoriesFiltered, 'description')
    },
    isAccesoryAdded (accesory) {
      return this.data.accesories.some(accesoryAdded => accesory.id === accesoryAdded.accesory.id)
    },
    deleteAccesory (index) {
      this.data.accesories.splice(index, 1)
    },
    async getAccesoriesByModel (modelID) {
      try {
        this.handleLoading(true)
        const res = await getAllAccesoriesByModel(this.userToken, modelID)
        this.accesories = res.data.data
        this.accesoriesItems = Helper.transformToSelect(this.accesories, 'description')
        this.alertMessage = res.data.message
        this.alertColor = 'success'
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
        this.alertMessage = error?.data?.message ? error.data.message : error
        this.alertColor = 'error'
      } finally {
        this.handleLoading(false)
        this.handleAlert({
          value: true,
          text: this.alertMessage,
          color: this.alertColor
        })
      }
    },
    calculateTotalAccesories () {
      this.data.total_accesories = this.$options.filters.magnitude(
        this.data.accesories.reduce((acc, accesory) =>
          acc + parseFloat(accesory.accesory.value) * parseInt(accesory.cant), 0)
      )
    },
    async getColorsByModel (modelID) {
      try {
        this.handleLoading(true)
        const res = await getAllColorsByModel(this.userToken, modelID)
        this.colors = res.data.data
        this.colorItems = Helper.transformToSelect(this.colors, 'color')
        this.colorItems.forEach(ci => { ci.code = Helper.getItemFromArray(ci.value, this.colors)?.codigo })
        this.alertMessage = res.data.message
        this.alertColor = 'success'
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
        this.alertMessage = error?.data?.message ? error.data.message : error
        this.alertColor = 'error'
        this.colorItems = []
        this.data.color = ''
      } finally {
        this.handleLoading(false)
        this.handleAlert({
          value: true,
          text: this.alertMessage,
          color: this.alertColor
        })
      }
    },
    async getUsedVehicleColors () {
      try {
        this.handleLoading(true)
        const res = await getAllUsedVehicleColors(this.userToken)
        this.colors = res.data.data
        this.colorItems = Helper.transformToSelect(this.colors, 'color')
        this.colorItems.forEach(ci => { ci.code = Helper.getItemFromArray(ci.value, this.colors)?.codigo })
        this.alertMessage = res.data.message
        this.alertColor = 'success'
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
        this.alertMessage = error?.data?.message ? error.data.message : error
        this.alertColor = 'error'
        this.colorItems = []
        this.data.color = ''
      } finally {
        this.handleLoading(false)
        this.handleAlert({
          value: true,
          text: this.alertMessage,
          color: this.alertColor
        })
      }
    },
    calculateSubtotal () {
      if (this.data.list_price === '') this.data.list_price = 0
      if (this.data.renault_bonus === '') this.data.renault_bonus = 0
      this.data.subtotal = this.$options.filters.magnitude(parseFloat(this.$options.filters.number(this.data.list_price)) - parseFloat(this.$options.filters.number(this.data.renault_bonus)))
    },
    calculateTotal () {
      if (this.data.subtotal === '') this.data.subtotal = 0
      if (this.data.lepic_bonification === '') this.data.lepic_bonification = 0
      this.data.total = this.$options.filters.magnitude(parseFloat(this.$options.filters.number(this.data.subtotal)) - parseFloat(this.$options.filters.number(this.data.lepic_bonification)))
    },
    calculateTotalManagement () {
      this.data.total_management =
      this.$options.filters.magnitude(
        parseFloat(this.$options.filters.number(this.data.patenting)) +
        parseFloat(this.$options.filters.number(this.data.ldLgVu)) -
        parseFloat(this.$options.filters.number(this.data.lepic_bonification_management)) +
        parseFloat(this.$options.filters.number(this.data.credit)) +
        parseFloat(this.$options.filters.number(this.data.formsPPE)) +
        parseFloat(this.$options.filters.number(this.data.others))
      )
    },
    calculateTotalOperation () {
      this.data.total_operation = this.$options.filters.magnitude(
        parseFloat(this.$options.filters.number(this.data.total)) +
        parseFloat(this.$options.filters.number(this.data.total_accesories)) +
        parseFloat(this.$options.filters.number(this.data.total_management)))
    }
  },
  mounted () {
    if (this.edit) {
      this.initialPatent = true
      this.previousValues = {
        lepic_bonification: this.$options.filters.magnitude(this.completeData.vehicle.lepic_bonification),
        list_price: this.$options.filters.magnitude(this.completeData.vehicle.list_price),
        renault_bonus: this.$options.filters.magnitude(this.completeData.vehicle.renault_bonus),
        patenting: this.$options.filters.magnitude(this.completeData.vehicle.patenting),
        patentTypeSelected: this.completeData.vehicle.patentType
      }
    }
    if (this.typeSale === '2') {
      this.getUsedVehicleColors()
      this.isVehicleRequired.push(this.valiadationsRules.required)
      this.patentTypeSelected = 1
    }
    this.modelsItems = Helper.transformToSelect(this.allModels, 'model')
    this.vehiclesItems = Helper.transformToSelect(this.allVehicles, 'vehicle')
    this.fuelItems = Helper.transformToSelect(this.fuels, 'fuel')
    this.transmitionsItems = Helper.transformToSelect(this.transmitions, 'name')
    if (!this.edit) { this.data.accesories = [] }
    if (this.edit) {
      this.modelSelected = {
        value: this.completeData.application.vehicle_model_id,
        text: ''
      }
      this.vehicleSelected = {
        value: this.completeData.application.vehicle_id,
        text: ''
      }
      this.colorSelected = {
        value: this.typeSale === '1' ? this.completeData.application.vehicle_color_id : 1,
        text: ''
      }
      /* this.patentTypeSelected = {
        // modificar con datos provenientes de la base con un if patente lepic else if patente cliente o algo así
        value: this.completeData.vehicle.patentType,
        text: ''
      } */
      this.finishInitData = true
    }
    if (this.typeSale === '1') {
      this.transformModelsToAutocomplete()
      this.isPatentTypeRequired.push(this.valiadationsRules.required)
      this.isPatentRequiredNotZero.push(this.valiadationsRules.required, this.valiadationsRules.isNotZero)
    }
    this.calculateTotalAccesories()
    this.calculateTotalManagement()
    this.calculateSubtotal()
    this.calculateTotal()
    this.calculateTotalOperation()
    this.transformVehiclesToAutocomplete()
  },
  watch: {
    patentTypeSelected: {
      deep: true,
      handler (newType, prevType) {
        if (newType !== prevType) {
          this.data.patentType = this.patentTypeSelected
          if (this.typeSale === '1') {
            if (newType === 1) {
              if (!isNaN(this.data.patenting) && this.data.patenting !== '' && this.initialPatent) {
                this.initialPatent = false
              } else {
                this.data.patenting = this.data.patenting !== null ? this.$options.filters.magnitude(Helper.transformTwoDecimalFloatToInt(this.data.patenting_client)) : 0
              }
            } else if (newType === 2) {
              if (!isNaN(this.data.patenting) && this.data.patenting !== '' && this.initialPatent) {
                this.initialPatent = false
              } else {
                this.data.patenting = this.$options.filters.magnitude(Helper.transformTwoDecimalFloatToInt(this.data.patenting_lepic))
              }
            }
          } else {
            this.data.patenting = 0
          }
        }
      }
    },
    modelSelected: {
      deep: true,
      async handler (newModel, prevModel) {
        if (newModel.value !== prevModel.value) {
          const model = Helper.getItemFromArray(newModel.value, this.allModels)
          if (!this.finishInitData) {
            if (this.data.list_price !== null) { this.data.list_price = this.$options.filters.magnitude(Helper.transformTwoDecimalFloatToInt(model.list_price)) }
            if (this.data.lepic_bonification !== null) { this.data.lepic_bonification = this.$options.filters.magnitude(Helper.transformTwoDecimalFloatToInt(model.lepic_bonification)) }
          }
          this.data.vin_code = ''
          this.data.year = ''
          this.data.vehicle_id = ''
          this.data.transmition = ''
          this.data.color = ''
          this.data.domain = ''
          this.data.fuel_type = model.vehicle_fuel_id
          this.data.doors = model.vehicle_doors
          this.data.model = model.id
          this.data.patenting_client = model.patenting_client
          this.data.patenting_lepic = model.patenting_lepic
          if (this.edit &&
            Object.keys(this.previousValues).length !== 0 &&
            (this.saleData.id !== null ||
            this.saleData !== '')) {
            this.data.color = this.completeData.application.vehicle_color_id
            this.data.list_price = this.previousValues.list_price
            this.data.lepic_bonification = this.previousValues.lepic_bonification
            this.data.renault_bonus = this.previousValues.renault_bonus
            this.data.patenting = this.previousValues.patenting
            this.patentTypeSelected = this.previousValues.patentTypeSelected
            this.previousValues = {}
          } else {
            this.data.color = this.data.car_color_id
            this.data.list_price = model.list_price !== null ? this.$options.filters.magnitude(Helper.transformTwoDecimalFloatToInt(model.list_price)) : 0
            this.data.lepic_bonification = model.lepic_bonification !== null && model.lepic_bonification !== '' ? this.$options.filters.magnitude(Helper.transformTwoDecimalFloatToInt(model.lepic_bonification)) : 0
            this.data.renault_bonus = model.rasa_bonification !== null ? this.$options.filters.magnitude(Helper.transformTwoDecimalFloatToInt(model.rasa_bonification)) : 0
            this.data.patenting = 0
            this.patentTypeSelected = 0
          }
          if (this.typeSale === '1') {
            await this.getAccesoriesByModel(model.id)
            await this.getColorsByModel(model.id)
          }
          await this.transformVehiclesToAutocomplete()
        }
      }
    },
    vehicleSelected: {
      deep: true,
      async handler (newVehicle, prevVehicle) {
        if (newVehicle !== null && (prevVehicle !== null ? prevVehicle.value !== newVehicle.value : prevVehicle === null)) {
          const vehicle = Helper.getItemFromArray(newVehicle.value, this.allVehicles)
          if (this.edit) {
            if (vehicle !== null) {
              this.data.industrial_code = vehicle.vehicle_model.code
              this.data.vin_code = vehicle.vin_code
              this.data.vehicle_id = vehicle.id
              this.data.fuel_type = vehicle.vehicle_model.vehicle_fuel.id
              this.data.doors = vehicle.vehicle_model.vehicle_doors
              this.data.model = vehicle.vehicle_model.id
              this.data.year = vehicle.year
              this.data.transmition = vehicle.transmition_id
              this.data.domain = vehicle.domain
              this.data.color = vehicle.vehicle_color.id
              if (Object.keys(this.previousValues).length !== 0) {
                // hacer parseo en watcher o computed
                this.data.list_price = this.previousValues.list_price
                this.data.lepic_bonification = this.previousValues.lepic_bonification
                this.data.rasa_bonification = this.previousValues.renault_bonus
                this.previousValues = {}
              } else {
                this.data.list_price = vehicle.list_price !== null ? this.$options.filters.magnitude(Helper.transformTwoDecimalFloatToInt(vehicle.list_price)) : 0
                this.data.lepic_bonification = vehicle.lepic_bonification !== null ? this.$options.filters.magnitude(Helper.transformTwoDecimalFloatToInt(vehicle.lepic_bonification)) : 0
                this.data.renault_bonus = vehicle.rasa_bonification !== null ? this.$options.filters.magnitude(Helper.transformTwoDecimalFloatToInt(vehicle.rasa_bonification)) : 0
              }
            } else {
              this.data.vehicle_id = ''
              this.data.industrial_code = ''
              this.data.year = ''
              this.data.vin_code = ''
              this.data.transmition = ''
            }
          } else {
            this.data.vehicle_id = vehicle.id
            this.data.industrial_code = vehicle.vehicle_model.code
            this.data.year = vehicle.year
            this.data.fuel_type = vehicle.vehicle_model.vehicle_fuel.id
            this.data.model = vehicle.vehicle_model.id
            this.data.doors = vehicle.vehicle_model.vehicle_doors
            this.data.vin_code = vehicle.vin_code
            this.data.transmition = vehicle.transmition_id
            this.data.color = vehicle.vehicle_color.id
            this.data.domain = vehicle.domain
            this.data.list_price = this.$options.filters.magnitude(Helper.transformTwoDecimalFloatToInt(vehicle.list_price))
            this.data.lepic_bonification = this.$options.filters.magnitude(Helper.transformTwoDecimalFloatToInt(vehicle.lepic_bonification))
          }
          if (this.typeSale === '2') {
            this.data.patenting = vehicle.patenting ? vehicle.patenting : 0
            this.data.vehicle_model_id = this.data.model
          }
          // this.data.modelSelected = this.data.model
          this.calculateSubtotal()
          this.calculateTotal()
          this.calculateTotalOperation()
        } else {
          this.clearVehiclesAutocomplete()
        }
      }
    },
    'data.accesories': {
      deep: true,
      handler () {
        this.calculateTotalAccesories()
        this.refreshAccesoriesItems()
        this.calculateTotalOperation()
      }
    },
    // CALCULOS DE SUBTOTAL Y TOTAL
    'data.list_price': {
      deep: true,
      handler () {
        this.calculateSubtotal()
        this.calculateTotal()
        this.calculateTotalOperation()
      }
    },
    'data.renault_bonus': {
      deep: true,
      handler () {
        this.calculateSubtotal()
        this.calculateTotal()
        this.calculateTotalOperation()
      }
    },
    'data.lepic_bonification': {
      deep: true,
      handler () {
        this.calculateSubtotal()
        this.calculateTotal()
        this.calculateTotalOperation()
      }
    },
    'data.subtotal': {
      deep: true,
      handler () {
        this.calculateSubtotal()
        this.calculateTotal()
        this.calculateTotalOperation()
      }
    },
    // CALCULOS DE GESTORIA Y TOTAL OPERACION
    'data.patenting': { handler () { this.calculateTotalManagement() } },
    'data.ldLgVu': { handler () { this.calculateTotalManagement() } },
    'data.lepic_bonification_management': { handler () { this.calculateTotalManagement() } },
    'data.credit': { handler () { this.calculateTotalManagement() } },
    'data.formsPPE': { handler () { this.calculateTotalManagement() } },
    'data.others': { handler () { this.calculateTotalManagement() } },
    'data.total_management': { handler () { this.calculateTotalOperation() } }
  }
}
</script>

<style lang="scss" scoped>
</style>
