<template>
    <svg width="24" height="14" fill="none" viewBox="0 0 24 14" xmlns="http://www.w3.org/2000/svg">
        <path d="M7 0C3.14 0 0 3.14 0 7C0 10.86 3.14 14 7 14C9.72 14 12.17 12.42 13.32 10H16V14H22V10H24V4H13.32C12.17 1.58 9.72 0 7 0ZM22 8H20V12H18V8H11.94L11.71 8.67C11.01 10.66 9.11 12 7 12C4.24 12 2 9.76 2 7C2 4.24 4.24 2 7 2C9.11 2 11.01 3.34 11.71 5.33L11.94 6H22V8ZM7 4C5.35 4 4 5.35 4 7C4 8.65 5.35 10 7 10C8.65 10 10 8.65 10 7C10 5.35 8.65 4 7 4ZM7 8C6.45 8 6 7.55 6 7C6 6.45 6.45 6 7 6C7.55 6 8 6.45 8 7C8 7.55 7.55 8 7 8Z"/>
    </svg>
</template>

<script>
export default {
  name: 'Awarded'
}
</script>

<style lang="scss" scoped>
</style>
