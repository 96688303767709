<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :hide-default-header="hideDefaultTableHeader"
    :hide-default-footer="true"
    :items-per-page="cantsPerPage"
    :sort-by="sortOptions.sortBy"
    :sort-desc="sortOptions.sortDesc"
    @click:row="rowClicked"
    class="elevation-0">

    <template v-slot:top="{ pagination, options, updateOptions }">

      <slot name="tableToolbar"
            :pagination="pagination"
            :options="options"
            :updateOptions="updateOptions"></slot>

      <slot name="componentDelete"></slot>
      <slot
        name="componentEditStatus"
      ></slot>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <TableActionsMenu :item='item'>
      </TableActionsMenu>
    </template>

    <template v-slot:[`item.firstcolumnbot`]="{ item }">
        <h3 class="user__h3 text-left mt-3">{{ item.firstcolumntop }}</h3>
        <p class="user__p text-left">{{ item.firstcolumnbot }}</p>
    </template>
    <template v-slot:[`item.vehicle_type`]="{ item }">
      <slot name="chipTypeStatus" :item="item"></slot>
    </template>
    <template v-slot:[`item.status`]="{ item }">
      <slot name="chipStatus" :item="item"></slot>
    </template>

    <template v-slot:[`item.indicators`]="{ item }">
      <slot name="indicators" :item="item"></slot>
    </template>

    <template v-slot:[`item.img`]="{ item }">
      <slot name="showImg" :item="item"></slot>
    </template>

    <template v-slot:[`item.action`]="{ item }">
      <slot name="showButton" :item="item"></slot>
    </template>

    <slot name="no-data"></slot>
  </v-data-table>
</template>

<script>
import TableActionsMenu from '@/components/tables/TableActionsMenu'
export default {
  name: 'Table',
  components: {
    TableActionsMenu
  },
  props: {
    title: String,
    sortOptions: {
      type: Object,
      default: () => {
        return {
          sortBy: '',
          sortDesc: false
        }
      }

    },
    userDb: Object,
    headers: {
      type: Array,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    hideDefaultTableHeader: {
      type: Boolean,
      default: true
    },
    cantsPerPage: {
      type: Number,
      default: 10
    },
    pagination: Object,
    options: Object,
    search: String
  },
  computed: {
    controlOpenAddOrUpdate: {
      get () {
        return this.openAddOrUpdateDialog
      },
      set (value) {
        this.$emit('handleAddOrUpdate', value)
      }
    },
    controlOpenPrintSale: {
      get () {
        return this.openPrintSaleDialog
      },
      set (value) {
        this.$emit('handlePrintSaleModal', value)
      }
    }
  },
  methods: {
    rowClicked (item, rowData) {
      this.$emit('rowClicked', item, rowData)
    }
  }
}
</script>

<style lang="scss" scoped>
.user__h3 {
  @include fontStyle(200, 16px, 0.01em, $black);
  font-style: normal;
}
.user__p {
  @include fontStyle (normal, 14px, 0.01em, $grey);
  font-style: normal;
}
::v-deep {
  .v-data-table__wrapper {
    padding: 5px 22px;
    table {
      border-spacing: 0 5px !important;
      tr {
        background-color: $tableRowBgColor;
        td {
          border-bottom: $border;
          border-top: $border;
          &:first-child {
            border-left: $border;
          }
          &:last-child {
            border-right: $border;
          }
        }

        &:hover {
          background-color: $tableRowBgColor !important;
          cursor: pointer;
          td {
            border-top: $borderHover;
            border-bottom: $borderHover;
            &:first-child {
              border-left: $borderHover;
              border-top-left-radius: $borderRadius;
              border-bottom-left-radius: $borderRadius;
            }
            &:last-child {
              border-right: $borderHover;
              border-top-right-radius: $borderRadius;
              border-bottom-right-radius: $borderRadius;
            }
          }

          .btn-table-options {
            background-color: $black !important;
            .v-icon {
              color: $white;
            }
          }
        }
      }
    }
    .v-data-table-header {
      tr {
        th > span {
          @include fontStyle(700, 14px, 0.01em, $grey);
        }
      }
    }
    tbody {
      tr {
        td:last-child {
          width: 40px;
        }
      }
    }
  }
}
</style>
