var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.items,"hide-default-header":_vm.hideDefaultTableHeader,"hide-default-footer":true,"items-per-page":_vm.cantsPerPage,"sort-by":_vm.sortOptions.sortBy,"sort-desc":_vm.sortOptions.sortDesc},on:{"click:row":_vm.rowClicked},scopedSlots:_vm._u([{key:"top",fn:function(ref){
var pagination = ref.pagination;
var options = ref.options;
var updateOptions = ref.updateOptions;
return [_vm._t("tableToolbar",null,{"pagination":pagination,"options":options,"updateOptions":updateOptions}),_vm._t("componentDelete"),_vm._t("componentEditStatus")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('TableActionsMenu',{attrs:{"item":item}})]}},{key:"item.firstcolumnbot",fn:function(ref){
var item = ref.item;
return [_c('h3',{staticClass:"user__h3 text-left mt-3"},[_vm._v(_vm._s(item.firstcolumntop))]),_c('p',{staticClass:"user__p text-left"},[_vm._v(_vm._s(item.firstcolumnbot))])]}},{key:"item.vehicle_type",fn:function(ref){
var item = ref.item;
return [_vm._t("chipTypeStatus",null,{"item":item})]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._t("chipStatus",null,{"item":item})]}},{key:"item.indicators",fn:function(ref){
var item = ref.item;
return [_vm._t("indicators",null,{"item":item})]}},{key:"item.img",fn:function(ref){
var item = ref.item;
return [_vm._t("showImg",null,{"item":item})]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_vm._t("showButton",null,{"item":item})]}}],null,true)},[_vm._t("no-data")],2)}
var staticRenderFns = []

export { render, staticRenderFns }