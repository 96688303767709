<template>
  <v-stepper-content :step="step">
    <v-form ref="saleStepThree">
      <div class="d-flex mx-8">
        <div class="d-flex flex-column" style="width: 70%;">
          <v-card color="grey lighten-4" elevation="0" class="pa-6 my-4 mx-4 d-flex flex-column justify-start">
            <span class="text-uppercase text-no-wrap font-weight-bold align-self-start pb-4">Detalle de forma de pago</span>
            <div class="d-flex flex-column">
              <v-row class="mt-4">
                <v-col cols="4">
                  <v-text-field
                    :disabled="userDb.role.id === 4"
                    dense
                    prefix="$"
                    label="Financiado"
                    placeholder="Financiado"
                    v-model="data.financed_detail"
                    @change="applyFilterTransformData(data.financed_detail, 'financed_detail', 'magnitude')"
                    @focus="applyFilterTransformData(data.financed_detail, 'financed_detail', 'number')"
                    :suffix=financed_detail_percentage
                    class="pr-6"
                  />
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    :disabled="userDb.role.id === 4"
                    dense
                    prefix="$"
                    label="Credito a gestionar"
                    placeholder="Credito a gestionar"
                    :items="['Plan 1', 'Plan 2', 'Plan Bonificado']"
                    v-model="data.credit"
                    @change="applyFilterTransformData(data.credit, 'credit', 'magnitude')"
                    @focus="applyFilterTransformData(data.credit, 'credit', 'number')"
                    class="pr-6"
                  />
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    :disabled="userDb.role.id === 4"
                    dense
                    type="number"
                    label="Cuotas"
                    placeholder="Cuotas"
                    :items="[3, 6, 12, 18, 24, 36]"
                    v-model="data.dues"
                  />
                </v-col>
              </v-row>
              <v-row class="mt-4">
                <v-col cols="4">
                  <v-text-field
                    :disabled="userDb.role.id === 4"
                    dense
                    label="Banco"
                    placeholder="Banco"
                    :items="['Río', 'Nación', 'HSBC', 'ISBC']"
                    v-model="data.bank"
                    class="pr-6"
                  />
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    :disabled="userDb.role.id === 4"
                    dense
                    label="Plan"
                    placeholder="Plan"
                    :items="['Rombo', 'Premium', 'Bonificado']"
                    v-model="data.plan"
                    class="pr-6"
                  />
                </v-col>
                <v-col cols="4"></v-col>
              </v-row>
            </div>
          </v-card>
          <v-card color="rgba(255, 183, 11, 1)" elevation="0" class="py-2 mx-4 d-flex flex-column justify-start">
            <div class="movil">
              <div class="d-flex rounded">
                <div
                  class="d-flex align-center justify-center"
                  style="width: 60px; height: 80px; fill: #000;">
                  <v-icon
                    color="black">$info</v-icon>
                </div>
                <div class="d-flex flex-column justify-center">
                  <p
                    class="text-start mb-1"
                    style="font-weight: bold; font-size: 14px;letter-spacing: 0.01em; color: #000;"
                    >Información
                  </p>
                  <p
                    class="text-start mb-0"
                    style="font-weight: 200; font-size: 13px;letter-spacing: 0.01em; color: #000;"
                    >En caso de no ser financiado, se recomienda ofrecer un seguro.
                  </p>
                </div>
              </div>
            </div>
          </v-card>
          <v-card color="grey lighten-4" elevation="0" class="pa-6 my-4 mx-4 d-flex flex-column justify-start">
            <span class="text-uppercase text-no-wrap font-weight-bold align-self-start pb-4">Seguro</span>
            <v-row class="mt-4">
              <v-col cols="4">
                <v-text-field
                :disabled="userDb.role.id === 4"
                dense
                prefix="$"
                label="Suma asegurable"
                placeholder="Suma asegurable"
                v-model="data.insurable_sum"
                @change="applyFilterTransformData(data.insurable_sum, 'insurable_sum', 'magnitude')"
                @focus="applyFilterTransformData(data.insurable_sum, 'insurable_sum', 'number')"
                class="pr-6"
              />
              </v-col>
              <v-col cols="4">
                <v-text-field
                  :disabled="userDb.role.id === 4"
                  dense
                  prefix="$"
                  label="Cuota aproximada"
                  placeholder="Cuota aproximada"
                  v-model="data.aproximate_fee"
                  @change="applyFilterTransformData(data.aproximate_fee, 'aproximate_fee', 'magnitude')"
                  @focus="applyFilterTransformData(data.aproximate_fee, 'aproximate_fee', 'number')"
                  class="pr-6"
                />
              </v-col>
              <v-col cols="4">
                <v-select
                  :disabled="userDb.role.id === 4"
                  dense
                  label="Cobertura"
                  placeholder="Cobertura"
                  :items="coveragesItems"
                  return-object
                  v-model="coverageSelected"
                  class="pr-6"
                />
              </v-col>
            </v-row>
            <v-row class="mt-4">
              <v-col cols="4">
                <v-text-field
                  :disabled="userDb.role.id === 4"
                  dense
                  label="Compañía"
                  placeholder="Compañía"
                  v-model="data.company"
                  class="pr-6"
                />
              </v-col>
              <v-col cols="8"></v-col>
            </v-row>
          </v-card>
          <span
            v-if="this.saleData"
            :data="this.saleData"
            @addUsedCarCard="addUsedCarCard"
          >
            <UsedVehicleCard
              v-for="(used_vehicle, index) in data.used_vehicle" :key="index"
              :userDb="userDb"
              :edit="edit"
              :index="index"
              :data="used_vehicle"
              :completeData="data.used_vehicle"
              :brands="brands"
              :brandsItems="brandsItems"
              :transmitionItems="transmitionItems"
              :fuelItems="fuelItems"
              :colorItems="colorItems"
              :valiadationsRules="valiadationsRules"
              @addUsedCarCard="addUsedCarCard"
              @removeUsedCarCard="removeUsedCarCard"
              @clearUsedCarCardData="clearUsedCarCardData"
              v-on:update:used_vehicle="used_vehicle = $event"
            >
            </UsedVehicleCard>
          </span>

        </div>
        <div class="d-flex flex-column" style="width: 30%;">
          <v-card color="grey lighten-4" elevation="0" class="pa-6 my-4 mx-4 d-flex flex-column justify-start">
            <div class="d-flex flex-row justify-space-between">
              <span class="text-uppercase text-no-wrap font-weight-bold align-self-start pb-4">Datos de facturacion</span>
              <v-btn class="mr-2" icon @click="toggleExpandTransition1">
              <v-icon color="black" class="pb-3">{{ showExpandTransition1 ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
              </v-btn>
            </div>
            <v-expand-transition>
              <div v-if="showExpandTransition1" class="mx-2">
                <div class="d-flex flex-column">
                  <v-text-field
                    dense
                    disabled
                    prefix="$"
                    label="Precio de Lista"
                    placeholder="Precio de lista"
                    v-model="completeData.vehicle.list_price"
                    @change="applyFilterTransformData(completeData.vehicle.list_price, 'list_price', 'magnitude')"
                    @focus="applyFilterTransformData(completeData.vehicle.list_price, 'list_price', 'number')"
                    class="mt-4"
                  />
                  <v-text-field
                    dense
                    disabled
                    prefix="$"
                    label="Bonficacion Renault"
                    placeholder="Bonficacion Renault"
                    v-model="completeData.vehicle.renault_bonus"
                    @change="applyFilterTransformData(completeData.vehicle.renault_bonus, 'renault_bonus', 'magnitude')"
                    @focus="applyFilterTransformData(completeData.vehicle.renault_bonus, 'renault_bonus', 'number')"
                    class="mt-4"
                  />
                  <v-text-field
                    dense
                    disabled
                    prefix="$"
                    label="Subtotal"
                    placeholder="Subtotal"
                    v-model="completeData.vehicle.subtotal"
                    class="mt-4"
                  />
                  <v-text-field
                    dense
                    disabled
                    prefix="$"
                    label="Descuento Lepic"
                    placeholder="Descuento Lepic"
                    v-model="completeData.vehicle.lepic_bonification"
                    @change="applyFilterTransformData(completeData.vehicle.lepic_bonification, 'lepic_bonification', 'magnitude')"
                    @focus="applyFilterTransformData(completeData.vehicle.lepic_bonification, 'lepic_bonification', 'number')"
                    class="mt-4"
                  />
                  <v-text-field
                    dense
                    disabled
                    prefix="$"
                    label="Total Facturado"
                    placeholder="Total Facturado"
                    v-model="completeData.vehicle.total"
                    @change="applyFilterTransformData(completeData.vehicle.total, 'total', 'magnitude')"
                    @focus="applyFilterTransformData(completeData.vehicle.total, 'total', 'number')"
                    class="mt-4"
                  />
                  <v-text-field
                    dense
                    disabled
                    prefix="$"
                    label="Total Accesorios"
                    placeholder="Total Accesorios"
                    v-model="completeData.vehicle.total_accesories"
                    @change="applyFilterTransformData(completeData.vehicle.total_accesories, 'total_accesories', 'magnitude')"
                    @focus="applyFilterTransformData(completeData.vehicle.total_accesories, 'total_accesories', 'number')"
                    class="mt-4"
                  />
                </div>
              </div>
            </v-expand-transition>
            <div class="mx-2">
        <v-container>
          <v-row v-if="!showExpandTransition1">
            <v-col>
              <v-text-field
                dense
                disabled
                prefix="$"
                label="Total Facturado"
                placeholder="Total Facturado"
                v-model="completeData.vehicle.total"
                @change="applyFilterTransformData(completeData.vehicle.total, 'total', 'magnitude')"
                @focus="applyFilterTransformData(completeData.vehicle.total, 'total', 'number')"
                class="mt-4"
              />
              <v-text-field
                v-if="typeSale === '1'"
                dense
                disabled
                prefix="$"
                label="Total Accesorios"
                placeholder="Total Accesorios"
                v-model="completeData.vehicle.total_accesories"
                @change="applyFilterTransformData(completeData.vehicle.total_accesories, 'total_accesories', 'magnitude')"
                @focus="applyFilterTransformData(completeData.vehicle.total_accesories, 'total_accesories', 'number')"
                class="mt-4"
              />
            </v-col>
          </v-row>
        </v-container>
      </div>
          </v-card>
          <v-card v-if="typeSale === '1'" color="grey lighten-4" elevation="0" class="pa-6 my-4 mx-4 d-flex flex-column justify-start">
            <div class="d-flex flex-row justify-space-between">
              <span class="text-uppercase text-no-wrap font-weight-bold align-self-start pb-4">Gestoría</span>
              <v-btn class="mr-2" icon @click="toggleExpandTransition2">
              <v-icon color="black" class="pb-3">{{ showExpandTransition2 ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
              </v-btn>
            </div>
          <v-expand-transition>
            <div v-show="showExpandTransition2" class="mx-2">
              <div class="d-flex flex-column">
                <v-text-field
                  dense
                  disabled
                  prefix="$"
                  label="Patentamiento/Transf."
                  placeholder="Patentamiento/Transf."
                  v-model="completeData.vehicle.patenting"
                  class="mt-4"
                />
                <v-text-field
                  dense
                  disabled
                  prefix="$"
                  label="L.D/L.G. y V.U."
                  placeholder="L.D/L.G. y V.U."
                  v-model="completeData.vehicle.ldLgVu"
                  class="mt-4"/>
                <v-text-field
                  dense
                  disabled
                  prefix="$"
                  label="Descuento Lepic"
                  placeholder="Descuento Lepic"
                  v-model="completeData.vehicle.lepic_bonification_management"
                  class="mt-4"
                />
                <v-text-field
                  dense
                  disabled
                  prefix="$"
                  label="Gestión de Crédito"
                  placeholder="Gestión de Crédito"
                  v-model="completeData.vehicle.credit"
                  class="mt-4"
                />
                <v-text-field
                  dense
                  disabled
                  prefix="$"
                  label="Flete, Formularios y PPE"
                  placeholder="Flete, Formularios y PPE"
                  v-model="completeData.vehicle.formsPPE"
                  class="mt-4"
                />
                <v-text-field
                  dense
                  disabled
                  prefix="$"
                  label="Otros"
                  placeholder="Otros"
                  v-model="completeData.vehicle.others"
                  class="mt-4"
                />
                <v-text-field
                  dense
                  disabled
                  prefix="$"
                  label="Total Gestoría"
                  placeholder="Total Gestoría"
                  v-model="completeData.vehicle.total_management"
                  class="mt-4"
                />
                <v-text-field
                  dense
                  disabled
                  prefix="$"
                  label="Total Operacion"
                  placeholder="Total Operacion"
                  v-model="completeData.vehicle.total_operation"
                  class="mt-4"
                />
              </div>
            </div>
          </v-expand-transition>
            <v-container v-if="!showExpandTransition2">
              <v-row>
                <v-col>
                  <v-text-field
                    dense
                    disabled
                    prefix="$"
                    label="Total Gestoría"
                    placeholder="Total Gestoría"
                    v-model="completeData.vehicle.total_management"
                    class="mt-4"
                  />
                  <v-text-field
                    dense
                    disabled
                    prefix="$"
                    label="Total Operacion"
                    placeholder="Total Operacion"
                    v-model="completeData.vehicle.total_operation"
                    class="mt-4"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card>
          <v-card color="grey lighten-4" elevation="0" class="pa-6 my-4 mx-4 d-flex flex-column justify-start">
            <span class="text-uppercase text-no-wrap font-weight-bold align-self-start pb-4">Forma de Pago</span>
            <div class="d-flex flex-column">
              <v-text-field
                :disabled="userDb.role.id === 4"
                dense
                prefix="$"
                label="Seña"
                placeholder="Seña"
                v-model="data.deposit"
                @change="applyFilterTransformData(data.deposit, 'deposit', 'magnitude')"
                @focus="applyFilterTransformData(data.deposit, 'deposit', 'number')"
                class="mt-4"
              />
              <v-text-field
                dense
                disabled
                prefix="$"
                label="Contado"
                v-model="cash_computed"
                class="mt-4"
              />
              <v-text-field
                dense
                disabled
                prefix="$"
                label="Financiado"
                v-model="data.financed_detail"
                :suffix="financed_detail_percentage"
                class="mt-4"
              />
              <v-text-field
                dense
                :disabled="this.data.areUsedVehiclesEvaluated"
                prefix="$"
                label="Valor usado"
                placeholder="Valor usado"
                v-model="data.used_value"
                @change="applyFilterTransformData(data.used_value, 'used_value', 'magnitude')"
                @focus="applyFilterTransformData(data.used_value, 'used_value', 'number')"
                class="mt-4"
              />
              <v-text-field
                dense
                disabled
                prefix="$"
                label="Total operación"
                placeholder="Total operación"
                v-model="data.total_operation"
                class="mt-4"
              />
            </div>
          </v-card>
        </div>
      </div>
    </v-form>
  </v-stepper-content>
</template>
<script>
import Helper from '../../../helpers/Helper'
import VehicleHelper from '../../../helpers/VehicleHelper'
import { getAllCoverages } from '../../../services/globals'
import { getAllColors, getAllBrands, getAllTransmitions } from '../../../services/vehicles'
import AuthHelper from '../../../helpers/AuthHelper'
import { mapActions, mapState } from 'vuex'
import UsedVehicleCard from './UsedVehicleCard.vue'

export default {
  name: 'StepPayment',
  components: {
    UsedVehicleCard
  },
  props: {
    userDb: Object,
    step: Number,
    fuels: Array,
    saleData: {
      type: Object,
      required: true
    },
    completeData: {
      type: Object,
      required: true
    },
    edit: Boolean,
    valiadationsRules: Object,
    typeSale: String
  },
  data () {
    return {
      VehicleHelper,
      brands: [],
      brandsItems: [],
      colors: [],
      fuelItems: [],
      colorItems: [],
      coverages: [],
      coveragesItems: [],
      transmitionItems: [],
      showExpandTransition1: false,
      showExpandTransition2: false,
      coverageSelected: '',
      usedVehiclesBuyingValues: [],
      areUsedVehiclesEvaluated: false
    }
  },
  methods: {
    ...mapActions(['handleLoading', 'handleAlert']),
    applyFilterTransformData (value, field, filter) {
      this.data[field] = this.$options.filters[filter](value)
    },
    applyFilterForComputed (value, filter) {
      return this.$options.filters[filter](value)
    },
    saleStepPaymentValidate () {
      this.$refs.saleStepThree.validate()
    },
    async getCoverages () {
      try {
        this.handleLoading(true)
        const res = await getAllCoverages(this.userToken)
        this.coverages = res.data.data
        this.coveragesItems = Helper.transformToSelect(this.coverages, 'name', { value: '', text: 'Sin cobertura' })
        this.alertMessage = res.data.message
        this.alertColor = 'success'
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
        this.alertMessage = error?.data?.message ? error.data.message : error
        this.alertColor = 'error'
      } finally {
        this.handleLoading(false)
        this.handleAlert({
          value: true,
          text: this.alertMessage,
          color: this.alertColor
        })
      }
    },
    async getTransmitions () {
      try {
        this.handleLoading(true)
        const res = await getAllTransmitions(this.userToken)
        this.transmitions = res.data.data
        this.transmitionItems = Helper.transformToSelect(this.transmitions, 'name')
        this.alertMessage = res.data.message
        this.alertColor = 'success'
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
        this.alertMessage = error?.data?.message ? error.data.message : error
        this.alertColor = 'error'
      } finally {
        this.handleLoading(false)
        this.handleAlert({
          value: true,
          text: this.alertMessage,
          color: this.alertColor
        })
      }
    },
    async getColors () {
      try {
        this.handleLoading(true)
        const res = await getAllColors(this.userToken)
        this.colors = res.data.data
        this.colorItems = Helper.transformToSelect(this.colors, 'color')
        this.colorItems.forEach(ci => { ci.code = Helper.getItemFromArray(ci.value, this.colors)?.codigo })
        this.alertMessage = res.data.message
        this.alertColor = 'success'
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
        this.alertMessage = error?.data?.message ? error.data.message : error
        this.alertColor = 'error'
      } finally {
        this.handleLoading(false)
        this.handleAlert({
          value: true,
          text: this.alertMessage,
          color: this.alertColor
        })
      }
    },
    async getBrands () {
      try {
        this.handleLoading(true)
        const res = await getAllBrands(this.userToken)
        this.brands = res.data.data
        this.brandsItems = Helper.transformToSelect(this.brands, 'brand')
        this.alertMessage = res.data.message
        this.alertColor = 'success'
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
        this.alertMessage = error?.data?.message ? error.data.message : error
        this.alertColor = 'error'
      } finally {
        this.handleLoading(false)
        this.handleAlert({
          value: true,
          text: this.alertMessage,
          color: this.alertColor
        })
      }
    },
    // borrar si no hace falta
    /*                                               <----------------------
    selectBrand () {
      const brand = Helper.getItemFromArray(this.brandSelected.value, this.brands)
      if (brand !== null) {
        this.setModelsByBrand(brand)
      }
    },
    setModelsByBrand (brand) {
      this.modelsItems = Helper.transformToSelect(brand.vehicles_models, 'model')
    },
    */
    // hasta acá
    toggleExpandTransition1 () {
      this.showExpandTransition1 = !this.showExpandTransition1
    },
    toggleExpandTransition2 () {
      this.showExpandTransition2 = !this.showExpandTransition2
    },
    calculateUsedValue () {
      this.usedVehiclesBuyingValues = []
      this.data.used_vehicle.forEach(uv => {
        if (uv.buying_value !== null && Object.prototype.hasOwnProperty.call(uv, 'buying_value')) {
          this.usedVehiclesBuyingValues.push(parseFloat(this.$options.filters.number(uv.buying_value)))
        } else {
          uv.estimated_price !== ''
            ? this.usedVehiclesBuyingValues.push(parseFloat(this.$options.filters.number(uv.estimated_price)))
            : this.usedVehiclesBuyingValues.push(0)
        }
      })
      if (this.usedVehiclesBuyingValues.length > 0) {
        this.data.used_value = this.$options.filters.magnitude(this.usedVehiclesBuyingValues.reduce((prev, curr) => prev + curr, 0))
        this.data.areUsedVehiclesEvaluated = true
      } else if (this.data.used_vehicle.length === 1 && this.data.used_vehicle[0].buying_value === null) {
        this.data.used_value = 0
        this.data.areUsedVehiclesEvaluated = false
      }
    },
    calculateTotalOperation () {
      this.data.total_operation = this.$options.filters.magnitude(
        parseFloat(this.$options.filters.number(this.data.financed_detail)) +
        parseFloat(this.$options.filters.number(this.data.deposit)) +
        parseFloat(this.$options.filters.number(this.cash_computed)) +
        parseFloat(this.$options.filters.number(this.data.used_value))
      )
    },
    addUsedCarCard () {
      this.data.used_vehicle.push(
        {
          id: '',
          vehicle_id: '',
          vehicle_model_id: '',
          brand: '',
          model: '',
          car_year: '',
          fuel_type: '',
          doors: '',
          color: '',
          domain: '',
          transmition: '',
          buying_value: null,
          estimated_price: ''
        }
      )
    },
    removeUsedCarCard (index) {
      if (this.data.used_vehicle.length > 1) {
        this.data.used_vehicle.splice(index, 1)
      }
      this.calculateUsedValue()
    },
    clearUsedCarCardData () {
      this.data.used_vehicle[0] = {
        id: '',
        vehicle_id: '',
        vehicle_model_id: '',
        brand: '',
        model: '',
        car_year: '',
        fuel_type: '',
        doors: '',
        color: '',
        domain: '',
        transmition: '',
        buying_value: null,
        estimated_price: ''
      }
      this.calculateUsedValue()
    }
  },
  computed: {
    ...mapState(['userToken']),
    data: {
      get () {
        return this.saleData
      },
      set (value) {
        this.$emit('update:data', value)
      }
    },
    financed_detail_percentage: {
      get () {
        if (this.data.financed_detail && this.completeData.vehicle.list_price && this.data.financed_detail !== '0' && this.data.financed_detail !== 0 && this.completeData.vehicle.list_price !== 0 && this.completeData.vehicle.list_price !== '0') {
          return String(((this.applyFilterForComputed(this.data.financed_detail, 'number') * 100) / this.applyFilterForComputed(this.completeData.vehicle.list_price, 'number')).toFixed(2) || '') + '%'
        } else {
          return '0%'
        }
      }
    },
    cash_computed: {
      // seba: revisar si está ok el cálculo del else
      get () {
        if (this.data.financed_detail) {
          return String(this.applyFilterForComputed(parseFloat(
            this.applyFilterForComputed(this.completeData.vehicle.total_operation, 'number')) -
            parseFloat(this.applyFilterForComputed(this.data.deposit, 'number')) -
            parseFloat(this.applyFilterForComputed(this.data.financed_detail, 'number')) -
            parseFloat(this.applyFilterForComputed(this.data.used_value, 'number')), 'magnitude'))
        } else {
          return String(this.applyFilterForComputed(parseFloat(
            this.applyFilterForComputed(this.completeData.vehicle.total_operation, 'number')) -
            parseFloat(this.applyFilterForComputed(this.data.deposit, 'number')) -
            parseFloat(this.applyFilterForComputed(this.data.used_value, 'number')), 'magnitude'))
        }
      }
    }
  },
  async mounted () {
    await this.getCoverages()
    await this.getBrands()
    await this.getColors()
    await this.getTransmitions()
    this.fuelItems = Helper.transformToSelect(this.fuels, 'fuel')
    if (this.edit) {
      this.coverageSelected = {
        value: this.saleData.coverage_id,
        text: ''
      }
      this.calculateUsedValue()
      if (this.data.used_vehicle.length === 0) {
        this.data.used_vehicle.push(
          {
            id: '',
            vehicle_id: '',
            vehicle_model_id: '',
            brand: '',
            model: '',
            car_year: '',
            fuel_type: '',
            doors: '',
            color: '',
            domain: '',
            transmition: '',
            buying_value: null,
            estimated_price: ''
          }
        )
      }
    }
    if (!this.edit) {
      this.saleData.financed_detail = 0
      this.saleData.deposit = 0
      this.saleData.used_value = 0
    }
    this.calculateTotalOperation()
  },
  watch: {
    coverageSelected: {
      deep: true,
      handler () {
        this.data.coverage_id = this.coverageSelected.value
      }
    },
    'data.used_vehicle': {
      deep: true,
      handler () {
        this.calculateUsedValue()
        this.calculateTotalOperation()
      }
    },
    'data.used_value': {
      handler () {
        this.calculateTotalOperation()
      }
    },
    'completeData.vehicle.total_operation': {
      handler () {
        this.calculateTotalOperation()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  ::v-deep {
    .v-col {
      padding: 0;
      margin: 0;
      outline: none;
      border: none;
      box-sizing: border-box;
    }
    :not(.v-input--is-disabled) .v-text-field__suffix {
      color: rgba(0,0,0,.38)
    }
    svg > * {
      fill: $black !important;
    }
  }
</style>
