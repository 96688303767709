<template>
  <svg width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 0.500427L0 3.50043V9.60043C0 14.6004 3.4 19.4004 8 20.5004C12.6 19.4004 16 14.6004 16 9.60043V3.50043L8 0.500427ZM14 9.60043C14 13.6004 11.4 17.3004 8 18.4004C4.6 17.3004 2 13.6004 2 9.60043V4.90043L8 2.60043L14 4.90043V9.60043Z" fill="#ffb70b"/>
    <path d="M9 12.5004H7V14.5004H9V12.5004Z" fill="#9D9D9D"/>
    <path d="M9 5.50043H7V10.5004H9V5.50043Z" fill="#9D9D9D"/>
  </svg>
</template>

<script>
export default {
  name: 'Insurance'
}
</script>
