<template>
  <div class="mt-16 pt-10">
    <p class="mt-6 mb-0 caption">
      Copyright © 2021 LEPIC. Todos los derechos reservados.
    </p>
    <a href="https://www.lepic.com.ar/" target="_blank" class="subtitle-2 text-decoration-none">www.lepic.com.ar/</a>
  </div>
</template>

<script>
export default {
  name: 'LoginCopyright'
}
</script>

<style lang="scss" scoped>

</style>
