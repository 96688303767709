<template>
  <v-data-footer
    :pagination="pagination"
    :options="options"
    @update:options="updateOptions"
    :items-per-page-options="[20, -1]"
  ></v-data-footer>
</template>

<script>
export default {
  name: 'TablePagination',
  props: {
    options: Object,
    pagination: Object,
    updateOptions: Function
  }
}
</script>

<style scoped lang="scss">
.v-data-table .v-data-footer {
  border: none;
}
::v-deep {
  .v-data-footer__select {
    display: none;
  }
  .v-data-footer__pagination {
    @include fontStyle(normal, 12px, normal, $grey);
    font-family: 'Roboto';
    margin-right: 10px;
  }
  .v-data-footer__icons-before {
    width: 30px;
  }
  .v-data-footer__icons-after {
    width: 30px;
    margin-right: 30px;
  }
}
</style>
