<template>
  <div
    style="width: 338px; height: 79px;"
    :style="CallIndicator.background ?
      'background-color: rgba(255, 183, 11, 0.15);' :
      'background-color: rgba(157, 157, 157, 0.05);'"
    class="rounded d-flex align-center"
    :class="CallIndicator.leftPosition ? 'left-position' : 'ml-7'">
    <img
      v-if="CallIndicator.imgSrc"
      src="../../assets/icons/call.svg"
      class="ml-4"
      :alt="CallIndicator.imgAlt">
    <img
      v-if="!CallIndicator.imgSrc"
      src="../../assets/icons/calendar.svg"
      class="ml-4"
      :alt="CallIndicator.imgAlt">
    <div class="d-flex justify-center align-center ml-7">
      <span
        style="font-size: 36px;"
        class="letter-style">{{CallIndicator.indicator}}</span>
    </div>
    <div class="d-flex flex-column justify-start ml-4">
      <span
        style="font-size: 14px;text-align: left;"
        class="letter-style">{{CallIndicator.firstLineText}}</span>
      <span
        style="font-size: 14px;text-align: left;"
        class="letter-style">{{CallIndicator.secondLineText}}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CallIndicators',
  props: {
    CallIndicator: Object
  }
}
</script>

<style lang="scss" scoped>
.left-position {
  margin-left: -20px;
}
.letter-style {
  font-weight: bold;
  color: #FFB70B;
}
</style>
