// import { get } from 'core-js/core/dict'
import axios from './instance'

const configToken = (token) => {
  return {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
}

export const getAllSales = async (token) => {
  try {
    const res = await axios.get('/api/sale', configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}

export const getASaleById = async (token, id) => {
  try {
    const res = await axios.get(`/api/sale/${id}`, configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}

export const getAllClients = async (token) => {
  try {
    const res = await axios.get('/api/clients/', configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}

export const getAllSaleStatuses = async (token) => {
  try {
    const res = await axios.get('/api/sale-status', configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}

export const getASalePdfById = async (token, id) => {
  try {
    const res = await axios.get(`/api/sale/pdf/${id}`, configToken(token))
    await window.open(res.data.data)
    return res
  } catch (err) {
    throw err.response
  }
}

export const getASalePdfByIdInWindow = async (id) => {
  try {
    const res = await axios.open(`/api/sale/pdf/${id}`)
    return res
  } catch (err) {
    throw err.response
  }
}

export const createSale = async (token, sale) => {
  try {
    const res = await axios.post('/api/sale', sale, configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}

export const updateSale = async (token, formDataSale, id) => {
  try {
    const config = configToken(token)
    config.headers['Content-Type'] = 'multipart/form-data'
    const res = await axios.post(`/api/sale/${id}`, formDataSale, config)
    return res
  } catch (err) {
    throw err.response
  }
}
export const updateSaleDistributor = async (token, formDataSale, id) => {
  try {
    const config = configToken(token)
    config.headers['Content-Type'] = 'multipart/form-data'
    const res = await axios.post(`/api/sale/update-distributor/${id}`, formDataSale, config)
    return res
  } catch (err) {
    throw err.response
  }
}

export const updateSaleStatusDistributor = async (token, status, id) => {
  try {
    const config = configToken(token)
    const res = await axios.put(`/api/sale/approval-distributor/${id}?status=${status}`, null, config)
    return res
  } catch (err) {
    throw err.response
  }
}

export const updateSaleStatusSupervisor = async (token, status, id) => {
  try {
    const config = configToken(token)
    const res = await axios.put(`/api/sale/approval-supervisor/${id}?status=${status}`, null, config)
    return res
  } catch (err) {
    throw err.response
  }
}

export const updateSaleStatusGeneralManager = async (token, status, id) => {
  try {
    const config = configToken(token)
    const res = await axios.put(`/api/sale/approval-manager/${id}?status=${status}`, null, config)
    return res
  } catch (err) {
    throw err.response
  }
}

export const updateSaleStatus = async (token, statusId, saleId) => {
  try {
    const res = await axios.put(`/api/sale-status/${saleId}`, { sale_status_id: statusId }, configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}

export const deleteSale = async (token, id) => {
  try {
    const res = await axios.delete(`/api/sale/${id}`, configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}

export const sendAnApplicationToClient = async (token, id) => {
  try {
    const res = await axios.get(`/api/sale/mail/send/${id}), `, configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}
