import { render, staticRenderFns } from "./SaleNewCar.vue?vue&type=template&id=b3455624&scoped=true&"
import script from "./SaleNewCar.vue?vue&type=script&lang=js&"
export * from "./SaleNewCar.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b3455624",
  null
  
)

export default component.exports