import axios from './instance'

const configToken = (token) => {
  return {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
}

export const getByDNI = async (token, type, dni) => {
  try {
    const res = await axios.get('/api/clients/' + type + '/' + dni, configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}

export const getAllClientTypes = async (token) => {
  try {
    const res = await axios.get('/api/clients-types/', configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}
