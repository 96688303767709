<template>
  <v-card-text class="pa-0 d-flex flex-column" style="height: 90%;">
    <p
      style="text-align: left;font-weight: bold;font-size: 24px;letter-spacing: 0.01em;color: #000000;line-height: 34px;"
      class="mt-2"
    >
      {{ questionData.question }}
    </p>
    <transition name="ques">
      <Questions
        ref="sq3q0"
        v-show="currentQues === 0"
        :isCurrency="false"
        :dataSeted="dataSeted"
        :question="questionData.questions[0]"
        :screens_question="null"
        @sendAnswer="confirmName"
        @goToNextQuestion="goToNextQuestion"
      ></Questions>
    </transition>
    <transition name="ques">
      <Questions
        ref="sq3q1"
        v-show="currentQues === 1"
        :isCurrency="false"
        :dataSeted="dataSeted"
        :question="questionData.questions[1]"
        :screens_question="null"
        :sq_aclaration="null"
        @sendAnswer="confirmAddres"
        @goToNextQuestion="goToNextQuestion"
      ></Questions>
    </transition>
    <transition name="ques">
      <Questions
        ref="sq3q2"
        v-show="currentQues === 2"
        :isCurrency="false"
        :dataSeted="dataSeted"
        :question="questionData.questions[2]"
        :screens_question="null"
        :sq_aclaration="null"
        @sendAnswer="confirmPhoneNumber"
        @goToNextQuestion="goToNextQuestion"
      ></Questions>
    </transition>
    <transition name="ques">
      <Questions
        ref="sq3q3"
        v-show="currentQues === 3"
        :isCurrency="false"
        :dataSeted="dataSeted"
        :question="questionData.questions[3]"
        :screens_question="null"
        :sq_aclaration="null"
        @sendAnswer="confirmDNI"
        @goToNextQuestion="goToNextQuestion"
      ></Questions>
    </transition>
    <transition name="ques">
      <Questions
        ref="sq3q4"
        v-show="currentQues === 4"
        :dataSeted="dataSeted"
        :question="questionData.questions[4]"
        :screens_question="null"
        :sq_aclaration="null"
        @sendAnswer="confirmDateOfBirth"
        @goToNextQuestion="goToNextQuestion"
      ></Questions>
    </transition>
    <transition name="ques">
      <Questions
        ref="sq3q5"
        v-show="currentQues === 5"
        :isCurrency="false"
        :dataSeted="dataSeted"
        :question="questionData.questions[5]"
        :screens_question="null"
        :sq_aclaration="null"
        @sendAnswer="confirmOccupation"
        @changeDataSeted="changeDataSeted"
        @goToNextQuestion="goToNextQuestion"
      ></Questions>
    </transition>
    <transition name="ques">
      <Questions
        ref="sq3q6"
        v-show="currentQues === 6"
        :dataSeted="dataSeted"
        :question="questionData.questions[6]"
        :screens_question="null"
        :sq_aclaration="null"
        :btnWidth="180"
        @selectOption="confirmMaritalStatus"
        @goToNextQuestion="goToNextQuestion"
      ></Questions>
    </transition>
    <transition name="ques">
      <NextQuestionButton
        v-if="showButton"
        @goToNextQuestion="goToNextQuestion"
      ></NextQuestionButton>
    </transition>
  </v-card-text>
</template>

<script>
import Questions from '@/components/savings/blocks/Questions'
import NextQuestionButton from '@/components/savings/blocks/NextQuestionButton'
import { mapGetters } from 'vuex'
import { answerData } from '@/mixins/answerData'

export default {
  name: 'SQuesThree',
  components: {
    Questions,
    NextQuestionButton
  },
  props: {
    questionData: Object
  },
  data () {
    return {
      showButton: false,
      alwaysShowButton: false,
      currentQues: 0,
      dataSeted: false,
      question0: '',
      question1: '',
      question2: '',
      question3: '',
      question4: '',
      question5: '',
      progress: [6, 8, 10, 11, 13, 15],
      indexProgress: 0,
      sq_data: [
        {
          question_id: this.questionData.questions[0].id,
          ponderation: '',
          updated_at: ''
        },
        {
          question_id: this.questionData.questions[1].id,
          ponderation: '',
          updated_at: ''
        },
        {
          question_id: this.questionData.questions[2].id,
          ponderation: '',
          updated_at: ''
        },
        {
          question_id: this.questionData.questions[3].id,
          ponderation: '',
          updated_at: ''
        },
        {
          question_id: this.questionData.questions[4].id,
          ponderation: '',
          updated_at: ''
        },
        {
          question_id: this.questionData.questions[5].id,
          ponderation: '',
          updated_at: ''
        },
        {
          question_id: this.questionData.questions[6].id,
          ponderation: '',
          updated_at: ''
        }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'getOperationNumber',
      'scoringData',
      'isBrowsingInScoringHistory',
      'continueScoring',
      'getIndexOfCurrentSQ',
      'getHistory',
      'SQNameByNumber',
      'sq_number'
    ])
  },
  methods: {
    setData (sqdata) {
      if (this.scoringData[sqdata] !== null) {
        this.dataSeted = true
        this.showButton = true
        this.alwaysShowButton = true
        this.$emit('handleProgress', 5)
        console.log('scoringData', this.scoringData[sqdata])
        this.$refs.sq3q0.setAnswer(this.scoringData[sqdata][0].ponderation)
        this.$refs.sq3q1.setAnswer(this.scoringData[sqdata][1].ponderation)
        this.$refs.sq3q2.setAnswer(this.scoringData[sqdata][2].ponderation)
        this.$refs.sq3q3.setAnswer(
          this.$options.filters.magnitude(
            this.scoringData[sqdata][3].ponderation
          )
        )
        this.$refs.sq3q4.setAnswer(this.scoringData[sqdata][4].ponderation)
        this.$refs.sq3q5.setAnswer(this.scoringData[sqdata][5].ponderation)
        this.$refs.sq3q6.setAnswer(this.scoringData[sqdata][6].ponderation)
        for (let i = 0; i < 7; i++) {
          this.sq_data[i].ponderation = this.scoringData[sqdata][i].ponderation
          this.sq_data[i].updated_at = this.scoringData[sqdata][i].updated_at
          if (this.continueScoring) {
            if (this.scoringData[sqdata][0].id !== undefined) {
              this.sq_data[i].id = this.scoringData[sqdata][i].id
            }
          }
        }
        if (this.isBrowsingInScoringHistory) {
          this.question0 = this.scoringData[sqdata][0].ponderation
          this.question1 = this.scoringData[sqdata][1].ponderation
          this.question2 = this.scoringData[sqdata][2].ponderation
          this.question3 = this.scoringData[sqdata][3].ponderation
          this.question4 = this.scoringData[sqdata][4].ponderation
          this.question5 = this.scoringData[sqdata][5].ponderation
          this.question6 = this.scoringData[sqdata][6].ponderation
          this.historyFirstValue = this.getHistory
        }
      }
    },
    confirmName (value) {
      this.dataFromQuestions(value, 0)
    },
    confirmAddres (value) {
      this.dataFromQuestions(value, 1)
    },
    confirmPhoneNumber (value) {
      this.dataFromQuestions(value, 2)
    },
    confirmDNI (value) {
      this.dataFromQuestions(value, 3)
    },
    confirmDateOfBirth (value) {
      this.dataFromQuestions(value, 4)
    },
    confirmOccupation (value) {
      this.dataFromQuestions(value, 5)
    },
    confirmMaritalStatus (value) {
      this.dataFromQuestions(value, 6)
    },
    goToNextQuestion () {
      this.handleIfEditQues(0, this.question0)
      this.handleIfEditQues(1, this.question1)
      this.handleIfEditQues(2, this.question2)
      this.handleIfEditQues(3, this.question3)
      this.handleIfEditQues(4, this.question4)
      this.handleIfEditQues(5, this.question5)
      this.handleIfEditQues(6, this.question6)
      if (this.currentQues < 6) {
        this.currentQues++
        this.$emit('handleProgress', this.progress[this.indexProgress])
        this.indexProgress++
        if (this.alwaysShowButton) {
          this.showButton = true
        } else {
          this.showButton = false
        }
      } else if (this.currentQues === 6) {
        this.$emit('handleProgress', 16)
        this.sq_data[3].ponderation = this.$options.filters.number(
          this.sq_data[3].ponderation
        )
        this.$store.dispatch('handleScoringData', this.sq_data)
        if (this.isBrowsingInScoringHistory) {
          this.$emit('reduceDecreasing', 1)
          this.$store.dispatch('handleOnlyCurrentSQ', 4)
        } else {
          this.$store.dispatch('handleCurrentSQ', 4)
        }
        this.$emit('checkIfHistoryModeContinues')
      }
    }
  },
  mixins: [answerData],
  mounted () {
    this.setData('sq_threeData')
  }
}
</script>

<style lang="scss" scoped>
.ques-enter-active {
  transition: opacity 0.5s;
}
.ques-enter {
  opacity: 0;
}
</style>
