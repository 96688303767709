<template>
<v-tooltip bottom>
  <template class="mx-1 tableMarkIndicator" v-slot:activator="{ on, attrs }">
    <v-icon
      v-bind="attrs"
      v-on="on"
      size="25"
      class="mx-1"
      :class="{
          'isOn': showIndicator
        }"
      >{{ icon }}</v-icon>
  </template>
  <span>{{ tooltipText }}</span>
</v-tooltip>
</template>

<script>
export default {
  name: 'TableMarkIndicator',
  props: {
    showIndicator: Boolean,
    tooltipText: String,
    icon: String
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  svg > path {
    color: #d9d9d9 !important;
    fill: #d9d9d9 !important;
  }
}

.isOn {
  &::v-deep {
    svg > * {
      fill: $colorPrimary !important;
    }
  }
}
</style>
