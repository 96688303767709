<template>
  <div>
    <AdvancedFilter @filter="filter">
      <template v-slot:filterOptions>
        <EvaluationsFilterOptions
          :lines="lines"
          :models="models">
        </EvaluationsFilterOptions>
      </template>
    </AdvancedFilter>
    <Table
      v-if="!addOrEditEvaluation"
      :title="tableData.title"
      :headers="tableData.headers"
      :items="tableData.items"
      :hideDefaultTableHeader="hideDefaultTableHeader"
      @rowClicked="rowClicked"
      >
      <template v-slot:chipStatus="slotProps">
        <TableMarkStatus :text="slotProps.item.status" :color="slotProps.item.color"></TableMarkStatus>
      </template>

      <template v-slot:tableToolbar="slotProps">
        <TableToolbar
          :title="tableData.title"
          :btnAdd="tableData.btnAdd"
          @handleAddOrUpdate="handleAddOrUpdate(true)"
          :pagination="slotProps.pagination"
          :options="slotProps.options"
          :updateOptions="slotProps.updateOptions"
          >
          <template v-slot:toolbarFilterButton>
            <TableFilterButton @filter="filter"></TableFilterButton>
          </template>

          <template v-slot:paginationTable="slotProps">
            <TablePagination
              :pagination="slotProps.pagination"
              :options="slotProps.options"
              :updateOptions="slotProps.updateOptions" >

            </TablePagination>
          </template>
        </TableToolbar>
      </template>

      <template v-slot:componentDelete>
        <TableDeleteItem
          :dialogDelete="dialogDelete"
          @handleDialogDelete="handleDialogDelete"
          @closeDelete="closeDelete" />
      </template>

      <template v-slot:no-data>
        <TableNoData />
      </template>
    </Table>
    <CreateOrEditEvaluation
      ref="createEval"
      v-if="addOrEditEvaluation"
      :itemToEditData="itemToEditData"
      @editEvaluation="editingEvaluation"
      @close="close"
      @stopEditing='stopEditing'
      @addEvaluation="addEvaluation"
      @updateEvaluationTable="updateEvaluationTable"
      @getEvaluations="getEvaluations"
    ></CreateOrEditEvaluation>
  </div>
</template>

<script>
import Table from '@/components/tables/Table'
import TableNoData from '@/components/tables/TableNoData'
import TableToolbar from '@/components/tables/TableToolbar'
import TablePagination from '@/components/tables/TablePagination'
import TableFilterButton from '../components/tables/TableFilterButton'
import TableMarkStatus from '@/components/tables/TableMarkStatus'
import TableDeleteItem from '@/components/tables/TableDeleteItem'
import AdvancedFilter from '@/components/tables/AdvancedFilter'
import CreateOrEditEvaluation from '@/components/evaluations/CreateOrEditEvaluation'
import EvaluationsFilterOptions from '@/components/evaluations/EvaluationsFilterOptions'
import { mapGetters, mapActions, mapState } from 'vuex'
import { getAllEvaluations } from '@/services/evaluations'
import { getAllModels } from '@/services/globals'
import { getAllVehicleLines } from '@/services/vehicles'
import AuthHelper from '@/helpers/AuthHelper'
import { EventBus } from '@/EventBus.js'
import Helper from '@/helpers/Helper'

export default {
  name: 'Evaluations',
  components: {
    Table,
    TableNoData,
    TableToolbar,
    TablePagination,
    TableFilterButton,
    TableMarkStatus,
    TableDeleteItem,
    AdvancedFilter,
    CreateOrEditEvaluation,
    EvaluationsFilterOptions
  },
  data () {
    return {
      allEvaluations: [],
      evaluationsReceived: false,
      modelsReceived: false,
      models: [],
      lines: [],
      evaluationStatuses: [],
      tableData: {
        title: 'Peritajes',
        headers: [
          {
            text: 'Nro',
            value: 'number',
            class: 'white'
          },
          {
            text: 'Dominio',
            value: 'domain',
            class: 'white'
          },
          {
            text: 'Vehículo',
            value: 'vehicle',
            class: 'white'
          },
          {
            text: 'Solicitante',
            value: 'applicant',
            class: 'white'
          },
          {
            text: 'Valuación',
            value: 'valuation',
            class: 'white'
          },
          {
            text: 'Estado',
            value: 'status',
            class: 'white'
          },
          {
            text: '',
            value: 'actions',
            class: 'white'
          }
        ],
        items: [],
        btnAdd: {
          title: 'Nuevo Peritaje'
        }
      },
      hideDefaultTableHeader: false,
      dialogDelete: false,
      itemToDelete: {},
      itemToEditData: {
        vehicle_data: {},
        client_data: {},
        application_detail: {},
        checklist: {},
        expertise_evaluation: [],
        valuation: {}
      },
      filteredEvaluations: []
    }
  },
  computed: {
    ...mapGetters(['addOrEditEvaluation', 'searchEvaluationsData']),
    ...mapState(['userToken']),
    modelsAndEvaluationsReceived () {
      return (this.modelsReceived && this.evaluationsReceived) ? 1 : 0
    }
  },
  methods: {
    ...mapActions(['handleAdvancedFilter', 'handleLoading', 'handleAlert']),
    rowClicked (item, rowData) {
      this.editingEvaluation(item)
    },
    async getEvaluations () {
      this.handleLoading(true)
      this.allEvaluations = []
      this.filteredEvaluations = []
      try {
        const res = await getAllEvaluations(this.userToken)
        this.allEvaluations = res.data.data.reverse()
        console.log(this.allEvaluations)
        this.filteredEvaluations = [...this.allEvaluations]
        this.evaluationsReceived = true
        this.alertMessage = res.data.message
        this.alertColor = 'success'
        this.handleLoading(false)
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
        this.alertMessage = error?.data?.message
        this.alertColor = 'error'
        this.handleLoading(false)
      } finally {
        this.handleAlert({
          value: true,
          text: this.alertMessage,
          color: this.alertColor
        })
      }
    },
    async getLines () {
      try {
        const res = await getAllVehicleLines(this.userToken)
        this.lines = res.data.data
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
      }
    },
    async getModels () {
      try {
        const res = await getAllModels(this.userToken)
        this.models = res.data.data
        this.modelsReceived = true
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
      }
    },
    formatEvaluationsTable (data) {
      this.tableData.items = []
      data.forEach((u, index) => {
        this.tableData.items.push(this.getFormatEvaluationsTable(u))
        if (u.status.id === 1) {
          this.tableData.items[index].actions.push({
            icon: 'mdi-clipboard-check-outline',
            text: 'Peritar',
            fn: (item) => {
              this.makingEvaluation(item)
            },
            classes: ''
          })
        } else {
          this.tableData.items[index].actions.push({
            icon: 'mdi-pencil',
            text: 'Editar',
            fn: (item) => {
              this.editingEvaluation(item)
            },
            classes: ''
          })
        }
      })
    },
    getFormatEvaluationsTable (u) {
      return {
        id: u.id,
        number: u.id,
        domain: u.vehicle.domain !== null ? this.$options.filters.uppercase(u.vehicle.domain) : 'Sin dominio',
        vehicle: this.$options.filters.uppercase(this.models[this.getModel(u.vehicle.vehicle_model_id)].model),
        applicant: u.client.name + ' ' + u.client.lastname,
        valuation: u.buying_value !== null ? this.$options.filters.currency(u.buying_value) : 'Sin valuación',
        status: u.status.name,
        color: u.status.color,
        actions: []
      }
    },
    getModel (id) {
      const index = this.models.findIndex(e => {
        return id === e.id
      })
      return index
    },
    handleAddOrUpdate (value) {
      this.$store.dispatch('handleShowAddOrEditEvaluation', value)
      this.dateOfOperation()
    },
    addEvaluation (evaluation) {
      this.allEvaluations.unshift(evaluation)
      this.formatEvaluationsTable(this.allEvaluations)
    },
    updateEvaluationTable (evaluation) {
      const index = this.allEvaluations.findIndex(a => a.id === evaluation.id)
      if (index !== -1) {
        this.allEvaluations[index] = evaluation
      }
      this.formatEvaluationsTable(this.allEvaluations)
    },
    filter () {
      this.filteredEvaluations = this.allEvaluations
      if (!this.allFilteredOptionsEmpty()) {
        this.filteredEvaluations = this.allEvaluations.filter(value => value.vehicle.vehicle_model.vehicle_line_id === this.searchEvaluationsData.line && value.vehicle.vehicle_model_id === this.searchEvaluationsData.model)
      }
      this.formatEvaluationsTable(this.filteredEvaluations)
      this.handleAdvancedFilter(false)
    },
    allFilteredOptionsEmpty () {
      return (this.searchEvaluationsData.line === '' && this.searchEvaluationsData.model === '')
    },
    handleDialogDelete (value) {
      this.dialogDelete = value
    },
    closeDelete () {
      this.handleDialogDelete(false)
      this.itemToDelete = {}
    },
    editingEvaluation (item) {
      this.$store.dispatch('handleShowAddOrEditEvaluation', true)
      this.$store.dispatch('handleEditEvaluation', true)
      this.setItemData(item)
      this.handleLoading(true)
    },
    setItemData (i) {
      const index = this.getItem(i.id)
      this.itemToEditData = this.allEvaluations[index]
      this.$store.dispatch('handleDataForMakeOrEdit', {
        id: this.itemToEditData.id,
        vehicle_id: this.itemToEditData.vehicle_id
      })
    },
    getItem (id) {
      const index = this.allEvaluations.findIndex(e => {
        return id === e.id
      })
      return index
    },
    makingEvaluation (item) {
      this.$store.dispatch('handleShowAddOrEditEvaluation', true)
      this.$store.dispatch('handleMakeEvaluation', true)
      this.setItemData(item)
      this.handleLoading(true)
    },
    close () {},
    stopEditing () {},
    dateOfOperation () {
      const date = new Date()
      const options = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        timeZone: 'America/Argentina/Buenos_Aires'
      }
      const dateWithFormat = new Intl.DateTimeFormat('es-AR', options)
      const dissectionDate = dateWithFormat.formatToParts(date)
      this.improvingDateFormat(dissectionDate)
      const dateOfOperation = dissectionDate.map(this.mapingDissectionDate).join('')
      return this.$store.dispatch('handleEvaluationDate', dateOfOperation)
    },
    improvingDateFormat (element) {
      for (let i = 0; i < element.length; i++) {
        if (element[i].value === '/') {
          element[i].value = '-'
        }
      }
      return element
    },
    mapingDissectionDate (element) {
      const onlyValue = element.value
      return onlyValue
    },
    searchEvaluations (evaluations, search) {
      this.filteredEvaluations = this.allEvaluations
      if (search !== '') {
        search = search.toString().toLocaleLowerCase()
        this.allEvaluations.forEach(e => {
          e.domain = e.vehicle.domain
          e.applicant = e.client.name + ' ' + e.client.lastname
        })
        this.filteredEvaluations = evaluations.filter(u => this.filterEvaluationsBySearchParam(u, search))
      } else {
        this.filteredEvaluations = this.allEvaluations
      }
      this.formatEvaluationsTable(this.filteredEvaluations)
    },
    filterEvaluationsBySearchParam (evaluation, search) {
      return Helper.searchItemByField(evaluation, 'id', search) ||
        Helper.searchItemByField(evaluation, 'domain', search) ||
        Helper.searchItemByField(evaluation, 'applicant', search)
    }
  },
  watch: {
    modelsAndEvaluationsReceived () {
      if (this.modelsAndEvaluationsReceived === 1) {
        this.formatEvaluationsTable(this.allEvaluations)
      }
    }
  },
  async mounted () {
    await this.getEvaluations()
    await this.getLines()
    await this.getModels()
    EventBus.$on('searchEvaluations', (input) => this.searchEvaluations(this.allEvaluations, input))
  },
  destroyed () {
    this.evaluationsReceived = false
    this.modelsReceived = false
  }
}
</script>

<style lang="scss" scoped>

</style>
