<template>
  <div>
    <AdvancedFilter @filter="filter">
      <template v-slot:filterOptions>
        <AccessoriesFilterOptions :lines="lines" :models="models"></AccessoriesFilterOptions>
      </template>
    </AdvancedFilter>
    <Table
      v-if="!addOrEditAccessory"
      :title="tableData.title"
      :headers="tableData.headers"
      :items="tableData.items"
      :hideDefaultTableHeader="hideDefaultTableHeader"
      @rowClicked="rowClicked"
      >
      <template v-slot:showImg="slotProp">
        <TableShowImg :img="slotProp.item.img"></TableShowImg>
      </template>

      <template v-slot:tableToolbar="slotProps">
        <TableToolbar
          :title="tableData.title"
          :btnAdd="tableData.btnAdd"
          @handleAddOrUpdate="handleAddOrUpdate(true)"
          :pagination="slotProps.pagination"
          :options="slotProps.options"
          :updateOptions="slotProps.updateOptions"
          >
          <template v-slot:toolbarFilterButton>
            <TableFilterButton @filter="filter"></TableFilterButton>
          </template>

          <template v-slot:paginationTable="slotProps">
            <TablePagination
              :pagination="slotProps.pagination"
              :options="slotProps.options"
              :updateOptions="slotProps.updateOptions" >

            </TablePagination>
          </template>
        </TableToolbar>
      </template>

      <template v-slot:componentDelete>
        <TableDeleteItem
          :dialogDelete="dialogDelete"
          @handleDialogDelete="handleDialogDelete"
          @closeDelete="closeDelete"
          @deleteItemConfirm="deleteItemConfirm" />
      </template>

      <template v-slot:no-data>
        <TableNoData />
      </template>
    </Table>
    <AddOrEditAccessory
      v-if="addOrEditAccessory"
      :models="models"
      :accessoryToEdit="accessoryToEdit"
      @stopEditing="stopEditing"
      @addAccesory="addAccesory"
      @updateAccesory="updateAccesory"
      @setValueFilterWhenCancel="setValueFilterWhenCancel"
      >
    </AddOrEditAccessory>
  </div>
</template>

<script>
import Table from '@/components/tables/Table'
import AddOrEditAccessory from '@/components/accessories/AddOrEditAccessory'
import TableDeleteItem from '@/components/tables/TableDeleteItem'
import TableNoData from '@/components/tables/TableNoData'
import TableToolbar from '@/components/tables/TableToolbar'
import TablePagination from '@/components/tables/TablePagination'
import TableFilterButton from '../components/tables/TableFilterButton'
import AdvancedFilter from '@/components/tables/AdvancedFilter'
import AccessoriesFilterOptions from '@/components/accessories/AccessoriesFilterOptions'
import AuthHelper from '@/helpers/AuthHelper'
import { mapState, mapGetters, mapActions } from 'vuex'
import { getAllAccessories, deleteAccessory } from '@/services/accessories'
import { getAllModels } from '@/services/globals'
import { getAllVehicleLines } from '@/services/vehicles'
import TableShowImg from '@/components/tables/TableShowImg'
import { EventBus } from '@/EventBus.js'
import Helper from '@/helpers/Helper'

export default {
  name: 'Accessories',
  components: {
    AdvancedFilter,
    Table,
    AddOrEditAccessory,
    TableDeleteItem,
    TableNoData,
    TableToolbar,
    TablePagination,
    TableFilterButton,
    AccessoriesFilterOptions,
    TableShowImg
  },
  data () {
    return {
      allAccessories: [],
      accessories: [],
      accessoryToEdit: {},
      lines: [],
      tableData: {
        title: 'Accesorios',
        headers: [
          {
            text: 'Imagen',
            value: 'img',
            class: 'white'
          },
          {
            text: 'Codigo',
            value: 'code',
            class: 'white'
          },
          {
            text: 'Nombre',
            value: 'name',
            class: 'white'
          },
          {
            text: 'Descripción',
            value: 'description',
            class: 'white'
          },
          {
            text: 'Valor',
            value: 'value',
            class: 'white'
          },
          {
            text: '',
            value: 'actions',
            class: 'white'
          }
        ],
        items: [],
        btnAdd: {
          title: 'Nuevo Accesorio'
        }
      },
      editedIndex: -1,
      editedItem: {},
      userToEdit: null,
      hideDefaultTableHeader: false,
      dialogDelete: false,
      itemToDelete: {},
      models: [],
      filteredAccessories: []
    }
  },
  computed: {
    ...mapState(['userToken']),
    ...mapGetters(['addOrEditAccessory', 'findModel', 'searchAccesoriesData'])
  },
  methods: {
    ...mapActions(['handleAlert', 'handleLoading', 'handleAdvancedFilter']),
    rowClicked (item, rowData) {
      this.editAccessory(item)
    },
    addAccesory (accesory) {
      this.allAccessories.unshift(accesory)
      this.formatAccessoriesTable(this.allAccessories)
    },
    updateAccesory (accesory) {
      const index = this.allAccessories.findIndex(a => a.id === accesory.id)
      if (index !== -1) {
        this.allAccessories[index] = accesory
      }
      this.formatAccessoriesTable(this.allAccessories)
    },
    async getAccessories () {
      this.handleLoading(true)
      this.filteredAccessories = []
      try {
        const res = await getAllAccessories(this.userToken)
        this.allAccessories = res.data.data
        this.formatAccessoriesTable(this.allAccessories)
        this.filteredAccessories = [...this.allAccessories]
        this.alertMessage = res.data.message
        this.alertColor = 'success'
        this.handleLoading(false)
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
        this.alertMessage = error?.data?.message
        this.alertColor = 'error'
        this.handleLoading(false)
      } finally {
        this.handleAlert({
          value: true,
          text: this.alertMessage,
          color: this.alertColor
        })
      }
    },
    formatAccessoriesTable (data) {
      this.tableData.items = []
      data.forEach(u => {
        this.tableData.items.push(this.getFormatAccessoriesTable(u))
      })
    },
    getFormatAccessoriesTable (u) {
      return {
        id: u.id,
        img: u.img,
        code: u.code,
        name: u.name,
        description: u.description,
        value: this.$options.filters.currency(u.value),
        models: u.models,
        actions: [
          {
            text: 'Editar',
            icon: 'mdi-pencil',
            fn: (item) => {
              this.editAccessory(item)
            },
            classes: ''
          },
          {
            text: 'Eliminar Accesorio',
            icon: 'mdi-block-helper',
            fn: (item) => {
              this.deleteAccessory(item)
            },
            classes: 'option-delete'
          }
        ],
        data: u
      }
    },
    stopEditing () {
      this.accessoryToEdit = {}
    },
    async getLines () {
      try {
        const res = await getAllVehicleLines(this.userToken)
        this.lines = res.data.data
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
      }
    },
    async getModels () {
      try {
        const res = await getAllModels(this.userToken)
        this.models = res.data.data
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
      }
    },
    filter () {
      if (this.searchAccesoriesData.line === '' && this.searchAccesoriesData.model === '') {
        this.filteredAccessories = this.allAccessories
      } else {
        this.filteredAccessories = this.allAccessories.filter(accessory => (this.filterByModelInLine(accessory) && this.filterByModel(accessory)))
      }
      this.formatAccessoriesTable(this.filteredAccessories)
      this.handleAdvancedFilter(false)
    },
    filterByModelInLine (accessory) {
      return this.searchAccesoriesData.line === '' || accessory.models.some(m => m.vehicle_line_id === this.searchAccesoriesData.line)
    },
    filterByModel (accessory) {
      return this.searchAccesoriesData.model === '' || accessory.models.some(m => m.id === this.searchAccesoriesData.model)
    },
    handleAddOrUpdate (value) {
      this.$store.dispatch('handleShowAddOrEditAccessory', value)
    },
    handleDialogDelete (value) {
      this.dialogDelete = value
    },
    closeDelete () {
      this.handleDialogDelete(false)
      this.itemToDelete = {}
    },
    editAccessory (item) {
      this.accessoryToEdit = item
      this.accessoryToEdit.value = this.$options.filters.number(this.accessoryToEdit.value)
      this.accessoryToEdit.value = this.$options.filters.magnitude(this.accessoryToEdit.value)
      this.$store.dispatch('handleEditAccessory', true)
      this.$store.dispatch('handleShowAddOrEditAccessory', true)
    },
    setValueFilterWhenCancel () {
      this.tableData.items.forEach(i => {
        i.value = this.$options.filters.number(i.value)
        i.value = this.$options.filters.currency(i.value)
      })
    },
    deleteAccessory (item) {
      // this.itemToDelete.push({ id: item.id })
      this.itemToDelete = {
        id: item.id
      }
      this.handleDialogDelete(true)
    },
    async deleteItemConfirm () {
      try {
        this.handleLoading(true)
        const res = await deleteAccessory(this.userToken, this.itemToDelete.id)
        this.alertMessage = res.data.message
        this.alertColor = 'success'
        const index = this.allAccessories.findIndex(a => a.id === this.itemToDelete.id)
        if (index !== -1) {
          this.allAccessories.splice(index, 1)
          this.formatAccessoriesTable(this.allAccessories)
        }
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
        this.alertMessage = error.data.message
        this.alertColor = 'error'
      } finally {
        this.handleLoading(false)
        this.handleAlert({
          value: true,
          text: this.alertMessage,
          color: this.alertColor
        })
        this.closeDelete()
      }
    },
    searchAccessories (accessories, search) {
      if (search !== '') {
        search = search.toString().toLocaleLowerCase()
        this.filteredAccessories = accessories.filter(u => this.filterAccesoriesBySearchParam(u, search))
      } else {
        this.filteredAccessories = this.allAccessories
      }
      this.formatAccessoriesTable(this.filteredAccessories)
    },
    filterAccesoriesBySearchParam (accessory, search) {
      return Helper.searchItemByField(accessory, 'name', search) || Helper.searchItemByField(accessory, 'description', search)
    }
  },
  mounted () {
    this.getAccessories()
    this.getModels()
    this.getLines()
    EventBus.$on('searchAccessories', (input) => this.searchAccessories(this.allAccessories, input))
  }
}
</script>
