<template>
  <SingleSQ
    :questionData="questionData"
    :btnWidth="160"
    :oneOption="false"
    :pendingOption="false"
    :sq_numberData="'sq_eightData'"
    :sqOptions="[ 9, 28]"
    @editQuestion="editQuestion"
    @deleteQuestion="deleteQuestion"
    @checkIfHistoryModeContinues="checkIfHistoryModeContinues"
    @reduceDecreasing="reduceDecreasing"
    @handleProgress="handleProgress">
  </SingleSQ>
</template>

<script>
import SingleSQ from '@/components/savings/blocks/SingleSQ'
import { singleQuesMethods } from '@/mixins/singleQuesMethods'

export default {
  name: 'SQuesEight',
  components: {
    SingleSQ
  },
  props: {
    questionData: Object
  },
  methods: {
    handleProgress (value) {
      if (value === 1) {
        this.$emit('handleProgress', 64)
      } else if (value === 2) {
        this.$emit('handleProgress', 61)
      } else if (value === 3) {
        this.$emit('handleProgress', 60)
      }
    }
  },
  mixins: [singleQuesMethods]
}
</script>
