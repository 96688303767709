import axios from './instance'

const configToken = (token) => {
  return {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
}

export const userLogin = async (body) => {
  try {
    const res = await axios.post('/api/auth/login', body)
    return res
  } catch (err) {
    throw err.response
  }
}

export const userLogout = async (token) => {
  try {
    const res = await axios.post('/api/auth/logout', null, configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}
