<template>
  <div
    class="d-flex justify-space-between align-center my-6">
    <span class="black--text">{{ text }}</span>
    <span class="d-flex flex-grow-1" aria-hidden="true"></span>
    <v-switch
      v-model="modelComputed"
      inset
      :disabled="disabled"
      flat
      color="primary"
      >
      </v-switch>
  </div>
</template>

<script>

export default {
  name: 'CustomSwitch',
  props: {
    text: String,
    disabled: {
      Boolean,
      default: false
    },
    model: Boolean
  },
  computed: {
    modelComputed: {
      get () {
        return this.model
      },
      set (value) {
        this.$emit('update:model', value)
      }
    }
  }

}
</script>

<style lang="scss" scoped>
::v-deep{
  span {
    font-size: 16px;
    letter-spacing: 0.01em;
  }
  .v-input__slot {
    margin: 0;
  }
  div.v-messages {
    height: 0px;
    min-height: 0px;
  }
}
</style>
