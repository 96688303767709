<template>
  <v-container fluid class="mt-8" style="margin: 0 auto">
    <v-row class="d-flex justify-space-between ma-0">
      <v-col cols="12" sm="8" class="d-flex align-center">
        <div class="header-movil">
          <v-btn
            elevation="0"
            fab
            color="transparent"
            class="mr-4"
            @click.stop="exitForm(true)">
            <v-icon>$exitform</v-icon>
          </v-btn>
          <span class="number-font mr-9 text-no-wrap">Nº {{newEvaluation.operation_number}}</span>
          <v-chip
            class="rounded mr-8 btn-draftcopy__custom text-uppercase"
            :color="(makeEvaluation || editEvaluation) ? status.color + 26 : '#F5F5F7'"
            :text-color="(makeEvaluation || editEvaluation) ? status.color : '#9D9D9D'"
            label
            >{{ (makeEvaluation || editEvaluation) ? status.name : 'BORRADOR' }}</v-chip>
          <div class="d-flex flex-column align-start mr-6">
            <span class="date-title-font">Fecha</span>
            <span class="text-no-wrap"> {{ newEvaluation.date }} </span>
          </div>
        </div>
        <div class="mt-3 text-no-wrap text-truncate movil" v-if="vehicle_data.brand.text">
          <span>{{ vehicle_data.brand.text ? vehicle_data.brand.text : '' }} </span>
          <span>{{ vehicle_data.model.text ? vehicle_data.model.text : '' }} </span>
          <span>| {{ vehicle_data.car_license_number ? vehicle_data.car_license_number : '' }}</span>
        </div>
      </v-col>
      <v-col
        cols="12"
        sm="4"
        :class="$vuetify.breakpoint.xs ? 'justify-space-between ml-4' : 'justify-end'"
        class="d-flex align-center">
        <v-btn
          v-if="getCurrentEvaluationStep < 4"
          elevation="0"
          class="mr-3 btn-next__custom"
          @click="doNextStep"
        >SIGUIENTE</v-btn>
        <v-btn
          elevation="0"
          :disabled="getCurrentEvaluationStep < 4"
          class="mr-9 my-4 btn-send__custom"
          @click="askIfSaveEvaluation"
          >GUARDAR SOLICITUD</v-btn>
      </v-col>
    </v-row>
    <v-dialog v-model="alertExitForm" max-width="600px">
      <AlertExitForm></AlertExitForm>
    </v-dialog>
    <v-dialog v-model="confirmModal" width="500">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <h1
            class="font-weight-bold my-4 black--text"
            style="font-family: 'NouvelR';letter-spacing: 0.01em;font-size: 22px;"
            >¿Desea confirmar los cambios en el peritaje?</h1>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            style="font-size: 12px;"
            class="mr-3"
            color="grey"
            text
            @click="cancelSaveEvaluation"
            >Cancelar</v-btn>
          <v-btn
            style="background: rgba(245, 245, 247, 0.5);border: 1px solid #000000;font-size: 12px;"
            class="bor-btn rounded mr-5 mt-2 mb-3 black--text"
            elevation="0"
            width="100"
            height="34"
            @click="saveFullEvaluation"
            >Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import AlertExitForm from '@/components/layout/alerts/AlertExitForm'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'CreateEvaluationHeader',
  components: {
    AlertExitForm
  },
  props: {
    status: Object,
    vehicle_data: Object
  },
  data () {
    return {
      confirmModal: false
    }
  },
  computed: {
    ...mapGetters([
      'newEvaluation',
      'getCurrentEvaluationStep',
      'alertExitForm',
      'newEvaluation',
      'editEvaluation',
      'makeEvaluation']),
    ...mapState(['currentEvaluationStep'])
  },
  methods: {
    ...mapActions(['handleCurrentEvaluationStep']),
    doNextStep () {
      switch (this.getCurrentEvaluationStep) {
        case 1:
          this.$emit('thisStep', 2)
          break
        case 2:
          this.$emit('thisStep', 3)
          break
        case 3:
          this.$emit('thisStep', 4)
          break
      }
    },
    exitForm (val) {
      this.$store.dispatch('handleExitForm', val)
    },
    askIfSaveEvaluation () {
      this.$emit('validateStepFour')
    },
    openAskModal () {
      this.confirmModal = true
    },
    cancelSaveEvaluation () {
      this.confirmModal = false
    },
    saveFullEvaluation () {
      if (this.editEvaluation || this.makeEvaluation) {
        this.$emit('editingEvaluation')
      } else {
        this.$emit('createNewEvaluation')
      }
      this.confirmModal = false
    }
  }
}
</script>

<style lang="scss" scoped>
.header-movil {
  display: flex;
  align-items: center;
}
.number-font {
  @include fontStyle(bold, 22px, 0.01em, $black);
}
.date-title-font {
  @include fontStyle(normal, 12px, 0.01em, $grey);
    line-height: 12px;
}
.date-font {
  @include fontStyle(200, 14px, 0.01em, $black);
}
.operation-number-font {
  @include fontStyle(normal, 12px, 0.01em, $grey);
  line-height: 12px;
}
.text-field-width {
    max-width: 140px;
}
::v-deep {
  .v-messages, .v-text-field__details {
    min-height: 4px !important;
    max-height: 5px;
  }
  .btn-draftcopy__custom {
      @include fontStyle(bold, 10px, 0.01em, $grey);
  }
  .btn-next__custom {
      @include fontStyle(normal, 10px, 0.01em, $black);
      border: 1px solid $black !important;
  }
  .btn-send__custom {
      font-size: 10px;
      letter-spacing: 0.01em;
      border: 1px solid $black !important;
  }
  .v-chip__content{
    padding-left: 15px;
    padding-right: 15px;
  }
  input::placeholder {
      @include fontStyle(normal, 12px, 0.01em, $grey);
      padding-left: 10px;
  }
  @media (max-width: 800px) {
    .movil {
      display: none;
    }
    .header-movil {
      width: 100%;
      justify-content: space-between;
    }
  }
}
</style>
