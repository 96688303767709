<template>
  <v-card-text class="pa-0 d-flex flex-column" style="height: 90%;min-height: 600px;">
    <Questions
      ref="sq40q2"
      @goToNextQuestion="goToNextQuestion"
      :dataSeted="dataSeted"
      :users="users"
      :question="questionData.questions[2]"
      :screens_question="questionData.question"
      :sq_aclaration="questionData.aclaration"
      @sendAnswer="userSelected"
    ></Questions>
    <Questions
      ref="sq40q0"
      @goToNextQuestion="goToNextQuestion"
      :isCurrency="false"
      :dataSeted="dataSeted"
      :question="questionData.questions[0]"
      :screens_question="null"
      :sq_aclaration="null"
      @sendAnswer="answerSellerName">
    </Questions>
    <Questions
      ref="sq40q1"
      @goToNextQuestion="goToNextQuestion"
      :isCurrency="false"
      :dataSeted="dataSeted"
      :question="questionData.questions[1]"
      :screens_question="null"
      :sq_aclaration="null"
      @sendAnswer="answerSellerSurname"
      @changeDataSeted="changeDataSeted">
    </Questions>
    <transition name="ques">
      <NextQuestionButton
        v-if="showButton"
        @goToNextQuestion="goToNextQuestion">
      </NextQuestionButton>
    </transition>
  </v-card-text>
</template>

<script>
import Questions from '@/components/savings/blocks/Questions'
import NextQuestionButton from '@/components/savings/blocks/NextQuestionButton'
import { mapGetters } from 'vuex'
import { answerData } from '@/mixins/answerData'

export default {
  name: 'SQuesForty',
  components: {
    Questions,
    NextQuestionButton
  },
  props: {
    questionData: Object,
    users: Array
  },
  data () {
    return {
      showButton: false,
      dataSeted: false,
      question0: '',
      question1: '',
      historyFirstValue: [],
      historyScrap: [],
      sq_data: [
        {
          question_id: this.questionData.questions[0].id,
          ponderation: '',
          updated_at: ''
        },
        {
          question_id: this.questionData.questions[1].id,
          ponderation: '',
          updated_at: ''
        },
        {
          question_id: this.questionData.questions[2].id,
          ponderation: '',
          updated_at: ''
        }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'getOperationNumber',
      'scoringData',
      'isBrowsingInScoringHistory',
      'continueScoring',
      'getIndexOfCurrentSQ',
      'getHistory',
      'SQNameByNumber',
      'sq_number'
    ])
  },
  methods: {
    setData (sqdata) {
      if (this.scoringData[sqdata] !== null) {
        this.dataSeted = true
        this.showButton = true
        this.$emit('handleProgress', 97)
        this.$refs.sq40q0.setAnswer(this.scoringData[sqdata][0].ponderation)
        this.$refs.sq40q1.setAnswer(this.scoringData[sqdata][1].ponderation)
        for (let i = 0; i < 2; i++) {
          this.sq_data[i].ponderation = this.scoringData[sqdata][i].ponderation
          this.sq_data[i].updated_at = this.scoringData[sqdata][i].updated_at
          if (this.continueScoring) {
            if (this.scoringData[sqdata][0].id !== undefined) {
              this.sq_data[i].id = this.scoringData[sqdata][i].id
            }
          }
        }
        if (this.isBrowsingInScoringHistory) {
          this.question0 = this.scoringData[sqdata][0].ponderation
          this.question1 = this.scoringData[sqdata][1].ponderation
          this.historyFirstValue = this.getHistory
        }
      }
    },
    userSelected (value) {
      console.log(value)
      const nameParts = value.split(' ')
      const name = nameParts.slice(0, -1)
      this.answerSellerName(name.join(' '))
      this.answerSellerSurname(nameParts[nameParts.length - 1])
      this.$refs.sq40q0.setAnswer(name.join(' '))
      this.$refs.sq40q1.setAnswer(nameParts[nameParts.length - 1])
    },
    answerSellerName (value) {
      this.dataFromQuestions(value, 0)
    },
    answerSellerSurname (value) {
      this.dataFromQuestions(value, 1)
    },
    goToNextQuestion () {
      this.$emit('handleProgress', 98)
      this.$store.dispatch('handleScoringData', this.sq_data)
      if (this.isBrowsingInScoringHistory) {
        this.$emit('reduceDecreasing', 1)
        this.handleIfEditDecisionQues(0, this.question0)
        this.handleIfEditDecisionQues(1, this.question1)
        this.$store.dispatch('handleOnlyCurrentSQ', 41)
      } else {
        this.$store.dispatch('handleCurrentSQ', 41)
      }
      this.$emit('checkIfHistoryModeContinues')
    }
  },
  mixins: [answerData],
  mounted () {
    this.setData('sq_fortyData')
  }
}
</script>

<style lang="scss" scoped>
.ques-enter-active {
  transition: opacity .5s;
}
.ques-enter {
  opacity: 0;
}
</style>
