<template>
  <div v-if="!createOrEditSaleOpen && !addOrEditAccessory && !addOrEditEvaluation && !addOrEditScoring">
    <v-app-bar app color="white" flat height="90">
      <v-app-bar-nav-icon class="hidden-lg-and-up mx-1" @click.stop="showingDrawer(true)"></v-app-bar-nav-icon>

      <HeaderContent />
    </v-app-bar>
    <v-divider></v-divider>
  </div>
</template>

<script>
import HeaderContent from './HeaderContent'
import { mapGetters } from 'vuex'

export default {
  name: 'Header',

  components: {
    HeaderContent
  },
  computed: {
    ...mapGetters(['createOrEditSaleOpen', 'addOrEditAccessory', 'addOrEditEvaluation', 'addOrEditScoring'])
  },
  methods: {
    showingDrawer (val) {
      this.$store.dispatch('handleMobileMenu', val)
    }
  }
}
</script>

<style lang="scss" scoped></style>
