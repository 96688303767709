<template>
  <v-col cols="12" sm="8" class="px-3">
    <v-card
      elevation="0"
      color="#F5F5F7"
      width="100%"
      height="85vh"
      class="pt-5 pl-10 pr-8 mt-3"
      style="overflow-y: scroll;"
    >
      <transition name="ques">
        <component
          ref="sq1"
          :is="component"
          :questionData="questionData"
          :clientData="clientData"
          :renaultModelItems="renaultModelItems"
          :users="users"
          @handleProgress="handleProgress"
          @deleteQuestion="sendDeleteQuestion"
          @editQuestion="sendEditQuestion"
          @checkIfHistoryModeContinues="checkIfHistoryModeContinues"
          @reduceDecreasing="reduceDecreasing"
          @goBackInScoringHistory="goBackInScoringHistory" />
      </transition>
      <v-card-actions
        class="d-flex justify-end mt-4"
        style="position: fixed;bottom: 7%;right: 40%;"
      >
        <div style="width: 70px;">
          <v-progress-linear
            background-color="#9D9D9D"
            color="#000000"
            v-model="progress"
            height="2"
          ></v-progress-linear>
        </div>
        <span
          class="ml-3"
          style="font-weight: 200;font-size: 14px;letter-spacing: 0.01em;color: #9D9D9D;"
          >{{ progress }}% Completado</span
        >
        <v-btn
          @click="goBackInScoringHistory"
          x-small
          fab
          elevation="0"
          class="ml-2"
          :disabled="sq_number === 1 || decreasing === getHistory.length - 1"
        >
          <v-icon>mdi-chevron-up</v-icon>
        </v-btn>
        <v-btn
          @click="goAheadInScoringHistory"
          x-small
          fab
          elevation="0"
          :disabled="
            sq_number === getHistory[getHistory.length - 1] ||
              scoringData['sq_oneData'] === null
          "
        >
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-col>
</template>

<script>
import { updateQuestion, deleteQuestion } from '@/services/savings'
import AuthHelper from '@/helpers/AuthHelper'
import { mapState, mapGetters, mapActions } from 'vuex'
import SQuesOne from '@/components/savings/screens/ones/SQuesOne'
import SQuesTwo from '@/components/savings/screens/ones/SQuesTwo'
import SQuesThree from '@/components/savings/screens/ones/SQuesThree'
import SQuesFour from '@/components/savings/screens/ones/SQuesFour'
import SQuesFive from '@/components/savings/screens/ones/SQuesFive'
import SQuesSix from '@/components/savings/screens/ones/SQuesSix'
import SQuesSeven from '@/components/savings/screens/ones/SQuesSeven'
import SQuesEight from '@/components/savings/screens/ones/SQuesEight'
import SQuesNine from '@/components/savings/screens/ones/SQuesNine'
import SQuesTen from '@/components/savings/screens/tens/SQuesTen'
import SQuesEleven from '@/components/savings/screens/tens/SQuesEleven'
import SQuesTwelve from '@/components/savings/screens/tens/SQuesTwelve'
import SQuesThirteen from '@/components/savings/screens/tens/SQuesThirteen'
import SQuesFourteen from '@/components/savings/screens/tens/SQuesFourteen'
import SQuesFifteen from '@/components/savings/screens/tens/SQuesFifteen'
import SQuesSixteen from '@/components/savings/screens/tens/SQuesSixteen'
import SQuesSeventeen from '@/components/savings/screens/tens/SQuesSeventeen'
import SQuesEighteen from '@/components/savings/screens/tens/SQuesEighteen'
import SQuesNineteen from '@/components/savings/screens/tens/SQuesNineteen'
import SQuesTwenty from '@/components/savings/screens/twentys/SQuesTwenty'
import SQuesTwentyOne from '@/components/savings/screens/twentys/SQuesTwentyOne'
import SQuesTwentyTwo from '@/components/savings/screens/twentys/SQuesTwentyTwo'
import SQuesTwentyThree from '@/components/savings/screens/twentys/SQuesTwentyThree'
import SQuesTwentyFour from '@/components/savings/screens/twentys/SQuesTwentyFour'
import SQuesTwentyFive from '@/components/savings/screens/twentys/SQuesTwentyFive'
import SQuesTwentyEight from '@/components/savings/screens/twentys/SQuesTwentyEight'
import SQuesTwentyNine from '@/components/savings/screens/twentys/SQuesTwentyNine'
import SQuesThirty from '@/components/savings/screens/thirties/SQuesThirty'
import SQuesThirtyOne from '@/components/savings/screens/thirties/SQuesThirtyOne'
import SQuesThirtyTwo from '@/components/savings/screens/thirties/SQuesThirtyTwo'
import SQuesThirtyThree from '@/components/savings/screens/thirties/SQuesThirtyThree'
import SQuesThirtyFour from '@/components/savings/screens/thirties/SQuesThirtyFour'
import SQuesThirtyFive from '@/components/savings/screens/thirties/SQuesThirtyFive'
import SQuesThirtySix from '@/components/savings/screens/thirties/SQuesThirtySix'
import SQuesThirtySeven from '@/components/savings/screens/thirties/SQuesThirtySeven'
import SQuesThirtyEight from '@/components/savings/screens/thirties/SQuesThirtyEight'
import SQuesThirtyNine from '@/components/savings/screens/thirties/SQuesThirtyNine'
import SQuesForty from '@/components/savings/screens/forties/SQuesForty'
import SQuesFortyOne from '@/components/savings/screens/forties/SQuesFortyOne'
import SQuesFortyTwo from '@/components/savings/screens/forties/SQuesFortyTwo'
import SQuesFortyThree from '@/components/savings/screens/forties/SQuesFortyThree'
import SQuesFortyFour from '@/components/savings/screens/forties/SQuesFortyFour'
import SQuesFortyFive from '@/components/savings/screens/forties/SQuesFortyFive'
import SQuesFortySix from '@/components/savings/screens/forties/SQuesFortySix'
import SQuesFortySeven from '@/components/savings/screens/forties/SQuesFortySeven'
import SQuesFortyEight from '@/components/savings/screens/forties/SQuesFortyEight'
import SQuesFortyNine from '@/components/savings/screens/forties/SQuesFortyNine'
import SQuesFifty from '@/components/savings/screens/fifties/SQuesFifty'
import SQuesFiftyOne from '@/components/savings/screens/fifties/SQuesFiftyOne'
import SQuesFiftyTwo from '@/components/savings/screens/fifties/SQuesFiftyTwo'
import SQuesFiftyThree from '@/components/savings/screens/fifties/SQuesFiftyThree'
import SQuesFiftyFour from '@/components/savings/screens/fifties/SQuesFiftyFour'

export default {
  name: 'Questionnaire',
  components: {
    SQuesOne,
    SQuesTwo,
    SQuesThree,
    SQuesFour,
    SQuesFive,
    SQuesSix,
    SQuesSeven,
    SQuesEight,
    SQuesNine,
    SQuesTen,
    SQuesEleven,
    SQuesTwelve,
    SQuesThirteen,
    SQuesFourteen,
    SQuesFifteen,
    SQuesSixteen,
    SQuesSeventeen,
    SQuesEighteen,
    SQuesNineteen,
    SQuesTwenty,
    SQuesTwentyOne,
    SQuesTwentyTwo,
    SQuesTwentyThree,
    SQuesTwentyFour,
    SQuesTwentyFive,
    SQuesTwentyEight,
    SQuesTwentyNine,
    SQuesThirty,
    SQuesThirtyOne,
    SQuesThirtyTwo,
    SQuesThirtyThree,
    SQuesThirtyFour,
    SQuesThirtyFive,
    SQuesThirtySix,
    SQuesThirtySeven,
    SQuesThirtyEight,
    SQuesThirtyNine,
    SQuesForty,
    SQuesFortyOne,
    SQuesFortyTwo,
    SQuesFortyThree,
    SQuesFortyFour,
    SQuesFortyFive,
    SQuesFortySix,
    SQuesFortySeven,
    SQuesFortyEight,
    SQuesFortyNine,
    SQuesFifty,
    SQuesFiftyOne,
    SQuesFiftyTwo,
    SQuesFiftyThree,
    SQuesFiftyFour
  },
  props: {
    questions: Object,
    renaultModelItems: Array,
    users: Array,
    itemToEditData: Object,
    clientData: Object
  },
  data () {
    return {
      progress: 0,
      typeQuestionOptions: '',
      decreasing: 0,
      sqNumber: {
        1: 'SQuesOne',
        2: 'SQuesTwo',
        3: 'SQuesThree',
        4: 'SQuesFour',
        5: 'SQuesFive',
        6: 'SQuesSix',
        7: 'SQuesSeven',
        8: 'SQuesEight',
        9: 'SQuesNine',
        10: 'SQuesTen',
        11: 'SQuesEleven',
        12: 'SQuesTwelve',
        13: 'SQuesThirteen',
        14: 'SQuesFourteen',
        15: 'SQuesFifteen',
        16: 'SQuesSixteen',
        17: 'SQuesSeventeen',
        18: 'SQuesEighteen',
        19: 'SQuesNineteen',
        20: 'SQuesTwenty',
        21: 'SQuesTwentyOne',
        22: 'SQuesTwentyTwo',
        23: 'SQuesTwentyThree',
        24: 'SQuesTwentyFour',
        25: 'SQuesTwentyFive',
        28: 'SQuesTwentyEight',
        29: 'SQuesTwentyNine',
        30: 'SQuesThirty',
        31: 'SQuesThirtyOne',
        32: 'SQuesThirtyTwo',
        33: 'SQuesThirtyThree',
        34: 'SQuesThirtyFour',
        35: 'SQuesThirtyFive',
        36: 'SQuesThirtySix',
        37: 'SQuesThirtySeven',
        38: 'SQuesThirtyEight',
        39: 'SQuesThirtyNine',
        40: 'SQuesForty',
        41: 'SQuesFortyOne',
        42: 'SQuesFortyTwo',
        43: 'SQuesFortyThree',
        44: 'SQuesFortyFour',
        45: 'SQuesFortyFive',
        46: 'SQuesFortySix',
        47: 'SQuesFortySeven',
        48: 'SQuesFortyEight',
        49: 'SQuesFortyNine',
        50: 'SQuesFifty',
        51: 'SQuesFiftyOne',
        52: 'SQuesFiftyTwo',
        53: 'SQuesFiftyThree',
        54: 'SQuesFiftyFour'
      }
    }
  },
  computed: {
    ...mapState(['userToken']),
    ...mapGetters([
      'sq_number',
      'isBrowsingInScoringHistory',
      'getHistory',
      'scoringData',
      'continueScoringButton',
      'SQNameByNumber'
    ]),
    component () {
      return this.sqNumber[this.sq_number]
    },
    questionData () {
      return this.questions.screens_questions[this.sq_number - 1]
    }
  },
  methods: {
    ...mapActions(['handleLoading']),
    async sendEditQuestion (data) {
      this.handleLoading(true)
      const dataSeted = this.setEditdData(data)
      try {
        const res = await updateQuestion(this.userToken, dataSeted, data.id)
        console.log('edit is working', res.data.message)
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
      } finally {
        this.handleLoading(false)
      }
    },
    sendDeleteQuestion (array) {
      if (array.length !== 0) {
        this.deleteAllQuestions(array)
      }
    },
    async deleteAllQuestions (array) {
      this.handleLoading(true)
      const ids = { ids: array }
      try {
        const res = await deleteQuestion(this.userToken, ids)
        console.log('delete is working', res.data.message)
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
      } finally {
        this.handleLoading(false)
      }
    },
    /* async getTypeQuestionOptions () {
      try {
        const res = await getAllTypesQuestionsOptions(this.userToken)
        this.typeQuestionOptions = res.data.data
      } catch (error) {
        AuthHelper.checkErrorExpiredToken(error)
        console.error(error)
      }
    }, */
    setEditdData (data) {
      return {
        ponderation: data.ponderation,
        updated_at: data.updated_at,
        scoring_status_id: this.itemToEditData.status.id,
        client_id: this.itemToEditData.client.id
      }
    },
    goBackInScoringHistory () {
      this.$store.dispatch('handleIfIsBrowsingInScoringHistory', true)
      this.decreasing++
      this.$store.dispatch(
        'handleOnlyCurrentSQ',
        this.getHistory[this.getHistory.length - 1 - this.decreasing]
      )
      this.$store.dispatch(
        'handleHistoryIndexOfCurrentSQ',
        this.getHistory.length - 1 - this.decreasing
      )
      if (this.continueScoringButton) {
        this.$store.dispatch('handleShowContinueScoringButton', false)
      }
    },
    goAheadInScoringHistory () {
      if (this.sq_number !== this.getHistory[this.getHistory.length - 1]) {
        this.decreasing--
        this.$store.dispatch(
          'handleOnlyCurrentSQ',
          this.getHistory[this.getHistory.length - 1 - this.decreasing]
        )
        this.$store.dispatch(
          'handleHistoryIndexOfCurrentSQ',
          this.getHistory.length - 1 - this.decreasing
        )
        if (this.sq_number === this.getHistory[this.getHistory.length - 1]) {
          this.$store.dispatch('handleIfIsBrowsingInScoringHistory', false)
          this.$store.dispatch('handleHistoryIndexOfCurrentSQ', null)
          this.decreasing = 0
        }
      }
    },
    reduceDecreasing (value) {
      if (value === 1) {
        this.decreasing--
      } else if (value === 0) {
        this.decreasing = 0
      }
    },
    checkIfHistoryModeContinues () {
      if (this.sq_number === this.getHistory[this.getHistory.length - 1]) {
        this.$store.dispatch('handleIfIsBrowsingInScoringHistory', false)
        this.$store.dispatch('handleHistoryIndexOfCurrentSQ', null)
      }
    },
    handleProgress (percentage) {
      this.progress = percentage
    },
    setOperationNumber () {
      this.$refs.sq1.setData('sq_oneData')
    }
  }
}
</script>

<style lang="scss" scoped>
.ques-enter-active {
  transition: opacity 0.5s;
}
.ques-enter {
  opacity: 0;
}
</style>
