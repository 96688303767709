<template>
  <v-container class="ml-2">
    <v-autocomplete
      :items="statusesItems"
      label="Estado"
      v-model="status"
      class="mx-2 mb-4">
    </v-autocomplete>
    <v-autocomplete
      :items="modelsItems"
      label="Modelo"
      v-model="model"
      class="mx-2 mb-4">
    </v-autocomplete>
    <v-autocomplete
      :items="transmitionsItems"
      label="Transmisión"
      v-model="transmition"
      class="mx-2 mb-4">
    </v-autocomplete>
    <v-autocomplete
      :items="typesItems"
      label="Tipo"
      v-model="type"
      class="mx-2 mb-4">
    </v-autocomplete>
  </v-container>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import Helper from '../../helpers/Helper'

export default {
  name: 'VehiclesFilterOptions',
  props: {
    statuses: {
      type: Array,
      required: true
    },
    models: {
      type: Array,
      required: true
    },
    transmitions: {
      type: Array,
      required: true
    },
    types: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    statusesItems: [],
    modelsItems: [],
    transmitionsItems: [],
    typesItems: []
  }),
  computed: {
    ...mapState(['userToken']),
    ...mapGetters(['searchVehiclesData']),
    status: {
      get () {
        return this.searchVehiclesData.status
      },
      set (value) {
        this.$store.dispatch('handleVehicleSearchStatus', value)
      }
    },
    model: {
      get () {
        return this.searchVehiclesData.model
      },
      set (value) {
        this.$store.dispatch('handleVehicleSearchModel', value)
      }
    },
    transmition: {
      get () {
        return this.searchVehiclesData.transmition
      },
      set (value) {
        this.$store.dispatch('handleVehicleSearchTransmition', value)
      }
    },
    type: {
      get () {
        return this.searchVehiclesData.type
      },
      set (value) {
        this.$store.dispatch('handleVehicleSearchType', value)
      }
    }
  },
  methods: {
    ...mapActions(['handleVehicleSearchStatus', 'handleVehicleSearchModel', 'handleVehicleSearchTransmition', 'handleVehicleSearchType'])
  },
  watch: {
    statuses: {
      handler () {
        this.statusesItems = Helper.transformToSelect(this.statuses, 'name', { value: '', text: 'Todos los estados' })
      }
    },
    models: {
      handler () {
        this.modelsItems = Helper.transformToSelect(this.models, 'model', { value: '', text: 'Todos los modelos' })
      }
    },
    transmitions: {
      handler () {
        this.transmitionsItems = Helper.transformToSelect(this.transmitions, 'name', { value: '', text: 'Todas las transmisiones' })
      }
    },
    types: {
      handler () {
        this.typesItems = Helper.transformToSelect(this.types, 'name', { value: '', text: 'Todos los tipos' })
      }
    }
  }
}
</script>
