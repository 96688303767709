<template>
  <v-card-text
    class="pa-0 d-flex flex-column"
    style="height: 90%;min-height: 600px;"
  >
    <Questions
      ref="sq36q0"
      @goToNextQuestion="goToNextQuestion"
      :dataSeted="dataSeted"
      :btnWidth="280"
      :question="questionData.questions[0]"
      :screens_question="questionData.question"
      :sq_aclaration="questionData.aclaration"
      @selectOption="sellerTellsClientAnswerYesAllQuestions"
      @changeDataSeted="changeDataSeted"
    ></Questions>
    <transition name="ques">
      <NextQuestionButton
        v-if="showButton"
        @goToNextQuestion="goToNextQuestion"
      ></NextQuestionButton>
    </transition>
    <v-dialog v-model="pendigAlert" max-width="600px">
      <PendingAlert
        :showDescription="true"
        :pendingMessage="questionData.questions[0].options[1]"
        @exitAlert="exitAlert"
      ></PendingAlert>
    </v-dialog>
  </v-card-text>
</template>

<script>
import Questions from '@/components/savings/blocks/Questions'
import NextQuestionButton from '@/components/savings/blocks/NextQuestionButton'
import PendingAlert from '@/components/savings/blocks/PendingAlert'
import { mapGetters } from 'vuex'
import { answerData } from '@/mixins/answerData'

export default {
  name: 'SQuesThirtySix',
  components: {
    Questions,
    NextQuestionButton,
    PendingAlert
  },
  props: {
    questionData: Object
  },
  data () {
    return {
      showButton: false,
      dataSeted: false,
      pendigAlert: false,
      decisionQuestionFirstValue: '',
      historyFirstValue: [],
      historyScrap: [],
      deleteData: [],
      sq_data: [
        {
          question_id: this.questionData.questions[0].id,
          ponderation: '',
          updated_at: ''
        }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'getOperationNumber',
      'scoringData',
      'isBrowsingInScoringHistory',
      'continueScoring',
      'getIndexOfCurrentSQ',
      'getHistory',
      'SQNameByNumber',
      'sq_number'
    ])
  },
  methods: {
    setData () {
      if (this.scoringData.sq_thirtySixData !== null) {
        this.showButton = true
        this.$emit('handleProgress', 85)
        this.$refs.sq36q0.setAnswer(
          this.scoringData.sq_thirtySixData[0].ponderation
        )
        this.sq_data[0].ponderation = this.scoringData.sq_thirtySixData[0].ponderation
        this.sq_data[0].updated_at = this.scoringData.sq_thirtySixData[0].updated_at
        if (this.continueScoring) {
          this.sq_data[0].id = this.scoringData.sq_thirtySixData[0].id
        }
        if (this.isBrowsingInScoringHistory) {
          this.decisionQuestionFirstValue = this.scoringData.sq_thirtySixData[0].ponderation
          this.historyFirstValue = this.getHistory
        }
      }
    },
    sellerTellsClientAnswerYesAllQuestions (value) {
      this.dataFromQuestions(value, 0)
    },
    exitAlert () {
      this.pendigAlert = false
    },
    goToNextQuestion () {
      if (this.isBrowsingInScoringHistory) {
        this.$emit('reduceDecreasing', 1)
        if (this.decisionQuestionFirstValue !== this.sq_data[0].ponderation) {
          this.$emit('reduceDecreasing', 0)
          this.handleIfEditDecisionQues(0, this.decisionQuestionFirstValue)
          this.historyScrap = this.historyFirstValue.splice(
            this.getIndexOfCurrentSQ + 1
          )
          this.historyScrap.forEach(e => {
            if (e !== 2 && e !== 32 && e !== 41) {
              if (this.scoringData[this.SQNameByNumber[e]] !== null) {
                if (
                  this.scoringData[this.SQNameByNumber[e]][0].id === undefined
                ) {
                  this.$store.dispatch('handleResetScoringData', e)
                } else {
                  this.scoringData[this.SQNameByNumber[e]].forEach(q => {
                    this.deleteData.push(q.id)
                  })
                  this.$store.dispatch('handleResetScoringData', e)
                }
              }
            }
          })
          this.$emit('deleteQuestion', this.deleteData)
          this.$store.dispatch('handleHistoryData', this.historyFirstValue)
          this.$store.dispatch('handleIfIsBrowsingInScoringHistory', false)
          this.$store.dispatch('handleHistoryIndexOfCurrentSQ', null)
        }
      }
      this.$store.dispatch('handleScoringData', this.sq_data)
      this.$emit('handleProgress', 87)
      if (
        this.sq_data[0].ponderation ===
        this.questionData.questions[0].options[0]
      ) {
        if (this.isBrowsingInScoringHistory) {
          this.$store.dispatch('handleOnlyCurrentSQ', 37)
        } else {
          this.$store.dispatch('handleCurrentSQ', 37)
        }
      } else {
        // this.pendigAlert = true
        if (this.isBrowsingInScoringHistory) {
          this.$store.dispatch('handleOnlyCurrentSQ', 54)
        } else {
          this.$store.dispatch('handleCurrentSQ', 54)
        }
      }
      this.$emit('checkIfHistoryModeContinues')
    }
  },
  mixins: [answerData],
  mounted () {
    this.setData()
  }
}
</script>

<style lang="scss" scoped>
.ques-enter-active {
  transition: opacity 0.5s;
}
.ques-enter {
  opacity: 0;
}
</style>
