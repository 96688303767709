import axios from './instance'

const configToken = (token) => {
  return {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
}

export const getAllEvaluations = async (token) => {
  try {
    const res = await axios.get('/api/expertises', configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}

export const getAllEvaluationsParts = async (token) => {
  try {
    const res = await axios.get('/api/evaluations-types', configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}

export const createEvaluation = async (token, evaluation) => {
  const config = configToken(token)
  config.headers['Content-Type'] = 'multipart/form-data'
  try {
    const res = await axios.post('/api/expertises', evaluation, config)
    return res
  } catch (err) {
    throw err.response
  }
}

export const updateEvaluation = async (token, evaluation, id) => {
  const config = configToken(token)
  config.headers['Content-Type'] = 'multipart/form-data'
  try {
    const res = await axios.post(`/api/expertises/${id}?_method=PUT`, evaluation, config)
    return res
  } catch (err) {
    throw err.response
  }
}
