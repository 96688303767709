<template>
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.6668 9.375V19.7917H8.3335V9.375H16.6668ZM15.1043 3.125H9.896L8.85433 4.16667H5.2085V6.25H19.7918V4.16667H16.146L15.1043 3.125ZM18.7502 7.29167H6.25016V19.7917C6.25016 20.9375 7.18766 21.875 8.3335 21.875H16.6668C17.8127 21.875 18.7502 20.9375 18.7502 19.7917V7.29167Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'TrashCan'
}
</script>
