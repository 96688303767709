<template>
  <v-card width="100%">
    <v-card-title>
      <h2
        class="mt-2 mb-2 ml-3"
        style="font-size: 22px;letter-spacing: 0.01em;font-family: NouvelR;"
        >{{ itemSelected.name }}</h2>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-container>
        <v-row class="mt-5">
          <v-col cols="6">
            <v-select
              :items="qualifications"
              return-object
              dense
              label="Elegir clasificación"
              v-model="localQualification"
            >
            </v-select>
          </v-col>
          <v-col cols="6">
            <v-text-field
              dense
              prefix="$"
              label="Gasto"
              placeholder="Gasto"
              v-model="expense"
              @focus="resetExpenseFilter"
              @change="setExpenseFilter"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-textarea
              dense
              label="Observaciones"
              rows="2"
              v-model="observations"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-file-input
              dense
              chips
              counter
              multiple
              show-size
              small-chips
              truncate-length="15"
              label="Subir imágenes"
              prepend-icon="$camera"
              v-model="localImg"
            ></v-file-input>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-checkbox
              label="Se necesita evaluar nuevamente este ítem en el futuro."
              v-model="recheck">
            </v-checkbox>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        @click="cancelEvaluation"
        elevation="0"
        class="mr-5"
        text
        color="#9D9D9D">
        <span
          style="font-size: 12px;letter-spacing: 0.01em;color: #9D9D9D;font-weight: normal;font-family: NouvelR;"
          >Cancelar</span>
      </v-btn>
      <v-btn
        @click="confirmEvaluation"
        class="mr-4 mt-2 mb-3"
        width="129"
        height="44"
        elevation="0"
        color="rgba(245, 245, 247, 0.5)"
        style="border: 1px solid #000000;">
        <span
          style="font-size: 12px;letter-spacing: 0.01em;font-weight: normal;font-family: NouvelR;"
          >GUARDAR</span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'EditEvaluationModal',
  props: {
    itemSelected: Object
  },
  data () {
    return {
      qualifications: [
        {
          text: 'Bueno',
          value: 100,
          stars: 3
        },
        {
          text: 'Regular',
          value: 66,
          stars: 2
        },
        {
          text: 'Malo',
          value: 33,
          stars: 1
        }
      ],
      recheck: false,
      localImg: [],
      observations: '',
      localQualification: {},
      expense: ''
    }
  },
  methods: {
    confirmEvaluation () {
      this.$emit('confirmEvaluation', this.localQualification, this.localImg, this.expense, this.observations, this.recheck)
      this.localImg = []
    },
    cancelEvaluation () {
      this.$emit('cancelEvaluation')
    },
    setLocalModel () {
      this.localQualification = this.itemSelected.qualification
      this.observations = this.itemSelected.observations
      this.expense = this.itemSelected.expense
      this.recheck = this.itemSelected.recheck
    },
    resetExpenseFilter () {
      this.expense = this.$options.filters.number(this.expense)
    },
    setExpenseFilter () {
      this.expense = this.$options.filters.magnitude(this.expense)
    }
  },
  mounted () {
    this.setLocalModel()
  }
}
</script>
